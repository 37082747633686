const NoFeedComponent = (
  <div className="mt-32 flex flex-col justify-center">
    <span className="text-gray800 self-center text-sm">
      No notifications yet
    </span>
    <p className="text-gray500 mx-14 text-center text-sm">
      You&apos;ll get important updates here.
    </p>
  </div>
);

export default NoFeedComponent;
