import React from 'react';
import AddAuditForm from 'components/Pages/AddAuditForm';

const AddAudit = () => (
  <>
    <AddAuditForm title="Add New Audit" />
  </>
);

export default AddAudit;
