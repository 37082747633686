import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { noop } from 'lodash';

import Table from 'refactored/components/Common/Table';

import { merchantsDetailsOffersColumns } from 'refactored/utils/merchants/merchantsDetailsColumns';
import { selectUserAttributesState } from 'state/selectors/auth';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';

import { IOffer } from '@kardfinancial/models';
import type { UserAttributesResponse } from 'types/responses';

type OffersTabType = {
  priorityOffers?: IOffer[];
  isEditing?: boolean;
  setPriorityOffers?: Function;
};

const OffersTab = ({
  priorityOffers = [],
  isEditing = false,
  setPriorityOffers,
}: OffersTabType) => {
  const { roles: userRoles, isAdmin }: UserAttributesResponse = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const columns = useMemo(() => {
    const totalOffers = priorityOffers?.length || 0;

    const changeOrder = (index: number, direction: number) => {
      const newPriorityOffers = [...priorityOffers];
      const currentOffer = newPriorityOffers[index];
      const nextOffer = newPriorityOffers[index + direction];

      newPriorityOffers[index] = nextOffer;
      newPriorityOffers[index + direction] = currentOffer;

      setPriorityOffers?.(newPriorityOffers);
    };

    return getAllowedColumns(
      merchantsDetailsOffersColumns({
        isEditing,
        changeOrder,
        totalOffers,
        isAdmin,
      }),
      userRoles,
    );
  }, [isEditing, priorityOffers, setPriorityOffers, isAdmin, userRoles]);

  return (
    <Table
      query={{}}
      updateQuery={noop}
      data={priorityOffers}
      columns={columns}
      id="offers"
    />
  );
};

export default OffersTab;
