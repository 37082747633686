import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import LayoutGap from 'components/Common/LayoutGap';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  IconPosition as InputIconPosition,
  Color as InputColor,
} from 'components/Common/Input';
import Button from 'components/Common/Button';

import RefreshIcon from 'assets/icons/arrow-refresh.svg?react';
import classes from './OfferEdit.module.scss';

type CommissionValueFieldProps = {
  currentValue?: { commissionValue: number };
  isEditing?: boolean;
  editableTextColor?: string;
  commissionTypeIcon?: string;
};

const CommissionValueField = ({
  currentValue,
  isEditing,
  editableTextColor = InputColor.Gray,
  commissionTypeIcon,
}: CommissionValueFieldProps) => {
  const { setValue, watch, trigger } = useFormContext();
  const commissionValue = watch('commissionValue');

  useEffect(() => {
    trigger(['issuerCommissionsMap', 'userCommissionsMap']);
  }, [commissionValue, trigger]);

  const isChangedValue =
    commissionValue !== currentValue?.commissionValue &&
    currentValue?.commissionValue !== undefined;

  return (
    <LayoutGap direction="column" size="small">
      <FormControl
        name="commissionValue"
        validations={{
          valueAsNumber: true,
        }}
        className="mb-[0]"
        render={(props) => (
          <Input
            label="Commission Value"
            labelClassName={classes.labelWidth}
            value={currentValue?.commissionValue}
            type={InputType.Number}
            readOnly={!isEditing}
            color={editableTextColor}
            step=".01"
            icon={commissionTypeIcon}
            iconPosition={InputIconPosition.Right}
            width="w-[15rem]"
            {...props}
          />
        )}
      />
      {isChangedValue && (
        <Button
          className="self-start"
          variant="text"
          size="small"
          icon={<RefreshIcon />}
          disabled={!isEditing}
          onClick={() =>
            setValue('commissionValue', currentValue.commissionValue)
          }
        >
          Revert to previous value
        </Button>
      )}
    </LayoutGap>
  );
};

export default CommissionValueField;
