import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { Auth } from 'aws-amplify';

const Logout = () => {
  const [loggedOut, setLoggedOut] = useState(false);
  useEffect(() => {
    async function logout() {
      try {
        await Auth.currentAuthenticatedUser();
        await Auth.signOut();
        datadogRum.clearUser();
      } catch {
        setLoggedOut(true);
      } finally {
        setLoggedOut(true);
      }
    }

    logout();
  }, []);

  return (
    <>
      <p>Signing out...</p>
      {loggedOut && <Navigate to="login" replace />}
    </>
  );
};

export default Logout;
