import {
  MINUTES_PER_HOUR,
  MS_PER_SECOND,
  SECONDS_PER_MINUTE,
} from 'constants/timeUnits.constant';

/**
 * Get the difference in time between now and some future deadline date, in various units.
 * @param deadline some deadline date. This date must be in the future.
 * @returns the difference in time in total milliseconds, and minutes/seconds as mm:ss; rounded down.
 */
const getTimeRemaining = (deadline) => {
  const total = Date.parse(deadline) - Date.parse(new Date());
  const seconds = Math.floor((total / MS_PER_SECOND) % SECONDS_PER_MINUTE);
  const minutes = Math.floor(
    (total / MS_PER_SECOND / SECONDS_PER_MINUTE) % MINUTES_PER_HOUR,
  );
  return {
    total,
    minutes,
    seconds,
  };
};

export default getTimeRemaining;
