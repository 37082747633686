/* modules */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Spinner } from 'flowbite-react';
import { noop } from 'lodash';

import { selectUserAttributesState } from 'state/selectors/auth';

import { selectCreateOfferState } from 'state/selectors/offers';
import { exportData } from 'state/actions/exportData';
import selectExportDataState from 'state/selectors/exportData';
import selectFeatureFlagsState from 'state/selectors/featureFlags';

/* custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import useIssuers from 'refactored/hooks/useIssuers';

/* custom components */
import SearchBy from 'refactored/components/Common/SearchBy';
import Table from 'refactored/components/Common/Table';
import Pagination from 'refactored/components/Common/Pagination2';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Path from 'enums/path.enum';
import SelectDropdown from 'refactored/components/Common/SelectDropdown';
import MultiSelectDropdown from 'refactored/components/Common/MultiSelectDropdown';
import SelectNumberRange from 'refactored/components/Common/SelectNumberRange';
import SelectDateRange from 'refactored/components/Common/SelectDateRange';
import PageHeader from 'components/Common/PageHeader';
import Button from 'components/Common/Button';
import LayoutGap from 'components/Common/LayoutGap';
import Form from 'components/Common/Form';
import FormControlSelect from 'components/Common/FormControlSelect';

/* enums */
import StatusType from 'enums/status/statusValue.enum'; // should this be statusType.enum ?
import ModelType from 'enums/export/modelType.enum';

/* utils */
import makeColumns from 'refactored/utils/offers/makeColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import getIdToken from 'utils/getIdToken';
import getParsedFilters from 'utils/getParsedFilters';
import getTotalCommission from 'utils/offers/getTotalCommission';

/* assets */
import AddIcon from 'assets/icons/plus.svg?react';
import ExportIcon from 'assets/icons/download.svg?react';

import useMerchantNetworks from 'refactored/hooks/useMerchantNetworks';
import useOffers from 'refactored/hooks/useOffers';
import { Item, Offer } from './Types';

/* main component definition  */
const Offers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);
  const { IS_1917_REWARDS_MAINTANENCE, REWARDS_2653_UPCOMING_OFFERS_FLAG } =
    flags;

  /* internal state */
  const [data, setData] = useState([]);

  /* query framework */
  const [query, updateQuery] = useQuery(
    {
      pagination: { page: 0, limit: 100 },
      sort: { direction: -1, columnName: 'createdDate' },
    },
    {
      filters: !merchantId
        ? {
            status: {
              selected: REWARDS_2653_UPCOMING_OFFERS_FLAG
                ? [StatusType.Active, StatusType.Upcoming]
                : [StatusType.Active],
              type: 'string',
            },
          }
        : { merchantId: { selected: [merchantId], type: 'string' } },
    },
  );

  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const isReporter = userRoles?.includes('reporter.issuer');

  const { success: successCreate } = useSelector(
    selectCreateOfferState,
    shallowEqual,
  );

  const { loading: exportInProgress } = useSelector(
    selectExportDataState,
    shallowEqual,
  );

  const [issuers, loadingIssuer] = useIssuers({ shouldFetch: isAdmin });

  const [offersData, loadingOffers, errorLoadingOffers] = useOffers(
    queryToSearchParams(query).toString(),
  );

  const [merchantNetworks] = useMerchantNetworks(isAdmin);

  const merchantNetworkOptions = merchantNetworks.map((item: Item) => ({
    label: item?.name,
    value: item?.name,
  }));

  const [selectedIssuer, setSelectedIssuer] = useState<string | null>(null);

  /* const */

  const noDataToShow = data.length === 0;

  const activeOfferById =
    !!query.filters?.offerId &&
    query.filters.offerId.selected[0] !== '' &&
    data.length === 1 &&
    data[0].status === StatusType.Active;

  const statusFilterValue = query.filters?.status?.selected;

  // If status filters include active, or no status filters are added (ensures that active offers are returned in the table)
  const activeOffersShown =
    statusFilterValue?.includes(StatusType.Active) ||
    statusFilterValue?.includes(StatusType.Upcoming) ||
    !statusFilterValue ||
    statusFilterValue?.length === 0;

  const disableExportButton =
    !(activeOffersShown || activeOfferById) ||
    loadingOffers ||
    exportInProgress ||
    noDataToShow;

  /* methods */
  const onAddOfferHandler = () => navigate(`${Path.Offers}/create`);

  const getExportOffersTooltip = () => {
    if (loadingOffers) {
      return '';
    }

    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (noDataToShow) {
      return 'There is no data to export';
    }

    if (!(activeOffersShown || activeOfferById)) {
      if (!activeOffersShown) {
        return 'There are no active offers to export';
      }

      if (!activeOfferById) {
        return 'Cannot export inactive/closed offers';
      }
    }

    return '';
  };

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();
    const filters = getParsedFilters(
      `?${queryToSearchParams(query).toString()}`,
    );

    if (activeOfferById) {
      const [_id] = query.filters.offerId.selected;
      filters._id = _id;
    }

    const exportInfo = {
      idToken,
      filters,
      model: ModelType.Offer,
    };

    dispatch(exportData(exportInfo));
  };

  const revampedMoreInfoHandler = useCallback(
    (offer: Offer) => {
      if (isAdmin) {
        return window.open(
          `${Path.Offers}/edit/${offer.offerId}`,
          '_blank',
          'noreferrer',
        );
      }
      return window.open(
        `${Path.Offers}/info/${offer.offerId}`,
        '_blank',
        'noreferrer',
      );
    },
    [isAdmin],
  );

  /* table columns */
  const columns = useMemo(
    () =>
      getAllowedColumns(
        makeColumns({
          moreInfoHandler: revampedMoreInfoHandler,
        }),
        userRoles,
      ),
    [merchantNetworks, userRoles, revampedMoreInfoHandler],
  );

  /* transform offers data */
  useEffect(() => {
    if (!offersData) {
      return;
    }
    const transformedData = offersData.results.map((offer) => ({
      ...offer,
      offerId: offer._id,
      merchantId: offer.merchant?._id,
      merchantName: offer.merchant?.name,
      totalCommission: getTotalCommission(
        offer.commissionType,
        offer.totalCommission,
      ),
      commissionValue: getTotalCommission(
        offer.commissionType,
        offer.commissionValue,
      ),
    }));

    setData(transformedData);
  }, [offersData]);

  useEffect(() => {
    if (selectedIssuer) {
      updateQuery({
        filters: {
          ...query.filters,
          issuer: { selected: [selectedIssuer], type: 'string' },
        },
      });
    }
  }, [query.filters, selectedIssuer, updateQuery]);

  const issuersOptions = [
    ...issuers.map(({ issuerName }: { issuerName: string }) => ({
      label: issuerName,
      value: issuerName,
    })),
  ];

  /* render */
  return (
    <>
      {errorLoadingOffers && (
        <Toast
          id="fetch-offers"
          text="Error fetching offers"
          type={ToastType.Error}
        />
      )}
      {successCreate && (
        <Toast
          id="create-offer"
          text="Successfully created offer"
          type={ToastType.Success}
        />
      )}
      {IS_1917_REWARDS_MAINTANENCE && (
        <Toast
          id="rewards-maintainence-flag"
          text="REWARDS MAINTANENCE ONGOING. RESTRICTING ACCESS TO OFFER EDIT/CREATE FUNCTIONALITY"
          type={ToastType.Error}
        />
      )}
      <LayoutGap direction="column">
        <LayoutGap direction="column" className="m-[2.5rem] mb-0">
          <PageHeader title="Offers" className="mb-4">
            {isAdmin && (
              <div className="flex w-full items-center justify-between">
                <Form onSubmit={noop}>
                  <FormControlSelect
                    name="issuer"
                    onChangeManual={setSelectedIssuer}
                    placeholder={loadingIssuer ? 'Loading...' : 'Select Issuer'}
                    options={issuersOptions}
                    className="!z-50 !mb-0"
                    controlClassName="!w-[14.5rem]"
                  />
                </Form>
                <Button
                  data-cy="create-offer-button"
                  type="submit"
                  onClick={onAddOfferHandler}
                  icon={<AddIcon />}
                  disabled={IS_1917_REWARDS_MAINTANENCE}
                  className="h-9"
                >
                  Create offer
                </Button>
              </div>
            )}

            {!isAdmin && (
              <span
                data-tooltip-content={getExportOffersTooltip()}
                data-tooltip-id="exportOffers"
              >
                <Button
                  icon={<ExportIcon />}
                  disabled={disableExportButton}
                  onClick={onExportDataHandler}
                >
                  Export view as CSV
                </Button>
              </span>
            )}
          </PageHeader>

          <SearchBy
            field="name"
            idField="offerId"
            placeholder="Search by Offer Name or ID"
            query={query}
            updateQuery={updateQuery}
            className="h-[37px] !w-[24.2rem]"
            showCancelIcon
          />
          <LayoutGap className="flex-wrap">
            <SelectDropdown
              query={query}
              fieldName="offerType"
              options={[
                { label: 'Instore', value: 'INSTORE' },
                { label: 'Online', value: 'ONLINE' },
              ]}
              updateQuery={updateQuery}
              label="Offer type"
            />

            {isAdmin && merchantNetworkOptions.length > 0 && (
              <MultiSelectDropdown
                query={query}
                fieldName="merchantNetwork"
                options={merchantNetworkOptions}
                defaultSelection={merchantNetworkOptions.map(
                  (option) => option.value,
                )}
                updateQuery={updateQuery}
                label="Merchant Network"
                searchable
              />
            )}

            <SelectDateRange
              query={query}
              fieldName="startDate"
              updateQuery={updateQuery}
              label="Start Date"
            />

            <SelectDateRange
              query={query}
              fieldName="expirationDate"
              updateQuery={updateQuery}
              label="Expiration Date"
            />

            {REWARDS_2653_UPCOMING_OFFERS_FLAG ? (
              <MultiSelectDropdown
                query={query}
                fieldName="status"
                options={[
                  { label: 'Active', value: 'ACTIVE' },
                  { label: 'Inactive', value: 'INACTIVE' },
                  { label: 'Closed', value: 'CLOSED' },
                  { label: 'Upcoming', value: 'UPCOMING' },
                ]}
                updateQuery={updateQuery}
                label="Status"
              />
            ) : (
              <MultiSelectDropdown
                query={query}
                fieldName="status"
                options={[
                  { label: 'Active', value: 'ACTIVE' },
                  { label: 'Inactive', value: 'INACTIVE' },
                  { label: 'Closed', value: 'CLOSED' },
                ]}
                updateQuery={updateQuery}
                label="Status"
              />
            )}

            <SelectDropdown
              query={query}
              fieldName="commissionType"
              options={[
                { label: 'Flat', value: 'FLAT' },
                { label: 'Percent', value: 'PERCENT' },
              ]}
              updateQuery={updateQuery}
              label="Commission Type"
            />

            {isAdmin && (
              <SelectNumberRange
                query={query}
                fieldName="commissionValue"
                updateQuery={updateQuery}
                label="Commission Value"
              />
            )}

            {isReporter && (
              <SelectNumberRange
                query={query}
                fieldName="totalCommission"
                updateQuery={updateQuery}
                label="Total Commission"
              />
            )}

            <SelectDropdown
              query={query}
              fieldName="isTargeted"
              options={[
                { label: 'Targeted Offers', value: 'true' },
                { label: 'No Targeted', value: 'false' },
              ]}
              updateQuery={updateQuery}
              label="Targeted Offers"
            />
          </LayoutGap>
        </LayoutGap>

        {loadingOffers ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner size="lg" color="gray" />
          </div>
        ) : (
          <>
            <div className="flex-1 overflow-auto">
              <Table
                id="table-offers"
                columns={columns}
                data={data}
                query={query}
                updateQuery={updateQuery}
              />
            </div>

            <div className="sticky bottom-0 bg-white">
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={offersData?.countTotal || 0}
              />
            </div>
          </>
        )}
      </LayoutGap>

      <ReactTooltip id="exportOffers" />
    </>
  );
};

export default Offers;
