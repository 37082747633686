/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  configureSubdomainSuccess,
  configureSubdomainFail,
  signInInit,
  signInSuccess,
  signInFail,
  fetchAuthConfigSuccess,
  fetchAuthConfigFail,
  passwordChangeRequired,
  passwordChangeInit,
  passwordChangeSuccess,
  passwordChangeFail,
  resumeSessionSuccess,
  resumeSessionFail,
  forgotPasswordInit,
  forgotPasswordSuccess,
  forgotPasswordFail,
  forgotPasswordConfirmationInit,
  forgotPasswordConfirmationSuccess,
  forgotPasswordConfirmationFail,
  clearForgotPasswordData,
  logoutInit,
  logoutSuccess,
  logoutFail,
  clearResendConfirmationProp,
  resendConfirmationCodeInit,
  resendConfirmationCodeSuccess,
  resendConfirmationCodeFail,
  resumeSessionInit,
} from 'state/actionCreators/auth';

export const initialState = {
  resumeSessionError: null,
  signInLoading: false,
  signInSuccess: false,
  signInError: null,
  subdomain: '',
  subdomainAuthConfig: null,
  passwordChangeRequired: false,
  passwordChangeLoading: false,
  passwordChangeSuccess: false,
  passwordChangeError: null,
  forgotPasswordLoading: false,
  forgotPasswordSuccess: false,
  forgotPasswordError: null,
  forgotPasswordConfirmationLoading: false,
  forgotPasswordConfirmationSuccess: false,
  forgotPasswordConfirmationError: null,
  logoutLoading: false,
  logoutSuccess: false,
  logoutError: null,
  username: null,
  oldPassword: null,
  user: null,
  resendConfirmationCodeLoading: false,
  resendConfirmationCodeSuccess: false,
  resendConfirmationCodeError: null,
};

const persistConfig = {
  key: 'auth',
  storage,
  blacklist: [
    'signInLoading',
    'signInSuccess',
    'signInError',
    'resumeSessionError',
    'passwordChangeRequired',
    'passwordChangeLoading',
    'passwordChangeSuccess',
    'passwordChangeError',
    'username',
    'oldPassword',
    'forgotPasswordLoading',
    'forgotPasswordSuccess',
    'forgotPasswordError',
    'forgotPasswordConfirmationLoading',
    'forgotPasswordConfirmationSuccess',
    'forgotPasswordConfirmationError',
    'logoutLoading',
    'logoutSuccess',
    'logoutError',
    'resendConfirmationCodeLoading',
    'resendConfirmationCodeSuccess',
    'resendConfirmationCodeError',
  ],
};

const authReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(configureSubdomainSuccess, (state, action) => {
      state.subdomain = action.payload;
    });
    builder.addCase(configureSubdomainFail, (state) => {
      state.subdomain = '';
    });

    builder.addCase(signInInit, (state) => {
      state.signInLoading = true;
    });

    builder.addCase(signInSuccess, (state, action) => {
      const { user } = action.payload;
      state.signInLoading = false;
      state.signInError = null;
      state.signInSuccess = true;
      state.user = user;
    });

    builder.addCase(signInFail, (state, action) => {
      const { error } = action.payload;
      state.signInLoading = false;
      state.signInError = error;
      state.signInSuccess = false;
    });

    builder.addCase(fetchAuthConfigSuccess, (state, action) => {
      const { config } = action.payload;
      state.subdomainAuthConfig = config;
    });

    builder.addCase(fetchAuthConfigFail, (state) => {
      state.subdomainAuthConfig = null;
    });

    builder.addCase(passwordChangeRequired, (state, action) => {
      const { username, oldPassword } = action.payload;
      state.passwordChangeRequired = true;
      state.signInLoading = false;
      state.signInError = null;
      state.username = username;
      state.oldPassword = oldPassword;
    });

    builder.addCase(passwordChangeInit, (state) => {
      state.passwordChangeLoading = true;
    });

    builder.addCase(passwordChangeSuccess, (state, action) => {
      const { user } = action.payload;
      state.passwordChangeLoading = false;
      state.passwordChangeError = null;
      state.passwordChangeSuccess = true;
      state.passwordChangeRequired = false;
      state.user = { ...user };
    });

    builder.addCase(passwordChangeFail, (state, action) => {
      const { error } = action.payload;
      state.passwordChangeLoading = false;
      state.passwordChangeError = error;
      state.passwordChangeSuccess = false;
    });

    builder.addCase(forgotPasswordInit, (state) => {
      state.forgotPasswordLoading = true;
    });

    builder.addCase(forgotPasswordSuccess, (state, action) => {
      const { username } = action.payload;
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = true;
      state.forgotPasswordError = null;
      state.username = username;
    });

    builder.addCase(forgotPasswordFail, (state, action) => {
      const { error } = action.payload;
      state.forgotPasswordLoading = false;
      state.forgotPasswordError = error;
      state.forgotPasswordSuccess = false;
    });

    builder.addCase(forgotPasswordConfirmationInit, (state) => {
      state.forgotPasswordConfirmationLoading = true;
    });

    builder.addCase(forgotPasswordConfirmationSuccess, (state) => {
      state.forgotPasswordConfirmationLoading = false;
      state.forgotPasswordConfirmationSuccess = true;
      state.forgotPasswordConfirmationError = null;
    });

    builder.addCase(forgotPasswordConfirmationFail, (state, action) => {
      const { error } = action.payload;
      state.forgotPasswordConfirmationLoading = false;
      state.forgotPasswordConfirmationError = error;
      state.forgotPasswordConfirmationSuccess = false;
    });

    builder.addCase(clearForgotPasswordData, (state) => {
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordError = null;
      state.forgotPasswordConfirmationLoading = false;
      state.forgotPasswordConfirmationSuccess = false;
      state.forgotPasswordConfirmationError = null;
    });

    builder.addCase(resumeSessionInit, (state) => {
      state.resumeSessionError = null;
    });

    builder.addCase(resumeSessionSuccess, (state, action) => {
      const { user } = action.payload;
      state.user = user;
    });

    builder.addCase(resumeSessionFail, (state, { payload }) => {
      const { error } = payload;
      state.user = null;
      state.resumeSessionError = error;
    });

    builder.addCase(logoutInit, (state) => {
      state.logoutLoading = true;
    });

    builder.addCase(logoutSuccess, (state) => {
      state.logoutLoading = false;
      state.logoutSuccess = true;
      state.logoutError = null;
      state.signInSuccess = false;
      state.user = null;
    });

    builder.addCase(logoutFail, (state, action) => {
      const { error } = action.payload;
      state.logoutLoading = false;
      state.logoutSuccess = false;
      state.logoutError = error;
    });

    builder.addCase(resendConfirmationCodeInit, (state) => {
      state.resendConfirmationCodeLoading = true;
    });

    builder.addCase(resendConfirmationCodeSuccess, (state) => {
      state.resendConfirmationCodeLoading = false;
      state.resendConfirmationCodeSuccess = true;
      state.resendConfirmationCodeError = null;
    });

    builder.addCase(resendConfirmationCodeFail, (state, action) => {
      const { error } = action.payload;
      state.resendConfirmationCodeLoading = false;
      state.resendConfirmationCodeSuccess = false;
      state.resendConfirmationCodeError = error;
    });

    builder.addCase(clearResendConfirmationProp, (state) => {
      state.resendConfirmationCodeLoading = false;
      state.resendConfirmationCodeSuccess = false;
      state.resendConfirmationCodeError = null;
    });
  }),
);

export default authReducer;
