import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import PlusIcon from 'assets/icons/plus.svg?react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/Dialog';
import Button from '@/components/Common/Button';
import Toast, { Type as ToastType } from '@/components/Common/Toast';
import useCreateSegment from '@/refactored/hooks/useSegment';
import SegmentDetailsForm from './SegmentDetailsForm';
import { SegmentDetailsFormValues } from '../schema';

const CreateSegment = ({ onSuccess }: { onSuccess: () => void }) => {
  const [createSegmentSuccess, setCreateSegmentSuccess] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [{ loading, error }, createSegment] = useCreateSegment();

  const handleSubmit: SubmitHandler<SegmentDetailsFormValues> = async (
    data,
  ) => {
    const newData = {
      ...data,
      rules: data.rules.map((rule) => {
        if (rule.ruleType === 'purchases') {
          const { dateRange, ...rest } = rule;
          return {
            ...rest,
            startDate: dateRange.from,
            endDate: dateRange.to,
          };
        }

        return rule;
      }),
    };
    try {
      // TODO: fix types for createSegment
      await createSegment({ data: newData });

      setCreateSegmentSuccess(true);
      setIsDialogOpen(false);
      onSuccess();
    } catch (err) {
      // no-dd-sa:typescript-best-practices/no-console
      console.error(err);
    }
  };

  return (
    <>
      <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
        <DialogTrigger asChild>
          <Button
            // Reset success state for new segment
            onClick={() => setCreateSegmentSuccess(false)}
            variant="primary"
            icon={<PlusIcon />}
          >
            Create segment
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New Segment</DialogTitle>
          </DialogHeader>
          <div className="min-h-[270px]">
            <SegmentDetailsForm
              onSubmit={handleSubmit}
              isSubmitting={loading}
              submitError={error}
            />
          </div>
        </DialogContent>
      </Dialog>
      {createSegmentSuccess && (
        <Toast
          id="create-segment-success"
          text="Created new segment"
          type={ToastType.Success}
        />
      )}
    </>
  );
};
export default CreateSegment;
