/**
 * Fetches the configuration for a given subdomain.
 *
 * @async
 * @param {string} subdomain - The subdomain for which to fetch the configuration.
 * @returns {Promise<SubdomainAuthConfig>} The configuration for the subdomain. Returns null if an error occurs or if the subdomain is not provided.
 * @throws {Error} Will throw an error if the subdomain is not provided.
 */
const configureSubdomain = async (subdomainOverride) => {
  try {
    const subdomain =
      subdomainOverride || window.location.hostname.split('.')[0];
    return subdomain;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default configureSubdomain;
