import { ChangeEvent, useCallback, useState } from 'react';
import { Popover, Tab } from '@headlessui/react';
import { DateRangePicker } from 'react-dates';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';

import arrowDownIcon from 'assets/icons/arrow-down.svg';
import { DateFilterComponentProps, MomentTypes } from '../Types';

import classes from './DateFilterComponent.module.scss';

const HOURS: { label: string; value: MomentTypes } = {
  label: 'Hours',
  value: 'hours',
};
const DAYS: { label: string; value: MomentTypes } = {
  label: 'Days',
  value: 'days',
};

const periodTypeOptions = [HOURS, DAYS];

const formatPeriodLabel = (amount: number, type: string): string => {
  const singularType = type.slice(0, -1);
  return `Date: Last ${amount} ${singularType}${amount > 1 ? 's' : ''}`;
};

const DateFilterComponent = ({
  query,
  updateQuery,
}: DateFilterComponentProps) => {
  const [selectedPeriodAmount, setSelectedPeriodAmount] = useState(3);
  const [selectedPeriodType, setSelectedPeriodType] = useState<MomentTypes>(
    DAYS.value,
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    null,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [displayLabel, setDisplayLabel] = useState(
    formatPeriodLabel(selectedPeriodAmount, selectedPeriodType),
  );
  // Contains the current focused input for the date range picker. Could be startDate or endDate.
  const [focusedInput, setFocusedInput] = useState<string | null>(null);

  const applyPeriod = () => {
    const newQuery = query;
    const startDate = dayjs().subtract(
      selectedPeriodAmount,
      selectedPeriodType,
    );
    const endDate = dayjs();

    newQuery.filters = {
      ...newQuery.filters,
      transactionTimeStamp: {
        type: 'date',
        start: startDate,
        end: endDate,
      },
    };

    setDisplayLabel(
      formatPeriodLabel(selectedPeriodAmount, selectedPeriodType),
    );
    updateQuery(newQuery);
  };

  const applyRange = () => {
    if (selectedEndDate === null || selectedStartDate === null) return;

    const newQuery = query;

    newQuery.filters = {
      ...newQuery.filters,
      transactionTimeStamp: {
        type: 'date',
        start: selectedStartDate,
        end: selectedEndDate,
      },
    };
    setDisplayLabel(
      `Date: ${selectedStartDate.format(
        'MM/DD/YYYY',
      )} - ${selectedEndDate.format('MM/DD/YYYY')}`,
    );
    updateQuery(newQuery);
  };

  const handleApply = () => {
    if (selectedTab === 0) {
      applyPeriod();
    }
    if (selectedTab === 1) {
      applyRange();
    }
  };

  const onChangeDatesHandler = ({
    startDate,
    endDate,
  }: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const onChangeTimePeriod = (e: ChangeEvent<HTMLInputElement>) => {
    const timePeriod = Number(e.target.value);

    // We need to allow empty values to allow the user delete the input
    if (!timePeriod) return setSelectedPeriodAmount(timePeriod);

    if (timePeriod > 0) {
      switch (selectedPeriodType) {
        case HOURS.value:
          if (timePeriod <= 24) setSelectedPeriodAmount(timePeriod);
          break;
        case DAYS.value:
          if (timePeriod <= 31) setSelectedPeriodAmount(timePeriod);
          break;
        default:
          break;
      }
    }

    return null;
  };

  const onChangePeriodType = (e: ChangeEvent<HTMLSelectElement>) => {
    const periodType = e.target.value as MomentTypes;

    switch (periodType) {
      case HOURS.value:
        if (Number(selectedPeriodAmount) > 24) setSelectedPeriodAmount(24);
        break;
      case DAYS.value:
        if (Number(selectedPeriodAmount) > 31) setSelectedPeriodAmount(31);
        break;
      default:
        break;
    }

    setSelectedPeriodType(periodType);
  };

  // If the input is empty, set the default value to 1
  const onBlurTimePeriod = () => {
    if (!selectedPeriodAmount) {
      setSelectedPeriodAmount(1);
    }
  };

  const isOutsideRange = (date: Dayjs): boolean => {
    const currentDate = dayjs();
    const lastYear = dayjs().subtract(365, DAYS.value);
    const startDatePlus31Days = selectedStartDate?.clone().add(31, DAYS.value);
    const endDateMinus31Days = selectedEndDate
      ?.clone()
      .subtract(31, DAYS.value);

    return (
      currentDate.isBefore(date) ||
      lastYear.isAfter(date) ||
      !!(selectedStartDate && date.isBefore(selectedStartDate)) ||
      !!(startDatePlus31Days && date.isAfter(startDatePlus31Days)) ||
      !!(selectedEndDate && date.isAfter(selectedEndDate)) ||
      !!(endDateMinus31Days && date.isBefore(endDateMinus31Days))
    );
  };

  // Set the default filter to the last 3 days
  const revertDateFilterToDefault = useCallback(() => {
    const newQuery = query;
    const startDate = dayjs().subtract(3, DAYS.value);
    const endDate = dayjs();

    newQuery.filters = {
      ...newQuery.filters,
      transactionTimeStamp: {
        type: 'date',
        start: startDate,
        end: endDate,
      },
    };

    setDisplayLabel(formatPeriodLabel(3, DAYS.value));
    setSelectedPeriodAmount(3);
    setSelectedPeriodType(DAYS.value);

    updateQuery(newQuery);
  }, [query, updateQuery]);

  const handleRevert = () => revertDateFilterToDefault();

  return (
    <Popover className="relative">
      <Popover.Button
        className={classes.button}
        onClick={() => setSelectedTab(0)}
      >
        <div>{displayLabel}</div>
        <img alt="arrow" className={classes.arrowIcon} src={arrowDownIcon} />
      </Popover.Button>
      <Popover.Panel className={classes.panelContainer}>
        <Tab.Group onChange={(e) => setSelectedTab(e)}>
          <Tab.List>
            <Tab className={classes.tabOption}>Filter by period</Tab>
            <Tab className={classes.tabOption}>Filter by dates</Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className={classes.content}>
              <div>Select the desired time period</div>
              <div className={classes.inputsContainer}>
                <input
                  type="text"
                  id="quantity"
                  name="quantity"
                  min="1"
                  pattern="[0-9]*"
                  value={selectedPeriodAmount}
                  className={classNames(classes.searchInput, 'w-16')}
                  onChange={onChangeTimePeriod}
                  onBlur={onBlurTimePeriod}
                />
                <select
                  onChange={onChangePeriodType}
                  value={selectedPeriodType}
                  className={classes.searchInput}
                >
                  {periodTypeOptions.map((option) => (
                    <option
                      key={`period-option-${option.label}`}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </Tab.Panel>
            <Tab.Panel className={classes.content}>
              <div className={classes.inputsContainer}>
                <DateRangePicker
                  startDate={selectedStartDate}
                  startDateId="start_date_id"
                  endDate={selectedEndDate}
                  endDateId="end_date_id"
                  onDatesChange={onChangeDatesHandler}
                  focusedInput={focusedInput}
                  onFocusChange={(newFocus: string) =>
                    setFocusedInput(newFocus)
                  }
                  noBorder
                  isOutsideRange={isOutsideRange}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <div className={classes.actions}>
          <Popover.Button
            type="button"
            className={classes.secondaryButton}
            onClick={handleRevert}
          >
            Revert filter
          </Popover.Button>
          <Popover.Button
            type="button"
            className={classes.actionButton}
            onClick={handleApply}
          >
            Apply filter
          </Popover.Button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default DateFilterComponent;
