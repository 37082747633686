import { FormProvider } from 'react-hook-form';
import Select from './inputs/Select';
import Input from './inputs/Input';
import DateRange from './inputs/DateRange';

export default {
  Provider: FormProvider,
  Select,
  Input,
  DateRange,
};
