import { useNavigate } from 'react-router-dom';
import OfferCreate from 'components/Pages/OfferCreate';
import Path from 'enums/path.enum';

const AddOffer = () => {
  const navigate = useNavigate();
  const onCancelHandler = () => {
    navigate(`${Path.Offers}`);
  };
  return (
    <div>
      <OfferCreate onCancel={onCancelHandler} />
    </div>
  );
};

export default AddOffer;
