import { createSelector } from '@reduxjs/toolkit';

const selectFlagsState = (state) => state.featureFlags;

const selectFeatureFlagsState = createSelector(
  selectFlagsState,
  ({ flags, error }) => ({ flags, error }),
);

export default selectFeatureFlagsState;
