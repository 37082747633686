import { useFlags } from 'flagsmith/react';
import { IFlagsmithFeature } from 'flagsmith/types';

const useFlag = (flag: string): IFlagsmithFeature => {
  const flags = useFlags([flag]);

  const flagValue = flags[flag];

  if (!flagValue) {
    console.error(`Flag not found: ${flag}\n\n`);
  }

  return flagValue || { enabled: false, value: undefined };
};

export default useFlag;
