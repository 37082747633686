export const fieldStyle = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
    width: '168px',
    fontSize: '14px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: '#6B7280',
  }),
};
export const sortStyle = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: '#6B7280',
  }),
};
