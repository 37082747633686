import FilterType from 'refactored/enums/query/filterType.enum';

const transformFilter = (columnName, filter) => {
  switch (filter.type) {
    case FilterType.String:
    case FilterType.Search:
      return filter.selected.map((option) => [columnName, option]);
    case FilterType.Date:
      return [
        filter.start ? [`${columnName}Start`, filter.start.toISOString()] : [],
        filter.end ? [`${columnName}End`, filter.end?.toISOString()] : [],
        filter.start || filter.end ? [columnName, 'true'] : [],
      ];
    case FilterType.Number:
      return [
        filter.start || filter.start === 0
          ? [`${columnName}Start`, filter.start.toString()]
          : [],
        filter.end || filter.end === 0
          ? [`${columnName}End`, filter.end?.toString()]
          : [],
        [columnName, 'true'],
      ];
    default:
      throw new Error(`Unexpected filter type "${filter.type}"`);
  }
};

export default transformFilter;
