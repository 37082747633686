import { Type } from 'components/Common/Input';
import {
  locationStates,
  locationTypes,
  sourceTypes,
} from 'utils/locations/values';

import classes from './LocationForm.module.scss';

export const informationFields = [
  {
    name: 'locationId',
    renderProps: {
      label: 'Location ID*',
      type: Type.Text,
      placeholder: 'Location ID',
      labelClassName: classes.labelWidth,
      isEditable: false,
      disabled: true,
    },
  },
  {
    name: 'name',
    renderProps: {
      label: 'Name*',
      type: Type.Text,
      placeholder: 'Name',
      labelClassName: classes.labelWidth,
      isEditable: true,
    },
  },
  {
    name: 'merchantName',
    renderProps: {
      label: 'Merchant Name*',
      type: Type.Text,
      placeholder: 'Merchant Name',
      labelClassName: classes.labelWidth,
      isEditable: false,
    },
  },
];

export const addressFields = [
  {
    name: 'googleId',
    renderProps: {
      label: 'Google ID',
      type: Type.Text,
      placeholder: 'Google ID',
      labelClassName: classes.labelWidth,
    },
  },
  {
    name: 'source',
    label: 'Source*',
    placeholder: 'Source',
    options: sourceTypes,
    labelClassName: classes.labelWidth,
  },
  {
    name: 'locationType',
    label: 'Location Type*',
    placeholder: 'Location Type',
    options: locationTypes,
    labelClassName: classes.labelWidth,
  },
  {
    name: 'street',
    renderProps: {
      label: 'Street*',
      type: Type.Text,
      placeholder: 'Street',
      labelClassName: classes.labelWidth,
    },
  },
  {
    name: 'city',
    renderProps: {
      label: 'City*',
      type: Type.Text,
      placeholder: 'City',
      labelClassName: classes.labelWidth,
    },
  },
  {
    name: 'state',
    label: 'State*',
    placeholder: 'State/Region',
    options: locationStates,
    labelClassName: classes.labelWidth,
  },
  {
    name: 'zipCode',
    renderProps: {
      label: 'Zip Code*',
      type: Type.Text,
      placeholder: 'Zip Code',
      labelClassName: classes.labelWidth,
    },
  },
  {
    name: 'latitude',
    renderProps: {
      label: 'Latitude*',
      type: Type.Text,
      placeholder: 'Latitude',
      labelClassName: classes.labelWidth,
    },
  },
  {
    name: 'longitude',
    renderProps: {
      label: 'Longitude*',
      type: Type.Text,
      placeholder: 'Longitude',
      labelClassName: classes.labelWidth,
    },
  },
  {
    name: 'phone',
    renderProps: {
      label: 'Phone*',
      type: Type.Text,
      placeholder: 'Phone',
      labelClassName: classes.labelWidth,
    },
  },
];
