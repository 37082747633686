/* modules */
import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

/* redux */
import {
  clearAudienceData,
  clearAudiencesErrors,
  clearSelectedAudienceRule,
  createAudience,
  editAudience,
  isEditingAudience,
  saveAudienceInformation,
  saveSelectedAudienceRule,
} from 'state/actions/audiences';
import {
  selectCreateAudienceState,
  selectCurrentAudienceRule,
  selectEditAudienceState,
  selectNewAudienceState,
} from 'state/selectors/audiences';
import selectFeatureFlagsState from 'state/selectors/featureFlags';
import { fetchMerchantById } from 'state/actions/merchants';
import { selectFetchMerchantByIdState } from 'state/selectors/merchants';

/* custom components */
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import ButtonGroup from 'components/Common/ButtonGroup';
import Button from 'components/Common/Button';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import commonToast from 'components/Common/commonToast';

/* utils */
import { audienceType, behaviorRangeValues } from 'utils/audiences/values';
import getAudienceRuleData from 'utils/audiences/getAudienceRuleData';
import Status from 'enums/status/status.enum';
import {
  transformAudienceToPayload,
  transformPayloadToAudienceRule,
} from 'utils/audiences/mapCriteriaPayload';
import getInputFields from './inputFields';
import audienceInformationSchema from './AudienceInformation.schema';

/* styling */
import classes from './AudienceInformation.module.scss';

dayjs.extend(utc);

const calculateDateRange = (timescale, period) => {
  const today = dayjs().utc();
  const endDate = today.clone();
  const startDate = today.clone().subtract(timescale, period);
  return {
    gte: startDate.toISOString(),
    lte: endDate.toISOString(),
  };
};

const generateText = (behaviorValue, timescaleValue, period) =>
  `${behaviorValue} purchases in the Last ${timescaleValue} ${period}`;

const AudienceInformation = ({ audience, isCreating, onCancel, goBack }) => {
  const dispatch = useDispatch();

  const {
    error: creatingAudienceError,
    loading: creatingAudience,
    success: audienceCreatedSuccessfully,
  } = useSelector(selectCreateAudienceState, shallowEqual);

  const {
    error: editingAudienceError,
    loading: editingAudience,
    success: audienceEditedSuccessfuly,
  } = useSelector(selectEditAudienceState, shallowEqual);

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);
  const { IS_1917_REWARDS_MAINTANENCE } = flags;

  const newAudience = useSelector(selectNewAudienceState, shallowEqual);

  const { selectedRule, isEditing } = useSelector(
    selectCurrentAudienceRule,
    shallowEqual,
  );

  const { merchant: fetchedMerchant, loading: fetchingMerchant } = useSelector(
    selectFetchMerchantByIdState,
    shallowEqual,
  );

  useEffect(() => {
    if (audienceCreatedSuccessfully || audienceEditedSuccessfuly) {
      dispatch(clearAudienceData());
      dispatch(clearSelectedAudienceRule());
      dispatch(clearAudiencesErrors());
    }
  }, [audienceCreatedSuccessfully, audienceEditedSuccessfuly, dispatch]);

  useEffect(() => {
    if (audience?.merchantId && !isEditing) {
      dispatch(fetchMerchantById(audience.merchantId));
    }
  }, [audience, dispatch, isEditing]);

  const isDynamic = selectedRule?.refreshAudience ?? false;

  const onSubmitHandler = (values) => {
    if (isCreating) {
      const rawCriteria = {
        ...values,
        ...newAudience.rule,
        isDynamic,
      };

      const transformedAudience = transformAudienceToPayload(rawCriteria);

      dispatch(saveAudienceInformation(values));
      dispatch(createAudience(transformedAudience));
    }

    if (audience && isEditing) {
      let transformedAudience = {};
      if (flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        const rawCriteria = {
          ...values,
          ...selectedRule,
          isDynamic,
        };

        transformedAudience = transformAudienceToPayload(rawCriteria);
      }
      const {
        behaviorValue,
        timescaleValue,
        merchant,
        behaviorRange,
        period,
        refreshAudience,
        enrollNewUser,
      } = selectedRule;

      const { description, name, status } = values;

      const { _id, status: currentStatus } = audience;

      const transactionCount = {
        ...(behaviorRange === behaviorRangeValues.ExactlyEqualTo && {
          eq: Number(behaviorValue),
        }),
        ...(behaviorRange === behaviorRangeValues.LessThanOrEqualTo && {
          lte: Number(behaviorValue),
        }),
        ...(behaviorRange === behaviorRangeValues.GreaterThanOrEqualTo && {
          gte: Number(behaviorValue),
        }),
      };

      let rule = {};
      if (timescaleValue.length > 1) {
        rule = {
          [selectedRule.logicOperator]: timescaleValue.map(
            (timescale, index) => ({
              transactionCount: {
                [behaviorRange[behaviorRange[index]]]: behaviorValue[index],
              },
              authorizationDateRange: calculateDateRange(
                timescale,
                period[index],
              ),
            }),
          ),
        };
      }

      if (timescaleValue.length === 1) {
        rule = {
          transactionCount: {
            [behaviorRange[behaviorRange[0]]]: behaviorValue[0],
          },
          authorizationDateRange: calculateDateRange(
            timescaleValue[0],
            period[0],
          ),
        };
      }
      const getAudienceType = () => {
        if (refreshAudience !== null && refreshAudience !== undefined) {
          return refreshAudience ? audienceType.Dynamic : audienceType.Static;
        }

        return audience.type;
      };
      let updatedAudience = {};
      if (flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        updatedAudience = {
          _id,
          ...(transformedAudience.rule &&
            currentStatus !== Status.active && {
              rule: transformedAudience.rule,
            }),
          ...(name && { name }),
          ...(description && { description }),
          ...(refreshAudience &&
            currentStatus !== Status.active && { interval: 24 }),
          ...(currentStatus === Status.draft && {
            type: getAudienceType(),
            merchantId: merchant._id,
            ...(!refreshAudience && {
              enrollNewUser: enrollNewUser ?? audience.enrollNewUser,
            }),
          }),
          ...(['ACTIVE', 'INACTIVE'].includes(status) && { status }),
        };
      }

      if (!flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        updatedAudience = {
          _id,
          ...(name && { name }),
          ...(description && { description }),
          ...(refreshAudience && { interval: 24 }),
          ...(Object.keys(transactionCount).length > 0 && { transactionCount }),
          ...(currentStatus === Status.DRAFT && {
            rule,
            type: getAudienceType(),
            merchantId: merchant._id,
            ...(!refreshAudience && {
              enrollNewUser: enrollNewUser ?? audience.enrollNewUser,
            }),
          }),
          ...(['ACTIVE', 'INACTIVE'].includes(status) && { status }),
        };
      }
      dispatch(editAudience(_id, updatedAudience));
    }
  };

  const title = isCreating
    ? 'New Audience - Audience Information'
    : 'Audience Information';

  const disabledEdit =
    fetchingMerchant ||
    audience?.status === Status['in progress'] ||
    audience?.status === Status.inactive;

  const inputFields = getInputFields({
    audience,
    isEditing,
    isCreating,
    newAudience,
    isDynamic,
    disabledEdit,
  });

  const onClickCancelHandler = () => {
    dispatch(clearAudienceData());
    dispatch(clearSelectedAudienceRule());
    onCancel();
  };

  const onEditHandler = () => {
    const rawAudience = { ...audience, merchant: fetchedMerchant };
    let audienceRule = {};
    if (flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
      audienceRule = transformPayloadToAudienceRule(rawAudience);
    }
    if (!flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
      audienceRule = getAudienceRuleData(audience, fetchedMerchant);
    }

    dispatch(saveSelectedAudienceRule(audienceRule, 0));
    dispatch(isEditingAudience());
  };

  const generateRuleText = (
    behaviorValue = [],
    timescaleValue = [],
    period = [],
    logicOperator,
    merchantName = '-',
  ) => {
    const validator = (value) =>
      value !== undefined && value !== null ? value.toString() : '-';

    const firstText = `${validator(
      behaviorValue[0],
    )} purchases in the Last ${validator(timescaleValue[0])} ${validator(
      period[0],
    )}`;

    let secondText = '';

    if (
      logicOperator &&
      behaviorValue.length >= 2 &&
      timescaleValue.length >= 2 &&
      period.length >= 2
    ) {
      secondText = `${logicOperator.toUpperCase()} ${validator(
        behaviorValue[1],
      )} purchases in the Last ${validator(timescaleValue[1])} ${validator(
        period[1],
      )}`;
    }

    return `${firstText} ${secondText} in ${merchantName}`;
  };

  const ruleText = useMemo(() => {
    if (!isEditing && !isCreating) {
      if (flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        if (!audience) {
          return '';
        }
        const rawAudience = { ...audience, merchant: fetchedMerchant };
        const { behaviorValue, timescaleValue, logicOperator, period } =
          transformPayloadToAudienceRule(rawAudience);

        return generateRuleText(
          behaviorValue,
          timescaleValue,
          period,
          logicOperator,
          fetchedMerchant?.name,
        );
      }

      const { behaviorValue, timescaleValue } = getAudienceRuleData(
        audience,
        fetchedMerchant,
      );
      return `${generateText(
        behaviorValue ?? '-',
        timescaleValue ?? '-',
        'days',
      )} in ${fetchedMerchant?.name || '-'}`;
    }

    const { behaviorValue, timescaleValue, period, merchant, logicOperator } =
      selectedRule || {};

    if (flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
      return generateRuleText(
        behaviorValue,
        timescaleValue,
        period,
        logicOperator,
        merchant?.name,
      );
    }

    return `${generateText(behaviorValue, timescaleValue, period)} in ${
      merchant?.name
    } ${logicOperator}`;
  }, [flags, audience, fetchedMerchant, isCreating, isEditing, selectedRule]);

  const onClickRuleHandler = () => {
    let rule = { ...selectedRule };

    if (!isEditing && !isCreating) {
      if (flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        const rawAudience = { ...audience, merchant: fetchedMerchant };
        rule = transformPayloadToAudienceRule(rawAudience);
      }
      if (!flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        rule = getAudienceRuleData(audience, fetchedMerchant);
      }
    }

    dispatch(
      saveSelectedAudienceRule(
        {
          ...rule,
          refreshAudience: isDynamic,
          ...(audience ? { enrollNewUser: audience.enrollNewUser } : {}),
        },
        0,
      ),
    );
    goBack();
  };

  const isLoading = creatingAudience || fetchingMerchant || editingAudience;

  const isSuccess = isEditing
    ? audienceEditedSuccessfuly
    : audienceCreatedSuccessfully;

  const isError = isEditing ? editingAudienceError : creatingAudienceError;

  return (
    <>
      {commonToast(
        isSuccess,
        !!isError,
        `Audience ${isEditing ? 'updated' : 'created'} successfully`,
        isError ?? '',
      )}
      {IS_1917_REWARDS_MAINTANENCE &&
        commonToast(
          false,
          true,
          ``,
          'REWARDS MAINTANENCE ONGOING. RESTRICTING ACCESS TO AUDIENCE EDIT/CREATE FUNCTIONALITY',
        )}

      <Form
        onSubmit={onSubmitHandler}
        validationMode={ValidationMode.OnChange}
        className={classes.form}
        validationSchema={audienceInformationSchema}
      >
        <Heading size={HeadingSize.M}>{title}</Heading>
        <div>
          {inputFields.map(
            ({
              name,
              renderProps,
              show,
              component: Component,
              ...otherProps
            }) =>
              show && (
                <Fragment key={name}>
                  {renderProps ? (
                    <FormControl
                      name={name}
                      render={(props) => (
                        <Component {...renderProps} {...props} />
                      )}
                    />
                  ) : (
                    <Component name={name} {...otherProps} />
                  )}
                </Fragment>
              ),
          )}
        </div>
        <div className="flex">
          <span className="text-gray900 mr-3">Audience Rule :</span>
          {/* <div>
            {Object.entries(newAudience.rules).map(
              ([ruleNumber, ruleData], index) => {
                const {
                  behaviorValue,
                  timescale,
                  timescaleValue,
                  period,
                  merchant,
                } = ruleData;

                return (
                  <div key={ruleNumber}>
                    <button
                      type="button"
                      onClick={null}
                      className={classes.audienceRule}
                    >
                      {`${behaviorValue} purchases in the ${timescale} ${timescaleValue} ${period} in ${merchant.name}`}
                    </button>
                    {index + 1 < rulesQuantity ? (
                      <span className={classes.ruleConnector}>
                        {newAudience.ruleCondition}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
            )}
            {rulesQuantity === 0 && (
              <span className={classes.noRules}>No rules were added yet</span>
            )}
          </div> */}

          <button
            type="button"
            alt="audience rule"
            onClick={onClickRuleHandler}
            className="text-primary600 text-left hover:underline hover:underline-offset-1"
          >
            {ruleText}
          </button>
        </div>
        <div className={classes.actionButtons}>
          <div
            className={classNames({ [classes.buttonsContainer]: isCreating })}
          >
            {isCreating && (
              <div className="w-fit">
                <Button
                  variant="secondary"
                  onClick={onClickRuleHandler}
                  className="w-fit"
                >
                  Go back to Rule Builder
                </Button>
              </div>
            )}
            <ButtonGroup reverse>
              {!isEditing && !isCreating && (
                <Button
                  disabled
                  loading={fetchingMerchant}
                  onClick={onEditHandler}
                >
                  Edit audience
                </Button>
              )}
              {(isEditing || isCreating) && (
                <Button
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading || IS_1917_REWARDS_MAINTANENCE}
                >
                  {isCreating ? 'Create audience' : 'Save audience'}
                </Button>
              )}
              <Button variant="secondary" onClick={onClickCancelHandler}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Form>
    </>
  );
};

AudienceInformation.propTypes = {
  audience: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  goBack: PropTypes.func,
};

AudienceInformation.defaultProps = {
  audience: null,
  isCreating: false,
  onCancel: () => {},
  goBack: null,
};

export default AudienceInformation;
