import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Button from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import { fetchUserProfile } from 'state/actions/users';
import { selectFetchUserProfileState } from 'state/selectors/users';
import { clearAuditsErrors, editAudit } from 'state/actions/audits';
import { selectEditAuditState } from 'state/selectors/audits';
import removeEmptyFieldsFromObject from 'utils/removeEmptyFieldsFromObject';
import Textarea from 'components/Common/Textarea';

import LayoutGap from 'components/Common/LayoutGap';
import ButtonGroup from 'components/Common/ButtonGroup';
import { editAuditSchema } from './AuditForm.schema';
import { getEditingFields, getInputFields } from './formFields';

const AuditInfoForm = ({ title, subtitle, audit, issuerId }) => {
  const dispatch = useDispatch();

  const {
    loading: editingAudit,
    success: successEditAudit,
    error: errorEditingAudit,
  } = useSelector(selectEditAuditState, shallowEqual);

  const { userProfile } = useSelector(
    selectFetchUserProfileState,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const [currentValue, setCurrentValue] = useState(audit || {});
  const [isEditing, setIsEditing] = useState(false);
  const [internalDispute, setInternalDispute] = useState(false);
  const [validDispute, setValidDispute] = useState(false);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (audit) {
      if (audit.internalDispute) {
        setInternalDispute(audit.internalDispute);
      }

      if (audit.validDispute) {
        setValidDispute(audit.validDispute);
      }
    }
  }, [audit]);

  const onEditHandler = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onToggleHandler = useCallback(
    (setValue) => {
      if (isEditing) {
        setValue((prevState) => !prevState);
      }
    },
    [isEditing],
  );

  const onCancelHandler = useCallback(() => {
    setShouldResetForm(true);
    setIsEditing(false);
    setCurrentValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (shouldResetForm) {
      setShouldResetForm(false);
    }
  }, [setShouldResetForm, shouldResetForm]);

  useEffect(() => {
    if (successEditAudit) {
      setIsEditing(false);
      dispatch(clearAuditsErrors());
    }
  }, [successEditAudit, dispatch]);

  const onSubmitHandler = useCallback(
    (values) => {
      const { auditId: _id, issuer } = audit;

      const valuesWithData = removeEmptyFieldsFromObject(values);

      const {
        resolutionCode,
        submitterName,
        preferredContactEmail,
        matchedMerchant,
        merchantSource,
        auditCode,
        transactionAmountInCents,
        referringPartnerUserId,
        merchantId,
        resolutionDescription,
        transactionDate,
        status,
        source,
      } = valuesWithData;

      const body = {
        ...(transactionDate
          ? { transactionDate: new Date(transactionDate).toISOString() }
          : {}),
        ...(_id && { _id }),
        ...(issuer && { issuer }),
        ...(status && { status }),
        ...(internalDispute && { internalDispute }),
        ...(validDispute && { validDispute }),
        ...(resolutionCode && { resolutionCode }),
        ...(submitterName && { submitterName }),
        ...(preferredContactEmail && { preferredContactEmail }),
        ...(matchedMerchant && { matchedMerchant }),
        ...(merchantSource && { merchantSource }),
        ...(auditCode && { auditCode }),
        ...(transactionAmountInCents && { transactionAmountInCents }),
        ...(referringPartnerUserId && { referringPartnerUserId }),
        ...(merchantId && { merchantId }),
        ...(resolutionDescription && { resolutionDescription }),
        ...(source && { source }),
      };

      dispatch(editAudit(audit.auditId, body, issuerId));
    },
    [audit, dispatch, internalDispute, validDispute],
  );

  const isUserIssuer = userProfile?.roles.includes('reporter.issuer');

  const formFields = getInputFields({ isEditing, audit });

  const editingFields = getEditingFields({
    audit,
    internalDispute,
    isEditing,
    validDispute,
    onSetInternalDisputeHandler: () => onToggleHandler(setInternalDispute),
    onSetValidDispute: () => onToggleHandler(setValidDispute),
  });

  return (
    <>
      {successEditAudit && (
        <Toast
          id="success creating audit"
          text="Changes saved"
          type={ToastType.Success}
        />
      )}

      {errorEditingAudit && (
        <Toast
          id="error editing audit"
          text={errorEditingAudit}
          type={ToastType.Error}
        />
      )}

      <Form
        onSubmit={onSubmitHandler}
        validationSchema={editAuditSchema}
        validationMode={ValidationMode.OnChange}
        shouldReset={shouldResetForm}
        defaultValues={currentValue}
        setValid={setValid}
        className="p-10"
      >
        <LayoutGap className="flex items-start justify-between">
          <>
            <h1 className="mb-4 text-xl font-semibold">{title}</h1>
            {subtitle && (
              <Body size={BodySize.M} color={BodyColor.Gray}>
                {subtitle}
              </Body>
            )}
          </>
          {!isEditing ? (
            <div>
              <Button onClick={onEditHandler}>Edit audit</Button>
            </div>
          ) : (
            <ButtonGroup reverse>
              <Button type="submit" loading={editingAudit} disabled={!valid}>
                Save changes
              </Button>
              <Button variant="secondary" onClick={onCancelHandler}>
                Cancel
              </Button>
            </ButtonGroup>
          )}
        </LayoutGap>
        <div>
          <FormControl
            name="auditId"
            render={(props) => (
              <Input
                label="Audit ID*"
                value={audit.auditId}
                type={InputType.Text}
                disabled
                readOnly
                size={InputSize.S}
                {...props}
              />
            )}
          />
          <FormControl
            name="issuer"
            render={(props) => (
              <Input
                label="Issuer*"
                value={isUserIssuer ? userProfile.issuerName : audit.issuer}
                type={InputType.Text}
                size={InputSize.S}
                disabled
                readOnly
                {...props}
              />
            )}
          />
          {formFields.map(
            ({
              name,
              component: Component,
              renderProps,
              type,
              ...otherProps
            }) => (
              <React.Fragment key={name}>
                {renderProps ? (
                  <FormControl
                    name={name}
                    render={(props) =>
                      type === 'textarea' ? (
                        <Textarea
                          value={currentValue[name]}
                          {...renderProps}
                          {...props}
                        />
                      ) : (
                        <Input
                          value={currentValue[name]}
                          {...renderProps}
                          {...props}
                        />
                      )
                    }
                  />
                ) : (
                  <Component name={name} {...otherProps} />
                )}
              </React.Fragment>
            ),
          )}
          {editingFields.map(
            ({ name, component: Component, renderProps, ...otherProps }) => (
              <React.Fragment key={name}>
                {renderProps ? (
                  <FormControl
                    name={name}
                    render={(props) => (
                      <Component {...renderProps} {...props} />
                    )}
                  />
                ) : (
                  <Component name={name} {...otherProps} />
                )}
              </React.Fragment>
            ),
          )}
        </div>
      </Form>
    </>
  );
};

AuditInfoForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  audit: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  issuerId: PropTypes.string.isRequired,
};

AuditInfoForm.defaultProps = {
  audit: {},
  subtitle: null,
};

export default AuditInfoForm;
