import transformFilter from './transformFilter';

const queryToSearchParams = ({ pagination = {}, sort = {}, filters = {} }) =>
  new URLSearchParams([
    // we use 2D array because object-based constructor doesn't allow duplicate entries
    ...Object.entries(pagination),
    ...(sort?.columnName
      ? [[`${sort.columnName}Sort`, sort.direction?.toString()]]
      : []),
    ...Object.entries(filters)
      .map((args) => transformFilter(...args))
      .flat()
      .filter(([, value]) => value !== undefined),
  ]);

export default queryToSearchParams;
