import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/* components */
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Input, {
  Type as InputType,
  Size as InputSize,
  IconPosition as InputIconPosition,
  Color as InputColor,
} from 'components/Common/Input';
import LayoutGap from 'components/Common/LayoutGap';
import PageHeader from 'components/Common/PageHeader';
import Fieldset from 'components/Common/Fieldset';
import Button from 'components/Common/Button';
import Checkbox, { Size as CheckboxSize } from 'components/Common/Checkbox';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import FormControlDatePicker from 'components/Common/FormControlDatePicker';
import Textarea from 'components/Common/Textarea';
import MerchantLocations from 'refactored/components/Common/MerchantLocations';
import AdminCommissionSplit from 'components/Forms/AdminCommissionSplit';

/* redux */
import {
  buildExperiment,
  clearOffersSuccess,
  clearOffersErrors,
  editOffer,
  updateExperimentStatus,
} from 'state/actions/offers';
import { clearAudiencesErrors, fetchAudiences } from 'state/actions/audiences';
import {
  selectBuildExperimentOfferState,
  selectEditOfferState,
} from 'state/selectors/offers';
import { fetchMerchantIssuers } from 'state/actions/merchants';
import { selectFetchMerchantNetworksState } from 'state/selectors/merchantNetworks';
import { fetchMerchantNetworks } from 'state/actions/merchantNetworks';
import { selectFetchAudiencesState } from 'state/selectors/audiences';
import selectFeatureFlagsState from 'state/selectors/featureFlags';

/* enums */
import StatusValue from 'enums/status/statusValue.enum';
import { CommissionType } from 'enums/offers/commissionTypes.enum';
import ExperimentBuildStatus from 'enums/offers/experimentBuildStatus.enum';
import Path from 'enums/path.enum';

/* assets */
import dollarIcon from 'assets/icons/dollar.png';
import percentIcon from 'assets/icons/percent.png';

/* utils */
import {
  defaultOfferStatusOptions,
  closedStatusOptions,
} from 'utils/common/values';
import {
  MerchantCategoryCodeOptions,
  filterMerchantCategoryCode,
} from 'utils/offers/merchantCategoryCodes';
import { commissionTypes, offerTypes, sources } from 'utils/offers/values';
import { useSegments } from '@/refactored/hooks/useSegment';
import {
  shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker,
  shiftDateFromDatePickerBackToUTC,
} from './utils';

import CommissionValueField from './CommissionValueField';
import validationSchema from './OfferEdit.schema';
import classes from './OfferEdit.module.scss';
import QualifiedIssuersField from './QualifiedIssuersField';

const FILTER_BY_CREATED_DATE_DESC = `createdDateSort=-1`;
const SELECT_ALL_WHITESPACE_REGEX = /\s/g;
const AUDIENCES_LIMIT = 'limit=400';
const AUDIENCES_FILTERS = `?${FILTER_BY_CREATED_DATE_DESC}&${AUDIENCES_LIMIT}`;

const CommissionTypeIcon = Object.freeze({
  [CommissionType.Percent]: percentIcon,
  [CommissionType.Flat]: dollarIcon,
});

const { BUILDING, READY } = ExperimentBuildStatus;

const OfferEdit = ({ offer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { merchantNetworks } = useSelector(
    selectFetchMerchantNetworksState,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchMerchantNetworks());
  }, [dispatch]);

  const merchantNetworkOptions = merchantNetworks.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  const [isEditing, setIsEditing] = useState(false);

  const [currentValue, setCurrentValue] = useState(offer);

  const [dataAudiences, setDataAudiences] = useState([]);

  const [shouldResetForm, setShouldResetForm] = useState(false);

  const {
    audiencesData,
    loading: loadingFetchAudiences,
    error: errorFetchAudiences,
  } = useSelector(selectFetchAudiencesState, shallowEqual);

  const [
    {
      data: segmentsData,
      loading: loadingFetchSegments,
      error: errorFetchSegments,
    },
  ] = useSegments({
    limit: 1000,
    includeRules: false,
  });

  const segmentResults = useMemo(
    () => segmentsData?.results || [],
    [segmentsData],
  );

  const {
    loading: loadingBuildExperiment,
    error: errorBuildExperiment,
    success: successBuildExperiment,
    msg: msgBuildExperiment,
  } = useSelector(selectBuildExperimentOfferState, shallowEqual);

  const { loading: loadingEditOffer, success: editOfferSuccess } = useSelector(
    selectEditOfferState,
    shallowEqual,
  );

  // setting isEditing in useEffect to avoid state updates during Toast render
  useEffect(() => {
    if (editOfferSuccess) {
      setIsEditing(false);
      dispatch(clearOffersErrors());
    }
  }, [dispatch, editOfferSuccess]);

  useEffect(() => {
    // Reset shouldResetForm back to false, since shouldReset has already reset form to defaultValues
    if (shouldResetForm) {
      setShouldResetForm(false);
    }
  }, [setShouldResetForm, shouldResetForm]);

  const [experimentCheckbox, setExperimentCheckbox] = useState(
    !!currentValue.controlGroupPercentage ?? false,
  );
  const [testGroupField, setTestGroupField] = useState(
    currentValue.controlGroupPercentage
      ? 100 - currentValue.controlGroupPercentage
      : '',
  );

  const [locationSpecific, setLocationSpecific] = useState(
    currentValue.isLocationSpecific,
  );
  const [optInRequired, setOptInRequired] = useState(
    currentValue.optInRequired,
  );
  const [redeemableOnce, setRedeemableOnce] = useState(
    currentValue.redeemableOnce,
  );
  const [displayAlways, setDisplayAlways] = useState(
    currentValue.displayAlways ?? false,
  );
  const [selectedCommissionType, setSelectedCommissionType] = useState(
    offer?.commissionType,
  );

  const locationMissingError = !currentValue.merchantLocationIds?.length;

  const experimentButtonLabel =
    (loadingBuildExperiment && 'Loading...') ||
    (currentValue.experimentBuildStatus === BUILDING &&
      'Building Experiment') ||
    (currentValue.experimentBuildStatus === READY && 'Experiment Ready') ||
    'Build Experiment';

  const experimentIsBuilding = currentValue.experimentBuildStatus === BUILDING;
  const experimentIsReady = currentValue.experimentBuildStatus === READY;
  const disableExperimentFields = experimentIsBuilding || experimentIsReady;
  const disableExperimentCheckbox =
    !isEditing || disableExperimentFields || loadingBuildExperiment;

  // start and expiration date are stored "2021-07-06T20:16:29.693+00:00" as UTC Time
  // we want to display as Date object "Jul 06 2021 20:16:29 " without converting to local time.
  const [expDate, setExpDate] = useState(
    shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker(
      currentValue.expirationDate,
    ),
  );
  const [startDateUTC, setStartDateUTC] = useState(
    shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker(currentValue.startDate),
  );

  const { results: audienceResults } = audiencesData;

  const formRef = useRef('');

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);

  const { REWARDS_2653_UPCOMING_OFFERS_FLAG, IS_1917_REWARDS_MAINTANENCE } =
    flags;

  // This is needed to transform the audiences data into options for the dropdown
  useEffect(() => {
    if (audienceResults?.length > 0 && dataAudiences?.length === 0) {
      const transformedAudiencesData = audienceResults?.map((audience) => ({
        label: audience.name,
        value: audience._id,
      }));
      setDataAudiences(transformedAudiencesData);
    }
  }, [audienceResults, dataAudiences]);

  const segmentsOptions = useMemo(
    () =>
      segmentResults?.map((segment) => ({
        label: segment.name,
        value: segment.id,
      })),
    [segmentResults],
  );

  useEffect(() => {
    if (errorFetchAudiences) {
      dispatch(clearAudiencesErrors());
    }
  }, [dispatch, errorFetchAudiences]);

  useEffect(() => {
    dispatch(fetchAudiences(AUDIENCES_FILTERS));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantIssuers());
  }, [dispatch, offer]);

  const editableTextColor = !isEditing ? InputColor.Gray : InputColor.Black;
  const editableSelectColor = !isEditing ? SelectColor.Gray : SelectColor.Black;

  const onEditHandler = useCallback(() => setIsEditing(true), []);

  const onCancelHandler = useCallback(() => {
    setIsEditing(false);
    setShouldResetForm(true);

    // checkboxes to reset manually
    setLocationSpecific(currentValue.isLocationSpecific);
    setExperimentCheckbox(!!currentValue.controlGroupPercentage);
    setOptInRequired(currentValue.optInRequired);
    setRedeemableOnce(currentValue.redeemableOnce);
    setDisplayAlways(currentValue.displayAlways ?? false);
  }, [currentValue]);

  const onChangeCheckboxStateHandler = useCallback((setState) => {
    setState((prevState) => !prevState);
  }, []);

  const onChangeStatusHandler = useCallback(
    (newStatus) => {
      // If changing status from Active -> Inactive, set the expDate to today
      setExpDate(
        newStatus === StatusValue.Inactive
          ? new Date()
          : shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker(
              currentValue.expirationDate,
            ),
      );
    },
    [currentValue.expirationDate],
  );

  const isValidNumber = useCallback(
    (value) => value !== undefined && value >= 0,
    [],
  );

  const onCloneHandler = useCallback(() => {
    navigate(`${Path.Offers}/clone/${offer._id}`, { state: { currentValue } });
  }, [navigate, offer, currentValue]);

  const onBuildExperimentHandler = useCallback(() => {
    if (
      // eslint-disable-next-line
      confirm(
        `You've assigned ${testGroupField}% of users in the audience to the test group and ${
          100 - testGroupField
        }% to the control group. To proceed, click 'OK' to initiate the experiment group generation process. The offer will be ready to activate once all users in the audience are assigned to a group.`,
      )
    ) {
      const { controlGroupPercentage } = formRef.current.getFormValues();
      dispatch(
        buildExperiment(offer._id, {
          controlGroupPercentage: Number(controlGroupPercentage),
        }),
      );
    }
  }, [dispatch, offer, testGroupField]);

  // Format as Date Object on Edit Mode
  const formatStartDate = currentValue.startDate ? startDateUTC : undefined;

  const formatExpirationDate = currentValue.expirationDate
    ? expDate
    : undefined;

  const onSubmitHandler = useCallback(
    (values) => {
      let audiences = [];
      if (Array.isArray(values.audiences)) audiences = values.audiences;
      if (typeof values.audiences === 'string' && values.audiences.length > 0)
        audiences = [values.audiences];

      let segments = [];
      if (Array.isArray(values.segments)) segments = values.segments;
      if (typeof values.segments === 'string' && values.segments.length > 0)
        segments = [values.segments];

      const body = {
        ...values,
        status: values.status,
        isLocationSpecific: locationSpecific,
        optInRequired,
        startDate: shiftDateFromDatePickerBackToUTC(values.startDate), // Format as UTC String
        expirationDate: shiftDateFromDatePickerBackToUTC(values.expirationDate), // Format as UTC String
        merchantId:
          values.merchantId.replace(SELECT_ALL_WHITESPACE_REGEX, '') ?? '',
        redeemableOnce,
        commissionValue: Number(values.commissionValue),
        minTransactionAmount: Number(values.minTransactionAmount),
        maxTransactionAmount: Number(values.maxTransactionAmount),
        minRewardAmount: Number(values.minRewardAmount),
        maxRewardAmount: Number(values.maxRewardAmount),
        minUserRewardAmount: Number(values.minUserRewardAmount),
        maxUserRewardAmount: Number(values.maxUserRewardAmount),
        mcc: values.mcc ?? [],
        displayAlways,
        merchantLocationIds: offer.merchantLocationIds,
        audiences,
        segments,
      };

      setCurrentValue({
        ...body,
        issuerCommissionsMap: currentValue.issuerCommissionsMap,
        userCommissionsMap: currentValue.userCommissionsMap,
      });

      // Remove any non-qualified issuers from commission maps
      const issuerCommissionsMap = {};
      const userCommissionsMap = {};

      body.qualifiedIssuer.forEach((issuerName) => {
        issuerCommissionsMap[issuerName] =
          body.issuerCommissionsMap[issuerName];
        userCommissionsMap[issuerName] = body.userCommissionsMap[issuerName];
      });

      body.issuerCommissionsMap = issuerCommissionsMap;
      body.userCommissionsMap = userCommissionsMap;

      if (values.controlGroupPercentage) {
        body.controlGroupPercentage = Number(values.controlGroupPercentage);
      }

      // if experimentCheckbox is false (unchecked) and offer already has an controlGroupPercentage value
      // this means the user wants to disable the experiment
      if (!experimentCheckbox && offer.controlGroupPercentage) {
        body.controlGroupPercentage = 0;
      }

      delete body.category;
      delete body.merchantName;
      delete body.offerId;
      delete body._id;
      delete body.createdDate;
      delete body.lastModified;
      delete body.merchant;
      delete body.isTargeted;

      delete body.merchantLocationIds;

      if (!values.terms) {
        delete body.terms;
      }

      if (!values.partnerOfferId) {
        delete body.partnerOfferId;
      }

      if (!isValidNumber(values.minTransactionAmount)) {
        delete body.minTransactionAmount;
      }

      if (!isValidNumber(values.maxTransactionAmount)) {
        delete body.maxTransactionAmount;
      }

      if (!isValidNumber(values.minRewardAmount)) {
        delete body.minRewardAmount;
      }

      if (!isValidNumber(values.maxRewardAmount)) {
        delete body.maxRewardAmount;
      }

      if (!isValidNumber(values.minUserRewardAmount)) {
        delete body.minUserRewardAmount;
      }

      if (!isValidNumber(values.maxUserRewardAmount)) {
        delete body.maxUserRewardAmount;
      }

      if (!values.startDate) {
        delete body.startDate;
      }

      dispatch(editOffer(offer._id, body));
    },
    [
      offer,
      locationSpecific,
      optInRequired,
      redeemableOnce,
      dispatch,
      isValidNumber,
      displayAlways,
      experimentCheckbox,
      currentValue.issuerCommissionsMap,
      currentValue.userCommissionsMap,
    ],
  );

  useEffect(() => {
    if (errorBuildExperiment) {
      dispatch(clearOffersErrors());
    }
  }, [errorBuildExperiment, dispatch]);

  useEffect(() => {
    if (successBuildExperiment) {
      dispatch(clearOffersSuccess());

      const body = {
        experimentBuildStatus: BUILDING,
        controlGroupPercentage:
          formRef.current?.getFormValues()?.controlGroupPercentage,
      };

      dispatch(updateExperimentStatus(currentValue._id, body));

      // Go back to offers list after kicking off experiment build
      navigate(Path.Offers);
    }
  }, [successBuildExperiment, currentValue._id, dispatch, navigate]);

  const commissionTypeIcon = CommissionTypeIcon[selectedCommissionType];

  const experimentStatusColor = (key) =>
    ({
      DRAFT: InputColor.Gray,
      BUILDING: InputColor.Blue,
      READY: InputColor.Green,
      FAILED: InputColor.Red,
    })[key];

  const updateTestGroupInput = () => {
    // Check if formRef.current exists and has the getFormValues method
    if (
      formRef.current &&
      typeof formRef.current.getFormValues === 'function'
    ) {
      const formValues = formRef.current.getFormValues();

      // Check if controlGroupPercentage exists in formValues
      if (formValues && formValues.controlGroupPercentage != null) {
        setTestGroupField(100 - formValues.controlGroupPercentage);
      }
    }
  };

  const handleExperimentCheckbox = () => {
    if (!disableExperimentCheckbox) {
      onChangeCheckboxStateHandler(setExperimentCheckbox);
    }
  };

  let statusOptions =
    currentValue.status === StatusValue.Closed
      ? closedStatusOptions
      : defaultOfferStatusOptions;

  if (locationMissingError) {
    statusOptions = statusOptions.map((option) => {
      if (option.value === StatusValue.Active) {
        return {
          ...option,
          isDisabled: true,
        };
      }
      return option;
    });
  }

  const defaultStatusOptionsWithoutUpcoming = defaultOfferStatusOptions.filter(
    (option) => option.value !== StatusValue.Upcoming,
  );

  const isClosed = currentValue.status === StatusValue.Closed;

  return (
    <>
      {errorBuildExperiment && (
        <Toast
          id="error-building-experiment"
          text={msgBuildExperiment}
          type={ToastType.Error}
        />
      )}
      {successBuildExperiment && (
        <Toast
          id="success-building-experiment"
          text="Experiment build started"
          type={ToastType.Success}
        />
      )}
      {IS_1917_REWARDS_MAINTANENCE && (
        <Toast
          id="rewards-maintainence-flag"
          text="REWARDS MAINTANENCE ONGOING. RESTRICTING ACCESS TO OFFER EDIT/CREATE FUNCTIONALITY"
          type={ToastType.Error}
        />
      )}

      <Form
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
        className="ml-10 mt-10"
        ref={formRef}
        shouldReset={shouldResetForm}
        defaultValues={currentValue}
      >
        <PageHeader
          title={isEditing ? 'Edit Offer' : 'Offer Information'}
          className="mr-[2.5rem]"
        >
          {!isEditing ? (
            <>
              <span data-tooltip-id="edit-offer-disabled">
                <Button
                  key="edit"
                  onClick={onEditHandler}
                  loading={loadingFetchAudiences}
                  disabled={loadingFetchAudiences || isClosed}
                  className={classes.primaryButton}
                >
                  Edit offer
                </Button>
              </span>
              <ReactTooltip
                id="edit-offer-disabled"
                offset={0}
                hidden={!isClosed}
                place="bottom"
                content="Cannot edit a closed offer"
              />
              <Button variant="secondary" key="clone" onClick={onCloneHandler}>
                Clone
              </Button>
            </>
          ) : (
            <>
              <Button
                key="save-create"
                type="submit"
                loading={loadingEditOffer}
                disabled={IS_1917_REWARDS_MAINTANENCE}
              >
                {isEditing ? 'Save changes' : 'Edit'}
              </Button>
              <Button
                variant="secondary"
                key="go-back-cancel"
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              {experimentCheckbox && (
                <Button
                  key="build-experiment"
                  variant="tertiary"
                  onClick={onBuildExperimentHandler}
                  disabled={disableExperimentFields}
                  loading={loadingBuildExperiment}
                >
                  {experimentButtonLabel}
                </Button>
              )}
            </>
          )}
        </PageHeader>
        <div>
          <Fieldset title="Offer Details">
            <LayoutGap direction="column" size="none">
              <FormControl
                name="offerId"
                render={(props) => (
                  <Input
                    label="Offer ID:"
                    labelClassName={classes.labelWidth}
                    value={offer._id}
                    type={InputType.Text}
                    readOnly
                    size={InputSize.S}
                    disabled
                    {...props}
                  />
                )}
              />
              <FormControl
                name="merchantId"
                render={(props) => (
                  <Input
                    label="Merchant ID:"
                    labelClassName={classes.labelWidth}
                    value={currentValue.merchantId}
                    type={InputType.Text}
                    readOnly
                    size={InputSize.S}
                    disabled
                    {...props}
                  />
                )}
              />
              <>
                <FormControl
                  name="merchantName"
                  render={(props) => (
                    <Input
                      label="Merchant Name:"
                      labelClassName={classes.labelWidth}
                      value={currentValue.merchantName}
                      type={InputType.Text}
                      readOnly
                      size={InputSize.S}
                      disabled
                      {...props}
                    />
                  )}
                />
              </>
              <FormControl
                name="name"
                render={(props) => (
                  <Input
                    label="Offer Name:"
                    labelClassName={classes.labelWidth}
                    value={offer.name}
                    type={InputType.Text}
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    size={InputSize.S}
                    color={editableTextColor}
                    {...props}
                  />
                )}
              />
              <FormControlDatePicker
                name="startDate"
                label="Start Date:"
                labelClassName={classes.labelWidth}
                defaultValue={formatStartDate}
                readOnly={!isEditing}
                color={editableSelectColor}
                disabled={!isEditing}
                showTimeSelect
                onChangeManual={setStartDateUTC}
                isUTC
                testId="start-date-picker"
              />

              <FormControlDatePicker
                name="expirationDate"
                label="Expiration Date:"
                labelClassName={classes.labelWidth}
                defaultValue={formatExpirationDate}
                readOnly={!isEditing}
                disabled={!isEditing}
                color={editableSelectColor}
                showTimeSelect
                onChangeManual={setExpDate}
                isUTC
                testId="expiration-date-picker"
              />
              {REWARDS_2653_UPCOMING_OFFERS_FLAG ? (
                <FormControlSelect
                  name="status"
                  defaultValue={currentValue.status}
                  label="Status"
                  labelClassName={classes.labelWidth}
                  readOnly={
                    !isEditing || currentValue.status === StatusValue.Closed
                  }
                  disabled={!isEditing}
                  options={statusOptions}
                  color={editableSelectColor}
                  onChangeManual={onChangeStatusHandler}
                />
              ) : (
                <FormControlSelect
                  name="status"
                  defaultValue={currentValue.status}
                  label="Status"
                  labelClassName={classes.labelWidth}
                  readOnly={
                    !isEditing ||
                    currentValue.status === StatusValue.Closed ||
                    locationMissingError
                  }
                  disabled={!isEditing}
                  options={
                    currentValue.status === StatusValue.Closed
                      ? closedStatusOptions
                      : defaultStatusOptionsWithoutUpcoming
                  }
                  color={editableSelectColor}
                  onChangeManual={onChangeStatusHandler}
                  tooltip={
                    locationMissingError && isEditing
                      ? 'Please associate locations with this offer before activating it.'
                      : ''
                  }
                />
              )}
              {currentValue.experimentBuildStatus && (
                <FormControl
                  name="experimentBuildStatus"
                  render={(props) => (
                    <Input
                      label="Experiment Status:"
                      labelClassName={classes.labelWidth}
                      value={currentValue.experimentBuildStatus}
                      type={InputType.Text}
                      readOnly
                      size={InputSize.S}
                      color={experimentStatusColor(
                        currentValue.experimentBuildStatus,
                      )}
                      {...props}
                    />
                  )}
                />
              )}
              <FormControlSelect
                name="source"
                defaultValue={currentValue.source}
                label="Source:"
                labelClassName={classes.labelWidth}
                readOnly={!isEditing}
                disabled={!isEditing}
                options={sources}
                color={editableSelectColor}
                testId="source-input"
              />
              <FormControlSelect
                name="offerType"
                defaultValue={currentValue.offerType}
                label="Offer Type:"
                labelClassName={classes.labelWidth}
                readOnly={!isEditing}
                disabled={!isEditing}
                options={offerTypes}
                color={editableSelectColor}
                testId="offer-type-input"
              />
              <FormControlSelect
                name="merchantNetwork"
                label="Merchant Network:"
                labelClassName={classes.labelWidth}
                defaultValue={currentValue.merchantNetwork}
                readOnly={!isEditing}
                disabled={!isEditing}
                options={merchantNetworkOptions}
                color={editableSelectColor}
                testId="merchant-network-input"
              />
              <FormControl
                name="partnerOfferId"
                render={(props) => (
                  <Input
                    label="Partner Offer ID:"
                    labelClassName={classes.labelWidth}
                    value={offer.partnerOfferId}
                    type={InputType.Text}
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    size={InputSize.S}
                    color={editableTextColor}
                    {...props}
                  />
                )}
              />
              <Checkbox
                label="Location Specific"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setLocationSpecific)
                }
                selected={locationSpecific}
                className={classes.checkbox}
                size={CheckboxSize.S}
                disabled={!isEditing}
              />
              <FormControlSelect
                name="audiences"
                label="Audience:"
                defaultValue={currentValue?.audiences?.[0]}
                labelClassName={classes.labelWidth}
                readOnly={!isEditing}
                options={dataAudiences}
                color={editableSelectColor}
                isClearable
                isLoading={loadingFetchAudiences}
                disabled={loadingFetchAudiences || !isEditing}
              />
              <FormControlSelect
                name="segments"
                label="Segment:"
                defaultValue={currentValue?.segments?.[0]}
                labelClassName={classes.labelWidth}
                readOnly={!isEditing}
                options={segmentsOptions || []}
                color={editableSelectColor}
                isClearable
                isLoading={loadingFetchSegments}
                disabled={loadingFetchSegments || !isEditing}
              />
              {segmentsOptions.length === 0 && errorFetchSegments && (
                <p className="text-red-500">
                  Error loading segments. Try refreshing the page. If the issue
                  persists, please reach out to the Back Office team.
                </p>
              )}
              <Checkbox
                label="Enable Experiment"
                setSelected={handleExperimentCheckbox}
                selected={experimentCheckbox}
                className={classNames(classes.checkbox, {
                  [classes.disabled]: disableExperimentCheckbox,
                })}
                disabled={disableExperimentCheckbox}
                size={CheckboxSize.S}
              />

              {experimentCheckbox && (
                <div className={classes.experimentFieldsWrapper}>
                  <FormControl
                    name="controlGroupPercentage"
                    render={(props) => (
                      <Input
                        label="Control Group:"
                        labelClassName={classes.labelWidth}
                        value={currentValue.controlGroupPercentage}
                        type={InputType.Number}
                        readOnly={
                          !isEditing ||
                          disableExperimentFields ||
                          loadingBuildExperiment
                        }
                        size={InputSize.S}
                        color={editableTextColor}
                        onChange={updateTestGroupInput}
                        icon={percentIcon}
                        iconPosition={InputIconPosition.Right}
                        {...props}
                      />
                    )}
                  />
                  <FormControl
                    name="testGroupPercentage"
                    render={(props) => (
                      <Input
                        label="Test Group"
                        labelClassName={classes.labelWidth}
                        value={testGroupField}
                        isSearch
                        type={InputType.Text}
                        readOnly
                        size={InputSize.S}
                        color={editableTextColor}
                        icon={percentIcon}
                        iconPosition={InputIconPosition.Right}
                        {...props}
                        disabled
                      />
                    )}
                  />
                </div>
              )}
              <FormControlSelect
                filterOption={filterMerchantCategoryCode}
                name="mcc"
                defaultValue={currentValue.mcc}
                label="Merchant Category Codes:"
                labelClassName={classes.labelWidth}
                readOnly={!isEditing}
                disabled={!isEditing}
                options={MerchantCategoryCodeOptions}
                isMulti
                allowSelectAll={false}
                noOptionsMessage={() =>
                  'Start typing MCC number or keyword for suggestions...'
                }
                color={editableSelectColor}
              />
              <FormControl
                name="terms"
                render={(props) => (
                  <Textarea
                    label="Terms And Conditions:"
                    labelClassName={classes.labelWidth}
                    value={currentValue.terms}
                    preventNewLines
                    readOnly={!isEditing}
                    size={InputSize.S}
                    color={editableTextColor}
                    {...props}
                  />
                )}
              />
            </LayoutGap>
          </Fieldset>
          <Fieldset title="Commission Details">
            <QualifiedIssuersField
              currentValue={currentValue}
              isEditing={isEditing}
              editableSelectColor={editableSelectColor}
            />
            <LayoutGap>
              <FormControlSelect
                name="commissionType"
                defaultValue={currentValue.commissionType}
                label="Commission Type"
                labelClassName={classes.labelWidth}
                readOnly={!isEditing}
                disabled={!isEditing}
                options={commissionTypes}
                color={editableSelectColor}
                onChangeManual={setSelectedCommissionType}
                testId="commission-type-input"
                className="mb-[0] w-[15rem]"
              />
              <CommissionValueField
                currentValue={currentValue}
                isEditing={isEditing}
                commissionTypeIcon={commissionTypeIcon}
                editableTextColor={editableTextColor}
              />
            </LayoutGap>
            <AdminCommissionSplit
              commissionType={selectedCommissionType}
              isEditing={isEditing}
            />
          </Fieldset>
          <Fieldset title="Redemption Details">
            <LayoutGap>
              <FormControl
                name="minTransactionAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min Txn Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={currentValue.minTransactionAmount}
                    type={InputType.Number}
                    readOnly={!isEditing}
                    color={editableTextColor}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxTransactionAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max Txn Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={currentValue.maxTransactionAmount}
                    type={InputType.Number}
                    readOnly={!isEditing}
                    color={editableTextColor}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap>
              <FormControl
                name="minRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={currentValue.minRewardAmount}
                    type={InputType.Number}
                    readOnly={!isEditing}
                    color={editableTextColor}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={currentValue.maxRewardAmount}
                    type={InputType.Number}
                    readOnly={!isEditing}
                    color={editableTextColor}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap>
              <FormControl
                name="minUserRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min User Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={currentValue.minUserRewardAmount}
                    type={InputType.Number}
                    readOnly={!isEditing}
                    color={editableTextColor}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxUserRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max User Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={currentValue.maxUserRewardAmount}
                    type={InputType.Number}
                    readOnly={!isEditing}
                    color={editableTextColor}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <Checkbox
              label="Opt-in Required"
              setSelected={() => onChangeCheckboxStateHandler(setOptInRequired)}
              selected={optInRequired}
              className={classNames(classes.checkbox, 'mb-[0]')}
              disabled={!isEditing}
              size={CheckboxSize.S}
            />
            <Checkbox
              label="Redeemable Once"
              setSelected={() =>
                onChangeCheckboxStateHandler(setRedeemableOnce)
              }
              selected={redeemableOnce}
              className={classNames(classes.checkbox, 'mb-[0]')}
              disabled={!isEditing}
              size={CheckboxSize.S}
            />
            <Checkbox
              label="Always Appears to Cardholder"
              setSelected={() => onChangeCheckboxStateHandler(setDisplayAlways)}
              selected={displayAlways}
              className={classNames(classes.checkbox, 'mb-[0]')}
              disabled={!isEditing}
              size={CheckboxSize.S}
            />
          </Fieldset>
        </div>
      </Form>
      <MerchantLocations
        merchantLocationIds={currentValue.merchantLocationIds}
      />
    </>
  );
};

OfferEdit.propTypes = {
  offer: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
};

export default OfferEdit;
