import { createAction } from '@reduxjs/toolkit';

export const fetchMerchantsFromCacheInit = createAction(
  '[Merchants] Fetch Merchants Cache Init',
);
export const fetchMerchantsFromCacheSuccess = createAction(
  '[Merchants] Fetch Merchants Cache Success',
);
export const fetchMerchantsFromCacheFail = createAction(
  '[Merchants] Fetch Merchants Cache Fail',
);

export const fetchMerchantsInit = createAction(
  '[Merchants] Fetch Merchants Init',
);
export const fetchMerchantsSuccess = createAction(
  '[Merchants] Fetch Merchants Success',
);
export const fetchMerchantsFail = createAction(
  '[Merchants] Fetch Merchants Fail',
);

export const fetchMerchantByIdInit = createAction(
  '[Merchants] Fetch Merchant By Id Init',
);
export const fetchMerchantByIdSuccess = createAction(
  '[Merchants] Fetch Merchant By Id Success',
);
export const fetchMerchantByIdFail = createAction(
  '[Merchants] Fetch Merchant By Id Fail',
);

export const createMerchantInit = createAction(
  '[Merchants] Create Merchant Init',
);
export const createMerchantSuccess = createAction(
  '[Merchants] Create Merchant Success',
);
export const createMerchantFail = createAction(
  '[Merchants] Create Merchant Fail',
);
export const clearCreateMerchantData = createAction(
  '[Merchants] Clear Create Merchant Data',
);

export const editMerchantInit = createAction('[Merchants] Edit Merchant Init');
export const editMerchantSuccess = createAction(
  '[Merchants] Edit Merchant Success',
);
export const editMerchantFail = createAction('[Merchants] Edit Merchant Fail');

export const clearMerchantsErrorsData = createAction(
  '[Users] Clear Merchants Errors Data',
);

export const fetchMerchantCategoriesInit = createAction(
  '[Merchants] Fetch Merchant Categories Init',
);
export const fetchMerchantCategoriesSuccess = createAction(
  '[Merchants] Fetch Merchant Categories Success',
);
export const fetchMerchantCategoriesFail = createAction(
  '[Merchants] Fetch Merchant Categories Fail',
);

export const fetchMerchantIssuersInit = createAction(
  '[Merchants] Fetch Merchant Issuers Init',
);
export const fetchMerchantIssuersSuccess = createAction(
  '[Merchants] Fetch Merchant Issuers Success',
);
export const fetchMerchantIssuersFail = createAction(
  '[Merchants] Fetch Merchant Issuers Fail',
);
