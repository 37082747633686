import React from 'react';
import PropTypes from 'prop-types';
import chevronRight from 'assets/icons/chevron-right.svg';
import classes from './MoreInfo.module.scss';

const MoreInfo = ({ onClick }) => (
  <button type="button" onClick={onClick} className={classes.moreInfo}>
    <img src={chevronRight} alt="More information" />
  </button>
);

MoreInfo.propTypes = {
  onClick: PropTypes.func,
};

MoreInfo.defaultProps = {
  onClick: undefined,
};

export default MoreInfo;
