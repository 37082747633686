import React, { useMemo, useEffect, useState } from 'react';
import { ToggleSwitch } from 'flowbite-react';
import Knock, { PreferenceSet, WorkflowPreferences } from '@knocklabs/client';
import importMeta from 'utils/vite/importMeta';
import { Workflows, workflowKeyToName, workflowTemplate } from './workflows';
import toggleTheme from './theme';

const { env } = importMeta();

type TokenProps = {
  userId: string;
  knockToken: string;
};

type PreferencesProps = {
  token: TokenProps;
};

const Preferences = ({ token }: PreferencesProps) => {
  const [preferences, setPreferences] = useState<PreferenceSet>();
  const { OFFER_EXTENDED, OFFER_CHANGED, OFFER_LIVE } = Workflows;

  const knockClient = useMemo(() => {
    const knock = new Knock(env.VITE_KNOCK_PUBLIC_API_KEY!);
    knock.authenticate(token?.userId, token?.knockToken);
    return knock;
  }, [token]);

  useEffect(() => {
    const fetchPreferences = async () => {
      const userPreferences = await knockClient.preferences.get();

      // for new users, set the default preferences since they don't have any workflows set
      if (!userPreferences?.workflows) {
        const workflow = await knockClient.preferences.set(workflowTemplate);
        setPreferences(workflow);
        return;
      }
      setPreferences(userPreferences);
    };

    fetchPreferences();
  }, [knockClient]);

  // prepare the preferences object with the default values
  const preparedPreferencesWorkflows: WorkflowPreferences = {
    [OFFER_LIVE]: {
      channel_types: { email: false },
      ...(preferences?.workflows && preferences?.workflows[OFFER_LIVE]),
    },
    [OFFER_EXTENDED]: {
      channel_types: { email: false },
      ...(preferences?.workflows && preferences?.workflows[OFFER_EXTENDED]),
    },
    [OFFER_CHANGED]: {
      channel_types: { email: false },
      ...(preferences?.workflows && preferences?.workflows[OFFER_CHANGED]),
    },
  };

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    workflowKey: string,
  ) => {
    const updatedPreferences: PreferenceSet = {
      ...preferences,
      workflows: {
        ...preferences?.workflows,
        [workflowKey]: {
          ...(preferences?.workflows && preferences?.workflows[workflowKey]),
          channel_types: {
            ...(preferences?.workflows &&
              preferences?.workflows[workflowKey]?.channel_types),
            email: event,
          },
        },
      },
    };

    setPreferences(updatedPreferences);
    await knockClient.preferences.set(updatedPreferences);
  };

  return (
    <div>
      <h1 className="mb-2 text-base font-semibold">Notifications</h1>
      <span className="text-gray600 text-sm">Send me an email when:</span>
      <div className="mt-4 flex flex-col gap-4">
        {Object.keys(preparedPreferencesWorkflows).map((workflowKey) => {
          const workflow = preparedPreferencesWorkflows[workflowKey];
          const workflowName = workflowKeyToName[workflowKey] || workflowKey;

          return (
            <div className="flex gap-2" key={workflowKey}>
              <ToggleSwitch
                theme={toggleTheme}
                checked={workflow.channel_types.email}
                label={workflowName}
                onChange={(event) => handleCheckboxChange(event, workflowKey)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Preferences;
