import { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';

/** custom hooks */
import useQuery from 'refactored/hooks/useQuery';

/** custom components */
import Table from 'refactored/components/Common/Table';
import SearchBy from 'refactored/components/Common/SearchBy';
import Pagination from 'refactored/components/Common/Pagination2';
import PageHeader from 'components/Common/PageHeader';
import Button from 'components/Common/Button';
import { selectUserAttributesState } from 'state/selectors/auth';
import {
  selectFetchAudiencesState,
  selectFetchAudienceSummaryDataPoints,
} from 'state/selectors/audiences';
import {
  fetchAudienceById,
  fetchAudiences,
  fetchAudiencesSummaryDataPoints,
} from 'state/actions/audiences';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import MultiSelectDropdown from 'refactored/components/Common/MultiSelectDropdown';
import SelectDateRange from 'refactored/components/Common/SelectDateRange';

/* utils */
import makeColumns from 'refactored/utils/audiences/makeColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';

/* assets  */
import AddIcon from 'assets/icons/plus.svg?react';

// 8>< TODO 1/4 remove this imports when the new form page is ready -----------
import CreateAudienceForm from 'components/Pages/CreateAudienceForm';
import Modal from 'components/Common/Modal';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import TableConfig from 'enums/table/table.config';
import { statuses as statusOptions } from 'utils/audiences/values';
import {
  selectCreateAudienceState,
  selectEditAudienceState,
} from '../../../state/selectors/audiences';
import {
  clearAudienceData,
  clearSelectedAudienceRule,
} from '../../../state/actions/audiences';
// 8>< ----------------------------- END TODO 1/4 -----------------------------

/* styles */
import classes from './Audiences.module.scss';

import Summary from './Summary';

const Audience = () => {
  const dispatch = useDispatch();

  /* internal state */
  const [data, setData] = useState([]);

  /* query framework */
  const [query, updateQuery] = useQuery({
    pagination: { page: 0, limit: 100 },
    sort: { direction: -1, columnName: 'createdDate' },
  });

  /* selectors */
  const {
    audiencesData,
    loading: loadingFetchAudiences,
    error: errorFetchAudiences,
  } = useSelector(selectFetchAudiencesState, shallowEqual);

  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const { audienceDataSummaryPoint, loading: fetchDataPointsLoading } =
    useSelector(selectFetchAudienceSummaryDataPoints, shallowEqual);

  /* const */
  const { countTotal, results: audiences } = audiencesData;

  /* cycles */

  /* methods */
  // ++ TODO 2/4 use this when the new form page is ready -----------------------
  // const moreInfoHandler = useCallback(
  //   (audienceData) => {},
  //   [
  //     /* onOpenModalHandler */
  //   ]
  // );

  // const onAddAudienceHandler = useCallback(() => {}, [
  //   /* onOpenModalHandler */
  // ]);

  // ++ ----------------------------- END TODO 2/4 -----------------------------

  // 8>< TODO 3/4 remove this when the new form page is ready -------------------
  const FILTER_BY_CREATED_DATE_DESC = `?createdDateSort=-1&page=${TableConfig.PAGE}&limit=${TableConfig.LIMIT}`;

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();
  const addAudience = modal.type === ModalType.ADD_AUDIENCE;
  const audienceInfo = modal.type === ModalType.AUDIENCE_INFO;

  const { success: successCreateAudience } = useSelector(
    selectCreateAudienceState,
    shallowEqual,
  );

  const { success: successUpdateAudience } = useSelector(
    selectEditAudienceState,
    shallowEqual,
  );

  const onAddAudienceHandler = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_AUDIENCE);
  }, [onOpenModalHandler]);

  useEffect(() => {
    if (successCreateAudience || successUpdateAudience) {
      updateQuery({
        pagination: { page: 0, limit: 100 },
        sort: { direction: -1, columnName: 'createdDate' },
      });
      onCloseModalHandler();
    }
    dispatch(fetchAudiences(FILTER_BY_CREATED_DATE_DESC));
  }, [
    dispatch,
    successCreateAudience,
    successUpdateAudience,
    onCloseModalHandler,
    FILTER_BY_CREATED_DATE_DESC,
    updateQuery,
  ]);

  const onCloseAudienceForm = useCallback(() => {
    dispatch(clearAudienceData());
    dispatch(clearSelectedAudienceRule());
    onCloseModalHandler();
  }, [dispatch, onCloseModalHandler]);

  const moreInfoHandler = useCallback(
    (audienceData) => {
      onOpenModalHandler(ModalType.AUDIENCE_INFO, {
        audience: audienceData,
      });
    },
    [onOpenModalHandler],
  );
  // 8>< ----------------------------- END TODO 3/4 ----------------------------

  /* table columns */
  const columns = useMemo(
    () => getAllowedColumns(makeColumns({ moreInfoHandler }), userRoles),
    [userRoles, moreInfoHandler],
  );

  /* audiences */
  /* fetch audiences filtered/sorted by query params */
  useEffect(() => {
    if (query.filters?.audienceId) {
      dispatch(fetchAudienceById(query.filters.audienceId.selected[0]));
    } else {
      dispatch(fetchAudiences(`?${queryToSearchParams(query).toString()}`));
    }
  }, [dispatch, query]);

  /** transform offers data */
  useEffect(() => {
    const transformedData = audiences.map((audience) => ({
      ...audience,
      audienceId: audience._id,
    }));

    setData(transformedData);
  }, [audiences]);

  /** loads data points for banner */
  useEffect(() => {
    dispatch(fetchAudiencesSummaryDataPoints());
  }, [dispatch]);

  return (
    <>
      {errorFetchAudiences && (
        <Toast
          id="fetch audiences"
          text="Error fetching the audiences"
          type={ToastType.Error}
        />
      )}

      {/* 8>< TODO 4/4 remove this when the new form page is ready ------ */}
      <Modal
        isOpen={addAudience || audienceInfo}
        onClose={onCloseAudienceForm}
        className={classes.ruleBuilderModalWidth}
      >
        <CreateAudienceForm
          isCreating={addAudience}
          onClose={onCloseAudienceForm}
          {...(audienceInfo && { position: 1 })}
          {...(audienceInfo && { audience: modal.audience })}
        />
      </Modal>
      {/* 8>< ---------------------- END TODO 4/4 ----------------------- */}

      <div className={classes.flexboxLayout}>
        <PageHeader title="Audiences" className="m-[2.5rem] mb-[1rem]">
          {isAdmin && (
            <Button onClick={onAddAudienceHandler} icon={<AddIcon />} disabled>
              Create Audience
            </Button>
          )}
        </PageHeader>
        {/* Data points banner */}
        <div className={classes.flexboxLayout__header__summary}>
          {fetchDataPointsLoading ? (
            <div className="mt-[2rem] flex h-full w-full items-center justify-center">
              <Spinner size="lg" color="gray" />
            </div>
          ) : (
            <Summary data={audienceDataSummaryPoint} />
          )}
        </div>

        <div className={classes.flexboxLayout__filters}>
          <SearchBy
            field="name"
            idField="audienceId"
            placeholder="Search by Audience Name or ID"
            query={query}
            updateQuery={updateQuery}
            className="h-[37px] !w-[24.2rem]"
            showCancelIcon
            onCancel
          />
          <>
            <MultiSelectDropdown
              query={query}
              fieldName="status"
              options={statusOptions}
              defaultSelection={statusOptions.map((status) => status.value)}
              updateQuery={updateQuery}
              label="Status"
            />

            <SelectDateRange
              query={query}
              fieldName="createdDate"
              updateQuery={updateQuery}
              label="Created Date"
              restrictToPastDates
            />
          </>
        </div>

        {/* Table */}
        {loadingFetchAudiences ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner size="lg" color="gray" />
          </div>
        ) : (
          <>
            <div className={classes.flexboxLayout__table}>
              <Table
                id="table-audiences"
                columns={columns}
                data={data}
                query={query}
                updateQuery={updateQuery}
              />
            </div>
            <div className={classes.flexboxLayout__pagination}>
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Audience;
