import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Papa from 'papaparse';
import { set, cloneDeep, chunk, merge } from 'lodash';
import exclamationMarkIcon from 'assets/icons/exclamation-mark.svg';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button from 'components/Common/Button';
import CancelIcon from 'assets/icons/x.svg?react';
import fileIcon from 'assets/icons/file.svg';
import circleIcon from 'assets/icons/gray-x-circle.svg';
import checkedIcon from 'assets/icons/check-circle.svg';
import errorFileIcon from 'assets/icons/error_file.svg';
import { selectUploadLocationsState } from 'state/selectors/locations';
import {
  clearUploadLocations,
  fetchLocations,
  uploadLocations,
} from 'state/actions/locations';

import csvData, { LocationCsv } from 'utils/locations/bulkExample';
import { CSVLink } from 'react-csv';
import client, { REWARDS_BASE_URL } from 'services/kardAPI';
import selectFeatureFlagsState from 'state/selectors/featureFlags';
import useModal from 'hooks/useModal';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import classes from './LocationsUpload.module.scss';

const FILTER_BY_CREATED_DATE_DESC = '?createdDateSort=-1';

enum DaysOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

type Address = {
  street: string;
  city: string;
  state: string;
  zipCode: string;
};

type Geolocation = {
  longitude: number;
  latitude: number;
};

type TransformedLocation = {
  name: string;
  merchantId: string;
  locationType: string;
  address: Address;
  source: string;
  phone: string;
  operationHours: Record<DaysOfWeek, string>;
  networkData: Array<{ network: string; networkMerchantId: string }>;
  geoLocation?: Geolocation;
  status: string;
  websiteURL: string;
  offerId: string;
  _id?: string;
  isValidated?: boolean;
  permanentlyClosed?: boolean;
  googleId?: string;
  storeId?: string;
};

type GeolocationAddress = Omit<Address, 'zipCode'> & {
  postal_code: string;
};

type GeolocationRequestBody = { addresses: Record<number, GeolocationAddress> };
type GeolocationResponse = {
  data: Record<number, Geolocation | { error: string }>;
};

const formatAddress = (location: TransformedLocation): GeolocationAddress => {
  const { zipCode, ...rest } = location.address;
  return { ...rest, postal_code: zipCode };
};

const fetchGeolocations = async (
  locations: TransformedLocation[],
): Promise<GeolocationResponse['data']> => {
  const addresses = locations.reduce<Record<number, GeolocationAddress>>(
    (acc, location, index) => {
      const hasGeoLocation =
        location.geoLocation?.longitude && location.geoLocation?.latitude;
      const isOnline = location.locationType === 'ONLINE';

      if (hasGeoLocation || isOnline) {
        return acc;
      }

      const address = formatAddress(location);

      return { ...acc, [index]: address };
    },
    {},
  );

  if (Object.keys(addresses).length === 0) return [];

  // Batch requests to avoid timeouts
  const BATCH_SIZE = 200;
  const batches = chunk(Object.entries(addresses), BATCH_SIZE);
  const batchObjects = batches.map((batch) => Object.fromEntries(batch));

  try {
    const geolocations = await Promise.all(
      batchObjects.map(async (batch) => {
        const { data } = await client.post<
          string,
          GeolocationResponse,
          GeolocationRequestBody
        >(`${REWARDS_BASE_URL}/portal/geolocations`, { addresses: batch });

        return data;
      }),
    );

    const mergedGeolocations: GeolocationResponse['data'] = merge(
      {},
      ...geolocations,
    );

    return mergedGeolocations;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    return [];
  }
};

const appendGeolocations = async (locations: TransformedLocation[]) => {
  const geolocations = await fetchGeolocations(locations);

  return locations.map((location, index) => {
    const geoLocation = geolocations[index];

    if (!geoLocation || 'error' in geoLocation) {
      return location;
    }

    return { ...location, geoLocation };
  });
};

const DEFAULT_LOCATION: Partial<TransformedLocation> = {
  name: '',
  merchantId: '',
  locationType: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
  },
  operationHours: {
    SUNDAY: 'N/A',
    MONDAY: 'N/A',
    TUESDAY: 'N/A',
    WEDNESDAY: 'N/A',
    THURSDAY: 'N/A',
    FRIDAY: 'N/A',
    SATURDAY: 'N/A',
  },
  networkData: [
    {
      network: '',
      networkMerchantId: '',
    },
  ],
  status: 'ACTIVE',
  websiteURL: '',
  offerId: '',
};

export const transformLocationCsvToJson = (file: File) =>
  new Promise<TransformedLocation[]>((resolve, reject) => {
    Papa.parse<LocationCsv>(file!, {
      header: true,
      skipEmptyLines: 'greedy',
      dynamicTyping: true,
      error(error) {
        reject(error);
      },
      complete(results) {
        const { data, errors } = results;

        if (errors.length > 0) {
          const errorMessage = errors.map((e) => e.message).join(' \n');
          reject(new Error(errorMessage));
        }

        const transformedLocations = data.map<TransformedLocation>(
          (location) => {
            const transformedLocation = Object.entries(location).reduce(
              (acc, [key, value]) => {
                // rename locationId to _id
                if (key === 'locationId') {
                  return value ? set(acc, '_id', value) : acc;
                }

                // Cast to string for certain fields
                if (['phone'].includes(key)) {
                  return value ? set(acc, key, `${value}`) : acc;
                }

                if (key === 'address.zipCode') {
                  return value
                    ? set(acc, key, `${value}`.padStart(5, '0'))
                    : acc;
                }

                // Cast to number for certain fields
                if (key.startsWith('geoLocation')) {
                  return set(acc, key, Number(value));
                }

                return value ? set(acc, key, value) : acc;
              },
              cloneDeep(DEFAULT_LOCATION) as TransformedLocation,
            );

            return transformedLocation;
          },
        );

        resolve(transformedLocations);
      },
    });
  });

type InstructionsProps = {
  attemptedLocations: number;
};

export const Instructions = ({ attemptedLocations }: InstructionsProps) => (
  <div className="m-0 whitespace-normal text-sm font-normal leading-tight text-[#4B5563]">
    {attemptedLocations} {attemptedLocations > 1 ? 'records' : 'record'} checked
    for errors. See status below.
  </div>
);

export const CsvTemplate = () => (
  <div className="m-0 whitespace-normal text-sm font-normal leading-tight text-[#4B5563]">
    Use the{' '}
    <CSVLink
      data={csvData}
      filename="bulk-upload-example.csv"
      className={classes.csv}
    >
      <span className="text-primaryLight underline underline-offset-2">
        CSV template
      </span>
    </CSVLink>{' '}
    to upload locations. To update existing records, make sure to include the
    LocationIds in the file.
  </div>
);

type StatusMessageProps = {
  successUploading: boolean;
  error: any;
  validLocations: number;
  errorMessage?: string;
  errorFileUrl?: string;
  erroredLocations: number;
};

export const StatusMessage = ({
  successUploading,
  error,
  validLocations,
  errorMessage,
  errorFileUrl,
  erroredLocations,
}: StatusMessageProps) => (
  <div className="whitespace-normal">
    {successUploading && !error && (
      <div className="my-3 flex items-start rounded-md border p-3">
        <img className="mr-1 w-4 pt-1" src={checkedIcon} alt="success" />
        <p>
          <span className="font-bold">{validLocations}</span>{' '}
          {validLocations > 1 ? 'records' : 'record'} processing now.
          You&apos;ll get a notification once it&apos;s complete.
        </p>
      </div>
    )}
    {errorMessage && (
      <>
        <div className="my-3 rounded-md border border-[#FDE8E8] bg-[#FDF2F2] p-3">
          <div className="flex items-start">
            <img
              className="mr-1 w-4 pt-1"
              src={exclamationMarkIcon}
              alt="error"
            />
            {errorFileUrl ? (
              <p>
                <span className="font-bold">{erroredLocations}</span>
                {erroredLocations > 1 ? ' records' : ' record'} could not be
                processed. Please resolve the errors in the file below and try
                again.
              </p>
            ) : (
              <p>
                There was a problem uploading the locations. Please ensure
                you&apos;re using the{' '}
                <CSVLink
                  data={csvData}
                  filename="bulk-upload-example.csv"
                  className={classes.csv}
                >
                  <span className="text-primaryLight underline underline-offset-2">
                    correct template
                  </span>
                </CSVLink>{' '}
                and try again.
              </p>
            )}
          </div>
          {errorFileUrl && (
            <a href={errorFileUrl} download>
              {' '}
              <span className="text-primaryLight underline underline-offset-2">
                Download invalid {validLocations > 1 ? 'records' : 'record'}{' '}
                (csv)
              </span>
            </a>
          )}
        </div>
        {errorMessage && !errorFileUrl && (
          <p className="text-sm text-gray-500">
            Still need help? Submit a support ticket.
          </p>
        )}
      </>
    )}
  </div>
);

type ChooseFileButtonProps = {
  errorMessage?: string;
  loading: boolean;
  onClickUploadFileHandler: () => void;
};

export const ChooseFileButton = ({
  errorMessage,
  loading,
  onClickUploadFileHandler,
}: ChooseFileButtonProps) => (
  <div
    className={`flex flex-col ${errorMessage ? 'items-end' : 'items-center'}`}
  >
    <Button
      variant="secondary"
      loading={loading}
      className="mt-5"
      onClick={onClickUploadFileHandler}
    >
      Choose file
    </Button>
    {!errorMessage && (
      <p className="mt-5 text-sm font-normal text-[#6B7280]">
        Max file size: 5000 rows
      </p>
    )}
  </div>
);

type SelectedFileProps = {
  loading: boolean;
  fileName: string;
  onRemoveItemHandler: () => void;
};

export const SelectedFile = ({
  loading,
  fileName,
  onRemoveItemHandler,
}: SelectedFileProps) => (
  <div className="flex flex-col pb-3">
    <p className="mb-2.5 mt-5 text-sm font-normal text-[#4B5563]">
      {loading ? 'Checking for errors...' : 'Selected file:'}
    </p>
    <div className="mr-1 flex rounded-md border py-3 pl-3">
      <img src={fileIcon} alt="success" />
      <p className={`ml-2 mr-1 text-sm font-medium ${classes.ellipsis}`}>
        {fileName}
      </p>
      <button type="button" onClick={onRemoveItemHandler}>
        {loading ? (
          <Spinner
            color={'9CA3AF' as SpinnerColor}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <img src={circleIcon} alt="remove" />
        )}
      </button>
    </div>
  </div>
);

type ActionButtonProps = {
  successUploading: boolean;
  onCancel: () => void;
  file?: File;
  errorMessage?: string;
  loading: boolean;
  onSubmitFile: () => void;
  error: any;
};

export const ActionButton = ({
  successUploading,
  onCancel,
  file,
  errorMessage,
  loading,
  onSubmitFile,
  error,
}: ActionButtonProps) => (
  <div className="flex justify-end pb-1 pr-1">
    {successUploading && !error && !errorMessage ? (
      <Button variant="secondary" onClick={onCancel}>
        Close
      </Button>
    ) : (
      file &&
      !successUploading &&
      !errorMessage && (
        <Button
          onClick={onSubmitFile}
          loading={loading}
          disabled={!!(!file || errorMessage)}
        >
          Upload
        </Button>
      )
    )}
  </div>
);

type Props = {
  title: string;
  subtitle?: string;
  onCancel: () => void;
};

export const LocationsUpload = ({ title, subtitle, onCancel }: Props) => {
  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);
  const { PI_257_LOCATIONS_UPLOAD_FLAG = false } = flags;

  const { onCloseModalHandler } = useModal();

  const dispatch = useDispatch();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const {
    loading: uploadLoading,
    success,
    error,
    payload,
  } = useSelector(selectUploadLocationsState, shallowEqual);

  const [fileName, setFileName] = useState<string>();
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorFileUrl, setErrorFileUrl] = useState<string>();
  const [attemptedLocations, setAttemptedLocations] = useState(0);
  const [validLocations, setValidLocations] = useState(0);
  const [erroredLocations, setErroredLocations] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [successUploading, setSuccessUploading] = useState(false);
  const [geolocationsLoading, setGeolocationsLoading] = useState(false);
  const loading = uploadLoading || geolocationsLoading;

  function getUniqueErrorsCount(e: any) {
    let errorsInArray = new Set();
    if (e?.response?.data?.errors) {
      errorsInArray = new Set(
        e.response.data.errors.map(
          (errorItem: { numberIndex: any }) => errorItem.numberIndex,
        ),
      );
    }
    return errorsInArray.size;
  }

  const resetLocationCounts = () => {
    setAttemptedLocations(0);
    setErroredLocations(0);
    setValidLocations(0);
  };

  const handleError = useCallback(() => {
    if (PI_257_LOCATIONS_UPLOAD_FLAG) {
      setErrorMessage(error);
      setErrorFileUrl(error.response.data.errorFileUrl);
      setErroredLocations(getUniqueErrorsCount(error));
    } else if (error.response) {
      setErrorMessage(error.response.data);
    } else {
      setErrorMessage(error.message);
    }
    dispatch(clearUploadLocations());
  }, [PI_257_LOCATIONS_UPLOAD_FLAG, error, dispatch]);

  useEffect(() => {
    if (error) {
      handleError();
    }
  }, [error, handleError]);

  const handleSuccess = useCallback(() => {
    if (payload?.errors?.length > 0) {
      setErrorFileUrl(payload.errorFileUrl);
      const uniqueErrorsCount = getUniqueErrorsCount({
        response: { data: { errors: payload.errors } },
      });
      setErroredLocations(uniqueErrorsCount);

      setErrorMessage(payload.errors?.[0]?.message);
      setValidLocations(attemptedLocations - uniqueErrorsCount);
    } else {
      setValidLocations(attemptedLocations - erroredLocations);
    }
    setSuccessUploading(true);
    dispatch(fetchLocations(FILTER_BY_CREATED_DATE_DESC));
  }, [payload, dispatch, attemptedLocations, erroredLocations]);

  useEffect(() => {
    if (success) {
      handleSuccess();
    }
  }, [success, handleSuccess]);

  const onClickUploadFileHandler = useCallback(() => {
    if (PI_257_LOCATIONS_UPLOAD_FLAG) {
      resetLocationCounts();
      inputFileRef.current?.click();
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
      setSuccessUploading(false);
      setErrorMessage(undefined);
    } else {
      inputFileRef.current?.click();
      setSuccessUploading(false);
      setErrorMessage(undefined);
    }
  }, [PI_257_LOCATIONS_UPLOAD_FLAG, inputFileRef]);

  const onChangeFileInputHandler = useCallback<
    FormEventHandler<HTMLInputElement>
  >(
    (event) => {
      const newFile = event.currentTarget.files?.[0];
      if (newFile) {
        setFile(newFile);
        setFileName(newFile.name);
      }
    },
    [setFile, setFileName],
  );

  const onCloseModal = useCallback(() => {
    if (PI_257_LOCATIONS_UPLOAD_FLAG) {
      if (errorFileUrl || (fileName && !successUploading)) {
        setShowConfirmation(true);
      } else {
        setShowConfirmation(false);
        onCancel();
      }
    } else {
      onCancel();
    }
  }, [
    PI_257_LOCATIONS_UPLOAD_FLAG,
    errorFileUrl,
    fileName,
    onCancel,
    successUploading,
  ]);

  const onSubmitFile = useCallback(async () => {
    if (file) {
      let transformedLocations: TransformedLocation[] = [];
      try {
        transformedLocations = await transformLocationCsvToJson(file);
      } catch (err) {
        if (err instanceof Error) {
          setErrorMessage(err.message);
        }
      }

      if (transformedLocations.length > 0) {
        setGeolocationsLoading(true);
        try {
          const locationsWithGeolocation =
            await appendGeolocations(transformedLocations);

          setGeolocationsLoading(false);

          const jsonObject = { locations: locationsWithGeolocation };
          if (PI_257_LOCATIONS_UPLOAD_FLAG) {
            setAttemptedLocations(transformedLocations.length);
          }
          await dispatch(
            uploadLocations(jsonObject, PI_257_LOCATIONS_UPLOAD_FLAG),
          );
        } catch (err: any) {
          setGeolocationsLoading(false);

          if (err instanceof Error) {
            setErrorMessage(err.message);
          }
        }
      }
    }
  }, [file, dispatch, PI_257_LOCATIONS_UPLOAD_FLAG]);

  const onRemoveItemHandler = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.value = inputFileRef.current.defaultValue;
    }
    setFile(undefined);
    setFileName('');
  }, []);

  return PI_257_LOCATIONS_UPLOAD_FLAG ? (
    <>
      {showConfirmation && (
        <ConfirmationMessage
          message="If you leave now, your upload will be canceled. Are you sure you want to leave?"
          onAccept={() => {
            onCloseModalHandler();
            onCancel();
          }}
          onCancel={() => setShowConfirmation(false)}
          acceptButtonProps={{
            children: 'Leave now',
            variant: 'destructive',
          }}
          cancelButtonProps={{
            children: 'Go back',
            variant: 'secondary',
          }}
        />
      )}
      {!showConfirmation && (
        <>
          <div
            className={`mb-0 flex justify-between ${
              errorMessage && !errorFileUrl && 'mb-2'
            } ${errorFileUrl && 'mb-0'}`}
          >
            <Heading size={HeadingSize.M} className={classes.heading}>
              {title}
            </Heading>
            <button
              type="button"
              onClick={onCloseModal}
              aria-label="Close"
              className="relative mb-2 rounded-lg p-2 hover:bg-gray-50"
            >
              <CancelIcon />
            </button>
          </div>
          {subtitle && (
            <Body size={BodySize.S} color={BodyColor.Gray}>
              {subtitle}
            </Body>
          )}
          {errorMessage && errorFileUrl && (
            <Instructions attemptedLocations={attemptedLocations} />
          )}
        </>
      )}
      {!showConfirmation && (
        <div>
          {!errorMessage && !successUploading && <CsvTemplate />}
          <input
            type="file"
            accept=".csv"
            onChange={onChangeFileInputHandler}
            className={classes.inputFile}
            ref={inputFileRef}
          />
          {successUploading || errorMessage ? (
            <StatusMessage
              successUploading={successUploading}
              error={error}
              validLocations={validLocations}
              errorMessage={errorMessage}
              errorFileUrl={errorFileUrl}
              erroredLocations={erroredLocations}
            />
          ) : null}
          {!file || errorMessage ? (
            <ChooseFileButton
              loading={loading}
              onClickUploadFileHandler={onClickUploadFileHandler}
              errorMessage={errorMessage}
            />
          ) : null}
          {fileName && !successUploading && !errorMessage ? (
            <SelectedFile
              loading={loading}
              fileName={fileName}
              onRemoveItemHandler={onRemoveItemHandler}
            />
          ) : null}
          <ActionButton
            successUploading={successUploading}
            onCancel={onCancel}
            file={file}
            errorMessage={errorMessage}
            loading={loading}
            onSubmitFile={onSubmitFile}
            error={error}
          />
        </div>
      )}
    </>
  ) : (
    <>
      <button
        type="button"
        onClick={onCancel}
        aria-label="Close"
        className="relative left-[37.5rem] rounded-lg p-2 hover:bg-gray-50"
      >
        <CancelIcon />
      </button>
      <div className="relative bottom-[30px] w-fit">
        <Heading size={HeadingSize.M} className={classes.heading}>
          {title}
        </Heading>
        {subtitle && (
          <Body
            size={BodySize.S}
            className={classes.subtitle}
            color={BodyColor.Gray}
          >
            {subtitle}
          </Body>
        )}
        <p className="text-sm font-normal leading-tight text-[#4B5563]">
          Upload new locations or update existing records using the Location ID.
        </p>
      </div>
      <div>
        <div className="relative bottom-[20px] flex text-sm font-normal leading-tight text-[#4B5563]">
          <CSVLink
            data={csvData}
            filename="bulk-upload-example.csv"
            className={classes.csv}
          >
            <p className="text-primaryLight underline underline-offset-1">
              Download CSV template
            </p>
          </CSVLink>
          <p className="ml-1">for the required format.</p>
        </div>

        <input
          type="file"
          accept=".csv"
          onChange={onChangeFileInputHandler}
          className={classes.inputFile}
          ref={inputFileRef}
        />
        {(!file || errorMessage) && (
          <div className="flex flex-col items-center">
            <p className="mb-2.5 text-sm font-normal text-[#4B5563]">
              Max limit: 1000 rows
            </p>
            <Button loading={loading} onClick={onClickUploadFileHandler}>
              Choose file
            </Button>
          </div>
        )}
        {fileName && !successUploading && !errorMessage && (
          <div className="flex flex-col items-center py-4">
            <p className="mb-2.5 text-sm font-normal text-[#4B5563]">
              Selected file
            </p>
            <div className="flex">
              <img src={fileIcon} alt="success" />
              <p className="ml-2 mr-1 text-sm font-medium">{fileName}</p>
              <button type="button" onClick={onRemoveItemHandler}>
                <img src={circleIcon} alt="remove" />
              </button>
            </div>
          </div>
        )}
        {successUploading && (
          <div className="flex flex-col items-center p-4">
            <p className="mb-2.5 text-sm font-normal text-[#4B5563]">
              File uploaded successfully
            </p>
            <div className="flex">
              <img src={fileIcon} alt="file" />
              <p className="ml-2 mr-1 text-sm font-medium">{fileName}</p>
              <button type="button" onClick={onRemoveItemHandler}>
                <img src={checkedIcon} alt="success" />
              </button>
            </div>
          </div>
        )}
        {errorMessage && (
          <div className="w-[37.5rem] p-2">
            <div className={`${classes.flexColumn} ${classes.flexCenter}`}>
              <div className={classes.flex}>
                <img
                  src={errorFileIcon}
                  alt="error"
                  className={classes.image}
                />
                <Body
                  size={BodySize.XS}
                  className="flex items-center justify-center whitespace-normal text-red-600"
                >
                  Your file cannot be uploaded
                </Body>
              </div>
              <p className="text-sm font-medium text-red-600">{errorMessage}</p>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end pb-1 pr-1">
        {successUploading ? (
          <Button
            variant="secondary"
            onClick={onCancel}
            className={classes.cancelButton}
          >
            Close
          </Button>
        ) : (
          <Button
            onClick={onSubmitFile}
            loading={loading}
            disabled={!!(!file || errorMessage)}
          >
            {loading ? 'Uploading...' : 'Upload'}
          </Button>
        )}
      </div>
    </>
  );
};

export default LocationsUpload;
