import { useCallback, useEffect, useRef, useState } from 'react';
import {
  MS_PER_SECOND,
  SECONDS_PER_MINUTE,
} from 'constants/timeUnits.constant';
import createDeadline from './createDeadline';
import getTimeRemaining from './getTimeRemaining';

/**
 * Warn the user before logging them out after some amount of idle time.
 *
 * 1. The user performs some activity, or logs in.
 * 2. At most once per msThrottleInterval, this activity triggers a reset of the countdown timer.
 * 3. When secondsShowingWarning are left, we showWarning with a timerText in mm:ss format.
 * 4. The timerText ticks down every second.
 * 5. When the timerText reads 00:00, the user is logged out.
 *
 * @param isAuthenticated whether the user is logged in.
 * @param logout function which logs the user out.
 * @param secondsUntilLogout seconds idle before the user is logged out.
 * @param secondsShowingWarning seconds showing warning before the user is logged out.
 * @param msThrottleInterval user activity can only be handled once per this amount of milliseconds.
 * @returns the timer text as mm:ss, a throttled activity handler, and whether to show the warning.
 */
const useIdleTracker = (
  isAuthenticated,
  logout,
  secondsUntilLogout = 15 * SECONDS_PER_MINUTE,
  secondsShowingWarning = 5 * SECONDS_PER_MINUTE,
  msThrottleInterval = MS_PER_SECOND,
) => {
  // output UI state
  const [timerText, setTimerText] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  // used to sync activity between browsing contexts (tabs)
  const activityChannelRef = useRef();

  // used to throttle activity
  const lastActivityUpdate = useRef();

  // current timeout/interval tracking idle time
  const timerId = useRef();

  // a timer or interval with this ID may be counting down at any given time; clear it.
  const clearTimer = () => {
    clearTimeout(timerId.current);
    clearInterval(timerId.current);
  };

  // clean up timer on unmount
  useEffect(() => clearTimer, []);

  /**
   * Set the timer text in mm:ss format.
   * @param param0 result of getTimeRemaining(deadline)
   */
  const updateTimerText = ({ minutes, seconds }) =>
    setTimerText(
      `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`,
    );

  /**
   * Reset the timer, optionally informing other browsing contexts of activity.
   */
  const reset = useCallback(
    (broadcast) => {
      if (broadcast && activityChannelRef.current !== undefined) {
        activityChannelRef.current?.postMessage('reset');
      }
      setShowWarning(false);
      clearTimer();

      /**
       * For a given deadline, creates a function which will run every second while the warning is shown,
       * updating the timer text and logging out if the deadline has been reached.
       * @param deadline when the user will be logged out
       * @returns
       */
      const handleWarningUpdateFactory = (deadline) => () => {
        const timeRemaining = getTimeRemaining(deadline);
        if (timeRemaining.total <= 0) {
          setShowWarning(false);
          clearTimer();
          logout();
        }
        updateTimerText(timeRemaining);
      };

      /**
       * Show the warning and update it every second until a deadline is reached.
       */
      const handleWarning = () => {
        setShowWarning(true);
        const deadline = createDeadline(secondsShowingWarning);
        updateTimerText(getTimeRemaining(deadline));
        timerId.current = setInterval(
          handleWarningUpdateFactory(deadline),
          MS_PER_SECOND,
        );
      };

      // set timeout until warning should be shown
      timerId.current = setTimeout(
        handleWarning,
        (secondsUntilLogout - secondsShowingWarning) * MS_PER_SECOND,
      );
    },
    [logout, secondsShowingWarning, secondsUntilLogout],
  );

  useEffect(() => {
    // start a new countdown when logging in
    if (isAuthenticated) {
      reset(true);
      return;
    }
    // edge case: clear the countdown when logging out
    clearTimer();
  }, [isAuthenticated, reset]);

  // set up the activity channel.
  useEffect(() => {
    activityChannelRef.current = new BroadcastChannel('activity');

    activityChannelRef.current?.addEventListener('message', () => reset(false));
    // clean up on unmount
    return () => {
      activityChannelRef.current?.close();
      // added because postMessage() was being fired during hot reload unmount
      activityChannelRef.current = undefined;
    };
  }, [reset]);

  /**
   * Resets the timer at most once per msThrottleInterval.
   * Designed for use with high-frequency user events like mousemove.
   */
  const activityHandler = useCallback(() => {
    if (!isAuthenticated) return;
    // throttle - ignore activity if last handled less than msThrottleInterval ago
    const now = new Date().getTime();
    if (now - lastActivityUpdate.current < msThrottleInterval) {
      return;
    }
    lastActivityUpdate.current = now;

    reset(true);
  }, [isAuthenticated, reset, msThrottleInterval]);

  return [timerText, activityHandler, showWarning];
};

export default useIdleTracker;
