import { useEffect } from 'react';

const useExternalScript = (url: string) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    if (!head) return;

    script.setAttribute('src', url);
    script.setAttribute('type', 'module');
    script.setAttribute('lang', 'javascript');
    script.setAttribute('async', '');
    head.appendChild(script);

    // eslint-disable-next-line consistent-return
    return () => {
      head.removeChild(script);
    };
  }, [url]);
};

export default useExternalScript;
