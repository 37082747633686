import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const fetchLocations = async (filters = '') => {
  try {
    const locations = await client.get(
      `${REWARDS_BASE_URL}/portal/merchant/locations${filters}`,
    );
    return locations.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchLocations;
