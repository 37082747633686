/* enums */
import SortDirection from 'refactored/enums/query/sortDirection.enum';

/* styling */
import classNames from 'classnames';
import ArrowUp from 'assets/icons/arrow-up.svg?react';
import classes from './SortableHeader.module.scss';

type SortableHeaderProps = {
  header?: string;
  displaySort?: boolean;
  columnName?: string;
  sortState: {
    columnName?: string;
    direction?: SortDirection;
  };
  filters?: any;
  onSubmit: Function;
};

const SortableHeader = ({
  header,
  displaySort,
  columnName,
  sortState,
  filters,
  onSubmit,
}: SortableHeaderProps) => {
  const cursorStyle = displaySort
    ? classes.cursorPointer
    : classes.cursorDefault;

  const getTextClass = () => {
    if (!displaySort) return classes.text;
    if (sortState?.columnName === columnName) return classes.textActive;
    return classes.hoverableText;
  };

  const handleSort = () => {
    if (!displaySort) return;
    const draftSort = {
      columnName,
      direction:
        sortState?.direction === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending,
    };
    onSubmit({ filter: filters, sort: draftSort });
  };

  const handleArrowSort = (direction: number) => {
    const draftSort = {
      columnName,
      direction,
    };
    onSubmit({ filter: filters, sort: draftSort });
  };

  return (
    <button
      type="button"
      onClick={handleSort}
      className={classNames(classes.header, cursorStyle)}
    >
      <span className={getTextClass()} data-testid={`sortableHeader_${header}`}>
        {header}
      </span>
      {displaySort && (
        <div className={classes.arrows}>
          <button
            type="button"
            onClick={() => handleArrowSort(SortDirection.Ascending)}
            aria-label="Sort ascending"
          >
            <ArrowUp />
          </button>
          <button
            type="button"
            onClick={() => handleArrowSort(SortDirection.Descending)}
            aria-label="Sort descending"
          >
            <div className="-mt-1.5 rotate-180">
              <ArrowUp />
            </div>
          </button>
        </div>
      )}
    </button>
  );
};

export default SortableHeader;
