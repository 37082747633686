import ClipLoader from 'react-spinners/ClipLoader';

export enum Size {
  L = 12,
  M = 8,
  S = 6,
}

export enum Color {
  White = '#FFF',
  Black = '#000',
}

export type SpinnerProps = {
  color: Color;
  size: Size;
  className: string;
};

const Spinner = ({ color = Color.White, size = Size.M, className = '' }) => (
  <div className={className}>
    <ClipLoader color={color} size={size} />
  </div>
);

export default Spinner;
