/* modules */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Spinner } from 'flowbite-react';

/* redux */
/* auth */
import { selectUserAttributesState } from 'state/selectors/auth';
/* merchants */
import {
  selectFetchMerchantsState,
  selectCreateMerchantState,
  selectEditMerchantState,
} from 'state/selectors/merchants';
import {
  clearCreateMerchant,
  clearMerchantsErrors,
  fetchMerchants,
} from 'state/actions/merchants';
/* merchants networks */
import { selectFetchMerchantNetworksState } from 'state/selectors/merchantNetworks';
import { fetchMerchantNetworks } from 'state/actions/merchantNetworks';
/* exports */
import selectExportDataState from 'state/selectors/exportData';
import { exportData } from 'state/actions/exportData';
import selectFeatureFlagsState from 'state/selectors/featureFlags';

/* custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import useModal from 'hooks/useModal';

/* custom components */
import SearchBy from 'refactored/components/Common/SearchBy';
import Table from 'refactored/components/Common/Table';
import Pagination from 'refactored/components/Common/Pagination2';
import Modal from 'components/Common/Modal';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import SelectDropdown from 'refactored/components/Common/SelectDropdown';
import MultiSelectDropdown from 'refactored/components/Common/MultiSelectDropdown';
import SelectDateRange from 'refactored/components/Common/SelectDateRange';
// this component shouldn't be inside ReportFilters component, should be on components/Pages folder
import MerchantNetworkForm from 'components/Pages/ReportFilters/Modal/MerchantNetwork/merchantNetwork';
import PageHeader from 'components/Common/PageHeader';
import Button from 'components/Common/Button';

/* enums */
import Status from 'enums/status/status.enum';
import ModalType from 'enums/modal/modalType.enum';
import Path from 'enums/path.enum';
import ModelType from 'enums/export/modelType.enum';

/* utils */
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import makeColumns from 'refactored/utils/merchants/makeColumns';
import sortByQueryToSearchParams from 'refactored/utils/query/sortByQueryToSearchParams';
import getIdToken from 'utils/getIdToken';
import getParsedFilters from 'utils/getParsedFilters';
import categoryList from 'utils/categories';

/* assets */
import AddIcon from 'assets/icons/plus.svg?react';
import ExportIcon from 'assets/icons/download.svg?react';

/* types */
import { IMerchant } from '@kardfinancial/models';
import type {
  MerchantNetworkResponse,
  MerchantResponse,
  EditCreateMerchantResponse,
  UserAttributesResponse,
  ExportResponse,
  FlagsResponse,
} from 'types/responses';

/* styling */
import classes from './Merchants.module.scss';

const categoryListOptions = categoryList.map((item) => ({
  label: item,
  value: item,
}));

const searchStatusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Closed', value: 'CLOSED' },
  { label: 'Deleted', value: 'DELETED' },
];

type DataMerchant = Omit<IMerchant, 'status'> & {
  status: Status;
};

const Merchants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* internal state */
  const [data, setData] = useState<DataMerchant[]>([]);
  const [showOffersAndLocations, setShowOffersAndLocations] = useState(false);

  /* query framework */
  const [query, updateQuery] = useQuery({
    pagination: { page: 0, limit: 100 },
    sort: { direction: -1, columnName: 'createdDate' },
  });

  /* modal */
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  /* selectors */
  const {
    merchantsData,
    error: errorFetchMerchants,
    loading: loadingFetchMerchants,
  }: MerchantResponse = useSelector(selectFetchMerchantsState, shallowEqual);

  const { roles: userRoles, isAdmin }: UserAttributesResponse = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const { merchantNetworks }: MerchantNetworkResponse = useSelector(
    selectFetchMerchantNetworksState,
    shallowEqual,
  );

  const merchantNetworkOptions = merchantNetworks.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  const { success: successEdit, error: errorEdit }: EditCreateMerchantResponse =
    useSelector(selectEditMerchantState, shallowEqual);

  const {
    success: successCreate,
    error: errorCreate,
  }: EditCreateMerchantResponse = useSelector(
    selectCreateMerchantState,
    shallowEqual,
  );

  const { loading: exportInProgress }: ExportResponse = useSelector(
    selectExportDataState,
    shallowEqual,
  );

  const { flags }: FlagsResponse = useSelector(
    selectFeatureFlagsState,
    shallowEqual,
  );
  const { IS_1917_REWARDS_MAINTANENCE } = flags;

  /* const */
  const { countTotal, merchants } = merchantsData;

  const noDataToShow = data.length === 0;

  const activeMerchantById =
    !!query.filters?.id &&
    query.filters.id.selected[0] !== '' &&
    data.length === 1 &&
    data[0].status === Status.ACTIVE;

  const statusFilterValue: string[] | undefined =
    query.filters?.status?.selected;

  // If status filters include active, or no status filters are added (ensures that active merchants are returned in the table)
  const activeMerchantsShown =
    statusFilterValue?.includes(Status.active) ||
    !statusFilterValue ||
    statusFilterValue?.length === 0;

  const disableExportButton =
    !(activeMerchantsShown || activeMerchantById) ||
    loadingFetchMerchants ||
    exportInProgress ||
    noDataToShow;

  /* methods */
  const handleAddNewMerchantNetwork = useCallback(() => {
    onOpenModalHandler(ModalType.ADD_MERCHANT_NETWORK);
  }, [onOpenModalHandler]);

  const onAddMerchantHandler = () => navigate(`${Path.Merchants}/create`);

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();
    const filters = getParsedFilters(
      `?${sortByQueryToSearchParams(query).toString()}`,
    );

    if (activeMerchantById) {
      const [_id] = query.filters.id.selected;
      filters._id = _id;
    }

    const exportInfo = {
      idToken,
      filters,
      model: ModelType.Merchant,
    };

    dispatch(exportData(exportInfo));
  };

  const getExportMerchantsTooltip = () => {
    if (loadingFetchMerchants) {
      return '';
    }

    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (noDataToShow) {
      return 'There is no data to export';
    }

    if (!(activeMerchantsShown || activeMerchantById)) {
      if (!activeMerchantsShown) {
        return 'There are no active merchants to export';
      }

      if (!activeMerchantById) {
        return 'Cannot export inactive/closed merchants';
      }
    }

    return '';
  };

  const revampMoreInfoHandler = useCallback(
    (merchant: IMerchant) =>
      window.open(
        `${Path.Merchants}/edit/${merchant._id}`,
        '_blank',
        'noreferrer',
      ),
    [],
  );

  /* table columns */
  const columns = useMemo(
    () =>
      getAllowedColumns(
        makeColumns({
          moreInfoHandler: revampMoreInfoHandler,
        }),
        userRoles,
      ),
    [userRoles, revampMoreInfoHandler],
  );

  /* cycles */
  /* merchants networks */
  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchMerchantNetworks());
    }
  }, [isAdmin, dispatch]);

  /* merchants */
  /* fetch merchants filtered/sorted by query params */
  useEffect(() => {
    dispatch(fetchMerchants(`?${sortByQueryToSearchParams(query).toString()}`));
  }, [dispatch, query]);

  useEffect(() => {
    const transformedData = merchants.map((merchant) => ({
      ...merchant,
      merchantId: merchant._id,
      status: Status[merchant.status as keyof typeof Status],
    }));

    setData(transformedData);
  }, [merchants]);

  /* handle create workflow */
  useEffect(() => {
    if (successCreate) {
      if (!showOffersAndLocations) {
        setShowOffersAndLocations(true);
        dispatch(clearMerchantsErrors());
      }
      dispatch(clearCreateMerchant());
    }
  }, [query, successCreate, showOffersAndLocations, dispatch, navigate]);

  /* handle edit workflow */
  useEffect(() => {
    if (successEdit) {
      dispatch(
        fetchMerchants(`?${sortByQueryToSearchParams(query).toString()}`),
      );
      onCloseModalHandler();
    }
  }, [query, successEdit, onCloseModalHandler, dispatch]);

  /* handle error creation/edition */
  useEffect(() => {
    if (errorCreate || errorEdit) {
      dispatch(clearMerchantsErrors());
    }
  }, [errorCreate, errorEdit, dispatch]);

  return (
    <>
      {errorFetchMerchants && (
        <Toast
          id="fetch-merchatns"
          text="Error fetching merchants"
          type={ToastType.Error}
        />
      )}
      {successCreate && (
        <Toast
          id="fetch-merchatns"
          text="Successfully created merchant"
          type={ToastType.Success}
        />
      )}
      {IS_1917_REWARDS_MAINTANENCE && (
        <Toast
          id="rewards-maintainence-flag"
          text="REWARDS MAINTANENCE ONGOING. RESTRICTING ACCESS TO MERCHANT EDIT/CREATE FUNCTIONALITY"
          type={ToastType.Error}
        />
      )}

      {/* this feature was moved here from ReportsFilers component, it makes more sense to have it here */}
      <Modal
        isOpen={modal.type === ModalType.ADD_MERCHANT_NETWORK}
        onClose={onCloseModalHandler}
        className="w-416 !overflow-y-hidden"
      >
        <MerchantNetworkForm onCancel={onCloseModalHandler} />
      </Modal>

      <div className={classes.flexboxLayout}>
        <PageHeader title="Merchants" className="m-[2.5rem] mb-[1rem]">
          {isAdmin ? (
            <>
              <Button
                data-cy="add-merchant"
                type="submit"
                onClick={onAddMerchantHandler}
                icon={<AddIcon />}
                disabled={IS_1917_REWARDS_MAINTANENCE}
              >
                Create merchant
              </Button>
              <Button
                variant="secondary"
                data-cy="add-merchant-network"
                onClick={handleAddNewMerchantNetwork}
              >
                Create merchant network
              </Button>
            </>
          ) : (
            <span
              data-tooltip-id="exportMerchants"
              data-tooltip-content={getExportMerchantsTooltip()}
            >
              <Button
                disabled={disableExportButton}
                onClick={onExportDataHandler}
                icon={<ExportIcon />}
              >
                Export view as CSV
              </Button>
            </span>
          )}
        </PageHeader>

        <div className={classes.flexboxLayout__filters}>
          <SearchBy
            field="name"
            idField="id"
            placeholder="Search by Merchant Name or ID"
            query={query}
            className="h-[37px] !w-[24.2rem]"
            updateQuery={updateQuery}
            showCancelIcon
          />

          <SelectDropdown
            query={query}
            fieldName="source"
            options={[
              { label: 'National', value: 'NATIONAL' },
              { label: 'Local', value: 'LOCAL' },
            ]}
            updateQuery={updateQuery}
            label="Source"
          />

          {isAdmin && (
            <>
              <SelectDropdown
                query={query}
                fieldName="merchantNetwork"
                options={merchantNetworkOptions}
                updateQuery={updateQuery}
                label="Merchant Network"
                searchable
              />
              <SelectDateRange
                query={query}
                updateQuery={updateQuery}
                fieldName="createdDate"
                label="Created Date"
              />
            </>
          )}

          <MultiSelectDropdown
            query={query}
            fieldName="status"
            options={searchStatusOptions}
            defaultSelection={searchStatusOptions.map((option) => option.value)}
            updateQuery={updateQuery}
            label="Status"
          />

          <SelectDropdown
            query={query}
            fieldName="category"
            options={categoryListOptions}
            updateQuery={updateQuery}
            label="Category"
            searchable
          />
        </div>

        {loadingFetchMerchants ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner size="lg" color="gray" />
          </div>
        ) : (
          <>
            <div className={classes.flexboxLayout__table}>
              <Table
                id="table-merchants"
                columns={columns}
                data={data}
                query={query}
                updateQuery={updateQuery}
              />
            </div>

            <div className={classes.flexboxLayout__pagination}>
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>

      <ReactTooltip id="exportMerchants" />
    </>
  );
};

export default Merchants;
