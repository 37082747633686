/* modules */
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Spinner } from 'flowbite-react';

/* redux */
/* locations */
import {
  selectEditLocationState,
  selectFetchLocationsState,
} from 'state/selectors/locations';
import { fetchLocations } from 'state/actions/locations';
import { clearEditLocationSuccessProp } from 'state/actionCreators/locations';
/* users */
import { selectUserAttributesState } from 'state/selectors/auth';
/* exports */
import { exportData } from 'state/actions/exportData';
import selectExportDataState from 'state/selectors/exportData';

/* enums */
import ModalType from 'enums/modal/modalType.enum';
import US_STATES_LABELED from 'constants/states.refactor.constant';
import ModelType from 'enums/export/modelType.enum';

/* utils */
import makeColumns from 'refactored/utils/locations/makeColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import getIdToken from 'utils/getIdToken';
import getParsedFilters from 'utils/getParsedFilters';

/* custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import useModal from 'hooks/useModal';

/* custom components */
import SearchBy from 'refactored/components/Common/SearchBy';
import Table from 'refactored/components/Common/Table';
import Pagination from 'refactored/components/Common/Pagination2';
import Modal from 'components/Common/Modal';
import { LocationsUpload } from 'components/Pages/LocationsUpload';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import PageHeader from 'components/Common/PageHeader';
import Button from 'components/Common/Button';

/* assets */
import ExportIcon from 'assets/icons/download.svg?react';
import UploadIcon from 'assets/icons/upload_file.svg?react';

/* styling */
import Path from 'enums/path.enum';
import MultiSelectDropdown from 'refactored/components/Common/MultiSelectDropdown';
import SelectDropdown from 'refactored/components/Common/SelectDropdown';
import Search from 'refactored/components/Common/Search';
import SelectDateRange from 'refactored/components/Common/SelectDateRange';
import selectFeatureFlagsState from 'state/selectors/featureFlags';
import classes from './Locations.module.scss';

const stateListOptions = Object.entries(US_STATES_LABELED).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

const Locations = () => {
  const dispatch = useDispatch();

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);

  const { PI_257_LOCATIONS_UPLOAD_FLAG = false, IS_1917_REWARDS_MAINTANENCE } =
    flags;

  /* search options */
  const searchStatusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
    { label: 'Closed', value: 'CLOSED' },
  ];

  /* internal state */
  const [data, setData] = useState([]);

  /* query framework */
  const [query, updateQuery] = useQuery({
    pagination: { page: 0, limit: 100 },
    sort: { direction: -1, columnName: 'createdDate' },
  });

  /* modal */
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  /* selectors */
  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const {
    locationsData: { countTotal, locations },
    loading: loadingFetchLocations,
    error: errorFetchLocations,
  } = useSelector(selectFetchLocationsState, shallowEqual);

  const { success: successEdit, error: errorEdit } = useSelector(
    selectEditLocationState,
    shallowEqual,
  );

  const { loading: exportInProgress } = useSelector(
    selectExportDataState,
    shallowEqual,
  );

  /* const */
  const noDataToShow = locations.length === 0;
  const disableExportButton =
    loadingFetchLocations || exportInProgress || noDataToShow;

  const locationById =
    !!query.filters?.locationId &&
    query.filters.locationId.selected[0] !== '' &&
    locations.length === 1;

  /* methods */
  const revampedMoreInfoHandler = useCallback(
    // used useCallback for columns useMemo
    (location) =>
      window.open(
        `${Path.Locations}/info/${location._id}`,
        '_blank',
        'noreferrer',
      ),
    [],
  );

  const onClickUploadLocationHandler = useCallback(() => {
    onOpenModalHandler(ModalType.UPLOAD_LOCATION);
  }, [onOpenModalHandler]);

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();
    const filters = getParsedFilters(
      `?${queryToSearchParams(query).toString()}`,
    );

    if (locationById) {
      const [_id] = query.filters.locationId.selected;
      filters._id = _id;
    }

    const exportInfo = {
      idToken,
      filters,
      model: ModelType.Location,
    };

    dispatch(exportData(exportInfo));
  };

  const getExportLocationsTooltip = () => {
    if (loadingFetchLocations) {
      return '';
    }

    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (noDataToShow) {
      return 'There is no data to export';
    }

    return '';
  };

  /* table columns */
  const columns = useMemo(
    () =>
      getAllowedColumns(
        makeColumns({
          moreInfoHandler: revampedMoreInfoHandler,
        }),
        userRoles,
      ),
    [userRoles, isAdmin, revampedMoreInfoHandler],
  );

  /* cycles */
  /* locations */
  /* fetch locations filtered/sorted by query params */
  useEffect(() => {
    dispatch(fetchLocations(`?${queryToSearchParams(query).toString()}`));
  }, [dispatch, query]);

  /* edit handler */
  useEffect(() => {
    if (successEdit) {
      dispatch(fetchLocations(`?${queryToSearchParams(query).toString()}`));
      dispatch(clearEditLocationSuccessProp());
      onCloseModalHandler();
    }
  }, [query, successEdit, dispatch, onCloseModalHandler]);

  /* transform offers data */
  useEffect(() => {
    const transformedData = locations.map((location) => ({
      ...location,
      locationName: location?.name ?? '',
      street: location?.address?.street ?? '',
      city: location?.address?.city ?? '',
      state: location?.address?.state ?? '',
      zipCode: location?.address?.zipCode ?? '',
    }));

    setData(transformedData);
  }, [locations]);

  /* render */
  return (
    <>
      {/* TODO: remove this Toast once the revamped Locations page is live */}
      {successEdit && (
        <Toast
          id="update-location-success"
          text="Location updated successfully"
          type={ToastType.Success}
        />
      )}

      {errorEdit && (
        <Toast id="submit-error" text={errorEdit} type={ToastType.Error} />
      )}

      {errorFetchLocations && (
        <Toast
          id="fetch-location"
          text="Error fetching locations"
          type={ToastType.Error}
        />
      )}

      {IS_1917_REWARDS_MAINTANENCE && (
        <Toast
          id="rewards-maintainence-flag"
          text="REWARDS MAINTANENCE ONGOING. RESTRICTING ACCESS TO LOCATION EDIT/CREATE FUNCTIONALITY"
          type={ToastType.Error}
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.UPLOAD_LOCATION}
        className={`${
          PI_257_LOCATIONS_UPLOAD_FLAG ? 'w-[34rem] rounded-md' : 'w-[42rem]'
        } h-auto overflow-y-hidden`}
        onClose={onCloseModalHandler}
      >
        <LocationsUpload
          title={
            PI_257_LOCATIONS_UPLOAD_FLAG
              ? 'Upload locations'
              : 'Add or update locations'
          }
          onCancel={onCloseModalHandler}
        />
      </Modal>

      <div className={classes.flexboxLayout}>
        <PageHeader title="Locations" className="m-[2.5rem] mb-[1rem]">
          {isAdmin ? (
            <Button
              onClick={onClickUploadLocationHandler}
              icon={<UploadIcon />}
              disabled={IS_1917_REWARDS_MAINTANENCE}
            >
              Upload Location
            </Button>
          ) : (
            <span
              data-tooltip-id="exportLocations"
              data-tooltip-content={getExportLocationsTooltip()}
            >
              <Button
                disabled={disableExportButton}
                onClick={onExportDataHandler}
                icon={<ExportIcon />}
              >
                Export view as CSV
              </Button>
            </span>
          )}
        </PageHeader>

        <div className={classes.flexboxLayout__filters}>
          <SearchBy
            field="locationName"
            idField="locationId"
            placeholder="Search by Location Name or ID"
            query={query}
            updateQuery={updateQuery}
            className="h-[37px] !w-[24.2rem]"
            showCancelIcon
            onCancel
          />
          {isAdmin && (
            <>
              <MultiSelectDropdown
                query={query}
                fieldName="status"
                updateQuery={updateQuery}
                options={searchStatusOptions}
                defaultSelection={searchStatusOptions.map(
                  (option) => option.value,
                )}
                label="Status"
              />
              <SelectDropdown
                query={query}
                fieldName="locationType"
                updateQuery={updateQuery}
                options={[
                  { label: 'Instore', value: 'INSTORE' },
                  { label: 'Online', value: 'ONLINE' },
                ]}
                label="Location Type"
              />
            </>
          )}

          <Search
            query={query}
            updateQuery={updateQuery}
            field="city"
            placeholder="City"
            className="h-[37px] !w-[11rem]"
            showCancelIcon
          />

          <MultiSelectDropdown
            query={query}
            fieldName="state"
            updateQuery={updateQuery}
            options={stateListOptions}
            defaultSelection={stateListOptions.map((option) => option.value)}
            label="State"
            searchable
          />

          <Search
            query={query}
            updateQuery={updateQuery}
            field="zipCode"
            placeholder="Zip Code"
            className="h-[37px] !w-[11rem]"
            showCancelIcon
            // TODO - add validation for zip code
          />

          {isAdmin && (
            <SelectDateRange
              query={query}
              fieldName="createdDate"
              updateQuery={updateQuery}
              label="Created Date"
              restrictToPastDates
            />
          )}
        </div>

        {loadingFetchLocations ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner size="lg" color="gray" />
          </div>
        ) : (
          <>
            <div className={classes.flexboxLayout__table}>
              <Table
                id="table-locations"
                columns={columns}
                data={data}
                query={query}
                updateQuery={updateQuery}
              />
            </div>

            <div className={classes.flexboxLayout__pagination}>
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>
      <ReactTooltip id="exportLocations" />
    </>
  );
};

export default Locations;
