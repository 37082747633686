import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const SelectInput = forwardRef(({ testId, ...rest }, ref) => {
  if (!testId) {
    return <Select {...rest} ref={ref} />;
  }

  return (
    <div data-cy={testId}>
      <Select {...rest} ref={ref} />
    </div>
  );
});

SelectInput.propTypes = {
  testId: PropTypes.string,
};

SelectInput.defaultProps = {
  testId: null,
};

export default SelectInput;
