/**
 * Create a new date which is some amount of seconds in the future.
 * @param secondsFromNow how far in the future, in seconds, the date should be.
 * @returns the future date.
 */
const createDeadline = (secondsFromNow = 0) => {
  const newDeadline = new Date();
  newDeadline.setSeconds(newDeadline.getSeconds() + secondsFromNow);
  return newDeadline;
};

export default createDeadline;
