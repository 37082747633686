import client from 'services/kardAPI';
import amplifyErrorMessage from 'utils/amplifyErrorMessage';

const resendConfirmationCodeService = async (body) => {
  try {
    const user = await client.post('/users/portal/cognito/user', body);
    return user;
  } catch (error) {
    const errorMessage = amplifyErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export default resendConfirmationCodeService;
