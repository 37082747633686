type Success<T> = [null, T];

type Failure<E> = [E, null];

type Result<T, E = Error> = Promise<Success<T> | Failure<E>>;

/**
 * Wraps a promise in a try/catch block and returns a tuple of the result and the error
 * @param promise - The promise to wrap
 * @returns A tuple of the result and the error
 */
export const tryCatch = async <T, E = Error>(
  promise: Promise<T>,
): Result<T, E> => {
  try {
    const data = await promise;
    return [null, data];
  } catch (error) {
    return [error as E, null];
  }
};
