export const commonMessages = {
  ReSendInvitationSuccessMsg: 'Invitation sent',
  ReSendInvitationErrorMsg:
    'Oops! There was a problem resending the invitation. Please try again.',
  SuccessEditTransactionMsg: 'Transaction approved successfully.',
  RejectEditTransactionMsg: 'Transaction rejected successfully.',
  ErrorEditTransactionMsg: 'Transaction was not approved.',
  DescriptionSupportMessage: 'Please add some details for us to assist you.',
  SuccessOfferMsg: 'Offer updated successfully.',
};

export default commonMessages;
