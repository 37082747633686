import classes from './Summary.module.scss';

type SummaryProps = {
  data: {
    liveAudiences?: number;
    audienceSize?: number;
    allAudiences?: number;
  };
};

const Summary = ({ data }: SummaryProps) => (
  <div className="flex gap-10">
    <div
      key="Live Audiences"
      className="flex w-full flex-col justify-center gap-4 rounded-lg border border-slate-300 p-3 text-base font-bold"
    >
      <span>Live Audiences</span>
      <span className={classes.summaryValue}>
        {data?.liveAudiences?.toLocaleString()}
      </span>
    </div>

    <div
      key="Avg. Audience Size"
      className="flex w-full flex-col justify-center gap-4 rounded-lg border border-slate-300 p-3 text-base font-bold"
    >
      <span>Avg. Audience Size</span>
      <span className={classes.summaryValue}>
        {data?.audienceSize?.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })}
      </span>
    </div>

    <div
      key="Total Audiences"
      className="flex w-full flex-col justify-center gap-4 rounded-lg border border-slate-300 p-3 text-base font-bold"
    >
      <span>Total Audiences</span>
      <span className={classes.summaryValue}>
        {data?.allAudiences?.toLocaleString()}
      </span>
    </div>
  </div>
);

export default Summary;
