import React from 'react';
import PropTypes from 'prop-types';

import { selectAuthenticatedState } from 'state/selectors/auth';
import { shallowEqual, useSelector } from 'react-redux';
import Path from 'enums/path.enum';
import RestrictedRoute from '../RestrictedRoute';
import RedirectToLogin from '../RedirectLogin';

const AuthenticatedRoute = ({ component, condition, redirectTo, ...rest }) => {
  const { isAuthenticated } = useSelector(
    selectAuthenticatedState,
    shallowEqual,
  );

  return isAuthenticated ? (
    <RestrictedRoute
      component={component}
      condition={condition}
      redirectTo={redirectTo}
      {...rest}
    />
  ) : (
    <RedirectToLogin />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  condition: PropTypes.bool,
  redirectTo: PropTypes.string,
};

AuthenticatedRoute.defaultProps = {
  condition: true,
  redirectTo: Path.Login,
};

export default AuthenticatedRoute;
