import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Alert } from 'flowbite-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useFormContext } from 'react-hook-form';
import useIssuers from 'refactored/hooks/useIssuers';
import LayoutGap from 'components/Common/LayoutGap';
import Input, {
  Type as InputType,
  IconPosition as InputIconPosition,
} from 'refactored/components/Common/Input';
import Modal from 'components/Common/Modal';
import Heading from 'components/Typography/Heading';
import Button from 'components/Common/Button';
import FormControl from 'components/Common/FormControl';
import EmptyState from 'components/Common/EmptyState';
import ButtonGroup from 'components/Common/ButtonGroup';

import getCommissionSplit from 'utils/commission/getCommissionSplit';
import {
  CommissionType,
  CommissionTypeIcon,
} from 'enums/offers/commissionTypes.enum';

import informationIcon from 'assets/icons/information.svg';
import ExclamationMarkIcon from 'assets/icons/exclamation-mark.svg?react';
import checkIcon from 'assets/icons/check.svg';
import WarningIcon from 'assets/icons/warning.svg?react';

import useGenerateCommissionMaps from './useGenerateCommissionMaps';

const getViewCommissionMapsIcon = (
  hasError: boolean,
  isPossiblyInvalid: boolean,
) => {
  if (hasError) {
    return <ExclamationMarkIcon />;
  }

  if (isPossiblyInvalid) {
    return <WarningIcon />;
  }

  return undefined;
};

type Issuer = {
  issuerName: string;
  isCommissionSplit?: boolean;
};

const getCommissionSplitFlag = (issuers: Issuer[] = [], issuerName: string) => {
  const currentIssuer = issuers.find(
    (issuer) => issuer.issuerName === issuerName,
  );

  return currentIssuer?.isCommissionSplit;
};

const COL_WIDTH = 'w-[9.7rem]';

type CommissionsSplitRowProps = {
  issuerName: string;
  isCommissionSplit?: boolean;
  isEditing?: boolean;
  commissionType: 'PERCENT' | 'FLAT';
};

export const CommissionsSplitRow = ({
  issuerName,
  isCommissionSplit,
  commissionType,
  isEditing = false,
}: CommissionsSplitRowProps) => {
  const { watch, getFieldState, setValue, trigger, formState } =
    useFormContext();
  const totalCommission = watch(`issuerCommissionsMap.${issuerName}`);
  const usersCommission = watch(`userCommissionsMap.${issuerName}`);

  const issuerCommissionField = getFieldState(
    `issuerCommissionsMap.${issuerName}`,
  );
  const userCommissionField = getFieldState(`userCommissionsMap.${issuerName}`);

  // Trigger validation when the commission maps are modified
  useEffect(() => {
    trigger([`userCommissionsMap`]);
  }, [trigger, totalCommission, issuerName]);

  // Update isCustom value based on whether the commission maps have been modified
  useEffect(() => {
    const commissionMapsDirty =
      issuerCommissionField.isDirty || userCommissionField.isDirty;
    const isCustomByDefault = formState.defaultValues?.isCustom;

    if (commissionMapsDirty) {
      setValue('isCustom', true);
    }

    if (!commissionMapsDirty && !isCustomByDefault) {
      setValue('isCustom', false);
    }
  }, [
    setValue,
    issuerCommissionField.isDirty,
    userCommissionField.isDirty,
    formState.defaultValues?.isCustom,
  ]);

  const { issuersCommission } = getCommissionSplit(
    { [issuerName]: totalCommission },
    { [issuerName]: usersCommission },
    issuerName,
  );

  const commissionTypeIcon = CommissionTypeIcon[commissionType];

  return (
    <LayoutGap className="basis-[min-content]">
      <FormControl
        name={`issuerCommissionsMap.${issuerName}`}
        noErrorMessage
        validations={{
          valueAsNumber: true,
        }}
        className="mb-[0]"
        render={(props) => (
          <LayoutGap direction="column" size="small">
            <Input
              type={InputType.Number}
              readOnly={!isEditing}
              disabled={!isEditing}
              icon={commissionTypeIcon}
              iconPosition={InputIconPosition.Right}
              className={COL_WIDTH}
              placeholder="--"
              error={props.error}
              {...props.register}
            />
            {issuerCommissionField.error && (
              <p className="text-red700 text-wrap text-xs">
                {issuerCommissionField.error.message}
              </p>
            )}
          </LayoutGap>
        )}
      />
      <LayoutGap direction="column" size="small" className={COL_WIDTH}>
        <FormControl
          name={`userCommissionsMap.${issuerName}`}
          validations={{ valueAsNumber: true }}
          noErrorMessage
          className="mb-[0]"
          render={(props) => (
            <LayoutGap direction="column" size="small">
              <Input
                type={InputType.Number}
                readOnly={!isEditing || !isCommissionSplit}
                disabled={!isEditing || !isCommissionSplit}
                icon={commissionTypeIcon}
                iconPosition={InputIconPosition.Right}
                className={COL_WIDTH}
                placeholder="--"
                data-tooltip-content="Commission split not enabled for this issuer"
                data-tooltip-id={
                  isCommissionSplit || issuerName === 'TEST'
                    ? undefined
                    : `commission-split-disabled`
                }
                error={props.error}
                {...props.register}
              />
              {userCommissionField.error && (
                <p className="text-red700 text-wrap text-xs">
                  {userCommissionField.error.message}
                </p>
              )}
            </LayoutGap>
          )}
        />
      </LayoutGap>
      <Input
        name={`issuerCommission.${issuerName}`}
        type={InputType.Number}
        value={issuersCommission}
        icon={commissionTypeIcon}
        iconPosition={InputIconPosition.Right}
        readOnly
        disabled
        className={COL_WIDTH}
      />
    </LayoutGap>
  );
};

type CommissionSplitProps = {
  commissionType: CommissionType;
  isEditing?: boolean;
};

const AdminCommissionSplit = ({
  commissionType,
  isEditing = false,
}: CommissionSplitProps) => {
  const [issuers, issuersLoading, issuersError] = useIssuers({
    shouldFetch: true,
  });
  const [isOpen, setIsOpen] = useState(false);

  const { getFieldState, watch, formState, trigger, register } =
    useFormContext();
  const hasCommissionMapError = Object.keys(formState.errors).some(
    (key) =>
      key.startsWith('issuerCommissionsMap') ||
      key.startsWith('userCommissionsMap'),
  );

  const merchantNetwork = watch('merchantNetwork');
  const qualifiedIssuerValue = watch('qualifiedIssuer', []) as string[];
  const commissionValue = watch('commissionValue');
  const isCustom = watch('isCustom');

  const qualifiedIssuerField = getFieldState('qualifiedIssuer');

  const qualifiedIssuer = useMemo(
    () => qualifiedIssuerValue || [],
    [qualifiedIssuerValue],
  );

  // Trigger validation when the commission value or qualified issuer field is modified
  useEffect(() => {
    trigger(['issuerCommissionsMap', 'userCommissionsMap']);
  }, [qualifiedIssuer, commissionValue, trigger]);

  useEffect(() => {
    const issuerName = qualifiedIssuer[qualifiedIssuer.length - 1];
    if (issuerName) {
      register(`issuerCommissionsMap.${issuerName}`);
      register(`userCommissionsMap.${issuerName}`);
    }
    trigger(['issuerCommissionsMap']);
  }, [qualifiedIssuer, register, trigger]);

  const defaultCommissionValue = formState.defaultValues?.commissionValue;
  const isPossiblyInvalid =
    (defaultCommissionValue !== undefined &&
      defaultCommissionValue !== commissionValue) ||
    qualifiedIssuerField.isDirty;

  const { generateCommissionMaps, isLoading, error } =
    useGenerateCommissionMaps(
      qualifiedIssuer,
      commissionValue,
      merchantNetwork,
    );

  const viewCommissionMapsIcon = getViewCommissionMapsIcon(
    hasCommissionMapError,
    isPossiblyInvalid,
  );

  return (
    <>
      {isCustom && (
        <LayoutGap size="small" className="items-center">
          <img
            src={checkIcon}
            alt="check icon"
            className="h-6 w-6 rounded-full bg-green-100 p-1.5"
          />
          <p className="text-sm">Custom commission values enabled</p>
        </LayoutGap>
      )}
      {isPossiblyInvalid && (
        <p className="max-w-[31rem] text-sm">
          Changing the commission value or qualified issuers affects commission
          maps.{' '}
          {isCustom
            ? 'Ensure all commission maps are correct before saving.'
            : 'Consider re-generating commission maps before saving.'}
        </p>
      )}
      <LayoutGap className="max-w-[31rem]">
        <Button
          className="flex-1"
          onClick={() => setIsOpen(true)}
          variant="secondary"
          disabled={isLoading}
          icon={viewCommissionMapsIcon}
          testId="view-edit-commission-maps"
        >
          View/edit commission maps
        </Button>
        <Button
          className="flex-1"
          onClick={generateCommissionMaps}
          disabled={!isEditing || isLoading}
          loading={isLoading}
          testId="generate-commission-maps"
        >
          Generate commission maps
        </Button>
      </LayoutGap>
      {error && (
        <Alert color="failure" className="max-w-[31rem] text-wrap">
          There was an error generating commission maps. Please ensure you have
          set the merchant network, qualified issuers, and commission value
          fields and try again.
        </Alert>
      )}
      <Modal
        className="relative"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <LayoutGap direction="column">
          <LayoutGap
            direction="column"
            className="sticky top-0 z-10 border-b border-gray-300 bg-white pb-2"
          >
            <Heading className="m-0 text-lg">Commission Map</Heading>
            {issuersError && (
              <Alert color="failure" className="max-w-[45rem] text-wrap">
                Error loading issuers. User commissions cannot be edited at this
                time.
              </Alert>
            )}
            {isPossiblyInvalid && (
              <Alert color="warning" className="max-w-[45rem] text-wrap">
                <p>
                  Commission split data could be out-of-date because the
                  commission value or qualified issuer field was modified.{' '}
                  {isCustom
                    ? 'Ensure all commission map values are accurate before saving.'
                    : 'Consider re-generating commission maps before saving.'}
                </p>
              </Alert>
            )}
            <LayoutGap className="text-sm">
              <div className="flex-grow">Issuer</div>
              <LayoutGap
                className={classNames(COL_WIDTH, 'justify-between')}
                size="small"
              >
                Total Commission
                <img
                  className="w-4"
                  src={informationIcon}
                  alt="Information"
                  data-tooltip-content="The total amount that goes to the issuer to split with the cardholder. (Commission Value - Kard Commission)"
                  data-tooltip-id="commission-heading-tooltip"
                />
              </LayoutGap>
              <LayoutGap
                className={classNames(COL_WIDTH, 'justify-between')}
                size="small"
              >
                User Commission
                <img
                  className="w-4"
                  src={informationIcon}
                  alt="Information"
                  data-tooltip-content="The amount that the issuer rewards to the cardholder (taken out of the Total Commission to Issuer)."
                  data-tooltip-id="commission-heading-tooltip"
                />
              </LayoutGap>
              <LayoutGap
                className={classNames(COL_WIDTH, 'justify-between')}
                size="small"
              >
                Issuer Commission
                <img
                  className="w-4"
                  src={informationIcon}
                  alt="Information"
                  data-tooltip-content="The amount leftover to the issuer after the cardholder reward (Total Commission to Issuer - User Commission)"
                  data-tooltip-id="commission-heading-tooltip"
                />
              </LayoutGap>
              <ReactTooltip
                id="commission-heading-tooltip"
                place="top"
                className="max-w-[20rem] text-wrap"
              />
              <ReactTooltip
                id="commission-split-disabled"
                place="bottom"
                className="max-w-[12rem] text-wrap text-center"
              />
            </LayoutGap>
          </LayoutGap>
          {!issuersLoading &&
            qualifiedIssuer.map((qualifiedIssuerName) => (
              <fieldset key={qualifiedIssuerName}>
                <LayoutGap>
                  <legend className="flex-grow self-center truncate">
                    {qualifiedIssuerName}
                  </legend>
                  <CommissionsSplitRow
                    commissionType={commissionType}
                    issuerName={qualifiedIssuerName}
                    isCommissionSplit={getCommissionSplitFlag(
                      issuers,
                      qualifiedIssuerName,
                    )}
                    isEditing={isEditing}
                  />
                </LayoutGap>
              </fieldset>
            ))}
          {qualifiedIssuer.length === 0 && (
            <EmptyState
              title="No qualified issuers"
              description="Please add qualified issuers to view commission maps."
            />
          )}
          <ButtonGroup className="self-end">
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </ButtonGroup>
        </LayoutGap>
      </Modal>
    </>
  );
};

export default AdminCommissionSplit;
