import client, { USERS_BASE_URL } from 'services/kardAPI';

const editPortalUser = async (username, body) => {
  try {
    await client.put(`${USERS_BASE_URL}/portal/cognito/user/${username}`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default editPortalUser;
