import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import checkIcon from 'assets/icons/check-circle.svg';
import cancelIcon from 'assets/icons/x.svg';

import selectExportDataState from 'state/selectors/exportData';
import { closeExportBanner } from 'state/actions/exportData';

const downloadFile = (filePath: string) => {
  const link = document.createElement('a');
  link.href = filePath;
  link.click();
};

const ExportCompleted = () => {
  const dispatch = useDispatch();

  const { data: downloadLink } = useSelector(
    selectExportDataState,
    shallowEqual,
  );

  useEffect(() => {
    if (downloadLink) {
      downloadFile(downloadLink);
    }
  }, [downloadLink]);

  const onCloseButton = () => dispatch(closeExportBanner());

  return (
    <div className="flex w-fit gap-2.5">
      <div className="flex items-center">
        <div className="rounded-full">
          <img src={checkIcon} alt="completed icon" className="" />
        </div>
      </div>
      <div className="w-[14.6rem]">
        <p className="text-gray900 text-sm font-semibold">Export complete</p>
        <p className="text-gray500 text-sm font-normal">
          File didn&apos;t download?
        </p>

        <a
          className="text-primary600 text-sm font-normal underline"
          href={downloadLink}
        >
          Download CSV now
        </a>
      </div>
      <button type="button" onClick={onCloseButton} className="flex">
        <img src={cancelIcon} alt="cancel icon" />
      </button>
    </div>
  );
};

export default ExportCompleted;
