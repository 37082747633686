import { forwardRef, ElementRef, ComponentPropsWithoutRef } from 'react';

import { cn } from '@/lib/utils';
import * as LabelPrimitive from '@radix-ui/react-label';

import { useFormField } from './FormField';

const FormLabel = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(error && 'text-red-500', className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = 'FormLabel';

export default FormLabel;
