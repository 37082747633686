/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchFeatureFlagsInit,
  fetchFeatureFlagsSuccess,
  fetchFeatureFlagsFail,
  FlagsState,
} from 'state/actionCreators/featureFlags';

export const initialState: FlagsState = {
  error: null,
  flags: {},
};

const persistConfig: PersistConfig<FlagsState> = {
  key: 'featureFlags',
  storage,
  blacklist: ['error'],
};

const featureFlagsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchFeatureFlagsInit, (state) => {
      state.flags = {};
      state.error = null;
    });

    builder.addCase(fetchFeatureFlagsSuccess, (state, action) => {
      const { flags } = action.payload;
      state.flags = flags;
      state.error = null;
    });

    builder.addCase(fetchFeatureFlagsFail, (state, { payload }) => {
      const { error } = payload;
      state.flags = {};
      state.error = error;
    });
  }),
);

export default featureFlagsReducer;
