import { Auth } from 'aws-amplify';

import setSessionUser from '@/lib/datadogRUM';
import getCognitoUserData from 'utils/users/getCognitoUserData';

const resumeSession = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    setSessionUser(cognitoUser);
    return getCognitoUserData(cognitoUser);
  } catch (error) {
    throw new Error(error);
  }
};

export default resumeSession;
