import { datadogRum } from '@datadog/browser-rum';

type CognitoUser = {
  attributes: {
    name?: string;
    email: string;
    'custom:auth_role': string;
    'custom:issuerName'?: string;
    sub: string;
  };
};

const setSessionUser = (user: CognitoUser) => {
  if (!user.attributes) {
    return;
  }

  datadogRum.setUser({
    id: user.attributes.sub,
    name: user.attributes.name,
    email: user.attributes.email,
    role: user.attributes['custom:auth_role'],
    issuer: user.attributes['custom:issuerName'],
  });
};

export default setSessionUser;
