import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const Redirect = () => {
  const redirectUrl: string = localStorage.getItem('redirectAfterLogin') || '/';

  useEffect(() => {
    localStorage.removeItem('redirectAfterLogin');
  }, []);

  return <Navigate to={redirectUrl} />;
};

export default Redirect;
