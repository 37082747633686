import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import { audienceType } from 'utils/audiences/values';
import getStatusOptions from 'utils/audiences/getStatusOptions';
import getDropdownValues from 'utils/common/getDropdownValues';
import Status from 'enums/status/status.enum';

import classes from './AudienceInformation.module.scss';

const getInputFields = ({
  audience,
  isEditing,
  isCreating,
  newAudience,
  isDynamic,
  disabledEdit,
}) => {
  const isAudienceDynamic = audience?.type === audienceType.Dynamic;

  const getTypeText = () => {
    if ((isCreating && isDynamic) || isAudienceDynamic) {
      return 'Dynamic';
    }

    return 'Static';
  };

  const statuses = getStatusOptions(audience, isCreating, isEditing);

  const offerOptions = getDropdownValues(audience?.activeOffers);

  const disabledOptions = ({ value }) =>
    isEditing && (value === 'DRAFT' || value === 'IN_PROGRESS');

  const statusDefaultValue = isCreating
    ? newAudience?.status
    : Status[audience?.status];

  return [
    {
      name: 'audienceId',
      renderProps: {
        label: 'ID',
        labelClassName: classes.labelWidth,
        value: isCreating ? newAudience?.audienceId : audience?.audienceId,
        type: InputType.Text,
        size: InputSize.S,
        color: InputColor.Gray,
        readOnly: true,
        disabled: isEditing,
      },
      component: Input,
      show: !isCreating,
    },
    {
      name: 'name',
      renderProps: {
        label: 'Name',
        labelClassName: classes.labelWidth,
        value: isCreating ? newAudience?.name : audience?.name,
        type: InputType.Text,
        size: InputSize.S,
        color: isEditing ? InputColor.Black : InputColor.Gray,
        readOnly: !isEditing && !isCreating,
        disabled: disabledEdit,
      },
      component: Input,
      show: true,
    },
    {
      name: 'status',
      component: FormControlSelect,
      label: 'Status',
      labelClassName: classes.labelWidth,
      defaultValue: statusDefaultValue?.toUpperCase() || '',
      color: isEditing ? SelectColor.Black : SelectColor.Gray,
      options: statuses,
      isOptionDisabled: disabledOptions,
      show: true,
      readOnly: !isEditing && !isCreating,
      disabled: isCreating ? false : disabledEdit || !isEditing,
      testId: 'status-input',
    },
    {
      name: 'description',
      renderProps: {
        label: 'Description',
        labelClassName: classes.labelWidth,
        value: isCreating ? newAudience?.description : audience?.description,
        type: InputType.Text,
        size: InputSize.S,
        color: isEditing ? InputColor.Black : InputColor.Gray,
        readOnly: !isEditing && !isCreating,
        disabled: disabledEdit,
      },
      component: Input,
      show: true,
    },
    {
      name: 'audienceSize',
      renderProps: {
        label: 'Size',
        labelClassName: classes.labelWidth,
        value: isCreating ? newAudience?.audienceSize : audience?.audienceSize,
        type: InputType.Text,
        size: InputSize.S,
        color: InputColor.Gray,
        readOnly: true,
        disabled: isEditing,
      },
      component: Input,
      show: !isCreating,
    },
    {
      name: 'createdDate',
      renderProps: {
        label: 'Created Date',
        labelClassName: classes.labelWidth,
        value: isCreating ? newAudience?.createdDate : audience?.createdDate,
        type: InputType.Text,
        size: InputSize.S,
        color: InputColor.Gray,
        readOnly: true,
        disabled: isEditing,
      },
      component: Input,
      show: !isCreating,
    },
    {
      name: 'type',
      renderProps: {
        label: 'Type',
        labelClassName: classes.labelWidth,
        value: getTypeText(),
        type: InputType.Text,
        size: InputSize.S,
        color: InputColor.Gray,
        readOnly: true,
        disabled: isEditing,
      },
      component: Input,
      show: true,
    },
    {
      label: 'Offers using the audience',
      labelClassName: classes.labelWidth,
      component: FormControlSelect,
      className: classes.formControlSelect,
      name: 'activeOffers',
      defaultValue: audience?.activeOffers,
      options: offerOptions,
      color: InputColor.Gray,
      placeholder:
        offerOptions.length === 0 ? 'No offers added' : 'Select Offer',
      readOnly: !isEditing,
      show: !isCreating,
      disabled: true,
      isClearable: false,
      isMulti: true,
    },
  ];
};

export default getInputFields;
