import { useEffect } from 'react';
import { ISSUERS_BASE_URL, useAxios } from 'services/kardAPI';

type Options = {
  shouldFetch?: boolean;
};

const useIssuers = ({ shouldFetch }: Options = {}) => {
  const [{ data, loading, error }, fetchData] = useAxios(
    {
      url: `${ISSUERS_BASE_URL}/portal/issuer`,
    },
    { manual: true },
  );

  useEffect(() => {
    if (shouldFetch) {
      fetchData();
    }
  }, [shouldFetch, fetchData]);

  return [data, loading, error];
};

export default useIssuers;
