import { Auth } from 'aws-amplify';

import AuthChallenge from 'enums/amplify/authChallenge.enum';
import getCognitoUserData from 'utils/users/getCognitoUserData';

const completeNewPassword = async (username, oldPassword, newPassword) => {
  const user = await Auth.signIn(username, oldPassword);

  if (user.challengeName !== AuthChallenge.NewPasswordRequired) {
    return getCognitoUserData(user, username);
  }

  const newUser = await Auth.completeNewPassword(user, newPassword, {
    email: username,
  });

  return getCognitoUserData(newUser, username);
};

export default completeNewPassword;
