import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';

/* const */
import SortDirection from 'refactored/enums/query/sortDirection.enum';

/* assets */
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import clearIcon from 'assets/icons/x.svg';

/* styles  */
import classes from './MultiSelectDropdown.module.scss';

/**
 * MultiSelectDropdown: Search filter component with dropdown and checkbox options
 * @param {object} query current query, example { pagination: { page: 0, limit: 50 }, sort: { direction: -1, columnName: 'createdDate' }}
 * @param {string} fieldName name of field to filter on, example "status" for {query: {filters: {status: { selected: ["enabled"], type: "string"}}}}
 * @param {array} options of objects with label and value properties, example [{label: "Enabled", value: "enabled"}]
 * @param {function} updateQuery to update query, function usually from useQuery hook
 * @param {string} filterType of filter, string example "string" for {query: {filters: {status: { selected: ["enabled"], type: "string"}}}}
 * @param {array} defaultSelection state of selection, also used to reset, array of strings of options' values example ["enabled"]
 * @param {string} label label for dropdown button example "Status"
 * @param {boolean} searchable whether or not to show search input, example true
 * @returns {JSX.Element}
 */
const MultiSelectDropdown = ({
  query,
  fieldName,
  options,
  updateQuery,
  filterType,
  defaultSelection,
  label,
  searchable,
  dataCy,
}) => {
  const [selected, setSelected] = useState(
    query?.filters?.[fieldName]
      ? query.filters[fieldName].selected
      : defaultSelection,
  );
  const [filteredOptions, setFilteredOptions] = useState(options);

  const onSelect = (option) => {
    setSelected((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      }
      return [...prev, option];
    });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilteredOptions(filtered);
  };

  const getDisplayValue = () => {
    if (selected?.length > 0 && selected?.length <= 2) {
      return `: ${selected.map((item) => {
        const option = options.find((o) => o.value === item);
        return option?.label ? ` ${option?.label}` : option;
      })}`;
    }
    if (selected?.length > 2) {
      return `: ${selected.length} selected`;
    }
    return '';
  };

  const apply = () => {
    const newQuery = query;

    newQuery.pagination = { page: 0, limit: 100 };

    if (selected?.length > 0) {
      newQuery.filters = { ...(query.filters || {}) };
      newQuery.filters[fieldName] = { selected, type: filterType };
      // remove filter if all options are selected
      if (selected.length === filteredOptions.length) {
        delete newQuery.filters[fieldName];
      }
    }
    if (
      selected?.length === 0 &&
      newQuery.filters &&
      newQuery.filters[fieldName]
    ) {
      delete newQuery.filters[fieldName]; // remove current filter if unselected

      if (Object.keys(newQuery.filters).length === 0) {
        delete newQuery.filters; // remove filters if empty
      }
    }
    setFilteredOptions(options);
    updateQuery(newQuery);
  };

  const reset = () => setSelected(defaultSelection);

  const onClearDropdown = () => {
    reset();
    if (query.filters && query.filters[fieldName]) {
      const newQuery = { ...query };
      delete newQuery.filters[fieldName];
      updateQuery(newQuery);
    }
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className={classes.button} data-cy={dataCy}>
            {label}
            {getDisplayValue()}
            {selected?.length > 0 && (
              <button
                className={classes.clearIcon}
                type="button"
                onClick={onClearDropdown}
              >
                <img alt="clear" src={clearIcon} />
              </button>
            )}
            <img
              alt="arrow"
              className={classNames(classes.arrowIcon, {
                [classes.arrowIconOpen]: open,
              })}
              src={arrowDownIcon}
            />
          </Popover.Button>
          <Popover.Panel className={classes.panelContainer}>
            {searchable && (
              <div className={classes.panelSearch}>
                <input
                  type="text"
                  placeholder="Search"
                  className={classes.searchInput}
                  onChange={handleSearch}
                />
              </div>
            )}

            <div className={classes.panelContent}>
              {filteredOptions.map((option) => (
                <button
                  type="button"
                  alt="button"
                  onClick={() => onSelect(option.value)}
                  data-tooltip-content={option.label}
                  data-tooltip-id="selectTooltip"
                  key={option.label}
                >
                  <div className={classes.panelItem}>
                    <input
                      type="checkbox"
                      checked={selected.includes(option.value)}
                      onClick={() => onSelect(option.value)}
                      onChange={() => onSelect(option.value)}
                      className={classes.checkbox}
                    />
                    <p className={classes.optionLabel}>{option.label}</p>
                  </div>
                </button>
              ))}
              <ReactTooltip id="selectTooltip" />
            </div>
            <div className={classes.actions}>
              <button
                type="button"
                onClick={reset}
                className={classes.revertButton}
              >
                Revert
              </button>
              <Popover.Button
                type="button"
                className={classes.actionButton}
                onClick={apply}
              >
                Apply
              </Popover.Button>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default MultiSelectDropdown;

MultiSelectDropdown.propTypes = {
  query: PropTypes.shape({
    pagination: PropTypes.shape({
      limit: PropTypes.number,
      page: PropTypes.number,
    }),
    filters: PropTypes.shape({}),
    sort: PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.oneOf(Object.values(SortDirection)),
    }),
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateQuery: PropTypes.func.isRequired,
  filterType: PropTypes.string,
  defaultSelection: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  searchable: PropTypes.bool,
  dataCy: PropTypes.string,
};

MultiSelectDropdown.defaultProps = {
  filterType: 'string',
  defaultSelection: [],
  label: '',
  searchable: false,
  dataCy: 'MultiSelectDropdown',
};
