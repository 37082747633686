/**
 * Convert user request to valid request
 * @param { Object } objectFilters
 */
const transformFiltersToMatchSchema = (objectFilters: Record<string, any>) => {
  if (!objectFilters) {
    return {};
  }

  return Object.entries(objectFilters).reduce(
    (acc: Record<string, any>, [key, value]) => {
      switch (key) {
        case 'startDateSort':
        case 'expirationDateSort':
        case 'totalCommissionSort':
        case 'sourceSort':
        case 'categorySort':
        case 'nameSort':
        case 'citySort':
        case 'stateSort':
        case 'createdDateSort':
        case 'zipCodeSort':
        case 'merchantNameSort':
        case 'merchantSourceSort':
        case 'merchantCategorySort':
        case 'groupSortDirection':
        case 'offerPerGroup':
        case 'groupsPerPage': {
          acc[key] = Number(value);
          break;
        }
        case 'category':
        case 'name':
        case 'startDateStart':
        case 'startDateEnd':
        case 'expirationDateStart':
        case 'expirationDateEnd':
        case 'merchantName':
        case 'merchantSource':
        case 'merchantCategory':
        case 'groupBy': {
          acc[key] = String(value);
          break;
        }
        case 'source':
        case 'offerType':
        case 'commissionType': {
          acc[key] = String(value).toUpperCase();
          break;
        }
        case 'totalCommissionStart': {
          acc.commissionRange = {
            ...acc.commissionRange,
            gte: Number(value),
          };
          break;
        }
        case 'totalCommissionEnd': {
          acc.commissionRange = {
            ...acc.commissionRange,
            lte: Number(value),
          };
          break;
        }
        case 'isTargeted': {
          if (value.toLowerCase() === 'true') {
            acc[key] = true;
          }

          if (value.toLowerCase() === 'false') {
            acc[key] = false;
          }
          break;
        }
        case 'locationNameSort': {
          acc.nameSort = Number(value);
          break;
        }
        case 'locationName': {
          acc.name = String(value);
          break;
        }
        // This has to be done because of the way we are sorting in Merchants
        case 'sortBy': {
          acc[`${value}Sort`] = Number(objectFilters.sortDirection);
          break;
        }
        case 'city':
        case 'state':
        case 'zipCode':
        case 'status': {
          if (!Array.isArray(value)) {
            acc[key] = [value];
            break;
          }

          acc[key] = value;
          break;
        }
        default: {
          return acc;
        }
      }

      return acc;
    },
    {},
  );
};

export default transformFiltersToMatchSchema;
