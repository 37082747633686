/* modules */
import React, { useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Spinner } from 'flowbite-react';

/* redux */
/* auth */
import { selectUserAttributesState } from 'state/selectors/auth';
/* offers */
import { fetchOffers } from 'state/actions/offers';
import { selectFetchOffersState } from 'state/selectors/offers';
import { closeExportBanner, exportData } from 'state/actions/exportData';
import selectExportDataState from 'state/selectors/exportData';

/* custom hooks */
import useQuery from 'refactored/hooks/useQuery';

/* custom components */
import SearchBy from 'refactored/components/Common/SearchBy';
import Table from 'refactored/components/Common/Table';
import Pagination from 'refactored/components/Common/Pagination2';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Button from 'components/Common/Button';
import SelectDropdown from 'refactored/components/Common/SelectDropdown';
import MultiSelectDropdown from 'refactored/components/Common/MultiSelectDropdown';
import SelectDateRange from 'refactored/components/Common/SelectDateRange';
import SearchFilter from 'refactored/components/Common/SearchFilter';
import SelectNumberRange from 'refactored/components/Common/SelectNumberRange';
import SelectGroup from 'refactored/components/Common/SelectGroup';

/* enums */
import StatusType from 'enums/status/statusValue.enum';
import ModelType from 'enums/export/modelType.enum';

/* utils */
import { makeMergedOffersColumns } from 'refactored/utils/offers/makeColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import getIdToken from 'utils/getIdToken';
import getParsedFilters from 'utils/getParsedFilters';
import getTotalCommission from 'utils/offers/getTotalCommission';
import {
  offerTypeFilterOptions,
  offerStatusFilterOptions,
  commissionTypeFilterOptions,
  targetedOfferFilterOptions,
  merchantSourceFilterOptions,
} from 'pages/MergedOffers/filterOptions';
import categoryList from 'utils/categories';
import { MAX_OFFERS_PER_MERCHANT } from 'utils/offers/values';

/* assets */
import ExportIcon from 'assets/icons/download.svg?react';

/* styling */
import classes from './MergedOffers.module.scss';

/* main component definition  */
const MergedOffers = () => {
  const dispatch = useDispatch();

  /* internal state */
  const [data, setData] = useState([]);
  const [groupedBy, setGroupedBy] = useState(false);

  /* query framework */
  const [query, updateQuery] = useQuery(
    {
      pagination: { page: 0, limit: 100 },
      sort: { direction: -1, columnName: 'createdDate' },
    },
    {
      filters: {
        status: {
          selected: [StatusType.Active, StatusType.Upcoming],
          type: 'string',
        },
      },
    },
  );

  /* selectors */
  const {
    offersData,
    loading: loadingFetchOffers,
    error: errorFetchOffers,
  } = useSelector(selectFetchOffersState, shallowEqual);

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const { loading: exportInProgress } = useSelector(
    selectExportDataState,
    shallowEqual,
  );

  /* const */
  const { countTotal, offers } = offersData;

  const noDataToShow = data.length === 0;

  const searchingById =
    !!query.filters?.offerId &&
    query.filters.offerId.selected[0] !== '' &&
    data.length === 1;

  const inactiveOfferById =
    searchingById &&
    [StatusType.Inactive, StatusType.Closed].includes(data[0].status);

  const statusFilterValue = query.filters?.status?.selected;

  // If status filters include active/upcoming, or no status filters are added (ensures that active/upcoming offers are returned in the table)
  const activeOffersShown =
    statusFilterValue?.includes(StatusType.Active) ||
    statusFilterValue?.includes(StatusType.Upcoming) ||
    !statusFilterValue ||
    statusFilterValue?.length === 0;

  const disableExportButton =
    !activeOffersShown ||
    inactiveOfferById ||
    loadingFetchOffers ||
    exportInProgress ||
    noDataToShow;

  /* methods */
  const getExportOffersTooltip = () => {
    if (loadingFetchOffers) {
      return '';
    }

    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (noDataToShow) {
      return 'There is no data to export';
    }

    if (!activeOffersShown) {
      return 'There are no active offers to export';
    }

    if (inactiveOfferById) {
      return 'Cannot export inactive/closed offers';
    }

    return '';
  };

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();
    const filters = getParsedFilters(
      `?${queryToSearchParams(query).toString()}`,
    );

    if (searchingById) {
      const [_id] = query.filters.offerId.selected;
      filters._id = _id;
    }

    const exportInfo = {
      idToken,
      filters,
      model: ModelType.MergedOffer,
    };

    dispatch(exportData(exportInfo));
  };

  /* table columns */
  const columns = useMemo(
    () => getAllowedColumns(makeMergedOffersColumns(), userRoles),
    [userRoles],
  );
  /* offers */
  /* fetch offers filtered/sorted by query params */
  useEffect(() => {
    dispatch(fetchOffers(`?${queryToSearchParams(query).toString()}`));
  }, [dispatch, query]);

  /* transform offers data */
  useEffect(() => {
    const transformedData = offers.map((offer) => ({
      ...offer,
      offerId: offer._id,
      merchantId: offer.merchant?._id,
      merchantName: offer.merchant?.name,
      merchantSource: offer.merchant?.source,
      merchantCategory: offer.merchant?.category,
      websiteURL: offer.merchant?.websiteURL,
      totalCommission: getTotalCommission(
        offer.commissionType,
        offer.totalCommission,
      ),
    }));

    const expandedData = offers?.map((offer) => {
      const subRows = offer?.documents?.map((document) => ({
        ...document,
        offerId: document._id,
        merchantId: document.merchant?._id,
        merchantName: document.merchant?.name,
        merchantSource: document.merchant?.source,
        merchantCategory: document.merchant?.category,
        websiteURL: document.merchant?.websiteURL,
        totalCommission: getTotalCommission(
          document.commissionType,
          document.totalCommission,
        ),
      }));

      if (offer.count > MAX_OFFERS_PER_MERCHANT) {
        subRows.push({
          offerId: {
            isComponent: true,
            count: offer.count,
            merchantId: offer.merchantId,
          },
        });
      }
      return {
        offerId: `${offer.merchantName} (${offer.count})`,
        count: offer.count,
        subRows,
      };
    });

    setData(groupedBy ? expandedData : transformedData);
  }, [offers, groupedBy]);

  // Close the export banner when the component is unmounted
  useEffect(
    () => () => {
      dispatch(closeExportBanner());
    },
    [dispatch],
  );

  /* 
    TODO: remove after the feature flag check is deleted from paths.js

    If the page is reloaded, the fetchOffers query fires multiple times because while fetching
    the feature flags, it executes the query from the old Offers page as well. As a result we 
    could have multiple queries being awaited, causing a race condition between them. This check 
    ensures that all queries have terminated, and we are receiving data in the expected groupedBy
    shape (and not from the old Offers page).
  */
  const groupedDataFetched = groupedBy && data[0]?.count === undefined;

  const onGroupByHandler = () => setGroupedBy(true);

  const onClearHandler = () => setGroupedBy(false);

  /* render */
  return (
    <>
      {errorFetchOffers && (
        <Toast
          id="fetch-offers"
          text="Error fetching offers"
          type={ToastType.Error}
        />
      )}

      <div className={classes.flexboxLayout}>
        <div className={classes.flexboxLayout__header}>
          <span
            className={classes.flexboxLayout__header__pageTitle}
            data-testid="page-header-title"
          >
            Offers
          </span>

          <div className={classes.flexboxLayout__header__buttons}>
            <SelectGroup
              query={query}
              handler={onGroupByHandler}
              onClear={onClearHandler}
              updateQuery={updateQuery}
              label="Group By"
              disabled={loadingFetchOffers}
            />
            <span
              data-tooltip-content={getExportOffersTooltip()}
              data-tooltip-id="exportOffers"
            >
              <Button
                icon={<ExportIcon />}
                disabled={disableExportButton}
                onClick={onExportDataHandler}
              >
                Export view as CSV
              </Button>
            </span>
          </div>
        </div>
        <div className="mx-10 flex flex-wrap gap-2 pb-4">
          <SearchBy
            field="name"
            idField="offerId"
            placeholder="Search by Offer Name or ID"
            query={query}
            updateQuery={updateQuery}
            className="h-[37px] !w-[24.2rem]"
            showCancelIcon
            onCancel
          />
        </div>

        <div className="mx-10 flex flex-wrap gap-2 pb-4">
          <SelectDropdown
            query={query}
            fieldName="offerType"
            options={offerTypeFilterOptions}
            updateQuery={updateQuery}
            filterType="string"
            label="Offer Type"
          />
          <MultiSelectDropdown
            query={query}
            fieldName="status"
            options={offerStatusFilterOptions}
            updateQuery={updateQuery}
            filterType="string"
            label="Offer Status"
          />
          <SelectDropdown
            query={query}
            fieldName="commissionType"
            options={commissionTypeFilterOptions}
            updateQuery={updateQuery}
            filterType="string"
            label="Commission Type"
          />
          <SelectNumberRange
            query={query}
            fieldName="totalCommission"
            updateQuery={updateQuery}
            label="Total Commission"
          />
          <SelectDateRange
            query={query}
            fieldName="startDate"
            updateQuery={updateQuery}
            label="Start Date"
          />

          <SelectDateRange
            query={query}
            fieldName="expirationDate"
            updateQuery={updateQuery}
            label="Expiration Date"
          />
          <SelectDropdown
            query={query}
            fieldName="isTargeted"
            options={targetedOfferFilterOptions}
            updateQuery={updateQuery}
            filterType="string"
            label="Targeted Offer"
          />
          <SearchFilter
            query={query}
            updateQuery={updateQuery}
            fieldName="merchantName"
            filterType="string"
            label="Merchant Name"
          />
          <SelectDropdown
            query={query}
            fieldName="merchantSource"
            options={merchantSourceFilterOptions}
            updateQuery={updateQuery}
            filterType="string"
            label="Merchant Source"
          />
          <SelectDropdown
            query={query}
            fieldName="merchantCategory"
            options={categoryList.map((category) => ({
              label: category,
              value: category,
            }))}
            updateQuery={updateQuery}
            filterType="string"
            label="Merchant Category"
            searchable
          />
        </div>

        {loadingFetchOffers || groupedDataFetched ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner size="lg" color="gray" />
          </div>
        ) : (
          <>
            <div className={classes.flexboxLayout__table}>
              <Table
                id="table-offers"
                columns={columns}
                data={data}
                query={query}
                updateQuery={updateQuery}
              />
            </div>

            <div className={classes.flexboxLayout__pagination}>
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>

      <ReactTooltip id="exportOffers" />
    </>
  );
};

export default MergedOffers;
