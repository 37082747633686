import {
  exportDataClearInit,
  exportDataClearSuccess,
  exportDataHide,
  exportDataInit,
  exportDataCancelExport,
} from 'state/actionCreators/exportData';

export const exportData =
  ({ filters, model, idToken }) =>
  async (dispatch) => {
    dispatch(exportDataInit({ filters, model, idToken }));
  };

export const closeExportBanner = () => (dispatch) => dispatch(exportDataHide());

export const clearExportSuccessField = () => (dispatch) =>
  dispatch(exportDataClearSuccess());

export const clearInitExportField = () => (dispatch) =>
  dispatch(exportDataClearInit());

export const cancelExport = () => (dispatch) =>
  dispatch(exportDataCancelExport());
