import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ErrorMessage = ({ className, children }) => (
  <p
    className={classNames(className, 'mb-4 text-sm font-normal text-red-600')}
    data-cy="errorMessage"
  >
    {children}
  </p>
);

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: '',
};

export default ErrorMessage;
