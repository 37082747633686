/** modules */
import React from 'react';
import Path from 'enums/path.enum';
import { Link } from 'react-router-dom';

/** custom components */
import Status from 'components/Common/Status';
import MoreInfo from 'refactored/components/Common/MoreInfo';

/** enums */
import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';

const makeColumns = ({ moreInfoHandler }) => [
  {
    header: 'Location ID',
    accessorKey: '_id',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (
      <Link
        to={`${Path.Locations}/info/${getValue()}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#263FC2] underline"
      >
        {getValue()}
      </Link>
    ),
  },
  {
    header: 'Location Name',
    accessorKey: 'locationName',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Status',
    accessorKey: 'status',
    allowedRoles: Groups.Administrators,
    meta: {
      tooltip: () => '',
      sortable: true,
    },
    cell: ({ getValue }) =>
      getValue() && getValue() !== 'PENDING' ? (
        <Status kind={getValue().toLowerCase()} />
      ) : (
        ''
      ),
  },
  {
    header: 'Google ID',
    accessorKey: 'googleId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => getValue() || '-',
  },
  {
    header: 'Location Type',
    accessorKey: 'locationType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Street',
    accessorKey: 'street',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => getValue() || '-',
  },
  {
    header: 'City',
    accessorKey: 'city',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => getValue() || '-',
  },
  {
    header: 'State',
    accessorKey: 'state',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => getValue() || '-',
  },
  {
    header: 'Zip Code',
    accessorKey: 'zipCode',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => getValue() || '-',
  },
  {
    header: 'Creation Date',
    accessorKey: 'createdDate',
    allowedRoles: Groups.Internal,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => (getValue() ? dateParse(getValue()) : '-'),
  },
  {
    header: 'More info',
    id: 'moreInfo',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => (
      <MoreInfo onClick={() => moreInfoHandler(row.original)} />
    ),
  },
];

export default makeColumns;
