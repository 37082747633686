import fetchPendingTransactionsService from 'services/pendingTransactions/fetchPendingTransactions';
import { useEffect, useState } from 'react';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import { PendingTransactionResponse } from 'types/responses';
import { IIssuer } from '@kardfinancial/models';

type PendingTransactionsCount = {
  issuerName: string;
  issuerId: string;
  countTotal: number;
  error?: string;
};

const usePendingTransactions = (
  issuers: IIssuer[],
  shouldFetch: boolean,
): [PendingTransactionsCount[], boolean, string | null] => {
  const [data, setData] = useState<PendingTransactionsCount[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    // NOTE: this is a temp solution while the MIG is in progress
    const fetchPendingTransactionCounts = async () => {
      try {
        const results: PendingTransactionsCount[] = await Promise.all(
          issuers.map(async ({ issuerId, issuerName }: IIssuer) => {
            const params = {
              pagination: {
                limit: 1,
                page: 0,
              },
              filters: {
                issuer: { selected: [issuerId], type: 'string' },
                reviewStatus: { selected: ['OPEN'], type: 'string' },
              },
            };
            let pendingTxns: PendingTransactionResponse;
            try {
              pendingTxns = await fetchPendingTransactionsService(
                `?${queryToSearchParams(params).toString()}`,
                issuerId,
              );
            } catch (e) {
              const err = e as Error;
              // Silently fail if a single request has an error
              return {
                issuerId,
                issuerName,
                countTotal: -1,
                error: err?.message,
              };
            }
            return {
              issuerId,
              issuerName,
              countTotal: pendingTxns?.countTotal || 0,
            };
          }),
        );
        results.sort((a, b) => b.countTotal - a.countTotal);
        setData(results);
        setLoading(false);
      } catch (e) {
        const err = e as Error;
        setError(err.message);
        setLoading(false);
        throw new Error(err.message);
      }
    };

    if (shouldFetch) {
      fetchPendingTransactionCounts();
    }
  }, [issuers, shouldFetch]);

  return [data, loading, error];
};

export default usePendingTransactions;

// TODO: once the MIG is ready with issuer txn counts, we'll do something like this
// const usePendingTransactions = (issuers, shouldFetch) => {
//     const [{ data = [], loading, error }, fetchData] = useAxios(
//         {
//             url: '', // txns count MIG endpoint
//         },
//         { manual: true },
//     );

//     useEffect(() => {
//         if (shouldFetch) {
//             fetchData();
//         }
//     }, [shouldFetch, issuers]);

//     return [data, loading, error];
// };
