import React, { useEffect } from 'react';
import { Spinner } from 'flowbite-react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchOfferById } from 'state/actions/offers';

import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Textarea from 'components/Common/Textarea';
import IssuerCommissionsSplit from 'components/Forms/IssuerCommissionSplit';
import PageHeader from 'components/Common/PageHeader';
import LayoutGap from 'components/Common/LayoutGap';
import Fieldset from 'components/Common/Fieldset';

import { selectFetchOfferByIdState } from 'state/selectors/offers';
import Status from 'enums/status/status.enum';

const OfferInfo = () => {
  const { offerId } = useParams();
  const dispatch = useDispatch();

  const { offer, loading: loadingFetchOffer } = useSelector(
    selectFetchOfferByIdState,
    shallowEqual,
  );
  useEffect(() => {
    dispatch(fetchOfferById(offerId));
  }, [dispatch, offerId]);

  const statusColor =
    offer?.status === Status.active ? InputColor.Green : InputColor.Red;
  return (
    <>
      {loadingFetchOffer || !offer ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner size="lg" color="gray" />
        </div>
      ) : (
        <>
          <PageHeader
            title="Offer Information"
            className="m-[2.5rem] mb-[1rem]"
          />

          <div className="mx-10 mb-10">
            <Fieldset title="Offer Details">
              <Input
                label="Offer ID"
                value={offer._id}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
              />

              <Input
                label="Offer Name"
                value={offer.name}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
              <Input
                label="Offer Type"
                value={offer.offerType}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
              <Input
                label="Start Date"
                value={offer.startDate}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
              <Input
                label="Expiration Date"
                value={offer.expirationDate}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
              <Input
                label="Status"
                value={offer.status}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={statusColor}
              />

              <Input
                label="Targeted Offer"
                value={offer.isTargeted ? 'Targeted' : 'Not Targeted'}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
              <Textarea
                label="Terms And Conditions"
                value={offer.terms}
                preventNewLines
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
            </Fieldset>
            <Fieldset title="Commission Details">
              <Input
                label="Commission Type"
                value={offer.commissionType}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
              <IssuerCommissionsSplit
                commissionType={offer.commissionType}
                totalIssuerCommission={offer.totalCommission}
                userCommission={offer.usersCommission}
              />
            </Fieldset>
            <Fieldset title="Redemption Details">
              <LayoutGap>
                <Input
                  label="Min Txn Amount In Cents"
                  value={offer.minTransactionAmount}
                  type={InputType.Number}
                  readOnly
                  size={InputSize.S}
                  color={InputColor.Gray}
                  width="w-[15rem]"
                />
                <Input
                  label="Max Txn Amount In Cents"
                  value={offer.maxTransactionAmount}
                  type={InputType.Number}
                  readOnly
                  size={InputSize.S}
                  color={InputColor.Gray}
                  width="w-[15rem]"
                />
              </LayoutGap>
              <LayoutGap>
                <Input
                  label="Min Reward Amount In Cents"
                  value={offer.minUserRewardAmount ?? offer.minRewardAmount}
                  type={InputType.Number}
                  readOnly
                  size={InputSize.S}
                  color={InputColor.Gray}
                  width="w-[15rem]"
                />
                <Input
                  label="Max Reward Amount In Cents"
                  value={offer.maxUserRewardAmount ?? offer.maxRewardAmount}
                  type={InputType.Number}
                  readOnly
                  size={InputSize.S}
                  color={InputColor.Gray}
                  width="w-[15rem]"
                />
              </LayoutGap>
              <Input
                label="Redeemable Once"
                value={offer.redeemableOnce ? 'True' : 'False'}
                type={InputType.Text}
                readOnly
                size={InputSize.S}
                color={InputColor.Gray}
              />
            </Fieldset>
          </div>
        </>
      )}
    </>
  );
};

export default OfferInfo;
