enum Validations {
  InvalidEmail = 'Please enter a valid email.',
  RequiredEmail = 'Email is required',
  RequiredCode = 'Code is required',
  RequiredNewPassword = 'New password is required',
  RequiredPassword = 'Password is required',
  PasswordLength = 'Password length should be at least 8 characters',
  RequiredRoles = 'At least one role is required',
  RequiredTitle = 'Title is required',
  RequiredName = 'Name is required',
  FullNameFormat = 'Name should only contain letters, accents, spaces, hyphens, and apostrophes',
  PhoneNumberFormat = 'The phone number should start with + and have 11-13 characters',
  RequiredType = 'Type is required',
  RequiredNetwork = 'Network is required',
  RequiredIssuer = 'Issuer is required for user with issuer role',
  RequiredCommissionType = 'Commission type is required',
  RequiredCommissionValue = 'Commission value is required',
  RequiredDescription = 'Description is required',
  RequiredSource = 'Source is required',
  RequiredAcceptedCards = 'At least one card is required',
  RequiredQualifiedIssuers = 'At least one issuer is required',
  RequiredCategory = 'Category is required',
  RequiredWebsiteURL = 'Website URL is required',
  RequiredImgURL = 'Image URL is required',
  RequiredFraudWarning = 'An amount is required',
  RequiredTerms = 'Terms and conditions are required',
  RequiredMinAmount = 'Minimum amount is required',
  RequiredMaxAmount = 'Maximum amount is required',
  RequiredTransactionId = 'Transaction ID is required',
  RequiredAuditCode = 'Audit Code is required',
  RequiredMerchantName = 'Merchant Name is required',
  PreferredContactEmail = 'Preferred Contact Email is required',
  PreferredContactEmailInvalid = 'Invalid email format',
  RequiredStartDate = 'Start date is required',
  RequiredExpirationDate = 'Expiration date is required',
  CommissionValueMinValue = 'Commission Value should be between 0 and 100',
  CommissionValueMaxValue = 'Commission Value should be between 0 and 100',
  MinTransactionAmountMinValue = 'Min Txn Amount must be equal or greater than 0',
  MaxTransactionAmountMinValue = 'Max Txn Amount must be equal or greater than 0',
  MinRewardAmountMinValue = 'Min Reward Amount must be equal or greater than 0',
  MaxRewardAmountMinValue = 'Max Reward Amount must be equal or greater than 0',
  MinUserRewardAmountMinValue = 'Min User Reward Amount must be equal or greater than 0',
  MaxUserRewardAmountMinValue = 'Max User Reward Amount must be equal or greater than 0',
  PasswordRequireUpperCaseLetter = 'Password must contain at least one upper case letter',
  PasswordRequireLowerCaseLetter = 'Password must contain at least one lower case letter',
  PasswordRequireDigit = 'Password must contain at least one digit',
  PasswordRequireSpecialCharacter = 'Password must contain at least one special character',
  RequiredStatus = 'Status is required',
  TransactionAmountInCentsMinValue = 'Transaction Amount In Cents must be equal or greater than 0',
  StatusWhenEditing = 'Status must be In Progress or Closed',
  RequiredReferringPartnerUserId = 'Referring Partner User ID is required',
  RequiredPreferredContactEmail = 'Preferred Contact Email is required',
  ExpirationDateGreaterThanStartDate = 'Expiration date must be after start date',
  RequiredAuditDescription = 'Audit Description is required',
  RequiredOffers = 'Offers is required',
  RequiredSize = 'Size is required',
  RequiredBehavior = 'Behavior is required',
  RequiredMerchant = 'Merchant is required',
  RequiredPurchaseRange = 'Purchase range is required',
  RequiredPurchaseCountValue = 'Purchase Count Value is required',
  RequiredTimescale = 'Timescale is required',
  RequiredTimescaleValue = 'Timescale value is required',
  RequiredPeriod = 'Period is required',
  RequiredMerchantId = 'Merchant ID is required',
  MinControlGroupPercentage = 'Min Control Group Percentage must be equal or greater than 5',
  MaxControlGroupPercentage = 'Max Control Group Percentage must be equal or less than 95',
  MaxDescriptionLen = 'Description exceeds 500 characters, consider shortening',
}

export default Validations;
