/* modules */
import { ReactNode, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  Row,
} from '@tanstack/react-table';

/* enums */
import Roles from 'enums/users/roles.enum';
import FilterType from 'refactored/enums/query/filterType.enum';

/* types */
import { Query } from 'types';

/* custom components */
import NoData from './NoData';
import SortableHeader from './SortableHeader';

/* styling */
import classes from './Table.module.scss';

export type ColumnProps = {
  header: string;
  accessorKey?: string;
  allowedRoles: readonly Roles[];
  id?: string;
  meta?: {
    tooltip?: Function;
    sortable?: boolean;
    filter?: {
      type: FilterType;
      multiValue?: boolean;
      hiddenOnColumn?: boolean;
      options?: Record<string, string>;
      searchable: boolean;
      validate: Function;
      pattern: RegExp;
    };
  };
  cell?: (props: {
    getValue: () => ReactNode | undefined;
    row: Row<any>;
  }) => ReactNode;
};

export type TableProps = {
  query: Query;
  updateQuery: Function;
  data: any[];
  columns: ColumnProps[];
  id: string;
  columnVisibility?: Record<string, boolean>;
  columnOrder?: string[];
  issuerSelected?: string | null;
};

const Table = ({
  query,
  updateQuery,
  data,
  columns,
  id,
  columnVisibility = {},
  columnOrder = [],
  issuerSelected,
}: TableProps) => {
  const tooltipId = `${id}-tip`;
  const [expanded, setExpanded] = useState(false);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onExpandedChange: setExpanded,
    expandedEnabled: true,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    state: {
      columnVisibility,
      expanded,
    },
    initialState: {
      columnOrder,
    },
  });

  // this array destructuring is due we are not using multi-level
  // header/footer and we avoid an unnecesary .map() render
  const [headerGroup] = table.getHeaderGroups();
  const [footerGroup] = table.getFooterGroups();
  const colSpan = table.getVisibleFlatColumns().length;

  // render methods
  const renderSortHeader = (header) => {
    const onSortHandler = ({ sort }) => {
      const nextQuery = {};

      if (sort !== undefined) {
        nextQuery.sort = sort;
      }
      updateQuery(nextQuery, ['pagination']);
    };

    return (
      <th
        key={header.id}
        className={classes.th}
        aria-label={header.column.columnDef.header}
      >
        <SortableHeader
          header={header.column.columnDef.header}
          columnName={header.id}
          sortState={query.sort}
          onSubmit={onSortHandler}
          filters={query.filters}
          displaySort={header.column.columnDef.meta?.sortable}
        />
      </th>
    );
  };

  const renderFooter = (header) => {
    const label = header.isPlaceholder
      ? null
      : flexRender(header.column.columnDef.footer, header.getContext());

    return <th key={header.id}>{label}</th>;
  };

  const renderCell = (cell) => {
    const isSelect = cell.column.columnDef.meta?.select;
    const dataTip =
      cell.column.columnDef.meta?.tooltip?.(cell.getValue()) ?? cell.getValue();
    const htmlTooltip = cell.column.columnDef.meta?.htmlTooltip?.(cell);

    return (
      <td key={cell.id} className={classes.td}>
        <div
          className={classNames({
            [classes.tdBodyOverflowVisible]:
              cell.column.columnDef.allowOverflow,
            [classes.tdBody]: !isSelect && !cell.column.columnDef.allowOverflow,
          })}
          data-tooltip-id={tooltipId}
          data-tooltip-content={dataTip}
          data-tooltip-html={htmlTooltip}
          data-tooltip-hidden={!dataTip}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      </td>
    );
  };

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.table} id={id}>
        <thead className={classes.thead}>
          <tr>{headerGroup.headers.map(renderSortHeader)}</tr>
        </thead>

        <tbody>
          {data.length === 0 && (
            <NoData colSpan={colSpan} issuerSelected={issuerSelected} />
          )}

          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={classes.tr}>
              {row.getVisibleCells().map(renderCell)}
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>{footerGroup.headers.map(renderFooter)}</tr>
        </tfoot>
      </table>

      <ReactTooltip
        clickable
        delayHide={100}
        id={tooltipId}
        render={({ content }) => `${content}`}
      />
    </div>
  );
};

export default Table;
