import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import PageHeader from 'components/Common/PageHeader';
import Button from 'components/Common/Button';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import { clearEditPortalUser, editPortalUser } from 'state/actions/users';
import { selectEditPortalUserState } from 'state/selectors/users';
import { RoleOptionsForFormControlSelect } from 'utils/users/roles';
import Roles from 'enums/users/roles.enum';
import LayoutGap from 'components/Common/LayoutGap';
import portalUserSchema from './UserProfile.schema';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
  XXS: 'xxs',
});

const UserProfile = ({ title, user }) => {
  const dispatch = useDispatch();

  const {
    loading: loadingEdit,
    error: errorEdit,
    success: successEdit,
  } = useSelector(selectEditPortalUserState, shallowEqual);

  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [valid, setValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(user);
  const [userRoles, setUserRoles] = useState(user?.roles ?? []);

  const userIsIssuer = useMemo(
    () => userRoles.includes(Roles.Issuer),
    [userRoles],
  );

  useEffect(() => {
    if (successEdit || errorEdit) {
      dispatch(clearEditPortalUser());
      setIsEditing(false);
    }
  }, [successEdit, errorEdit, dispatch]);

  const onEditHandler = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        email: values.email,
        role: values.roles.join(','), // note: the API is not updated to use 'roles'
        ...(userIsIssuer && { issuerName: values.issuerName }),
        title: values.title,
        name: values.fullName,
        phone_number: values.phoneNumber,
      };

      setCurrentValue(body);

      const editBody = {
        ...body,
        username: user?.username,
      };
      if (editBody.name === 'N/A') {
        delete editBody.name;
      }
      if (editBody.title === 'N/A') {
        delete editBody.title;
      }
      if (!editBody.phone_number) {
        delete editBody.phone_number;
      }
      dispatch(editPortalUser(editBody));
    },
    [userIsIssuer, dispatch, user?.username],
  );

  const onCancelHandler = useCallback(() => {
    setIsEditing(false);
    setShouldResetForm(true);
  }, []);

  useEffect(() => {
    if (shouldResetForm) {
      setShouldResetForm(false);
    }
  }, [setShouldResetForm, shouldResetForm]);

  const readOnly = useMemo(() => !isEditing, [isEditing]);

  return (
    <>
      {errorEdit && (
        <Toast
          id="error-updating-user"
          text="Error Edit Profile"
          type={ToastType.Error}
        />
      )}

      {successEdit && (
        <Toast
          id="success-updating-user"
          text="User updated successfully!"
          type={ToastType.Success}
        />
      )}

      <Form
        onSubmit={onSubmitHandler}
        validationSchema={portalUserSchema}
        validationMode={ValidationMode.OnChange}
        setValid={setValid}
        shouldReset={shouldResetForm}
        defaultValues={currentValue}
      >
        <LayoutGap direction="column">
          <PageHeader title={title}>
            {readOnly && <Button onClick={onEditHandler}>Edit profile</Button>}
            {isEditing && (
              <>
                <Button type="submit" loading={loadingEdit} disabled={!valid}>
                  Save changes
                </Button>
                <Button variant="secondary" onClick={onCancelHandler}>
                  Cancel
                </Button>
              </>
            )}
          </PageHeader>
          <LayoutGap direction="column" size="none">
            <FormControl
              name="fullName"
              render={(props) => (
                <Input
                  label="Full Name*"
                  value={currentValue?.fullName}
                  placeholder="Full Name"
                  type={InputType.Text}
                  readOnly={readOnly}
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
            <FormControl
              name="title"
              render={(props) => (
                <Input
                  label="Title"
                  value={currentValue?.title}
                  placeholder="Title"
                  type={InputType.Text}
                  readOnly={readOnly}
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
            <FormControlSelect
              name="roles"
              defaultValue={userRoles || []}
              label="Roles*"
              placeholder="Roles"
              readOnly={readOnly}
              disabled
              options={RoleOptionsForFormControlSelect}
              color={isEditing ? SelectColor.Black : SelectColor.Gray}
              isMulti
              onChangeManual={setUserRoles}
            />
            <FormControl
              name="email"
              render={(props) => (
                <Input
                  label="Email*"
                  value={currentValue?.email}
                  placeholder="Email"
                  type={InputType.Email}
                  readOnly={readOnly || isEditing}
                  disabled
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />

            {userIsIssuer && (
              <FormControl
                name="issuerName"
                render={(props) => (
                  <Input
                    label="Issuer"
                    value={currentValue?.issuerName}
                    placeholder="Issuer"
                    type={InputType.Text}
                    readOnly
                    size={InputSize.S}
                    color={InputColor.Gray}
                    {...props}
                  />
                )}
              />
            )}

            <FormControl
              name="phoneNumber"
              render={(props) => (
                <Input
                  label="Phone Number"
                  value={user?.phoneNumber}
                  placeholder="Phone Number"
                  type={InputType.Tel}
                  readOnly={readOnly}
                  size={InputSize.S}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
          </LayoutGap>
        </LayoutGap>
      </Form>
    </>
  );
};

UserProfile.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  ),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  isProfile: PropTypes.bool,
};

UserProfile.defaultProps = {
  user: {},
  isCreating: false,
  onCancel: () => {},
  isProfile: false,
};

export default UserProfile;
