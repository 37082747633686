import { CopyButton } from '@/components/ui/copy-button';
import { formatDate } from '@/lib/utils';
import { useId, useMemo } from 'react';

export const DisplayId = ({ value }: { value: string }) => (
  <code className="inline-flex items-center justify-start rounded-md text-xs">
    {value || '-'}
    {value && <CopyButton text={value} />}
  </code>
);

export const DisplayTimestamp = ({ timestamp }: { timestamp: string }) => {
  const formattedTimestamp = useMemo(() => {
    try {
      return formatDate(timestamp);
    } catch (error) {
      console.error('Error formatting timestamp', error);
      return timestamp || '-';
    }
  }, [timestamp]);

  return <time dateTime={timestamp}>{formattedTimestamp}</time>;
};

type DisplayValueProps = {
  label: string;
  value: string;
  type?: 'text' | 'datetime' | 'id';
};

export const DisplayValue = ({ label, value, type }: DisplayValueProps) => {
  const formattedValue = useMemo(() => {
    switch (type) {
      case 'datetime':
        return <DisplayTimestamp timestamp={value} />;
      case 'id':
        return <DisplayId value={value} />;
      case 'text':
      default:
        return value;
    }
  }, [type, value]);

  const id = useId();

  return (
    <div className="flex flex-col gap-1">
      <span className="text-sm font-medium" id={id}>
        {label}
      </span>
      <span className="text-sm" aria-labelledby={id}>
        {formattedValue}
      </span>
    </div>
  );
};
