import React, { forwardRef, useMemo, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';

import classes from './DatePicker.module.scss';
import './style.scss';

export type DatePickerProps = {
  date?: Date | null;
  defaultValue?: string;
  onChange: (date: Date) => void;
  color: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  showTimeSelect?: boolean;
  onBlur: (event: React.FocusEvent) => void;
  isUTC?: boolean;
  testId?: string;
};

const DatePicker = ({
  date = null,
  onChange,
  placeholder = 'MM/DD/YYYY',
  error = false,
  color,
  disabled = false,
  readOnly = false,
  defaultValue = '',
  showTimeSelect = false,
  onBlur,
  isUTC = false,
  testId = '',
}: DatePickerProps) => {
  const CustomInput = forwardRef<
    HTMLButtonElement,
    { value?: string; onClick?: () => void }
  >(({ value, onClick }, ref) => (
    <button
      className={classNames(classes.button, 'text-sm', {
        [classes.error]: error,
        [classes[color]]: !!value,
        [classes.disabled]: disabled,
      })}
      type="button"
      onClick={onClick}
      ref={ref}
      disabled={disabled || readOnly}
    >
      {value || placeholder} {isUTC && 'UTC'}
    </button>
  ));

  const dateValue = useCallback(() => {
    if (date) {
      return new Date(date);
    }
    if (defaultValue) {
      return new Date(defaultValue);
    }
    return null;
  }, [date, defaultValue]);

  const dateFormat = useMemo(
    () => (showTimeSelect ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy'),
    [showTimeSelect],
  );

  const datePicker = (
    <ReactDatePicker
      selected={dateValue()}
      onChange={onChange}
      onBlur={onBlur}
      customInput={<CustomInput />}
      showTimeSelect={showTimeSelect}
      timeFormat="HH:mm"
      timeIntervals={15}
      dateFormat={dateFormat}
    />
  );

  if (!testId) {
    return datePicker;
  }

  return <div data-cy={testId}>{datePicker}</div>;
};

export default DatePicker;
