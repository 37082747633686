/* modules */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';
import { noop } from 'lodash';

/* redux */
import { fetchMerchantById } from 'state/actions/merchants';
import { selectFetchMerchantByIdState } from 'state/selectors/merchants';

/* components */
import Input, { IconPosition } from 'refactored/components/Common/Input';
import Form from 'components/Common/Form';
import Textarea from 'components/Common/Textarea';
import Image from 'components/Common/Image';
import OffersAndLocationsTab from 'components/Pages/OffersAndLocationsTab';
import Heading from 'components/Typography/Heading';

/* types */
import { MerchantByIdResponse } from 'types/responses';

const MerchantInfo = () => {
  const { merchantId } = useParams();
  const dispatch = useDispatch();

  const { loading, merchant }: MerchantByIdResponse = useSelector(
    selectFetchMerchantByIdState,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchMerchantById(merchantId));
  }, [dispatch, merchantId]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner size="lg" color="gray" />
      </div>
    );
  }

  if (!merchant && !loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Heading>No merchant available</Heading>
      </div>
    );
  }

  return (
    <div className="px-10 pt-10">
      <h1 className="mb-6 text-xl font-semibold not-italic leading-7">
        {' '}
        Merchant Information
      </h1>
      <div className="!mb-[2.5rem]">
        <Form onSubmit={noop}>
          <div className="flex flex-col gap-4">
            <Input
              label="Merchant ID"
              value={merchant._id?.toString()}
              iconPosition={IconPosition.Right}
              readOnly
              disabled
            />
            <Input
              label="Name"
              value={merchant.name}
              iconPosition={IconPosition.Right}
              readOnly
              disabled
            />
            <Textarea
              name="Description"
              label="Description"
              value={merchant.description}
              readOnly
              disabled
            />
            <Input
              label="Source"
              value={merchant.source}
              iconPosition={IconPosition.Right}
              readOnly
              disabled
            />
            <Input
              label="Category"
              value={merchant.category}
              iconPosition={IconPosition.Right}
              readOnly
              disabled
            />
            <Input
              label="Website URL"
              value={merchant.websiteURL}
              iconPosition={IconPosition.Right}
              readOnly
              disabled
            />
            <div className="mb-10 flex flex-col gap-4">
              <p className="text-lg font-semibold leading-7">Merchant Assets</p>
              <Image label="Image URL" value={merchant.imgUrl} />
              <Image label="Banner Image" value={merchant.bannerImgUrl || ''} />
            </div>
          </div>
        </Form>
        <OffersAndLocationsTab
          merchantId={merchant._id?.toString() || ''}
          priorityOffers={merchant?.offers}
        />
      </div>
    </div>
  );
};

export default MerchantInfo;
