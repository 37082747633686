import client, { USERS_BASE_URL } from 'services/kardAPI';

const getKnockToken = async (id) => {
  const body = {
    userId: id,
  };
  try {
    const knockToken = await client.post(
      `${USERS_BASE_URL}/portal/token/knock`,
      body,
    );
    return knockToken.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default getKnockToken;
