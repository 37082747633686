import client, { TXN_BASE_URL } from 'services/kardAPI';

const editAudit = async (id, body, issuerId) => {
  try {
    const config = {
      headers: {
        'X-Kard-IssuerID': issuerId,
      },
    };
    await client.put(`${TXN_BASE_URL}/portal/audit/${id}`, body, config);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default editAudit;
