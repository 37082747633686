import { ReactNode } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import LayoutGap from '@/components/Common/LayoutGap';
import Select, { SelectProps } from '@/components/ui/Select';

import FormField from '../helpers/FormField';
import FormItem from '../helpers/FormItem';
import FormLabel from '../helpers/FormLabel';
import FormDescription from '../helpers/FormDescription';
import FormMessage from '../helpers/FormMessage';
import FormControl from '../helpers/FormControl';

export type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type SelectFieldProps<T extends FieldValues> = {
  label?: string;
  name: Path<T>;
  placeholder?: string;
  options: Option[];
  description?: ReactNode;
  className?: string;
} & SelectProps;

const SelectFormControl = <T extends FieldValues>({
  label,
  name,
  options,
  description,
  className,
  defaultValue,
  onValueChange,
  disabled,
  ...props
}: SelectFieldProps<T>) => {
  const form = useFormContext<T>();

  return (
    <FormField
      name={name}
      control={form.control}
      render={({ field: { ref, ...field } }) => (
        <FormItem className={className}>
          <LayoutGap direction="column" size="small">
            <FormLabel htmlFor={`form-field--${field.name}`}>{label}</FormLabel>
            <Select.Root
              {...field}
              onValueChange={(value) => {
                field.onChange(value);
                onValueChange?.(value);
              }}
              value={field.value}
              defaultValue={defaultValue}
              disabled={disabled || field.disabled}
            >
              <FormControl>
                <Select.Trigger>
                  <Select.Value {...props} ref={ref} />
                </Select.Trigger>
              </FormControl>
              <Select.Content>
                {options.map((option) => (
                  <Select.Item
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage />
          </LayoutGap>
        </FormItem>
      )}
    />
  );
};
SelectFormControl.displayName = 'SelectFormControl';

export default SelectFormControl;
