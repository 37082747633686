import { ReactNode } from 'react';
import LayoutGap from '../LayoutGap';

type Props = {
  title: string;
  children: ReactNode;
};

const MyComponent = ({ title, children }: Props) => (
  <fieldset>
    <LayoutGap direction="column">
      <LayoutGap direction="column" size="small">
        <legend className="mt-4 text-lg font-bold">{title}</legend>
        <hr className="w-[500px]" />
      </LayoutGap>
      {children}
    </LayoutGap>
  </fieldset>
);

export default MyComponent;
