import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import arrowLeftIcon from 'assets/icons/arrow-left.svg';
import arrowLeftDoubleIcon from 'assets/icons/arrow-left-double.svg';
import arrowLeftWhiteIcon from 'assets/icons/arrow-left-white.svg';
import arrowLeftDoubleWhiteIcon from 'assets/icons/arrow-left-double-white.svg';
import arrowRightIcon from 'assets/icons/arrow-right.svg';
import arrowRightWhiteIcon from 'assets/icons/arrow-right-white.svg';

import classes from './Pagination.module.scss';

const Pagination = ({
  manualPagination,
  firstItemPagination,
  lastItemPagination,
  data,
  pageIndex,
  pageCount,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  gotoPage,
  className,
  countTotal,
  newPagination,
}) => {
  const listPages = Array.from(
    Array(countTotal),
    (_, indexPage) => indexPage + 1,
  );
  return (
    <>
      <div className={classNames(className, classes.pagination)}>
        <Body
          size={BodySize.XXS}
          color={BodyColor.Gray}
          className={classes.paginationInfo}
        >
          {manualPagination
            ? `${data.length} Items`
            : `${firstItemPagination} - ${lastItemPagination} of ${data.length} Items`}
        </Body>
        {!newPagination && (
          <div className={classes.paginationButtons}>
            {manualPagination && (
              <button
                name="firstPage"
                type="button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className={classNames(classes.icon, {
                  [classes.disabled]: !canPreviousPage,
                })}
              >
                <img
                  src={
                    canPreviousPage
                      ? arrowLeftDoubleWhiteIcon
                      : arrowLeftDoubleIcon
                  }
                  alt="firstPage"
                />
              </button>
            )}
            <button
              name="previous"
              type="button"
              onClick={previousPage}
              disabled={!canPreviousPage}
              className={classNames(classes.icon, {
                [classes.disabled]: !canPreviousPage,
              })}
            >
              <img
                src={canPreviousPage ? arrowLeftWhiteIcon : arrowLeftIcon}
                alt="previous"
              />
            </button>
            {manualPagination ? (
              <button
                type="button"
                className={classNames(classes.pageNumber, classes.selectedPage)}
              >
                {pageIndex + 1}
              </button>
            ) : (
              <>
                {pageIndex !== 0 && (
                  <button
                    type="button"
                    onClick={() => gotoPage(0)}
                    className={classes.pageNumber}
                  >
                    1
                  </button>
                )}
                {pageIndex > 3 && (
                  <span className={classes.paginationElipsis}>…</span>
                )}
                {pageIndex === 3 && (
                  <button
                    type="button"
                    onClick={() => gotoPage(1)}
                    className={classes.pageNumber}
                  >
                    2
                  </button>
                )}
                {pageIndex - 1 > 0 && (
                  <button
                    type="button"
                    onClick={() => previousPage()}
                    className={classes.pageNumber}
                  >
                    {pageIndex}
                  </button>
                )}
                <button
                  type="button"
                  className={classNames(
                    classes.pageNumber,
                    classes.selectedPage,
                  )}
                >
                  {pageIndex + 1}
                </button>
                {canNextPage && (
                  <button
                    type="button"
                    onClick={() => nextPage()}
                    className={classes.pageNumber}
                  >
                    {pageIndex + 2}
                  </button>
                )}
                {pageCount - pageIndex === 4 && (
                  <button
                    type="button"
                    onClick={() => gotoPage(pageCount - 2)}
                    className={classes.pageNumber}
                  >
                    {pageCount - 1}
                  </button>
                )}
                {pageCount - pageIndex > 4 && (
                  <span className={classes.paginationElipsis}>…</span>
                )}
                {pageIndex + 2 < pageCount && (
                  <button
                    type="button"
                    onClick={() => gotoPage(pageCount - 1)}
                    className={classes.pageNumber}
                  >
                    {pageCount}
                  </button>
                )}
              </>
            )}
            <button
              name="next"
              type="button"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className={classNames(classes.icon, classes.lastIcon, {
                [classes.disabled]: !canNextPage,
              })}
            >
              <img
                src={canNextPage ? arrowRightWhiteIcon : arrowRightIcon}
                alt="next"
              />
            </button>
          </div>
        )}
        {newPagination && (
          <div className={classes.paginationButtons}>
            <button
              type="button"
              className={classNames(classes.icon, {
                [classes.disabled]: !canPreviousPage,
              })}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <img
                src={
                  canPreviousPage
                    ? arrowLeftDoubleWhiteIcon
                    : arrowLeftDoubleIcon
                }
                alt="firstPage"
              />
            </button>
            <button
              type="button"
              className={classNames(classes.icon, {
                [classes.disabled]: !canPreviousPage,
              })}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <img
                src={canPreviousPage ? arrowLeftWhiteIcon : arrowLeftIcon}
                alt="previous"
              />
            </button>
            <span className={classes.labelPageNumber}>
              Page{' '}
              <strong>
                {pageIndex + 1} of {countTotal}
              </strong>{' '}
            </span>
            <button
              type="button"
              onClick={() => nextPage()}
              className={classNames(classes.icon, classes.lastIcon, {
                [classes.disabled]: countTotal === pageIndex + 1,
              })}
              disabled={countTotal === pageIndex + 1}
            >
              <img
                className={classNames(classes.iconRight, {
                  [classes.disabled]: countTotal === pageIndex + 1,
                })}
                src={
                  countTotal === pageIndex + 1
                    ? arrowLeftIcon
                    : arrowLeftWhiteIcon
                }
                alt="next"
              />
            </button>
            <button
              type="button"
              onClick={() => gotoPage(countTotal - 1)}
              disabled={countTotal === pageIndex + 1}
              className={classNames(classes.icon, {
                [classes.disabled]: countTotal === pageIndex + 1,
              })}
            >
              <img
                className={classNames(classes.iconRight, {
                  [classes.disabled]: countTotal === pageIndex + 1,
                })}
                src={
                  countTotal === pageIndex + 1
                    ? arrowLeftDoubleIcon
                    : arrowLeftDoubleWhiteIcon
                }
                alt="next"
              />
            </button>
            <span className={classes.labelPageNumber}>| Go to page: </span>
            <select
              className={classes.selectStyle}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              value={pageIndex + 1}
            >
              {listPages.map((num) => (
                <option key={`bdg ${num}`} value={num}>
                  {num}
                </option>
              ))}
            </select>{' '}
          </div>
        )}
      </div>
    </>
  );
};
Pagination.propTypes = {
  firstItemPagination: PropTypes.number.isRequired,
  lastItemPagination: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  manualPagination: PropTypes.bool.isRequired,
  className: PropTypes.string,
  countTotal: PropTypes.number,
  newPagination: PropTypes.bool,
};

Pagination.defaultProps = {
  className: '',
  countTotal: 0,
  newPagination: false,
};
export default Pagination;
