import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spinner, Pagination } from 'flowbite-react';

/* custom components */
import Table from 'refactored/components/Common/Table';
import { selectFetchMerchantLocationsState } from 'state/selectors/locations';
import { fetchMerchantLocations } from 'state/actions/locations';
import Input, { IconPosition } from 'refactored/components/Common/Input';

/* utils */
import merchantLocationsMakeColumns from 'refactored/utils/offers/merchantLocationsMakeColumns';
import sortByQueryToSearchParams from 'refactored/utils/query/sortByQueryToSearchParams';

/* custom hooks */
import useQuery from 'refactored/hooks/useQuery';

import searchIcon from 'assets/icons/search.svg';
import classes from './MerchantLocations.module.scss';

type MerchantLocationsProps = {
  merchantLocationIds?: string[];
};

const LIMIT_PER_PAGE = 10;

const MerchantLocations = ({
  merchantLocationIds = [],
}: MerchantLocationsProps) => {
  const dispatch = useDispatch();
  const defaultQuery = {
    filters: { locationIds: { type: 'search', selected: merchantLocationIds } },
  };

  const [query, updateQuery] = useQuery(defaultQuery);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const {
    locationsData: { merchantLocations, countTotal },
    loading: loadingLocations,
  } = useSelector(selectFetchMerchantLocationsState, shallowEqual);

  const onSearchHandler = (id: string) => {
    if (!id) {
      updateQuery(defaultQuery);
      return;
    }

    updateQuery({
      filters: { locationIds: { type: 'search', selected: [id] } },
    });
  };

  const cancelSearchHandler = () => {
    updateQuery(defaultQuery);
    setSearchValue('');
  };

  const getPageCount = () => {
    const dataLength = searchValue ? 1 : merchantLocationIds.length;
    return Math.ceil(dataLength / LIMIT_PER_PAGE);
  };

  const getShowingFrom = (page: number) => {
    if (page === 1) {
      return 1;
    }
    return (page - 1) * LIMIT_PER_PAGE + 1;
  };

  const getShowingTo = (page: number) => {
    if (countTotal === 0) {
      return 0;
    }
    if (page === getPageCount()) {
      return merchantLocationIds.length;
    }
    return page * LIMIT_PER_PAGE;
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    const lowerBound = getShowingFrom(page) - 1;
    const upperBound = getShowingTo(page) + 1;

    const newSelected = merchantLocationIds.slice(lowerBound, upperBound);

    updateQuery({
      filters: { locationIds: { type: 'search', selected: newSelected } },
    });
  };

  useEffect(() => {
    if (query?.filters?.locationIds?.selected?.length > 0) {
      dispatch(
        fetchMerchantLocations(
          `?${sortByQueryToSearchParams(query).toString()}`,
        ),
      );
    }
  }, [dispatch, query]);

  return (
    <>
      <div
        className={classes.merchantLocationsTitle}
      >{`Merchant Locations (${merchantLocationIds.length})`}</div>
      {loadingLocations ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner size="lg" color="gray" />
        </div>
      ) : (
        <>
          <div className={classes.offers_locations_search_container}>
            <Input
              name="searchByLocation"
              placeholder="Search By Location ID"
              icon={searchIcon}
              iconPosition={IconPosition.Left}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => onSearchHandler(e.target.value)}
              onCancel={cancelSearchHandler}
              showCancelIcon
            />
          </div>
          <div className={classes.offers_locations_table_container}>
            <Table
              id="table-locations"
              columns={merchantLocationsMakeColumns()}
              data={merchantLocations}
              query={query}
              updateQuery={updateQuery}
            />
          </div>
          <div className={classes.paginationContainer}>
            <div className={classes.paginationInfo}>
              Showing
              <span className={classes.bold}>
                {' '}
                {getShowingFrom(currentPage)} - {getShowingTo(currentPage)}{' '}
              </span>
              of{' '}
              <span className={classes.bold}>{merchantLocationIds.length}</span>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={getPageCount()}
              onPageChange={onPageChange}
              showIcons
              previousLabel=""
              nextLabel=""
            />
          </div>
        </>
      )}
    </>
  );
};

export default MerchantLocations;
