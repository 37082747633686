import React from 'react';
import PropTypes from 'prop-types';

import SearchBy from 'refactored/components/Common/SearchBy';
import SortDirection from 'refactored/enums/query/sortDirection.enum';
import MultiSelectDropdown from 'refactored/components/Common/MultiSelectDropdown';
import SearchFilter from 'refactored/components/Common/SearchFilter';

import classes from './Filters.module.scss';

const searchStatusOptions = [
  { label: 'NEW', value: 'NEW' },
  { label: 'IN PROGRESS', value: 'IN_PROGRESS' },
  { label: 'CLOSED', value: 'CLOSED' },
];

const Filters = ({ query, updateQuery, isAdmin }) => {
  const defaultStatusOptions = isAdmin ? ['NEW', 'IN_PROGRESS'] : [];

  return (
    <div className={classes.container}>
      <SearchBy
        field="transactionId"
        placeholder="Search by Transaction ID"
        query={query}
        updateQuery={updateQuery}
        className="h-[37px] !w-[24.2rem]"
        showCancelIcon
        onCancel
      />
      <MultiSelectDropdown
        query={query}
        fieldName="status"
        options={searchStatusOptions}
        updateQuery={updateQuery}
        filterType="string"
        label="Status"
        defaultSelection={defaultStatusOptions}
      />
      <SearchFilter
        query={query}
        updateQuery={updateQuery}
        fieldName="referringPartnerUserId"
        filterType="string"
        label="Customer ID"
      />
    </div>
  );
};

export default Filters;

const filterPropTypes = {
  query: PropTypes.shape({
    pagination: PropTypes.shape({
      limit: PropTypes.number,
      page: PropTypes.number,
    }),
    filters: PropTypes.objectOf(PropTypes.shape({})),
    sort: PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.oneOf(Object.values(SortDirection)),
    }),
  }).isRequired,
  updateQuery: PropTypes.func.isRequired,
};

Filters.propTypes = {
  ...filterPropTypes,
  isAdmin: PropTypes.bool.isRequired,
};
