import {
  fetchPendingTransactionsInit,
  fetchPendingTransactionsSuccess,
  fetchPendingTransactionsFail,
  editPendingTransactionInit,
  editPendingTransactionSuccess,
  editPendingTransactionFail,
  reprocessIncomingTransactionInit,
  reprocessIncomingTransactionSuccess,
  reprocessIncomingTransactionFail,
  clearReprocessIncomingTransactionValues,
  reprocessPendingTransactionInit,
  reprocessPendingTransactionSuccess,
  reprocessPendingTransactionFail,
  clearPendingTransactionData,
} from 'state/actionCreators/pendingTransactions';

import fetchPendingTransactionsService from 'services/pendingTransactions/fetchPendingTransactions';
import editPendingTransactionService from 'services/pendingTransactions/editPendingTransaction';
import reprocessIncomingTransactionService from 'services/pendingTransactions/reprocessIncomingTransaction';
import reprocessPendingTransactionService from 'services/pendingTransactions/reprocessPendingTransactions';

export const fetchPendingTransactions =
  (filters = '', issuerId) =>
  async (dispatch) => {
    dispatch(fetchPendingTransactionsInit());

    try {
      const data = await fetchPendingTransactionsService(filters, issuerId);
      return dispatch(fetchPendingTransactionsSuccess(data));
    } catch (error) {
      return dispatch(fetchPendingTransactionsFail({ error: error.message }));
    }
  };

export const editPendingTransaction =
  ({ pendingTransactionId, ...rest }) =>
  async (dispatch) => {
    dispatch(editPendingTransactionInit());
    try {
      await editPendingTransactionService(pendingTransactionId, rest);
      return dispatch(editPendingTransactionSuccess());
    } catch (error) {
      return dispatch(editPendingTransactionFail({ error: error.message }));
    }
  };

export const reprocessIncomingTransaction =
  (id, body, issuerId) => async (dispatch) => {
    dispatch(reprocessIncomingTransactionInit());

    try {
      await reprocessIncomingTransactionService(id, body, issuerId);
      return dispatch(reprocessIncomingTransactionSuccess());
    } catch (error) {
      return dispatch(
        reprocessIncomingTransactionFail({ error: error.message }),
      );
    }
  };

export const reprocessPendingTransaction =
  ({ pendingTransactionId, issuerId }) =>
  async (dispatch) => {
    dispatch(reprocessPendingTransactionInit());

    try {
      const data = await reprocessPendingTransactionService(
        pendingTransactionId,
        issuerId,
      );
      return dispatch(reprocessPendingTransactionSuccess(data));
    } catch (error) {
      return dispatch(
        reprocessPendingTransactionFail({ error: error.message }),
      );
    }
  };

export const clearReprocessIncomingTransactionStateData =
  () => async (dispatch) =>
    dispatch(clearReprocessIncomingTransactionValues());

export const clearPendingTransactionStateData = () => async (dispatch) =>
  dispatch(clearPendingTransactionData());
