import { Spinner } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import InfoQuestion from 'assets/icons/info-question.svg?react';
import Dropdown from 'components/Common/DropdownUpdated';
import TableauWrapper, {
  TableauVizFilter,
} from 'components/Common/Tableau/TableauWrapper';
import importMeta from 'utils/vite/importMeta';
import selectFeatureFlagsState from 'state/selectors/featureFlags';
import { selectFetchUserProfileState } from 'state/selectors/users';
import type { UserProfileResponse } from 'types';
import Roles from 'enums/users/roles.enum';
import useExternalScript from 'hooks/useExternalScript';
import useTableauJWT from 'hooks/useTableauJWT';
import {
  dashboardDateRangeOptions as dateRangeOptions,
  viewByOptions,
} from 'utils/dropdown/options';
import useCurrentIssuer from 'refactored/hooks/useCurrentIssuer';

const { VITE_TABLEAU_BASE_URL } = importMeta().env;

const TableauURLs = {
  MatchedTransactionsByOfferType: `${VITE_TABLEAU_BASE_URL}/MatchedTransactionsByOfferType`,
  MatchedTransactionsByMerchantSource: `${VITE_TABLEAU_BASE_URL}/MatchedTransactionsByMerchantSource`,
  CommissionOverTimeByOfferType: `${VITE_TABLEAU_BASE_URL}/CommissionOverTimeByOfferType`,
  CommissionOverTimeByMerchantSource: `${VITE_TABLEAU_BASE_URL}/CommissionOverTimeByMerchantSource`,
  TotalTransactions: `${VITE_TABLEAU_BASE_URL}/TotalTransactions`,
  MatchedTransactions: `${VITE_TABLEAU_BASE_URL}/MatchedTransactions`,
  TotalCommission: `${VITE_TABLEAU_BASE_URL}/TotalCommission`,
  MatchedvsTotalTransactions: `${VITE_TABLEAU_BASE_URL}/MatchedvsTotalTransactions`,
  TransactedvsTotalEnrolledCardholders: `${VITE_TABLEAU_BASE_URL}/TransactedvsTotalEnrolledCardholders`,
  EnrolledCardholderGrowth: `${VITE_TABLEAU_BASE_URL}/EnrolledCardholderGrowth`,
  IssuerVsUserCommission: `${VITE_TABLEAU_BASE_URL}/IssuervsUserCommission2`,
  UserCommission: `${VITE_TABLEAU_BASE_URL}/UserCommission`,
  IssuerCommission: `${VITE_TABLEAU_BASE_URL}/IssuerCommission`,
};

const Home = () => {
  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);

  const isLoadingFlags = !Object.keys(flags).length;
  const { PORTAL_KPI_DASHBOARD_PI_95, IS_1604_ISSUER_USER_COMMISSION_KPIS } =
    flags;

  useExternalScript(
    'https://embedding.tableauusercontent.com/tableau.embedding.3.10.0.min.js',
  );

  const [dateRangeValue, setDateRangeValue] = useState<string>(
    dateRangeOptions[0].value,
  );

  const [viewByValue, setViewByValue] = useState<string>(
    viewByOptions[0].value,
  );

  const { userProfile }: UserProfileResponse = useSelector(
    selectFetchUserProfileState,
    shallowEqual,
  );

  const { tableauJWT, checkTokenExpiration } = useTableauJWT();

  const { roles } = userProfile || {};

  const userIsIssuer = useMemo(() => roles?.includes(Roles.Issuer), [roles]);
  const [issuer] = useCurrentIssuer({
    shouldFetch: userIsIssuer && IS_1604_ISSUER_USER_COMMISSION_KPIS,
  });

  const showKPIDashboard = userIsIssuer && PORTAL_KPI_DASHBOARD_PI_95;

  const defaultFilters: TableauVizFilter[] = [
    {
      field: dateRangeValue,
      value: 'true',
    },
  ];

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration, dateRangeValue, viewByValue]);

  const { matchedTransactionsUrl, totalCommissionUrl } =
    viewByValue === 'offer_type'
      ? {
          matchedTransactionsUrl: TableauURLs.MatchedTransactionsByOfferType,
          totalCommissionUrl: TableauURLs.CommissionOverTimeByOfferType,
        }
      : {
          matchedTransactionsUrl:
            TableauURLs.MatchedTransactionsByMerchantSource,
          totalCommissionUrl: TableauURLs.CommissionOverTimeByMerchantSource,
        };

  const isCommissionSplit =
    IS_1604_ISSUER_USER_COMMISSION_KPIS && issuer?.isCommissionSplit;

  return (
    <div className="ml-5 p-6">
      <div className="flex gap-5 pt-3">
        <h1
          className="text-3xl font-semibold text-gray-900"
          data-testid="page-header-title"
        >
          Dashboard
        </h1>
        {showKPIDashboard && (
          <Dropdown
            options={dateRangeOptions}
            value={dateRangeValue}
            onChange={(value: string) => setDateRangeValue(value)}
          />
        )}
      </div>
      <div className="mt-4 flex">
        {isLoadingFlags && <Spinner color="gray" size="xl" />}
      </div>
      {showKPIDashboard && (
        <>
          <div>
            <div className="flex w-full items-center">
              <h3 className="mt-4 py-2 text-xl font-medium text-gray-900">
                Overview
              </h3>
              <span className="flex-grow" />
              <span
                id="xyz"
                className="flex cursor-pointer items-center gap-1 text-sm text-gray-500 hover:text-gray-700"
                data-tooltip-content="Data includes settled transactions from card-linked offers only. Data is refreshed once a day at 5AM ET."
                data-tooltip-id="about-data"
                data-place="top"
              >
                <InfoQuestion />
                About the data
              </span>
            </div>
            <ReactTooltip
              id="about-data"
              arrowColor="white"
              className="max-w-52 hyphens-auto bg-white p-2 text-gray-500 drop-shadow-md"
              disableStyleInjection
            />
            <div
              className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ${
                isCommissionSplit && 'xl:grid-cols-5'
              } justify-between gap-4`}
            >
              <TableauWrapper
                height="55px"
                filters={defaultFilters}
                title="Total Transactions"
                titlePosition="lower"
                token={tableauJWT}
                url={TableauURLs.TotalTransactions}
              />
              <TableauWrapper
                height="55px"
                filters={defaultFilters}
                title="Matched Transactions"
                titlePosition="lower"
                token={tableauJWT}
                url={TableauURLs.MatchedTransactions}
              />
              <TableauWrapper
                className="flex-grow"
                height="55px"
                filters={defaultFilters}
                title="Total Commission"
                titlePosition="lower"
                token={tableauJWT}
                url={TableauURLs.TotalCommission}
              />
              {isCommissionSplit && (
                <TableauWrapper
                  className="flex-grow"
                  height="55px"
                  filters={defaultFilters}
                  title="Issuer Commission"
                  titlePosition="lower"
                  token={tableauJWT}
                  url={TableauURLs.IssuerCommission}
                />
              )}
              {isCommissionSplit && (
                <TableauWrapper
                  className="flex-grow"
                  height="55px"
                  filters={defaultFilters}
                  title="User Commission"
                  titlePosition="lower"
                  token={tableauJWT}
                  url={TableauURLs.UserCommission}
                />
              )}
            </div>

            <div className="mt-8 gap-8">
              <div className="flex flex-grow flex-col">
                <h3 className="py-2 text-xl font-medium text-gray-900">
                  Trending over time
                </h3>
                <div className="mb-6 flex items-center gap-2 text-sm text-gray-500">
                  <InfoQuestion /> To switch between daily, weekly, or monthly
                  views, hover over the dates on the x-axis and click the - or +
                  buttons.
                </div>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <TableauWrapper
                    height="391px"
                    filters={defaultFilters}
                    title="Matched vs Total Transactions"
                    url={TableauURLs.MatchedvsTotalTransactions}
                    token={tableauJWT}
                  />
                  <TableauWrapper
                    height="391px"
                    filters={defaultFilters}
                    title="Transacted vs Total Enrolled Cardholders"
                    url={TableauURLs.TransactedvsTotalEnrolledCardholders}
                    token={tableauJWT}
                  />
                </div>
                <div className="z-100 mt-4 flex gap-4">
                  <Dropdown
                    options={viewByOptions}
                    value={viewByValue}
                    onChange={(value: string) => setViewByValue(value)}
                    label="View By: "
                  />
                </div>
                <div className="mt-2 grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <TableauWrapper
                    height="391px"
                    filters={defaultFilters}
                    title="Matched Transactions"
                    url={matchedTransactionsUrl}
                    token={tableauJWT}
                  />
                  <TableauWrapper
                    height="391px"
                    filters={defaultFilters}
                    title="Total Commission ($)"
                    token={tableauJWT}
                    url={totalCommissionUrl}
                  />
                </div>
                <hr className="max-w-85 mb-6 ml-2 mt-6 border-t-2 border-gray-200" />
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <TableauWrapper
                    height="430px"
                    filters={defaultFilters}
                    title="Enrolled Cardholder Growth (net new)"
                    url={TableauURLs.EnrolledCardholderGrowth}
                    token={tableauJWT}
                  />
                  {isCommissionSplit && (
                    <TableauWrapper
                      height="430px"
                      filters={defaultFilters}
                      title="Issuer vs User Commission"
                      url={TableauURLs.IssuerVsUserCommission}
                      token={tableauJWT}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
