import { MouseEventHandler } from 'react';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button, { ButtonProps } from 'components/Common/Button';
import LayoutGap from 'components/Common/LayoutGap';

import classes from './ConfirmationMessage.module.scss';

export type ConfirmationMessageProps = {
  title?: string | null;
  message: string;
  onAccept: MouseEventHandler;
  onCancel: MouseEventHandler;
  loading?: boolean;
  acceptButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
};

const ConfirmationMessage = ({
  title = null,
  message,
  onAccept,
  onCancel,
  loading = false,
  acceptButtonProps = { children: 'Accept', variant: 'primary' },
  cancelButtonProps = { children: 'Cancel', variant: 'secondary' },
}: ConfirmationMessageProps) => (
  <div className={classes.container}>
    {title && <Heading size={HeadingSize.M}>{title}</Heading>}
    <Body size={BodySize.S} className={classes.body}>
      {message}
    </Body>
    <LayoutGap className="justify-end">
      <Button onClick={onCancel} {...cancelButtonProps} />
      <Button onClick={onAccept} loading={loading} {...acceptButtonProps} />
    </LayoutGap>
  </div>
);

export default ConfirmationMessage;
