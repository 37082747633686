/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const selectMerchantNetworksState = (state) => state.merchantNetworks;

/**
 * @deprecated Use refactored/hooks/useMerchantNetworks instead
 */
export const selectFetchMerchantNetworksState = createSelector(
  selectMerchantNetworksState,
  ({
    fetchMerchantNetworksLoading,
    fetchMerchantNetworksSuccess,
    fetchMerchantNetworksError,
    merchantNetworks,
  }) => ({
    loading: fetchMerchantNetworksLoading,
    success: fetchMerchantNetworksSuccess,
    error: fetchMerchantNetworksError,
    merchantNetworks,
  }),
);

export const selectCreateMerchantNetworkState = createSelector(
  selectMerchantNetworksState,
  ({
    createMerchantNetworkLoading,
    createMerchantNetworkSuccess,
    createMerchantNetworkError,
  }) => ({
    loading: createMerchantNetworkLoading,
    success: createMerchantNetworkSuccess,
    error: createMerchantNetworkError,
  }),
);
