/* modules */
import { Popover } from '@headlessui/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';

/* types */
import type { SelectableOption } from 'types';

/* assets */
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import clearIcon from 'assets/icons/x.svg';

export type DropdownProps = {
  options: SelectableOption[];
  placeholder?: string;
  value: string | null;
  onChange: Function;
  label?: string;
  onCancel?: Function;
};

const getSelectedValueLabel = (
  options: SelectableOption[],
  selectedValue: string | null,
): string | null =>
  options?.find((option) => option?.value === selectedValue)?.label || null;

const Dropdown = ({
  options,
  placeholder = '',
  value,
  onChange,
  label = '',
  onCancel,
}: DropdownProps) => (
  <Popover>
    {({ open }) => (
      <div className="relative">
        <Popover.Button className="flex items-center gap-3 rounded-md border border-gray-200 bg-white px-3 py-2 text-sm">
          <div className="overflow-x-hidden overflow-ellipsis">
            {label}
            {getSelectedValueLabel(options, value) ?? placeholder}
          </div>
          {value && onCancel && (
            <button type="button" onClick={() => onCancel()}>
              <img alt="clear" src={clearIcon} />
            </button>
          )}
          <img
            alt="arrow"
            className={classNames(
              'transition-transform duration-100 ease-linear',
              { 'rotate-180 transform': open },
            )}
            src={arrowDownIcon}
          />
        </Popover.Button>
        <Popover.Panel className="absolute z-50 mt-2 flex max-h-80 w-full flex-col rounded-md bg-white shadow-md">
          <ul className="mt-0.1 flex flex-1 flex-col overflow-x-hidden overflow-y-hidden">
            {options.map((option) => (
              <Popover.Button
                key={option.label}
                type="button"
                className="option cursor-pointer p-4 pl-3 hover:bg-gray-200"
                onClick={() => onChange(option?.value)}
                data-tooltip-content={option.label}
                data-tooltip-id={`${option.label}-tooltip`}
                data-testid={`option-${option.value}`}
              >
                <li className="optionLabel overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-sm">
                  {option.label}
                </li>
                {option.label.length > 12 && (
                  <ReactTooltip id={`${option.label}-tooltip`} float />
                )}
              </Popover.Button>
            ))}
          </ul>
        </Popover.Panel>
      </div>
    )}
  </Popover>
);

export default Dropdown;
