import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PlusIcon } from 'lucide-react';
import { toast } from 'sonner';

import PageHeader from '@/components/Common/PageHeader';
import LayoutGap from '@/components/Common/LayoutGap';
import Button from '@/components/Common/Button';
import Form from '@/components/ui/Form';

import {
  ExperimentRequest,
  experimentRequestSchema,
  useCreateExperiment,
} from '@/hooks/api/experiments';
import { tryCatch } from '@/utils/tryCatch';

const CreateExperiment = () => {
  const navigate = useNavigate();
  const form = useForm<ExperimentRequest>({
    resolver: zodResolver(experimentRequestSchema),
    defaultValues: {
      attributes: {
        name: '',
      },
    },
  });

  const [createExperimentState, createExperiment] = useCreateExperiment();

  const onSubmit = async (data: ExperimentRequest) => {
    const [error, response] = await tryCatch(createExperiment({ data }));

    if (error) {
      toast.error('Error creating experiment', {
        description: error.message,
      });
    } else {
      // TODO: navigate to the created experiment once the page exists
      navigate(`/experiments/${response.data.id}`);
      toast.success('Experiment created successfully!', {
        description: 'You can now start creating offers for this experiment.',
      });
    }
  };

  return (
    <Form.Provider {...form}>
      <form className="p-4" onSubmit={form.handleSubmit(onSubmit)}>
        <LayoutGap direction="column">
          <PageHeader title="Create Experiment">
            <LayoutGap>
              <Link to="/experiments">
                <Button variant="secondary">Cancel</Button>
              </Link>
              <Button
                type="submit"
                disabled={createExperimentState.loading}
                onClick={form.handleSubmit(onSubmit)}
                loading={createExperimentState.loading}
                icon={<PlusIcon size="1em" />}
              >
                Create Experiment
              </Button>
            </LayoutGap>
          </PageHeader>

          <LayoutGap direction="column">
            <Form.Input
              name="attributes.name"
              label="Experiment Name"
              placeholder="Enter experiment name"
            />
          </LayoutGap>
        </LayoutGap>
      </form>
    </Form.Provider>
  );
};

export default CreateExperiment;
