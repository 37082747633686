import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { createPortalUser } from 'state/actions/users';
import { selectCreatePortalUserState } from 'state/selectors/users';
import { fetchMerchantIssuers } from 'state/actions/merchants';
import { selectFetchMerchantIssuersState } from 'state/selectors/merchants';
import { selectResendConfirmationCodeState } from 'state/selectors/auth';

import msg from 'utils/commonMessages';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import ButtonGroup from 'components/Common/ButtonGroup';
import Button from 'components/Common/Button';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import FormControlSelect from 'components/Common/FormControlSelect';
import commonToast from 'components/Common/commonToast';

import { RoleOptionsForFormControlSelect } from 'utils/users/roles';
import Roles from 'enums/users/roles.enum';
import classes from './InviteUserForm.module.scss';

import portalUserSchema from './InviteUserForm.schema';

const UserForm = ({ title, onCancel }) => {
  const dispatch = useDispatch();

  const {
    success: successResendingConfirmation,
    error: errorResendingConfirmation,
  } = useSelector(selectResendConfirmationCodeState, shallowEqual);

  const { issuersOptions, loading: loadingMerchantIssuers } = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual,
  );

  const {
    loading: loadingCreate,
    success: createPortalUserSuccess,
    error: errorCreate,
  } = useSelector(selectCreatePortalUserState, shallowEqual);

  const [userRoles, setUserRoles] = useState([]);
  const userIsIssuer = useMemo(
    () => userRoles.includes(Roles.Issuer),
    [userRoles],
  );

  useEffect(() => {
    // if issuer role is selected, fetch merchant issuers
    if (userIsIssuer) {
      dispatch(fetchMerchantIssuers());
    }
  }, [dispatch, userIsIssuer]);

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        email: values.email,
        role: values.roles.join(','), // note: the API is not updated to use 'roles'
        ...(userIsIssuer && { issuerName: values.issuerName }),
        title: values.title,
        name: values.fullName,
        phone_number: values.phoneNumber,
      };
      if (!body.title) {
        delete body.title;
      }
      if (!body.phone_number) {
        delete body.phone_number;
      }
      dispatch(createPortalUser(body));
    },
    [userIsIssuer, dispatch],
  );

  return (
    <>
      {errorCreate && (
        <Toast
          id="error create user"
          text={errorCreate}
          type={ToastType.Error}
        />
      )}
      {createPortalUserSuccess && (
        <Toast
          id="success=creating-user"
          text="User created successfully!"
          type={ToastType.Success}
        />
      )}
      {commonToast(
        successResendingConfirmation,
        !!errorResendingConfirmation,
        msg.ReSendInvitationSuccessMsg,
        msg.ReSendInvitationErrorMsg,
      )}
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={portalUserSchema}
        validationMode={ValidationMode.OnBlur}
        className={classes.form}
      >
        <div className={classes.title}>{title}</div>
        <div>
          <FormControl
            name="fullName"
            render={(props) => (
              <Input
                label="Full Name*"
                labelClassName={classes.inputLabel}
                placeholder="John Smith"
                type={InputType.Text}
                isFullScreen
                {...props}
              />
            )}
          />
          <FormControl
            name="title"
            render={(props) => (
              <Input
                label="Title"
                labelClassName={classes.inputLabel}
                placeholder="Project Manager"
                type={InputType.Text}
                isFullScreen
                {...props}
              />
            )}
          />
          <FormControlSelect
            name="roles"
            defaultValue={userRoles}
            label="Roles*"
            labelClassName={classes.inputLabel}
            placeholder=""
            options={RoleOptionsForFormControlSelect}
            isMulti
            onChangeManual={setUserRoles}
          />
          {userIsIssuer && (
            <FormControlSelect
              name="issuerName"
              label="Issuer*"
              placeholder={
                loadingMerchantIssuers ? 'Loading options\u2026' : 'Issuer'
              }
              labelClassName={classes.inputLabel}
              disabled={loadingMerchantIssuers}
              options={issuersOptions}
            />
          )}
          <FormControl
            name="email"
            render={(props) => (
              <Input
                label="Email*"
                labelClassName={classes.inputLabel}
                placeholder="name@example.com"
                type={InputType.Email}
                size={InputSize.S}
                isFullScreen
                {...props}
              />
            )}
          />
          <FormControl
            name="phoneNumber"
            render={(props) => (
              <Input
                label="Phone"
                labelClassName={classes.inputLabel}
                placeholder="+1555-555-5555"
                type={InputType.Tel}
                size={InputSize.S}
                isFullScreen
                {...props}
              />
            )}
          />
          <ButtonGroup reverse>
            <Button type="submit" loading={loadingCreate}>
              Send invite
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    </>
  );
};

UserForm.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  ),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  isProfile: PropTypes.bool,
};

UserForm.defaultProps = {
  user: {},
  isCreating: false,
  onCancel: () => {},
  isProfile: false,
};

export default UserForm;
