import { createSelector } from '@reduxjs/toolkit';

const selectMerchantsState = (state) => state.merchants;

export const selectFetchMerchantsState = createSelector(
  selectMerchantsState,
  ({
    fetchMerchantsLoading,
    fetchMerchantsSuccess,
    fetchMerchantsError,
    merchants: { results, countTotal },
  }) => ({
    loading: fetchMerchantsLoading,
    success: fetchMerchantsSuccess,
    error: fetchMerchantsError,
    merchantsData: {
      merchants: results || [],
      countTotal: countTotal || 0,
    },
  }),
);

export const selectFetchMerchantsFromCacheState = createSelector(
  selectMerchantsState,
  ({
    fetchMerchantsFromCacheLoading,
    fetchMerchantsFromCacheSuccess,
    fetchMerchantsFromCacheError,
    cachedMerchants,
    lastUpdated,
  }) => ({
    loading: fetchMerchantsFromCacheLoading,
    success: fetchMerchantsFromCacheSuccess,
    error: fetchMerchantsFromCacheError,
    merchants: cachedMerchants,
    lastUpdated,
  }),
);

export const selectFetchMerchantByIdState = createSelector(
  selectMerchantsState,
  ({
    fetchMerchantByIdLoading,
    fetchMerchantByIdSuccess,
    fetchMerchantByIdError,
    merchant,
  }) => ({
    loading: fetchMerchantByIdLoading,
    success: fetchMerchantByIdSuccess,
    error: fetchMerchantByIdError,
    merchant,
  }),
);

export const selectCreateMerchantState = createSelector(
  selectMerchantsState,
  ({
    createMerchantLoading,
    createMerchantSuccess,
    createMerchantError,
    createdMerchant,
  }) => ({
    loading: createMerchantLoading,
    success: createMerchantSuccess,
    error: createMerchantError,
    createdMerchant,
  }),
);

export const selectEditMerchantState = createSelector(
  selectMerchantsState,
  ({ editMerchantLoading, editMerchantSuccess, editMerchantError }) => ({
    loading: editMerchantLoading,
    success: editMerchantSuccess,
    error: editMerchantError,
  }),
);

export const selectFetchMerchantCategoriesState = createSelector(
  selectMerchantsState,
  ({
    fetchMerchantCategoriesLoading,
    fetchMerchantCategoriesSuccess,
    fetchMerchantCategoriesError,
    categories,
  }) => ({
    loading: fetchMerchantCategoriesLoading,
    success: fetchMerchantCategoriesSuccess,
    error: fetchMerchantCategoriesError,
    categories,
    categoriesOptions:
      categories?.map((category) => ({
        label: category,
        value: category,
      })) ?? [],
  }),
);

export const selectFetchMerchantIssuersState = createSelector(
  selectMerchantsState,
  ({
    fetchMerchantIssuersLoading,
    fetchMerchantIssuersSuccess,
    fetchMerchantIssuersError,
    issuers,
  }) => ({
    loading: fetchMerchantIssuersLoading,
    success: fetchMerchantIssuersSuccess,
    error: fetchMerchantIssuersError,
    issuers,
    issuersOptions:
      issuers?.map((issuer) => ({
        label: issuer,
        value: issuer,
      })) ?? [],
  }),
);
