import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import failIcon from 'assets/icons/boolean-false.svg';
import selectExportDataState from 'state/selectors/exportData';
import cancelIcon from 'assets/icons/x.svg';

import { closeExportBanner, exportData } from 'state/actions/exportData';
import getIdToken from 'utils/getIdToken';
import { getExportModelDisplayName } from '../utils';

const ExportFailed = () => {
  const dispatch = useDispatch();

  const { model, filters, error } = useSelector(
    selectExportDataState,
    shallowEqual,
  );

  const onCloseButton = () => dispatch(closeExportBanner());

  const onTryAgainButton = async () => {
    const idToken = await getIdToken();

    const exportInfo = {
      idToken,
      filters,
      model,
    };

    dispatch(exportData(exportInfo));
  };

  const getErrorMessage = () => {
    // If we have ACTIVE/UPCOMING filters enabled, but we don't get any ACTIVE/UPCOMING results we show a custom error message
    if (error === 'No matches found for your query') {
      return `No active ${getExportModelDisplayName(model)}s to export`;
    }
    return `${getExportModelDisplayName(model)}s export has failed`;
  };

  return (
    <div className="flex w-fit gap-2.5">
      <div className="flex items-center">
        <img src={failIcon} alt="export failed" />
      </div>
      <div className="flex w-[14.6rem] flex-col">
        <p className="text-gray900 text-sm font-semibold">
          {getErrorMessage()}
        </p>
        <button
          type="button"
          onClick={onTryAgainButton}
          className="text-primary600 text-left text-sm font-normal underline"
        >
          Try again
        </button>
      </div>
      <div>
        <button type="button" onClick={onCloseButton}>
          <img src={cancelIcon} alt="cancel icon" />
        </button>
      </div>
    </div>
  );
};

export default ExportFailed;
