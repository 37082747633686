import Roles from './roles.enum';

const Groups: {
  Everyone: readonly Roles[];
  Administrators: readonly Roles[];
  Internal: readonly Roles[];
  Reporters: readonly Roles[];
  Data: readonly Roles[];
} = Object.freeze({
  Everyone: Object.freeze([
    Roles.Issuer,
    Roles.KardReporter,
    Roles.KardAdmin,
    Roles.DataAdmin,
  ]),
  Administrators: Object.freeze([Roles.KardAdmin, Roles.DataAdmin]),
  Internal: Object.freeze([
    Roles.KardAdmin,
    Roles.KardReporter,
    Roles.DataAdmin,
  ]),
  Reporters: Object.freeze([Roles.Issuer, Roles.KardReporter]),
  Data: Object.freeze([Roles.DataAdmin]),
});

export default Groups;
