import client, { TXN_BASE_URL } from 'services/kardAPI';

const fetchPendingTransactions = async (filters = '', issuerId) => {
  try {
    const config = {
      headers: {
        'X-Kard-IssuerID': issuerId,
      },
    };
    const users = await client.get(
      `${TXN_BASE_URL}/portal/pending${filters}`,
      config,
    );
    return users.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchPendingTransactions;
