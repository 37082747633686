import client, { AUDIENCE_BASE_URL } from 'services/kardAPI';

const createAudience = async (body) => {
  try {
    await client.post(`${AUDIENCE_BASE_URL}/portal/audience`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createAudience;
