enum Roles {
  KardAdmin = 'admin.kard',
  KardReporter = 'reporter.kard',
  Issuer = 'reporter.issuer',
  DataAdmin = 'data.admin',
}

/**
 * These are the roles that are usable in the UI and their visual labels.
 * NOTE: This map should be one-to-one, such that labels are unique
 * and an ID can be derived from a label.
 * @see {@link utils/users/roles}
 */
export const RoleLabels = Object.freeze({
  [Roles.KardAdmin]: 'Kard Admin',
  [Roles.KardReporter]: 'Kard Read Only',
  [Roles.Issuer]: 'Issuer Read Only',
  [Roles.DataAdmin]: 'Data Admin',
});

export default Roles;
