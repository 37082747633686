import client, { AUDIENCE_BASE_URL } from 'services/kardAPI';

const fetchAudienceById = async (id) => {
  try {
    const { data } = await client.get(
      `${AUDIENCE_BASE_URL}/portal/audience/${id}`,
    );

    return { results: [data], countTotal: 1 };
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchAudienceById;
