import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';

const editAuditSchema = yup.object().shape({
  auditId: yup.string(),
  transactionId: yup.string(),
  transactionAmountInCents: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.TransactionAmountInCentsMinValue),
  referringPartnerUserId: yup.string(),
  merchantId: yup.string(),
  merchantName: yup.string(),
  auditCode: yup
    .number()
    .transform((val) => (Number.isNaN(val) ? -1 : val))
    .required(Validations.RequiredAuditCode),
  auditDescription: yup.string(),
  issuer: yup.string(),
  status: yup
    .mixed()
    .oneOf(['IN_PROGRESS', 'CLOSED'], Validations.StatusWhenEditing)
    .required(Validations.RequiredStatus),
  resolutionCode: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  resolutionDescription: yup.string(),
  resolutionTimeStamp: yup.string(),
  notes: yup.array().of(yup.object()),
  internalDispute: yup.boolean(),
  source: yup.string().nullable(),
  submitterName: yup.string(),
  transactionDate: yup.date().typeError('Invalid format, must be a date'),
  validDispute: yup.boolean(),
  matchedMerchant: yup.string(),
  merchantSource: yup.string().nullable(),
});

const createAuditSchema = yup.object().shape({
  transactionId: yup.string().required(Validations.RequiredTransactionId),
  transactionAmountInCents: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.TransactionAmountInCentsMinValue),
  referringPartnerUserId: yup.string(),
  merchantId: yup.string(),
  merchantName: yup.string().required(Validations.RequiredMerchantName),
  auditCode: yup
    .number()
    .transform((val) => (Number.isNaN(val) ? undefined : val))
    .required(Validations.RequiredAuditCode),
  auditDescription: yup
    .string()
    .max(500, 'Must be less than 500 Characters')
    .required(Validations.RequiredAuditDescription),
  issuer: yup.string().required(Validations.RequiredIssuer).nullable(),
  submitterName: yup.string(),
  preferredContactEmail: yup
    .string()
    .email(Validations.PreferredContactEmailInvalid)
    .required(Validations.RequiredPreferredContactEmail),
});

export { createAuditSchema, editAuditSchema };
