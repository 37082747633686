import React from 'react';
import PropTypes from 'prop-types';
import searchIcon from 'assets/icons/search-outline.svg';
import classNames from 'classnames';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'refactored/components/Common/Input';

const SearchBox = ({
  className,
  placeholder,
  testId,
  searchValue,
  onChange,
  onEnterDown,
  showCancelIcon,
  onCancel,
}) => (
  <Input
    name="search"
    aria-label="search"
    value={searchValue}
    onChange={onChange}
    onEnterDown={onEnterDown}
    placeholder={placeholder}
    type={InputType.Text}
    icon={searchIcon}
    size={InputSize.S}
    className={classNames(className)}
    showCancelIcon={showCancelIcon}
    onCancel={onCancel}
    testId={testId}
  />
);

SearchBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnterDown: PropTypes.func.isRequired,
  showCancelIcon: PropTypes.bool,
  onCancel: PropTypes.func,
};

SearchBox.defaultProps = {
  className: undefined,
  placeholder: 'Search',
  testId: null,
  showCancelIcon: false,
  onCancel: null,
};

export default SearchBox;
