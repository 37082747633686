import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import PageHeader from 'components/Common/PageHeader';
import Button from 'components/Common/Button';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import FormControlSelect from 'components/Common/FormControlSelect';
import Textarea from 'components/Common/Textarea';

import { fetchUserProfile } from 'state/actions/users';
import { selectFetchUserProfileState } from 'state/selectors/users';
import { createAudit } from 'state/actions/audits';
import { fetchMerchantIssuers } from 'state/actions/merchants';
import { selectFetchMerchantIssuersState } from 'state/selectors/merchants';
import { selectCreateAuditState } from 'state/selectors/audits';

import Roles from 'enums/users/roles.enum';
import Path from 'enums/path.enum';

import removeEmptyFieldsFromObject from 'utils/removeEmptyFieldsFromObject';
import getIssuerId from 'refactored/utils/getIssuerId';
import useIssuers from 'refactored/hooks/useIssuers';
import useCurrentIssuer from 'refactored/hooks/useCurrentIssuer';
import { createAuditSchema } from './AuditForm.schema';
import { getInputFields } from './formFields';

import classes from './AuditForm.module.scss';

const AddAuditForm = ({ subtitle, audit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfile } = useSelector(
    selectFetchUserProfileState,
    shallowEqual,
  );

  const { isAdmin } = userProfile;

  const { issuers, loading: loadingMerchantIssuers } = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual,
  );

  const [issuer] = useCurrentIssuer({ shouldFetch: !isAdmin });
  const [issuerList] = useIssuers({ shouldFetch: isAdmin });

  const issuersOptions = useMemo(
    () =>
      issuers?.map((category) => ({
        label: category,
        value: category,
      })),
    [issuers],
  );

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchMerchantIssuers());
  }, [dispatch, isAdmin]);

  const {
    loading: creatingAudit,
    success: successCreatingAudit,
    error: errorCreatingAudit,
  } = useSelector(selectCreateAuditState, shallowEqual);

  const userIsIssuer = useMemo(
    () => userProfile?.roles.includes(Roles.Issuer),
    [userProfile],
  );

  const onSubmitHandler = useCallback(
    (values) => {
      const valuesWithData = removeEmptyFieldsFromObject(values);
      const { transactionDate } = valuesWithData;

      const body = {
        ...valuesWithData,
        ...(transactionDate
          ? { transactionDate: new Date(transactionDate).toISOString() }
          : {}),
      };

      delete body.issuerName;

      const issuerId = isAdmin
        ? getIssuerId(issuerList, body?.issuer)
        : issuer?.issuerId;

      dispatch(createAudit(body, issuerId));
    },
    [dispatch, issuerList, issuer, isAdmin],
  );

  useEffect(() => {
    if (successCreatingAudit) {
      navigate(`${Path.TransactionsSupport}`);
    }
  }, [navigate, successCreatingAudit]);

  const isUserIssuer = userProfile?.roles.includes('reporter.issuer');

  const formFields = getInputFields({ audit });

  const onCanceledHandler = useCallback(() => {
    navigate(`${Path.TransactionsSupport}`);
  }, [navigate]);

  return (
    <div className="p-10">
      {successCreatingAudit && (
        <Toast
          id="success-toast"
          text="Audit created"
          type={ToastType.Success}
        />
      )}
      {errorCreatingAudit && (
        <Toast
          id="success-toast"
          text={`We were unable to create an audit for the requested issuer: ${errorCreatingAudit}. Please try again.`}
          type={ToastType.Error}
        />
      )}
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={createAuditSchema}
        validationMode={ValidationMode.OnBlur}
      >
        <PageHeader title="Create Audit" className="mb-[1rem]">
          <Button type="submit" loading={creatingAudit}>
            Create audit
          </Button>
          <Button onClick={onCanceledHandler} variant="secondary">
            Cancel
          </Button>
        </PageHeader>
        {subtitle && (
          <Body
            size={BodySize.M}
            className={classes.subtitle}
            color={BodyColor.Gray}
          >
            {subtitle}
          </Body>
        )}
        <div>
          {userIsIssuer ? (
            <FormControl
              name="issuer"
              render={(props) => (
                <Input
                  label="Issuer*"
                  labelClassName={classes.labelWidth}
                  value={isUserIssuer ? userProfile.issuerName : audit.issuer}
                  className={
                    isUserIssuer ? classes.disabledInput : classes.enabledInput
                  }
                  type={InputType.Text}
                  readOnly={isUserIssuer}
                  size={InputSize.S}
                  color={InputColor.Gray}
                  {...props}
                />
              )}
            />
          ) : (
            <FormControlSelect
              name="issuer"
              label="Issuer*"
              placeholder={
                loadingMerchantIssuers
                  ? 'Loading options\u2026'
                  : 'Select issuer'
              }
              labelClassName={classes.labelWidth}
              disabled={loadingMerchantIssuers}
              options={issuersOptions}
            />
          )}
          {formFields.map(
            ({
              name,
              component: Component,
              renderProps,
              type,
              ...otherProps
            }) =>
              renderProps ? (
                <FormControl
                  key={name}
                  name={name}
                  render={(props) =>
                    type === 'textarea' ? (
                      <Textarea
                        // eslint-disable-next-line react/prop-types
                        {...props.register}
                        value={audit[name]}
                        showCharacterLength
                        {...renderProps}
                        {...props}
                      />
                    ) : (
                      <Input value={audit[name]} {...renderProps} {...props} />
                    )
                  }
                />
              ) : (
                <Component name={name} {...otherProps} />
              ),
          )}
        </div>
      </Form>
    </div>
  );
};

AddAuditForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  audit: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

AddAuditForm.defaultProps = {
  audit: {},
  subtitle: null,
};

export default AddAuditForm;
