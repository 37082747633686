import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';
import { MERCH_FORM_DESC_MAX } from 'constants/merchantForm.constant';
import isValidRegex from '@/lib/validators/isValidRegex';

const validationSchema = yup.object().shape({
  name: yup.string().required(Validations.RequiredName),
  description: yup
    .string()
    .max(MERCH_FORM_DESC_MAX, Validations.MaxDescriptionLen)
    .required(Validations.RequiredDescription),
  source: yup.string().nullable().required(Validations.RequiredSource),
  type: yup.string().nullable().required(Validations.RequiredType),
  merchantNetwork: yup
    .string()
    .nullable()
    .required(Validations.RequiredNetwork),
  acceptedCards: yup
    .array()
    .nullable()
    .required(Validations.RequiredAcceptedCards),
  qualifiedIssuer: yup
    .array()
    .nullable()
    .required(Validations.RequiredQualifiedIssuers),
  category: yup.string().nullable().required(Validations.RequiredCategory),
  associatedName: yup
    .string()
    .default('')
    .test('valid-regex', 'Invalid regular expression.', isValidRegex),
  excludedNames: yup
    .string()
    .default('')
    .test('valid-regex', 'Invalid regular expression.', isValidRegex),
  websiteURL: yup.string().url().required(Validations.RequiredWebsiteURL),
  imgUrl: yup.string().url().required(Validations.RequiredImgURL),
  fraudWarningAmountInCents: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(Validations.RequiredFraudWarning),
});

export default validationSchema;
