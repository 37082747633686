import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import arrowDownIcon from 'assets/icons/arrow-down.svg';
import classes from './SelectNumberRange.module.scss';

const SelectNumberRange = ({
  label,
  fieldName,
  query,
  updateQuery,
  dataCy,
}) => {
  const [selectedStart, setSelectedStart] = useState('');
  const [selectedEnd, setSelectedEnd] = useState('');
  const [displayLabel, setDisplayLabel] = useState(label);

  const handleChange = (type, value) => {
    const regex = /^\d*(\.\d{0,1})?$/;

    if (value !== '' && !regex.test(value)) return;

    if (type === 'min') {
      setSelectedStart(value);
    }
    if (type === 'max') {
      setSelectedEnd(value);
    }
  };

  const handleApply = () => {
    const newQuery = query;
    newQuery.pagination = { page: 0, limit: 100 };
    newQuery.filters = {
      ...newQuery.filters,
      [fieldName]: {
        type: 'number',
        start: selectedStart,
        end: selectedEnd,
      },
    };
    setDisplayLabel(`${label} : ${selectedStart} - ${selectedEnd}`);
    updateQuery(newQuery);
  };

  const handleClear = () => {
    const newQuery = { ...query };
    delete newQuery.filters[fieldName];

    if (newQuery.filters.length === 0) {
      delete newQuery.filters;
    }

    setDisplayLabel(label);
    setSelectedStart('');
    setSelectedEnd('');
    updateQuery(newQuery);
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className={classes.button} data-cy={dataCy}>
            <div className="flex items-center">
              <span className="mr-2">{displayLabel}</span>
              <img
                alt="arrow"
                className={classNames(classes.arrowIcon, {
                  [classes.arrowIconOpen]: open,
                })}
                src={arrowDownIcon}
              />
            </div>
          </Popover.Button>
          <Popover.Panel className={classes.panelContainer}>
            <div className={classes.inputsContainer}>
              <input
                type="number"
                className={classes.rangeInput}
                placeholder="Min"
                value={selectedStart}
                onChange={(e) => handleChange('min', e.target.value)}
              />
              _
              <input
                type="number"
                className={classes.rangeInput}
                placeholder="Max"
                value={selectedEnd}
                onChange={(e) => handleChange('max', e.target.value)}
              />
            </div>

            <div className={classes.actions}>
              <Popover.Button
                type="button"
                className={classes.secondaryButton}
                onClick={handleClear}
              >
                Clear
              </Popover.Button>
              <Popover.Button
                type="button"
                className={classes.actionButton}
                onClick={handleApply}
              >
                Apply
              </Popover.Button>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default SelectNumberRange;

SelectNumberRange.propTypes = {
  label: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  query: PropTypes.shape({}).isRequired,
  updateQuery: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
};

SelectNumberRange.defaultProps = {
  label: '',
  dataCy: 'SelectNumberRange',
};
