import { useCallback, useEffect, useState } from 'react';
import { TXN_BASE_URL, useAxios } from 'services/kardAPI';

const MAX_RETRY_COUNT = 3;

const LAMBDA_MAX_TIMEOUT_IN_MS = 30_000;
const TIMEOUT_IN_MS = LAMBDA_MAX_TIMEOUT_IN_MS - 2000; // Cancel the request before the server times out!

type UseReportsOptions = {
  filters?: Record<string, any>;
  issuerId?: string;
};

const useReports = ({ filters = {}, issuerId }: UseReportsOptions = {}) => {
  const [retryCount, setRetryCount] = useState(0);
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${TXN_BASE_URL}/portal/reporting`,
      params: {
        issuerId,
        ...filters,
      },
      headers: issuerId ? { 'X-Kard-IssuerID': issuerId } : undefined,
      timeout: TIMEOUT_IN_MS,
      timeoutErrorMessage: 'TIMEOUT',
    },
    { manual: true, autoCancel: true },
  );

  // Retry fetching reports if there is a timeout error
  const hasTimeoutError = error?.message === 'TIMEOUT';
  const isRetrying = hasTimeoutError && retryCount < MAX_RETRY_COUNT;

  useEffect(() => {
    if (isRetrying) {
      refetch();
      setRetryCount((value) => value + 1);
    }
  }, [isRetrying, refetch]);

  const fetchReportsWithRetries = useCallback(() => {
    setRetryCount(0);
    refetch();
  }, [refetch]);

  // Don't fetch reports if issuerId is not provided
  useEffect(() => {
    if (issuerId) {
      fetchReportsWithRetries();
    }
  }, [issuerId, filters, fetchReportsWithRetries]);

  return {
    isRetrying,
    reports: data?.results || [],
    countTotal: data?.countTotal || 0,
    loading: loading || isRetrying,
    error: !isRetrying ? error : undefined,
    fetchReportsWithRetries,
  };
};

export default useReports;
