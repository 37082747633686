/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchAudiencesInit,
  fetchAudiencesSuccess,
  fetchAudiencesFail,
  clearAudiencesErrorsProps,
  addAudienceRule,
  saveAudienceData,
  selectedAudienceRule,
  clearSelectedRule,
  editAudienceInit,
  editAudienceSuccess,
  editAudienceFail,
  createAudienceInit,
  createAudienceSuccess,
  createAudienceFail,
  updateAudienceRule,
  clearAudience,
  editingAudience,
  addAudienceToOfferInit,
  addAudienceToOfferSuccess,
  addAudienceToOfferFail,
  removeAudienceToOfferInit,
  removeAudienceToOfferSuccess,
  removeAudienceToOfferFail,
  clearAddAndRemoveAudienceToOffer,
  fetchAudiencesDataPointsSuccess,
  fetchAudiencesDataPointsFail,
  fetchAudiencesDataPointsInit,
} from 'state/actionCreators/audiences';

export const initialState = {
  fetchAudiencesLoading: false,
  fetchAudiencesSuccess: false,
  fetchAudiencesError: null,
  fetchAudienceByIdLoading: false,
  fetchAudienceByIdSuccess: false,
  fetchAudienceByIdError: null,
  editAudienceLoading: false,
  editAudienceSuccess: false,
  editAudienceError: null,
  createAudienceLoading: false,
  createAudienceSuccess: false,
  createAudienceError: null,
  addAudienceToOfferLoading: false,
  addAudienceToOfferSuccess: false,
  addAudienceToOfferError: null,
  removeAudienceToOfferLoading: false,
  removeAudienceToOfferSuccess: false,
  removeAudienceToOfferError: null,
  audiences: {
    results: [],
    countTotal: 0,
  },
  audience: null,
  newAudience: {
    rule: null,
  },
  selectedRule: {},
  isEditing: false,
  fetchDataPointsLoading: false,
  fetchDataPointsSuccess: false,
  fetchDataPointsError: null,
  audienceDataSummaryPoints: {},
};

const persistConfig = {
  key: 'audiences',
  storage,
  blacklist: [
    'fetchAudiencesLoading',
    'fetchAudiencesSuccess',
    'fetchAudiencesError',
    'fetchAudienceByIdLoading',
    'fetchAudienceByIdSuccess',
    'fetchAudienceByIdError',
    'editAudienceLoading',
    'editAudienceSuccess',
    'editAudienceError',
    'createAudienceLoading',
    'createAudienceSuccess',
    'createAudienceError',
    'addAudienceToOfferLoading',
    'addAudienceToOfferSuccess',
    'addAudienceToOfferError',
    'removeAudienceToOfferLoading',
    'removeAudienceToOfferSuccess',
    'removeAudienceToOfferError',
    'audiences',
    'audience',
    'audienceDataSummaryPoints',
    'newAudience',
    'selectedRule',
    'isEditing',
    'fetchDataPointsLoading',
    'fetchDataPointsSuccess',
    'fetchDataPointsError',
  ],
};

const audiencesReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchAudiencesInit, (state) => {
      state.fetchAudiencesLoading = true;
      state.fetchAudiencesSuccess = false;
      state.fetchAudiencesError = null;
      state.audiences = {
        results: [],
        countTotal: 0,
      };
    });

    builder.addCase(fetchAudiencesSuccess, (state, action) => {
      const { audiences } = action.payload;
      state.fetchAudiencesLoading = false;
      state.fetchAudiencesError = null;
      state.fetchAudiencesSuccess = true;
      state.audiences = audiences;
    });

    builder.addCase(fetchAudiencesFail, (state, action) => {
      const { error } = action.payload;
      state.fetchAudiencesLoading = false;
      state.fetchAudiencesError = error;
      state.fetchAudiencesSuccess = false;
    });

    builder.addCase(clearAudiencesErrorsProps, (state) => {
      state.fetchAudienceByIdError = null;
      state.fetchAudiencesError = null;
      state.editAudienceError = null;
      state.createAudienceError = null;
      state.createAudienceSuccess = false;
      state.editAudienceSuccess = false;
    });

    builder.addCase(editAudienceInit, (state) => {
      state.editAudienceLoading = true;
      state.editAudienceError = null;
      state.editAudienceSuccess = false;
    });

    builder.addCase(editAudienceSuccess, (state) => {
      state.editAudienceLoading = false;
      state.editAudienceError = null;
      state.editAudienceSuccess = true;
    });

    builder.addCase(editAudienceFail, (state, action) => {
      const { error } = action.payload;
      state.editAudienceLoading = false;
      state.editAudienceError = error;
      state.editAudienceSuccess = false;
    });

    builder.addCase(createAudienceInit, (state) => {
      state.createAudienceLoading = true;
      state.createAudienceError = null;
      state.createAudienceSuccess = false;
    });

    builder.addCase(createAudienceSuccess, (state) => {
      state.createAudienceLoading = false;
      state.createAudienceError = null;
      state.createAudienceSuccess = true;
    });

    builder.addCase(createAudienceFail, (state, action) => {
      const { error } = action.payload;
      state.createAudienceLoading = false;
      state.createAudienceError = error;
      state.createAudienceSuccess = false;
    });

    builder.addCase(addAudienceToOfferInit, (state) => {
      state.addAudienceToOfferLoading = true;
      state.addAudienceToOfferError = null;
      state.addAudienceToOfferSuccess = false;
    });

    builder.addCase(addAudienceToOfferSuccess, (state) => {
      state.addAudienceToOfferLoading = false;
      state.addAudienceToOfferError = null;
      state.addAudienceToOfferSuccess = true;
    });

    builder.addCase(addAudienceToOfferFail, (state, action) => {
      const { error } = action.payload;
      state.addAudienceToOfferLoading = false;
      state.addAudienceToOfferError = error;
      state.addAudienceToOfferSuccess = false;
    });

    builder.addCase(removeAudienceToOfferInit, (state) => {
      state.removeAudienceToOfferLoading = true;
      state.removeAudienceToOfferError = null;
      state.removeAudienceToOfferSuccess = false;
    });

    builder.addCase(removeAudienceToOfferSuccess, (state) => {
      state.removeAudienceToOfferLoading = false;
      state.removeAudienceToOfferError = null;
      state.removeAudienceToOfferSuccess = true;
    });

    builder.addCase(removeAudienceToOfferFail, (state, action) => {
      const { error } = action.payload;
      state.removeAudienceToOfferLoading = false;
      state.removeAudienceToOfferError = error;
      state.removeAudienceToOfferSuccess = false;
    });

    builder.addCase(clearAddAndRemoveAudienceToOffer, (state) => {
      state.addAudienceToOfferLoading = false;
      state.addAudienceToOfferSuccess = false;
      state.addAudienceToOfferError = null;
      state.removeAudienceToOfferLoading = false;
      state.removeAudienceToOfferSuccess = false;
      state.removeAudienceToOfferError = null;
      // [addAudienceRule]: (state, { payload }) => {
      //   const { rule } = payload;
      //   state.newAudience = {
      //     ...state.newAudience,
      //     rules: [...state.newAudience.rules, rule],
      //   };
      // },
    });

    builder.addCase(addAudienceRule, (state, { payload }) => {
      const { rule } = payload;
      state.newAudience = {
        ...state.newAudience,
        rule,
      };
    });

    builder.addCase(saveAudienceData, (state, { payload }) => {
      const { audience } = payload;
      state.newAudience = {
        ...state.newAudience,
        ...audience,
      };
    });

    builder.addCase(selectedAudienceRule, (state, { payload }) => {
      const { rule } = payload;
      state.selectedRule = rule;
    });

    builder.addCase(updateAudienceRule, (state, { payload }) => {
      const { rule } = payload;

      state.newAudience = {
        ...state.newAudience,
        rule,
      };
      state.isEditing = false;
      state.selectedRule = {};
    });

    builder.addCase(clearSelectedRule, (state) => {
      state.selectedRule = {};
      state.isEditing = false;
    });

    builder.addCase(clearAudience, (state) => {
      state.newAudience = {
        rule: null,
      };
    });

    builder.addCase(editingAudience, (state) => {
      state.isEditing = true;
    });

    builder.addCase(fetchAudiencesDataPointsInit, (state) => {
      state.fetchDataPointsLoading = true;
      state.fetchDataPointsSuccess = false;
      state.fetchDataPointsError = null;
      state.audienceDataSummaryPoint = {};
    });

    builder.addCase(fetchAudiencesDataPointsSuccess, (state, action) => {
      const { audiences } = action.payload;
      state.fetchDataPointsLoading = false;
      state.fetchDataPointsError = null;
      state.fetchDataPointsSuccess = true;
      state.audienceDataSummaryPoint = audiences;
    });

    builder.addCase(fetchAudiencesDataPointsFail, (state, action) => {
      const { error } = action.payload;
      state.fetchDataPointsLoading = false;
      state.fetchDataPointsError = error;
      state.fetchDataPointsSuccess = false;
    });
  }),
);

export default audiencesReducer;
