import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import LayoutGap from '@/components/Common/LayoutGap';
import { Spinner } from 'flowbite-react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../table';

export type { ColumnDef };

type DataTableProps<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data?: TData[];
  loading?: boolean;
  emptyMessage?: string;
  error?: Error | null;
};

export function DataTable<TData, TValue>({
  columns,
  data = [],
  loading,
  emptyMessage,
  error,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id} className="first:pl-6 last:pr-6">
                <div className="flex items-center gap-1">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </div>
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody className="min-h-full">
        {!loading && !error && table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && 'selected'}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="first:pl-6 last:pr-6">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow className="h-[calc(100vh-17rem)] w-full items-center justify-center">
            <TableCell colSpan={columns.length} className="w-full text-center">
              {!loading && !error && (emptyMessage || 'No results.')}
              {loading && (
                <LayoutGap direction="column" className="items-center">
                  <Spinner size="lg" color="gray" />
                  Loading...
                </LayoutGap>
              )}
              {error && <p className="text-red-500">{error.message}</p>}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
