import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import {
  selectForgotPasswordConfirmationState,
  selectForgotPasswordState,
} from 'state/selectors/auth';
import ForgotPasswordForm from 'components/Forms/ForgotPassword';
import PasswordSuccess from 'components/Forms/PasswordSuccess';
import ForgotPasswordConfirmation from 'components/Forms/ForgotPasswordConfirmation';
import { clearForgotPassword } from 'state/actions/auth';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearForgotPassword());
  }, [dispatch]);

  const { success: successChangingPassword } = useSelector(
    selectForgotPasswordConfirmationState,
    shallowEqual,
  );
  const { success: successValidatingEmail } = useSelector(
    selectForgotPasswordState,
    shallowEqual,
  );
  return (
    <>
      {!successValidatingEmail && <ForgotPasswordForm />}
      {successValidatingEmail && !successChangingPassword && (
        <ForgotPasswordConfirmation />
      )}
      {successChangingPassword && <PasswordSuccess />}
    </>
  );
};
export default ForgotPassword;
