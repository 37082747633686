/* modules */
import dayjs from 'dayjs';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/* enums */
import Groups from 'enums/users/groups.enum';

import Status from 'components/Common/Status';
import ArrowUp from 'assets/icons/arrow-up.svg?react';

import { ColumnProps } from 'refactored/components/Common/Table';

const DATE_FORMAT = 'MM/DD/YYYY';

type merchantsDetailsOffersColumnsType = {
  changeOrder: (index: number, direction: number) => void;
  totalOffers?: number;
  isEditing?: boolean;
  isAdmin: boolean;
};

type arrowActionsType = {
  index: number;
  totalOffers?: number;
  changeOrder: (index: number, direction: number) => void;
  isEditing?: boolean;
  isAdmin: boolean;
};

const arrowActions = ({
  index,
  totalOffers,
  changeOrder,
  isEditing,
  isAdmin,
}: arrowActionsType) => {
  const hideIfNotAdmin = !isAdmin ? 'hidden' : '';
  const isUpDisabled = index === 0;
  const isDownDisabled = totalOffers ? index === totalOffers - 1 : true;
  const isDisabledMessage = isEditing
    ? ''
    : 'Enable edit mode to change the order';

  return (
    <div className={hideIfNotAdmin}>
      <button
        type="button"
        onClick={() => changeOrder(index, -1)}
        disabled={isUpDisabled || !isEditing}
        aria-label={`${
          isUpDisabled || !isEditing ? isDisabledMessage : 'Move offer up'
        }`}
        data-tooltip-content={`${
          isUpDisabled || !isEditing ? isDisabledMessage : 'Move offer up'
        }`}
        data-tooltip-id="infoTooltip"
      >
        <ArrowUp />
      </button>
      <button
        type="button"
        onClick={() => changeOrder(index, 1)}
        disabled={isDownDisabled || !isEditing}
        aria-label={`${
          isDownDisabled || !isEditing ? isDisabledMessage : 'Move offer down'
        }`}
        data-tooltip-content={`${
          isDownDisabled || !isEditing ? isDisabledMessage : 'Move offer down'
        }`}
        data-tooltip-id="infoTooltip"
      >
        <div className="-mt-1.5 rotate-180">
          <ArrowUp />
        </div>
      </button>
      <ReactTooltip id="infoTooltip" />
    </div>
  );
};

export const merchantsDetailsOffersColumns = ({
  changeOrder,
  totalOffers,
  isEditing,
  isAdmin,
}: merchantsDetailsOffersColumnsType): ColumnProps[] => [
  {
    header: '',
    accessorKey: 'arrow',
    allowedRoles: Groups.Everyone,
    cell: ({ row }) =>
      arrowActions({
        index: row.index,
        totalOffers,
        changeOrder,
        isEditing,
        isAdmin,
      }),
  },
  {
    header: 'Offer ID',
    accessorKey: '_id',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Offer name',
    accessorKey: 'name',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Offer Type',
    accessorKey: 'offerType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Start Date',
    accessorKey: 'startDate',
    allowedRoles: Groups.Everyone,
    cell: ({ row }) => dayjs(row.original.startDate).format(DATE_FORMAT),
  },
  {
    header: 'Expiration Date',
    id: 'expirationDate',
    allowedRoles: Groups.Everyone,
    cell: ({ row }) => {
      const { expirationDate } = row.original;
      return dayjs(expirationDate).format(DATE_FORMAT) || 'Never';
    },
  },
  {
    header: 'Offer Status',
    accessorKey: 'status',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) =>
      getValue() ? <Status kind={getValue().toLowerCase()} /> : '',
  },
  {
    header: 'Commission Type',
    accessorKey: 'commissionType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Total Commission',
    accessorKey: 'totalCommission',
    allowedRoles: Groups.Reporters,
    cell: ({ row }) => {
      const { totalCommission, commissionType } = row.original;
      if (commissionType === 'PERCENT') return `${totalCommission} %`;
      return `$ ${totalCommission}`;
    },
  },
  {
    header: 'Targeted Offer',
    accessorKey: 'isTargeted',
    allowedRoles: Groups.Reporters,
    cell: ({ row }) => {
      const { isTargeted } = row.original;
      return isTargeted ? 'Targeted' : 'Not Targeted';
    },
  },
  {
    header: 'Commission Value',
    accessorKey: 'commissionValue',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => {
      const { commissionValue, commissionType } = row.original;
      if (commissionType === 'PERCENT') return `${commissionValue} %`;
      return `$ ${commissionValue}`;
    },
  },
  {
    header: 'Merchant Network',
    accessorKey: 'merchantNetwork',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Always Appears to Cardholder',
    id: 'displayAlways',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => {
      const { displayAlways } = row.original;
      return displayAlways ? 'Yes' : 'No';
    },
  },
];

export const merchantsDetailsLocationsColumns = (): ColumnProps[] => [
  {
    header: 'Location ID',
    accessorKey: '_id',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Name',
    accessorKey: 'name',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Google ID',
    accessorKey: 'googleId',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Location Type',
    accessorKey: 'locationType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Street',
    accessorKey: 'street',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'City',
    accessorKey: 'city',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'State',
    accessorKey: 'state',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Zip Code',
    accessorKey: 'zipCode',
    allowedRoles: Groups.Everyone,
  },
];
