import { Spinner } from 'flowbite-react';
import { useSelector, shallowEqual } from 'react-redux';

import { selectFetchUserProfileState } from 'state/selectors/users';

import UserProfile from 'components/Pages/UserProfile';

const Profile = () => {
  const { userProfile, loading: userLoading } = useSelector(
    selectFetchUserProfileState,
    shallowEqual,
  );
  return (
    <div className="h-screen">
      {userLoading ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner size="lg" color="gray" />
        </div>
      ) : (
        <div className="p-10">
          <UserProfile user={userProfile} title="Profile" />
        </div>
      )}
    </div>
  );
};
export default Profile;
