import { ColumnDef } from '@tanstack/react-table';
import Status, { Types as StatusTypes } from 'components/Common/Status';
import EditSegment from '@/components/Pages/Segments/EditSegment';
import { formatDate } from '@/lib/utils';
import Roles from 'enums/users/roles.enum';

const formatNumber = (value: number): string =>
  Intl.NumberFormat('en-US').format(value);

const transformSegmentStatus = (status: string): keyof typeof StatusTypes => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'inProgress';
    default:
      return status.toLowerCase() as keyof typeof StatusTypes;
  }
};

type Segment = {
  segmentId: string;
  name: string;
  status: string;
  size: number;
  created: string;
};

type ColumnMetaType = {
  allowedRoles?: Roles[];
};

type SegmentColumnDef = ColumnDef<Segment, unknown> & { meta: ColumnMetaType };

const makeColumns = ({
  onSuccess,
}: {
  onSuccess: () => void;
}): SegmentColumnDef[] => [
  {
    accessorKey: 'segmentId',
    header: 'ID',
    cell: ({ row }) => (
      <code className="text-xs">{row.getValue('segmentId') || '-'}</code>
    ),
    meta: {
      allowedRoles: [Roles.KardAdmin, Roles.DataAdmin],
    },
  },
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => row.getValue('name'),
    meta: {
      allowedRoles: [Roles.KardAdmin, Roles.DataAdmin],
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = row.getValue('status') as string;
      const kind = transformSegmentStatus(status);
      return kind ? <Status kind={kind} className="text-xs" /> : '';
    },
    meta: {
      allowedRoles: [Roles.KardAdmin, Roles.DataAdmin],
    },
  },
  {
    accessorKey: 'size',
    header: 'Size',
    cell: ({ row }) => {
      const size = row.getValue('size') as number;
      return size ? formatNumber(size) : '-';
    },
    meta: {
      allowedRoles: [Roles.KardAdmin, Roles.DataAdmin],
    },
  },
  {
    accessorKey: 'created',
    header: 'Creation Date',
    cell: ({ row }) => {
      const date = row.getValue('created') as string;
      return date ? formatDate(date) : '-';
    },
    meta: {
      allowedRoles: [Roles.KardAdmin, Roles.DataAdmin],
    },
  },
  {
    id: 'moreInfo',
    header: 'More info',
    cell: ({ row }) => (
      <EditSegment segmentId={row.original.segmentId} onSuccess={onSuccess} />
    ),
    meta: {
      allowedRoles: [Roles.KardAdmin, Roles.DataAdmin],
    },
  },
];

export default makeColumns;
