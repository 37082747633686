import { ReactNode } from 'react';
import LayoutGap from 'components/Common/LayoutGap';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Body, { Size as BodySize } from 'components/Typography/Body';

type NoDataMessageProps = {
  title: string;
  children: ReactNode;
};

const NoDataMessage = ({ title, children }: NoDataMessageProps) => (
  <LayoutGap direction="column" size="small" className="my-[5rem]">
    <Heading size={HeadingSize.S} className="m-0">
      {title}
    </Heading>
    <Body size={BodySize.XS}>{children}</Body>
  </LayoutGap>
);

type NoDataProps = {
  colSpan: number;
  issuerSelected: boolean | string | undefined | null;
};

const NoData = ({ colSpan, issuerSelected = true }: NoDataProps) => (
  <tr>
    <td data-testid="td" colSpan={colSpan}>
      {issuerSelected ? (
        <NoDataMessage title="No results found">
          Sorry, we couldn&apos;t find any results that match your search.
          <br />
          Please try again.
        </NoDataMessage>
      ) : (
        <NoDataMessage title="No Issuer selected">
          Select an issuer from the dropdown filter to view data.
        </NoDataMessage>
      )}
    </td>
  </tr>
);

export default NoData;
