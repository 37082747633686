import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import classes from 'refactored/pages/Locations/Locations.module.scss';
import LocationForm from 'refactored/components/Pages/LocationForm';
import { fetchLocationById } from 'state/actions/locations';
import { selectFetchLocationByIdState } from 'state/selectors/locations';

const LocationInfo = () => {
  const { locationId } = useParams();
  const dispatch = useDispatch();

  const {
    location,
    loading: loadingFetchLocation,
    error: errorFetchLocation,
  } = useSelector(selectFetchLocationByIdState, shallowEqual);

  useEffect(() => {
    dispatch(fetchLocationById(locationId));
  }, [dispatch, locationId]);

  const transformedLocationData = location
    ? {
        ...location,
        locationName: location.name,
        locationId: location._id,
        status: location.status,
        merchantName: location.merchant?.name,
        googleId: location.googleId || '',
        city: location.address?.city || '-',
        state: location.address?.state || '-',
        street: location.address?.street || '-',
        zipCode: location.address?.zipCode || '-',
        latitude: location.geoLocation?.latitude || '-',
        longitude: location.geoLocation?.longitude || '-',
        monday: location.operationHours?.MONDAY || '',
        tuesday: location.operationHours?.TUESDAY || '',
        wednesday: location.operationHours?.WEDNESDAY || '',
        thursday: location.operationHours?.THURSDAY || '',
        friday: location.operationHours?.FRIDAY || '',
        saturday: location.operationHours?.SATURDAY || '',
        sunday: location.operationHours?.SUNDAY || '',
      }
    : null;

  return (
    <>
      {errorFetchLocation && (
        <Toast
          id="fetch-location"
          text="Error fetching location"
          type={ToastType.Error}
        />
      )}
      {loadingFetchLocation || !location ? (
        <Spinner
          color={SpinnerColor.Black}
          size={SpinnerSize.L}
          className={classes.spinner}
        />
      ) : (
        <LocationForm
          title="Location Information"
          location={transformedLocationData}
        />
      )}
    </>
  );
};

export default LocationInfo;
