import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';
import { PASSWORD_MIN_LENGTH } from 'constants/passwordMinLength.constant';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(PASSWORD_MIN_LENGTH, Validations.PasswordLength)
    .required(Validations.RequiredPassword)
    .matches(/(?=.*[A-Z])/, Validations.PasswordRequireUpperCaseLetter)
    .matches(/(?=.*[a-z])/, Validations.PasswordRequireLowerCaseLetter)
    .matches(/(?=.*\d)/, Validations.PasswordRequireDigit)
    .matches(/(?=.*\W)/, Validations.PasswordRequireSpecialCharacter),
});

export default validationSchema;
