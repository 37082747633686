import { z } from 'zod';
import { useAxios } from '@/services/kardAPI';
import importMeta from '@/utils/vite/importMeta';

const { env } = importMeta();

// Merchant cell
const baseURL = `https://rewards-api.${env.VITE_STAGE}-us-east-1-001.merchant-platform.getkard.engineering/experiments/portal/experiments`;

/**
 * Experiments Schemas
 */
export const linkedOfferSchema = z.object({
  offerId: z.string().min(1, 'Offer is required'),
  status: z.enum(['DRAFT', 'LINKED']),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const experimentAttributesSchema = z.object({
  name: z.string().min(1, 'Name is required'),
});

export const experimentResponseSchema = z.object({
  id: z.string().uuid(),
  type: z.literal('experiment').default('experiment'),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  attributes: experimentAttributesSchema,
});

export const experimentRequestSchema = z.object({
  type: z.literal('experiment').default('experiment'),
  attributes: experimentAttributesSchema,
});

export type ExperimentRequest = z.infer<typeof experimentRequestSchema>;
export type ExperimentResponse = z.infer<typeof experimentResponseSchema>;
export type LinkedOffer = z.infer<typeof linkedOfferSchema>;

export const getExperimentsResponseSchema = z.object({
  data: z.array(experimentResponseSchema),
});
export type GetExperimentsResponse = z.infer<
  typeof getExperimentsResponseSchema
>;

/**
 * hooks
 */
export const useGetExperiments = () =>
  useAxios<GetExperimentsResponse>({
    baseURL,
    url: '',
    method: 'GET',
  });

export const useGetExperiment = (id: ExperimentResponse['id']) =>
  useAxios<ExperimentResponse>({
    baseURL,
    url: `/${id}`,
    method: 'GET',
  });

export const useCreateExperiment = () =>
  useAxios<ExperimentResponse, ExperimentRequest>(
    {
      baseURL,
      url: '',
      method: 'POST',
    },
    { manual: true },
  );

export const useUpdateExperiment = (id: ExperimentResponse['id']) =>
  useAxios<ExperimentResponse, ExperimentRequest>(
    {
      baseURL,
      url: `/${id}`,
      method: 'PUT',
    },
    { manual: true },
  );
