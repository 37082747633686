import client, { TXN_BASE_URL } from 'services/kardAPI';

const createAudit = async (body, issuerId) => {
  try {
    const config = {
      headers: {
        'X-Kard-IssuerID': issuerId,
      },
    };

    await client.post(`${TXN_BASE_URL}/portal/audit`, body, config);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createAudit;
