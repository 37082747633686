import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { audienceType } from 'utils/audiences/values';
import { behaviorRangeMap, behaviorTimeframeValues } from '../values';

dayjs.extend(utc);

export const transformAudienceToPayload = (audience) => {
  const type = audience.isDynamic ? audienceType.Dynamic : audienceType.Static;
  const calculateDateRange = (timescale, period) => {
    const today = dayjs().utc();
    const endDate = today.clone();
    const startDate = today.clone().subtract(timescale, period);
    return {
      gte: startDate.toISOString(),
      lte: endDate.toISOString(),
    };
  };

  let rule = {};
  if (audience.timescaleValue.length > 1) {
    rule = {
      [audience.logicOperator]: audience.timescaleValue.map(
        (timescale, index) => ({
          transactionCount: {
            [behaviorRangeMap[audience.behaviorRange[index]]]:
              audience.behaviorValue[index],
          },
          authorizationDateRange: calculateDateRange(
            timescale,
            audience.period[index],
          ),
        }),
      ),
    };
  }

  if (audience.timescaleValue.length === 1) {
    rule = {
      transactionCount: {
        [behaviorRangeMap[audience.behaviorRange[0]]]:
          audience.behaviorValue[0],
      },
      authorizationDateRange: calculateDateRange(
        audience.timescaleValue[0],
        audience.period[0],
      ),
    };
  }

  return {
    name: audience.name,
    description: audience.description,
    type,
    ...(audience.isDynamic && { interval: 24 }), // default value for now
    status: audience.status,
    merchantId: audience.merchant._id,
    enrollNewUser: audience.enrollNewUser,
    rule,
  };
};

const mapTimescaleToValue = (timescaleValue, period) => {
  if (timescaleValue === 1 && period === 'months') {
    return behaviorTimeframeValues.LastMonth.value;
  }
  if (timescaleValue === 3 && period === 'months') {
    return behaviorTimeframeValues.LastThreeMonths.value;
  }
  if (timescaleValue === 6 && period === 'months') {
    return behaviorTimeframeValues.LastSixMonths.value;
  }
  if (timescaleValue === 1 && period === 'years') {
    return behaviorTimeframeValues.LastYear.value;
  }
  return behaviorTimeframeValues.LastCustom.value;
};

export const transformPayloadToAudienceRule = (audience) => {
  const calculateTimescale = (startDate, endDate) => {
    const start = dayjs(startDate).startOf('day');
    const end = dayjs(endDate).startOf('day');

    const timescale = ['days', 'months', 'years'].reduce(
      (result, timerange) => {
        const diff = end.diff(start, timerange, true);

        if (diff % 1 === 0) {
          return [timerange, diff];
        }

        return result;
      },
      [],
    );

    return timescale;
  };

  const result = {
    timescaleValue: [],
    period: [],
    timescale: [],
    behaviorValue: [],
    behaviorRange: [],
    logicOperator: 'and',
    behavior: 'Purchases',
    merchant: audience.merchant,
    enrollNewUser: audience.enrollNewUser,
    refreshAudience: audience.type === 'DYNAMIC',
  };

  if (audience.rule.and) {
    result.logicOperator = 'and';
  } else if (audience.rule.or) {
    result.logicOperator = 'or';
  }

  const processSingleRule = (rule) => {
    if (rule.transactionCount || rule.transactionCount === 0) {
      Object.entries(rule.transactionCount).forEach(([key, value]) => {
        result.behaviorValue.push(value);
        const rangeDescription = Object.keys(behaviorRangeMap).find(
          (desc) => behaviorRangeMap[desc] === key,
        );
        result.behaviorRange.push(rangeDescription || key);
      });
    }

    if (rule.authorizationDateRange) {
      const [periodUnit, timescaleValue] = calculateTimescale(
        rule.authorizationDateRange.gte,
        rule.authorizationDateRange.lte,
      );
      result.timescaleValue.push(timescaleValue);
      result.period.push(periodUnit);

      const timescaleMappedValue = mapTimescaleToValue(
        timescaleValue,
        periodUnit,
      );
      result.timescale.push(timescaleMappedValue);
    }
  };

  if (audience.rule.and || audience.rule.or) {
    const rulesArray = audience.rule.and || audience.rule.or;
    rulesArray.forEach((rule) => processSingleRule(rule));
  } else {
    processSingleRule(audience.rule);
  }

  return result;
};
