/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  fetchLocationsInit,
  fetchLocationsSuccess,
  fetchLocationsFail,
  editLocationInit,
  editLocationSuccess,
  editLocationFail,
  createLocationInit,
  createLocationSuccess,
  createLocationFail,
  clearLocationSuccessProp,
  clearLocationFailProp,
  uploadLocationsInit,
  uploadLocationsSuccess,
  uploadLocationsFail,
  clearUploadLocationsData,
  fetchLocationsByMerchantIdInit,
  fetchLocationsByMerchantIdSuccess,
  fetchLocationsByMerchantIdFail,
  clearLocationsByMerchantIdData,
  fetchLocationByIdInit,
  fetchLocationByIdSuccess,
  fetchLocationByIdFail,
  clearEditLocationSuccessProp,
  fetchMerchantLocationsFail,
  fetchMerchantLocationsInit,
  fetchMerchantLocationsSuccess,
  clearMerchantLocationsData,
} from 'state/actionCreators/locations';

export const initialState = {
  fetchLocationsLoading: false,
  fetchLocationsSuccess: false,
  fetchLocationsError: null,
  locations: [],
  fetchLocationsByMerchantIdLoading: false,
  fetchLocationsByMerchantIdSuccess: false,
  fetchLocationsByMerchantIdError: null,
  locationsByMerchant: [],
  editLocationLoading: false,
  editLocationSuccess: false,
  editLocationError: null,
  createLocationLoading: false,
  createLocationSuccess: false,
  createLocationError: null,
  uploadLocationsLoading: false,
  uploadLocationsSuccess: false,
  uploadLocationsPayload: null,
  uploadLocationsError: null,
  fetchLocationByIdLoading: false,
  fetchLocationByIdSuccess: false,
  fetchLocationByIdError: null,
  location: null,
  fetchMerchantLocationsLoading: false,
  fetchMerchantLocationsSuccess: false,
  fetchMerchantLocationsError: null,
  merchantLocations: [],
};

const persistConfig = {
  key: 'locations',
  storage,
  blacklist: [
    'fetchLocationsLoading',
    'fetchLocationsSuccess',
    'fetchLocationsError',
    'locations',
    'fetchLocationsByMerchantIdLoading',
    'fetchLocationsByMerchantIdSuccess',
    'fetchLocationsByMerchantIdError',
    'locationsByMerchant',
    'editLocationLoading',
    'editLocationSuccess',
    'editLocationError',
    'createLocationLoading',
    'createLocationSuccess',
    'createLocationError',
    'clearLocationSuccessProp',
    'uploadLocationsLoading',
    'uploadLocationsSuccess',
    'uploadLocationsError',
    'uploadLocationsPayload',
    'fetchLocationByIdLoading',
    'fetchLocationByIdSuccess',
    'fetchLocationByIdError',
    'location',
    'fetchMerchantLocationsLoading',
    'fetchMerchantLocationsSuccess',
    'fetchMerchantLocationsError',
    'merchantLocations',
  ],
};

const locationsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchLocationsInit, (state) => {
      state.fetchLocationsLoading = true;
      state.fetchLocationsError = null;
      state.fetchLocationsSuccess = false;
      state.locations = [];
      state.location = null;
      state.fetchLocationByIdError = null;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = false;
    });

    builder.addCase(fetchLocationsSuccess, (state, action) => {
      const { locations } = action.payload;
      state.fetchLocationsLoading = false;
      state.fetchLocationsError = null;
      state.fetchLocationsSuccess = true;
      state.locations = locations;
    });

    builder.addCase(fetchLocationsFail, (state, action) => {
      const { error } = action.payload;
      state.fetchLocationsLoading = false;
      state.fetchLocationsError = error;
      state.fetchLocationsSuccess = false;
    });

    builder.addCase(fetchLocationsByMerchantIdInit, (state) => {
      state.fetchLocationsByMerchantIdLoading = true;
      state.fetchLocationsByMerchantIdError = null;
      state.fetchLocationsByMerchantIdSuccess = false;
      state.locationsByMerchant = [];
    });

    builder.addCase(fetchLocationsByMerchantIdSuccess, (state, action) => {
      const { locations } = action.payload;
      state.fetchLocationsByMerchantIdLoading = false;
      state.fetchLocationsByMerchantIdError = null;
      state.fetchLocationsByMerchantIdSuccess = true;
      state.locationsByMerchant = locations;
    });

    builder.addCase(fetchLocationsByMerchantIdFail, (state, action) => {
      const { error } = action.payload;
      state.fetchLocationsByMerchantIdLoading = false;
      state.fetchLocationsByMerchantIdError = error;
      state.fetchLocationsByMerchantIdSuccess = false;
    });

    builder.addCase(fetchLocationByIdInit, (state) => {
      state.fetchLocationByIdLoading = true;
      state.fetchLocationByIdError = null;
      state.fetchLocationByIdSuccess = false;
      state.location = null;
    });

    builder.addCase(fetchLocationByIdSuccess, (state, action) => {
      const { location } = action.payload;
      state.fetchLocationByIdLoading = false;
      state.fetchLocationByIdError = null;
      state.fetchLocationByIdSuccess = true;
      state.location = location;
    });

    builder.addCase(fetchLocationByIdFail, (state, action) => {
      const { error } = action.payload;
      state.fetchLocationByIdLoading = false;
      state.fetchLocationByIdError = error;
      state.fetchLocationByIdSuccess = false;
      state.location = null;
    });

    builder.addCase(clearLocationsByMerchantIdData, (state) => {
      state.fetchLocationsByMerchantIdLoading = false;
      state.fetchLocationsByMerchantIdError = null;
      state.fetchLocationsByMerchantIdSuccess = false;
      state.locationsByMerchant = [];
    });

    builder.addCase(editLocationInit, (state) => {
      state.editLocationLoading = true;
      state.editLocationError = null;
      state.editLocationSuccess = false;
    });

    builder.addCase(editLocationSuccess, (state) => {
      state.editLocationLoading = false;
      state.editLocationError = null;
      state.editLocationSuccess = true;
    });

    builder.addCase(editLocationFail, (state, action) => {
      const { error } = action.payload;
      state.editLocationLoading = false;
      state.editLocationError = error;
      state.editLocationSuccess = false;
    });

    builder.addCase(createLocationInit, (state) => {
      state.createLocationLoading = true;
      state.createLocationError = null;
      state.createLocationSuccess = false;
    });

    builder.addCase(createLocationSuccess, (state) => {
      state.createLocationLoading = false;
      state.createLocationError = null;
      state.createLocationSuccess = true;
    });

    builder.addCase(createLocationFail, (state, action) => {
      const { error } = action.payload;
      state.createLocationLoading = false;
      state.createLocationError = error;
      state.createLocationSuccess = false;
    });

    builder.addCase(clearLocationSuccessProp, (state) => {
      state.createLocationSuccess = false;
      state.createLocationError = null;
      state.createLocationLoading = false;
    });

    builder.addCase(clearLocationFailProp, (state) => {
      state.createLocationLoading = false;
      state.createLocationError = null;
      state.createLocationSuccess = false;
    });

    builder.addCase(uploadLocationsInit, (state) => {
      state.uploadLocationsLoading = true;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = false;
    });

    builder.addCase(uploadLocationsSuccess, (state, action) => {
      let payloadData = null;
      if (action.payload) {
        payloadData = action.payload.data ?? null;
      }
      state.uploadLocationsLoading = false;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = true;
      state.uploadLocationsPayload = payloadData;
    });

    builder.addCase(uploadLocationsFail, (state, action) => {
      const { error } = action.payload;
      state.uploadLocationsLoading = false;
      state.uploadLocationsError = error;
      state.uploadLocationsSuccess = false;
    });

    builder.addCase(clearUploadLocationsData, (state) => {
      state.uploadLocationsLoading = false;
      state.uploadLocationsError = null;
      state.uploadLocationsSuccess = false;
    });

    builder.addCase(clearEditLocationSuccessProp, (state) => {
      state.editLocationLoading = false;
      state.editLocationError = null;
      state.editLocationSuccess = false;
    });

    builder.addCase(fetchMerchantLocationsInit, (state) => {
      state.fetchMerchantLocationsLoading = true;
      state.fetchMerchantLocationsError = null;
      state.fetchMerchantLocationsSuccess = false;
      state.merchantLocations = [];
    });

    builder.addCase(fetchMerchantLocationsSuccess, (state, action) => {
      const { locations } = action.payload;
      state.fetchMerchantLocationsLoading = false;
      state.fetchMerchantLocationsError = null;
      state.fetchMerchantLocationsSuccess = true;
      state.merchantLocations = locations;
    });

    builder.addCase(fetchMerchantLocationsFail, (state, action) => {
      const { error } = action.payload;
      state.fetchMerchantLocationsLoading = false;
      state.fetchMerchantLocationsError = error;
      state.fetchMerchantLocationsSuccess = false;
    });

    builder.addCase(clearMerchantLocationsData, (state) => {
      state.fetchMerchantLocationsLoading = false;
      state.fetchMerchantLocationsError = null;
      state.fetchMerchantLocationsSuccess = false;
      state.merchantLocations = [];
    });
  }),
);

export default locationsReducer;
