import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const uploadLocations = async (
  file: File,
  PI_257_LOCATIONS_UPLOAD_FLAG: boolean = false,
): Promise<any> => {
  const response = await client.put(
    `${REWARDS_BASE_URL}/portal/merchant/locations${
      PI_257_LOCATIONS_UPLOAD_FLAG ? '/async' : ''
    }`,
    file,
  );
  return response;
};

export default uploadLocations;
