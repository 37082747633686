import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import notFound from 'assets/icons/notFound.png';
import LabelPosition from 'enums/inputProps';
import Input from '../Input';

import classes from './InputImage.module.scss';

const InputImage = (props) => {
  const { name, register, label } = props;

  const { watch } = useFormContext();

  const [imageData, setImageData] = useState({});

  const imageValue = watch(name);

  useEffect(() => {
    if (imageValue) {
      setImageData({ src: imageValue, hint: '' });
    }

    if (!imageValue) {
      setImageData({ src: notFound, hint: 'Enter URL for preview' }); // TODO: Waiting for icon for no URL selected, change src here when ready
    }
  }, [imageValue]);

  const onImgError = useCallback(
    () => setImageData({ src: notFound, hint: 'Unable to display image' }),
    [],
  );

  return (
    <div className="mb-20 flex w-[85%]">
      <Input {...props} register={register} />
      <div className="ml-6 flex grow flex-col">
        {imageValue ? (
          <>
            <h1 className="mb-3 text-xs font-semibold text-gray-500">
              {label}
            </h1>
            <div className="flex h-[150px] justify-center justify-self-center border-2 border-gray-100 p-4">
              <img
                src={imageData.src}
                alt="imgPreview"
                onError={onImgError}
                className={classNames(
                  classes.imagePreview,
                  '!h-[110px] justify-center',
                )}
              />
              {imageData.hint && (
                <p className="self-center text-xs font-normal text-red-500">
                  {imageData.hint}
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            <h1 className="mb-3 text-xs font-semibold text-gray-500">
              {label}
            </h1>
            <div className="flex h-[150px] justify-center border-2 border-gray-100 bg-gray-100 p-4">
              <p className="self-center text-xs font-normal text-gray-500">
                {imageData.hint}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

InputImage.propTypes = {
  name: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  label: PropTypes.string,
  register: PropTypes.shape({
    name: PropTypes.string,
    ref: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
};

InputImage.defaultProps = {
  labelPosition: LabelPosition.Top,
  register: null,
  label: '',
};

export default InputImage;
