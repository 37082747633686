import { ReactNode } from 'react';
import LayoutGap from '@/components/Common/LayoutGap';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { DayPickerProps } from 'react-day-picker';

import DatePickerRange from '../../DatePickerRange';
import FormField from '../helpers/FormField';
import FormItem from '../helpers/FormItem';
import FormLabel from '../helpers/FormLabel';
import FormDescription from '../helpers/FormDescription';
import FormMessage from '../helpers/FormMessage';
import FormControl from '../helpers/FormControl';

type InputFieldProps<T extends FieldValues> = {
  label: string;
  name: Path<T>;
  description?: ReactNode;
  disabled?: boolean;
} & DayPickerProps;

const DateRangeField = <T extends FieldValues>({
  label,
  description,
  name,
  className,
  ...props
}: InputFieldProps<T>) => {
  const form = useFormContext<T>();
  return (
    <FormField
      name={name}
      control={form.control}
      render={({ field }) => (
        <FormItem className={className}>
          <LayoutGap direction="column" size="small">
            <FormLabel>{label}</FormLabel>
            <FormControl>
              <DatePickerRange {...field} {...props} />
            </FormControl>
            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage />
          </LayoutGap>
        </FormItem>
      )}
    />
  );
};

export default DateRangeField;
