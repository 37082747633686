import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AddIcon from 'assets/icons/plus.svg?react';
import closeIcon from 'assets/icons/x.svg';
import exclamationMarkIcon from 'assets/icons/exclamation-mark.svg';
import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Button from 'components/Common/Button';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';

import classes from './MultiValueInput.module.scss';
import LayoutGap from '../LayoutGap';

const MultiValueInput = ({
  disabled,
  readOnly,
  values,
  setValues,
  label,
  emptyLabel,
  name,
  placeholder,
  realTimeMatch,
}) => {
  const [addNewValue, setAddNewValue] = useState(false);
  const [newValue, setNewValue] = useState('');

  const onCancelAddAssociatedNameHandler = () => {
    setNewValue('');
    setAddNewValue(false);
  };

  const onDeleteAssociatedNameHandler = (index) => {
    setValues((prevState) => {
      const previousNames = [...prevState];
      previousNames.splice(index, 1);
      return previousNames;
    });
  };

  const onClickAddAssociatedNameHandler = () => {
    setAddNewValue(true);
  };

  const onAddNewAssociatedNameHandler = () => {
    setValues((prevState) => [...prevState, newValue]);
    onCancelAddAssociatedNameHandler();
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {label && (
          <Body
            size={BodySize.S}
            className={classes.labelText}
            color={BodyColor.Black}
          >
            {label}
            {name === 'associatedName' &&
              realTimeMatch &&
              values.length === 0 && (
                <img
                  src={exclamationMarkIcon}
                  className={classes.errorIcon}
                  alt="Regex Required"
                />
              )}
          </Body>
        )}
        {!readOnly && (
          <Button
            variant="tertiary"
            icon={<AddIcon />}
            onClick={onClickAddAssociatedNameHandler}
          >
            Add
          </Button>
        )}
      </div>
      {values.length === 0 && !addNewValue && (
        <Body
          size={BodySize.XS}
          color={BodyColor.Gray}
          className={classes.itemText}
        >
          {emptyLabel}
        </Body>
      )}
      {values.map((value, index) => (
        <div className="space-between flex">
          <div
            key={`opt ${index.toString()}`}
            className={classNames(classes.item, {
              [classes.lastItem]: index === values.length - 1 && addNewValue,
            })}
          >
            <Body
              size={BodySize.XS}
              color={disabled ? BodyColor.Black : BodyColor.Gray}
              className={classes.itemText}
            >
              {value}
            </Body>
          </div>
          {(!readOnly || !disabled) && (
            <button
              type="button"
              className={classes.removeIcon}
              onClick={() => onDeleteAssociatedNameHandler(index)}
            >
              <img src={closeIcon} alt="remove" />
            </button>
          )}
        </div>
      ))}
      {addNewValue && (
        <LayoutGap direction="column">
          <Input
            name={name}
            value={newValue}
            onChange={({ target }) => setNewValue(target.value)}
            placeholder={placeholder}
            type={InputType.Text}
            size={InputSize.S}
            className="mt-1"
          />
          <LayoutGap>
            <Button
              variant="secondary"
              size="small"
              onClick={onCancelAddAssociatedNameHandler}
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={onAddNewAssociatedNameHandler}
              disabled={newValue === ''}
            >
              Add
            </Button>
          </LayoutGap>
        </LayoutGap>
      )}
    </div>
  );
};

MultiValueInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array,
  setValues: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  realTimeMatch: PropTypes.bool,
};

MultiValueInput.defaultProps = {
  values: [],
  label: null,
  placeholder: '',
  disabled: false,
  readOnly: false,
  realTimeMatch: false,
};

export default MultiValueInput;
