import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';

import selectExportDataState from 'state/selectors/exportData';
import cancelIcon from 'assets/icons/x.svg';

import { cancelExport } from 'state/actions/exportData';
import { getExportModelDisplayName } from '../utils';

const ExportInProgress = () => {
  const dispatch = useDispatch();

  const { model } = useSelector(selectExportDataState, shallowEqual);

  const onCancelButton = () => {
    dispatch(cancelExport());
  };

  return (
    <div className="flex w-fit gap-2.5">
      <div className="flex items-center">
        <Spinner color="custom" />
      </div>
      <div className="w-[14.6rem]">
        <p className="text-gray900 text-sm font-semibold">
          Exporting {getExportModelDisplayName(model)}s
        </p>
        <p className="text-gray500 text-sm font-normal">
          This could take a while...
        </p>

        <button
          type="button"
          onClick={onCancelButton}
          className="text-primary600 text-sm font-normal underline"
        >
          Cancel download
        </button>
      </div>
      <img src={cancelIcon} alt="cancel icon" className="self-start" />
    </div>
  );
};

export default ExportInProgress;
