import { ChangeEvent, useState } from 'react';

import { isMongoId } from 'validator';
import { Query } from 'types';
import SearchBox from '../SearchBox';

/**
 * Search by a given field; or, if a Mongo ID is provided, search by an ID field.
 * @param params query updater; includes "field" and optional "offerId" strings.
 * @returns the component.
 */

type SearchByProps = {
  query: Query;
  updateQuery: Function;
  field: string;
  idField?: string;
  className?: string;
  placeholder?: string;
  showCancelIcon?: boolean;
  testId?: string;
};

const SearchBy = ({
  query,
  updateQuery,
  field: givenField,
  idField = 'id',
  className,
  placeholder,
  showCancelIcon,
  testId,
}: SearchByProps) => {
  // this is a controlled search input value.
  const [displaySearchValue, setDisplaySearchValue] = useState('');

  const search = (value: string, field: string) => {
    const filters = query?.filters || {};

    if (value.length === 0) {
      delete filters[field];
    }

    if (value.length > 0) {
      filters[field] = {
        type: 'search',
        selected: [value],
      };
    }

    const newQuery = Object.keys(filters).length > 0 ? { filters } : {};

    updateQuery(newQuery, ['pagination', 'filters']);
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplaySearchValue(event.target.value);
  };

  const onEnterDownHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const enteredField = isMongoId(event.target.value) ? idField : givenField;

    const tempQuery = query;

    // If user enters a MongoID into search, delete any previous names that may be in the query (and vice versa)
    if (enteredField === idField && query.filters?.[givenField] !== undefined) {
      delete tempQuery.filters?.[givenField];
      updateQuery(tempQuery);
    } else if (
      idField &&
      enteredField === givenField &&
      query.filters?.[idField] !== undefined
    ) {
      delete tempQuery.filters?.[idField];
      updateQuery(tempQuery);
    }

    search(event.target.value, enteredField);
  };

  const onCancelHandler = () => {
    setDisplaySearchValue('');

    if (query.filters?.[idField]?.selected?.[0]) {
      search('', idField);
    } else if (query.filters?.[givenField]?.selected?.[0]) {
      search('', givenField);
    }
  };

  return (
    <SearchBox
      className={className}
      placeholder={placeholder ?? ''}
      testId={testId}
      searchValue={displaySearchValue}
      onChange={onChangeHandler}
      onEnterDown={onEnterDownHandler}
      showCancelIcon={showCancelIcon}
      onCancel={onCancelHandler}
    />
  );
};

export default SearchBy;
