export enum Workflows {
  OFFER_LIVE = 'offer-live',
  OFFER_EXTENDED = 'offer-expiration-extended',
  OFFER_CHANGED = 'offer-expiration-moved-earlier',
}

export const workflowKeyToName: Record<Workflows, string> = {
  [Workflows.OFFER_LIVE]: 'New offer(s) are live',
  [Workflows.OFFER_EXTENDED]: 'Offer expiration date is extended',
  [Workflows.OFFER_CHANGED]: 'Offer expiration date is moved earlier',
};

export const workflowTemplate = {
  channel_types: { email: true },
  workflows: {
    [Workflows.OFFER_LIVE]: {
      channel_types: { email: true },
    },
    [Workflows.OFFER_EXTENDED]: {
      channel_types: { email: true },
    },
    [Workflows.OFFER_CHANGED]: {
      channel_types: { email: true },
    },
  },
};
