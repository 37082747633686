import { ReactNode } from 'react';
import classNames from 'classnames';

import classes from './Body.module.scss';

export enum Size {
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs',
}

export enum Color {
  Black = 'black',
  Gray = 'gray',
  White = 'white',
  Blue = 'blue',
  Red = 'red',
}

type Props = {
  size?: Size;
  color?: Color;
  children: ReactNode;
  className?: string;
};

const Body = ({
  size = Size.S,
  color = Color.Black,
  children,
  className = '',
  ...props
}: Props) => (
  <p
    className={classNames(classes[color], classes[size], className)}
    {...props}
  >
    {children}
  </p>
);

export default Body;
