import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const buildExperiment = async (id, body) => {
  try {
    await client.put(
      `${REWARDS_BASE_URL}/portal/merchant/offer/${id}/experiment`,
      body,
    );
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default buildExperiment;
