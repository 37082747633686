import React from 'react';

export type LayoutGapSize = 'small' | 'medium' | 'large' | 'none';

const getGapSize = (size: LayoutGapSize) =>
  ({
    none: '',
    small: 'gap-2',
    medium: 'gap-4',
    large: 'gap-6',
  })[size];

export type LayoutGapDirection = 'column' | 'row';

const getDirection = (direction: LayoutGapDirection) =>
  ({
    column: 'flex-col',
    row: 'flex-row',
  })[direction];

type Props = {
  children: React.ReactNode;
  size?: LayoutGapSize;
  direction?: LayoutGapDirection;
  className?: string;
};

const LayoutGap = ({
  children,
  size = 'medium',
  direction = 'row',
  className,
}: Props) => (
  <div
    className={`flex ${getGapSize(size)} ${getDirection(
      direction,
    )} ${className}`}
  >
    {children}
  </div>
);

export default LayoutGap;
