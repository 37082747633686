import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SideBar from 'components/Navigation/SideBar';
import Header from 'components/Navigation/Header';
import ExportBanner from 'components/Common/ExportBanner';
import IdleMessage from 'components/Common/IdleMessage';

import useIdleTracker from 'hooks/useIdleTracker';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { selectAuthenticatedState } from 'state/selectors/auth';
import { logout } from 'state/actions/auth';
import { useLocation } from 'react-router-dom';
import { getLoginRoutes } from 'pages/Router/paths';
import EnvironmentBanner from '@/components/Navigation/EnvironmentBanner';

const NavigationLayout = ({ children, fullscreen }) => {
  const { isAuthenticated } = useSelector(
    selectAuthenticatedState,
    shallowEqual,
  );

  const dispatch = useDispatch();

  const doLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const [timerText, activityHandler, showWarning] = useIdleTracker(
    isAuthenticated,
    doLogout,
  );

  const location = useLocation();

  const isLoginRoute = useMemo(
    () => getLoginRoutes().some((route) => route.path === location.pathname),
    [location.pathname],
  );

  return (
    <div className="bg-primaryGray flex h-screen" onMouseMove={activityHandler}>
      {!fullscreen && <SideBar />}
      <div
        className={classNames('flex min-w-0 flex-1 flex-col bg-cover', {
          'p-0': fullscreen,
        })}
      >
        <EnvironmentBanner />
        {!fullscreen && <Header />}
        <ExportBanner />
        <IdleMessage
          timer={timerText}
          show={
            // don't show idle warning on login page,
            // even having logged in from another tab
            !isLoginRoute && showWarning
          }
        />
        <main className="h-full overflow-scroll bg-white">{children}</main>
      </div>
    </div>
  );
};

NavigationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
};

NavigationLayout.defaultProps = {
  fullscreen: false,
};

export default NavigationLayout;
