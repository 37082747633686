/** modules */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

/** custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import useModal from 'hooks/useModal';
import useIssuers from 'refactored/hooks/useIssuers';

/** custom components */
import Pagination from 'refactored/components/Common/Pagination2';
import Table from 'refactored/components/Common/Table';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import Form from 'components/Common/Form';
import FormControlSelect from 'components/Common/FormControlSelect';
import Modal from 'components/Common/Modal';
import MerchantsSelect from 'components/Pages/MerchantsSelect';
import PendingTransactionReprocess from 'components/Pages/PendingTransactionsReprocess';
import ConfirmationMessage from 'components/Common/ConfirmationMessage';
import TransactionData from 'components/Pages/TransactionData';
import commonToast from 'components/Common/commonToast';
import SelectDateRange from 'refactored/components/Common/SelectDateRange';

/** state */
import { selectUserAttributesState } from 'state/selectors/auth';
import { fetchMerchantsFromCache } from 'state/actions/merchants';
import {
  clearLocationsByMerchantId,
  fetchLocationsByMerchantId,
} from 'state/actions/locations';
import {
  clearPendingTransactionStateData,
  clearReprocessIncomingTransactionStateData,
  editPendingTransaction,
  fetchPendingTransactions,
  reprocessIncomingTransaction,
  reprocessPendingTransaction,
} from 'state/actions/pendingTransactions';
import { selectFetchMerchantsFromCacheState } from 'state/selectors/merchants';
import { selectFetchLocationsByMerchantIdState } from 'state/selectors/locations';
import {
  selectEditPendingTransactionState,
  selectFetchPendingTransactionsState,
  selectReprocessIncomingTransactionState,
  selectReprocessPendingTransactionState,
} from 'state/selectors/pendingTransactions';

/** utils */
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import makeColumns from 'refactored/utils/pendingTransactions/makeColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import {
  ReviewStatusOptions,
  ReviewStatusValues,
} from 'utils/pendingTransactions/reviewStatus';
import {
  TRANSACTION_STATUSES,
  TransactionStatusOptions,
} from 'utils/pendingTransactions/transactionStatus';
import msg from 'utils/commonMessages';

/** enums */
import ModalType from 'enums/modal/modalType.enum';
import Path from 'enums/path.enum';

/** styling */
import PageHeader from 'components/Common/PageHeader';
import classes from './PendingTransactions.module.scss';

/** If you need to reset a record, set to this - otherwise React gets caught in a loop of comparing empty objects. */
const EMPTY = {};
const ALL_OPTIONS = 'ALL';

const PendingTransactions = () => {
  const { issuerId: issuerIdParam } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** states */
  const [submitError, setSubmitError] = useState(false);
  const [selectedMerchantRow, setSelectedMerchantRow] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(EMPTY);
  const [selectedLocation, setSelectedLocation] = useState(EMPTY);
  const [merchantOptions, setMerchantOptions] = useState(EMPTY);
  const [locationOptions, setLocationOptions] = useState(EMPTY);
  const [reviewStatus, setReviewStatus] = useState('OPEN');
  const [selectedIssuer, setSelectedIssuer] = useState(issuerIdParam);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isApprovedStatus, setIsApprovedStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(
    TRANSACTION_STATUSES.ALL,
  );

  useEffect(() => {
    // Reset selected issuer logic when the issuerIdParam in url changes
    setSelectedIssuer(issuerIdParam);
  }, [issuerIdParam]);

  /** custom hooks */
  const [query, updateQuery] = useQuery({
    pagination: { page: 0, limit: 100 },
    filters: {
      reviewStatus: { selected: [reviewStatus], type: 'string' },
      // TODO: this check can be removed when we guarantee there is always a selected issuer
      ...(selectedIssuer && {
        issuer: { selected: [selectedIssuer], type: 'string' },
      }),
    },
  });

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  /** selectors */
  const {
    result: pendingTransactions,
    count: countTotal,
    loading: loadingPendingTransactions,
    error: errorFetchingPendingTransactions,
  } = useSelector(selectFetchPendingTransactionsState, shallowEqual);

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const { locations, loading: loadingLocations } = useSelector(
    selectFetchLocationsByMerchantIdState,
    shallowEqual,
  );

  // TODO: do we need to fetch all the merchants on page load?
  const { merchants, loading: loadingMerchants } = useSelector(
    selectFetchMerchantsFromCacheState,
    shallowEqual,
  );

  const {
    loading: reprocessTransactionLoading,
    success: reprocessTransactionSuccess,
    error: reprocessTransactionError,
  } = useSelector(selectReprocessIncomingTransactionState, shallowEqual);

  const { success: reprocessPendingTransactionSuccess, reprocessPendingTxns } =
    useSelector(selectReprocessPendingTransactionState, shallowEqual);

  const [issuers, loadingIssuer] = useIssuers({ shouldFetch: true });

  const { success: successEditTransaction, error: errorEditTransaction } =
    useSelector(selectEditPendingTransactionState, shallowEqual);

  /** methods */
  // this will call itself in the following useEffect block if any filters change.
  const fetchPage = useCallback(
    (somePage) => {
      updateQuery({
        pagination: { limit: query.pagination.limit, page: somePage },
        filters: {
          reviewStatus: { selected: [reviewStatus], type: 'string' },
          ...(transactionStatus !== ALL_OPTIONS && {
            transactionStatus: {
              selected: [transactionStatus],
              type: 'string',
            },
          }),
          // TODO: this check can be removed when we guarantee there is always a selected issuer
          ...(selectedIssuer && {
            issuer: { selected: [selectedIssuer], type: 'string' },
          }),
        },
      });
    },
    [
      reviewStatus,
      selectedIssuer,
      transactionStatus,
      updateQuery,
      query.pagination.limit,
    ],
  );

  const onChangeSelectedIssuer = useCallback(
    (issuerId) => {
      // navigate to table for selected issuer
      navigate(`${Path.PendingTransactions}/${issuerId}`);
    },
    [navigate],
  );

  const onChangeSelectedMerchantHandler = useCallback(
    (index, newValue) => {
      setSubmitError(false);
      if (newValue === 'other') {
        setSelectedMerchantRow(index);
        onOpenModalHandler(ModalType.ALL_MERCHANTS_SELECT);
      } else {
        setSelectedMerchant((prevState) => ({
          ...prevState,
          [index]: newValue,
        }));

        if (newValue.source === 'LOCAL') {
          setSelectedMerchantRow(index);
          dispatch(fetchLocationsByMerchantId(newValue._id));
          setSelectedLocation((prevState) => ({ ...prevState, [index]: null }));
        } else {
          setSelectedLocation((prevState) => ({
            ...prevState,
            [index]: 'N/A',
          }));
        }
      }
    },
    [dispatch, onOpenModalHandler],
  );

  const onChangeSelectedLocationHandler = useCallback((index, newValue) => {
    setSubmitError(false);
    setSelectedLocation((prevState) => ({
      ...prevState,
      [index]: newValue,
    }));
  }, []);

  const renderCorrectMerchantDropdown = useCallback(
    (index) => (
      <Form className="relative top-3" onSubmit={noop}>
        <FormControlSelect
          name="correctMerchant"
          onChangeManual={(newValue) => {
            onChangeSelectedMerchantHandler(index, newValue);
          }}
          defaultValue={selectedMerchant[index] || null}
          value={selectedMerchant[index] || null}
          placeholder="Correct merchant"
          options={merchantOptions[index] || []}
          disabled={loadingMerchants || !merchantOptions[index]}
          controlClassName="!w-[200px] !m-0"
          maxMenuHeight={150}
          tooltip={
            loadingMerchants
              ? 'Loading merchants, please wait a few seconds.'
              : ''
          }
        />
      </Form>
    ),
    [
      loadingMerchants,
      merchantOptions,
      onChangeSelectedMerchantHandler,
      selectedMerchant,
    ],
  );

  const renderCorrectLocationDropdown = useCallback(
    (index) => {
      let placeholder = 'Correct location';
      let defaultValue = selectedLocation[index] || null;
      let disabled =
        (loadingLocations && selectedMerchantRow === index) ||
        !selectedMerchant[index] ||
        selectedMerchant[index] === 'other';
      let options = locationOptions[index] || [];

      if (defaultValue === 'N/A') {
        defaultValue = null;
        placeholder = 'N/A';
        disabled = true;
        options = [];
      }

      return (
        <Form className="relative top-3" onSubmit={noop}>
          {loadingLocations && selectedMerchantRow === index ? (
            <div className={classes.alignSpinner}>
              <Spinner color={SpinnerColor.Black} size={SpinnerSize.M} />
            </div>
          ) : (
            <FormControlSelect
              name="correctLocation"
              placeholder={placeholder}
              onChangeManual={(newValue) => {
                onChangeSelectedLocationHandler(index, newValue);
              }}
              defaultValue={defaultValue}
              value={selectedLocation[index] || null}
              options={options}
              disabled={disabled}
              maxMenuHeight={346}
            />
          )}
        </Form>
      );
    },
    [
      loadingLocations,
      locationOptions,
      onChangeSelectedLocationHandler,
      selectedLocation,
      selectedMerchant,
      selectedMerchantRow,
    ],
  );

  const onSelectOtherMerchantHandler = useCallback(
    (merchant) => {
      setSubmitError(false);
      const selectedMerchantInfo = {
        name: merchant.name,
        _id: merchant._id,
        source: merchant.source,
      };
      setMerchantOptions((prevState) => {
        const optionsList = [];
        const row = selectedMerchantRow || 0;
        const previousOptions = [...prevState[row]];
        const matchedOptions = previousOptions[0].options;
        const otherOptions = previousOptions[1].options;

        // remove 'All Other Merchants'
        otherOptions.pop();

        otherOptions.push(
          {
            label: selectedMerchantInfo.name,
            value: selectedMerchantInfo,
          },
          { label: 'All Other Merchants..', value: 'other' },
        );

        optionsList.push(
          {
            label: 'Matched merchants',
            options: matchedOptions,
          },
          {
            label: 'Other',
            options: otherOptions,
          },
        );
        return { ...prevState, [selectedMerchantRow]: optionsList };
      });

      onCloseModalHandler();

      onChangeSelectedMerchantHandler(
        selectedMerchantRow || 0,
        selectedMerchantInfo,
      );
    },
    [onCloseModalHandler, onChangeSelectedMerchantHandler, selectedMerchantRow],
  );

  const onEditTransactionHandler = useCallback(
    (transactions: unknown[]) => {
      const { transaction, isApproved, index } = modal;
      const issuerId = query.filters?.issuer?.selected[0];

      const body = {
        issuerId,
        reviewStatus: isApproved ? 'APPROVED' : 'REJECTED',
        pendingTransactionId: transaction?._id,
        pendingTransactionIds:
          transactions &&
          transactions.length > 0 &&
          transactions.length !== undefined
            ? transactions
            : [transaction._id],
      };

      if (isApproved) {
        body.matchedMerchantId = selectedMerchant[index]._id;
        body.source = selectedMerchant[index].source;
        if (selectedMerchant[index].source === 'LOCAL') {
          body.locationId = selectedLocation[index]._id;
        }
      }

      dispatch(editPendingTransaction(body));
    },
    [modal, dispatch, selectedLocation, selectedMerchant, query],
  );

  const moreInfoHandler = useCallback(
    (transaction) => {
      onOpenModalHandler(ModalType.TRANSACTION_DATA, {
        transaction,
      });
    },
    [onOpenModalHandler],
  );

  const onReprocessTransaction = useCallback(() => {
    const {
      transaction: { _id: incomingTransactionId },
    } = modal;

    const body = {
      incomingTransactionId,
      realTimeMatchStatus: 'PENDING',
    };

    dispatch(reprocessIncomingTransaction(incomingTransactionId, body));
  }, [dispatch, modal]);

  const viewNewData = useCallback(
    async (transaction, isApproved, index) => {
      const body = {
        pendingTransactionId: transaction._id,
      };
      const issuerId = query.filters?.issuer?.selected[0];

      body.source = isApproved ? selectedMerchant[index].source : 'LOCAL';
      body.issuerId = issuerId;

      dispatch(reprocessPendingTransaction(body));
      onOpenModalHandler(
        isApproved
          ? ModalType.ACCEPT_TRANSACTION
          : ModalType.REJECT_TRANSACTION,
        {
          transaction,
          isApproved,
          index,
        },
      );
      setIsApprovedStatus(isApproved);
    },
    [
      selectedMerchant,
      onOpenModalHandler,
      dispatch,
      query.filters?.issuer?.selected,
    ],
  );

  const acceptTransactionHandler = useCallback(
    (rowIndex) => {
      if (
        !selectedMerchant[rowIndex] ||
        (selectedMerchant[rowIndex].source === 'LOCAL' &&
          !selectedLocation[rowIndex])
      ) {
        setSubmitError(true);
      } else {
        viewNewData(pendingTransactions[rowIndex], true, rowIndex);
      }
    },
    [pendingTransactions, selectedLocation, selectedMerchant, viewNewData],
  );

  const rejectTransactionHandler = useCallback(
    (rowIndex) => viewNewData(pendingTransactions[rowIndex], false, rowIndex),
    [pendingTransactions, viewNewData],
  );

  /** const */
  /** table columns */
  const columns = useMemo(
    () =>
      getAllowedColumns(
        makeColumns({
          moreInfoHandler,
          renderCorrectLocationDropdown,
          renderCorrectMerchantDropdown,
          acceptTransactionHandler,
          rejectTransactionHandler,
        }),
        userRoles,
      ),
    [
      acceptTransactionHandler,
      moreInfoHandler,
      rejectTransactionHandler,
      renderCorrectLocationDropdown,
      renderCorrectMerchantDropdown,
      userRoles,
    ],
  );

  const issuerOptions = issuers.map(({ issuerName, issuerId }) => ({
    label: issuerName,
    value: issuerId,
  }));

  /** effects */
  useEffect(() => {
    dispatch(fetchMerchantsFromCache());
  }, [dispatch]);

  /** fetch offers filtered/sorted by query params */
  useEffect(() => {
    const issuerId = query.filters?.issuer?.selected[0];

    // TODO: this can be removed when we guarantee there is always a selected issuer
    if (selectedIssuer && selectedIssuer !== 'ALL') {
      dispatch(
        fetchPendingTransactions(
          `?${queryToSearchParams(query).toString()}`,
          issuerId,
        ),
      );
    }

    // TODO: this can be removed when we guarantee there is always a selected issuer
    if (!selectedIssuer) {
      dispatch(clearPendingTransactionStateData());
    }
  }, [dispatch, query, selectedIssuer]);

  useEffect(() => {
    if (selectedMerchantRow !== null) {
      const options = locations.map((location) => ({
        label: !location.address
          ? 'No address'
          : `${location.address?.street} - ${location.address?.city} - ${location.address?.state}`,
        value: location,
      }));

      setLocationOptions((prevState) => ({
        ...prevState,
        [selectedMerchantRow]: options,
      }));

      if (locations.length > 0) {
        setSelectedMerchantRow(null);
        dispatch(clearLocationsByMerchantId());
      }
    }
  }, [locations, selectedMerchantRow, dispatch]);

  useEffect(() => {
    const newMerchantOptions = {};
    pendingTransactions.forEach((transaction, transactionIndex) => {
      const { matchedMerchantIds } = transaction;
      const optionsList = [];
      const matchedOptions = [];
      // TODO: we should just query for the set of matchedMerchantIds instead of querying all merchants on page load
      // If the merchant is not in the matchedMerchantIds, we can then query other merchants 1 page at a time
      matchedMerchantIds.forEach((merchantId) => {
        const merchantData = merchants.find(
          (merchant) => merchant._id === merchantId,
        );
        if (merchantData) {
          matchedOptions.push({
            label: merchantData.name,
            value: merchantData,
          });
        }
      });
      optionsList.push(
        {
          label: 'Matched merchants',
          options: matchedOptions,
        },
        {
          label: 'Other',
          options: [{ label: 'All Other Merchants..', value: 'other' }],
        },
      );
      newMerchantOptions[transactionIndex] = optionsList;
    });

    setMerchantOptions((prevState) => ({
      ...prevState,
      ...newMerchantOptions,
    }));
  }, [pendingTransactions, merchants]);

  useEffect(() => {
    if (reprocessTransactionError || reprocessTransactionSuccess) {
      onCloseModalHandler();
      dispatch(clearReprocessIncomingTransactionStateData());
    }
  }, [
    reprocessTransactionError,
    reprocessTransactionSuccess,
    dispatch,
    onCloseModalHandler,
  ]);

  useEffect(
    () => {
      // clear all merchant and location options and selections
      setMerchantOptions(EMPTY);
      setLocationOptions(EMPTY);
      setSelectedMerchant(EMPTY);
      setSelectedLocation(EMPTY);
      setSelectedMerchantRow(null);

      // reset pagination
      fetchPage(0);
    },
    // this gets triggered when the fetchPage callback updates, and fetchPage is dependent on all the filters.
    [fetchPage],
  );

  // upon successful transaction review, refresh the data but stay on the same page
  useEffect(() => {
    if (successEditTransaction) {
      onCloseModalHandler();
      fetchPage(query.pagination.page);

      // clear all merchant and location options and selections
      setMerchantOptions(EMPTY);
      setLocationOptions(EMPTY);
      setSelectedMerchant(EMPTY);
      setSelectedLocation(EMPTY);
      setSelectedMerchantRow(null);
    }
  }, [
    onCloseModalHandler,
    successEditTransaction,
    fetchPage,
    query.pagination.page,
  ]);

  useEffect(() => {
    const hideAcceptAndRejectButtons =
      reviewStatus === ReviewStatusValues.Accepted ||
      reviewStatus === ReviewStatusValues.Rejected;

    const hiddenColumns = hideAcceptAndRejectButtons
      ? { accept: false, reject: false }
      : {};

    setColumnVisibility(hiddenColumns);
  }, [reviewStatus]);

  return (
    <>
      {submitError && (
        <Toast
          id="submit error"
          text="You must select a merchant and location before accepting the transaction"
          type={ToastType.Error}
        />
      )}
      {errorFetchingPendingTransactions && (
        <Toast
          id="fetch pending transactions error"
          text={errorFetchingPendingTransactions}
          type={ToastType.Error}
        />
      )}
      {reprocessTransactionError && (
        <Toast
          id="reprocess incoming transaction error"
          text={reprocessTransactionError}
          type={ToastType.Error}
        />
      )}
      {(successEditTransaction || errorEditTransaction) &&
        commonToast(
          successEditTransaction,
          !!errorEditTransaction,
          isApprovedStatus
            ? msg.SuccessEditTransactionMsg
            : msg.RejectEditTransactionMsg,
          msg.ErrorEditTransactionMsg,
        )}

      <Modal
        isOpen={modal.type === ModalType.ALL_MERCHANTS_SELECT}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <MerchantsSelect
          onCancel={onCloseModalHandler}
          onSubmit={onSelectOtherMerchantHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ACCEPT_TRANSACTION}
        onClose={onCloseModalHandler}
        className={classes.confirmationModal}
      >
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length > 1 && (
            <PendingTransactionReprocess
              isApproved
              onCancel={onCloseModalHandler}
              onSubmit={onEditTransactionHandler}
            />
          )}
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length === 1 && (
            <ConfirmationMessage
              message="Are you sure you want to approve this transaction?"
              onAccept={onEditTransactionHandler}
              onCancel={onCloseModalHandler}
            />
          )}
        {!reprocessPendingTransactionSuccess && (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        )}
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.REJECT_TRANSACTION}
        onClose={onCloseModalHandler}
        className={classes.confirmationModal}
      >
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length > 1 && (
            <PendingTransactionReprocess
              isApproved={false}
              onCancel={onCloseModalHandler}
              onSubmit={onEditTransactionHandler}
            />
          )}
        {reprocessPendingTransactionSuccess &&
          reprocessPendingTxns?.length === 1 && (
            <ConfirmationMessage
              message="Are you sure you want to reject this transaction?"
              onAccept={onEditTransactionHandler}
              onCancel={onCloseModalHandler}
            />
          )}
        {!reprocessPendingTransactionSuccess && (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        )}
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.TRANSACTION_DATA}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <TransactionData
          onCancel={onCloseModalHandler}
          transaction={modal.transaction}
          merchants={merchants}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.REPROCESS_TRANSACTION}
        onClose={onCloseModalHandler}
        className={classes.confirmationModal}
      >
        <ConfirmationMessage
          message="Are you sure you want to reprocess this transaction through the matching algorithm?"
          onAccept={onReprocessTransaction}
          onCancel={onCloseModalHandler}
          loading={reprocessTransactionLoading}
        />
      </Modal>

      <div className={classes.flexboxLayout}>
        <div className={classes.flexboxLayout__header}>
          <PageHeader title="Pending Transactions" className="mb-[1rem]" />

          {/* TODO: update this to use the new filters */}
          <Form className={classes.flexboxLayout__filters} onSubmit={noop}>
            <FormControlSelect
              name="issuerId"
              onChangeManual={onChangeSelectedIssuer}
              defaultValue={selectedIssuer}
              placeholder={loadingIssuer ? 'Loading issuers...' : 'Issuer'}
              options={issuerOptions}
              controlClassName="!w-[14.5rem]"
            />
            <FormControlSelect
              name="reviewStatus"
              onChangeManual={setReviewStatus}
              defaultValue={reviewStatus}
              options={ReviewStatusOptions}
              placeholder="Review status"
              controlClassName="!w-[14.5rem]"
            />
            <FormControlSelect
              name="transactionStatus"
              onChangeManual={setTransactionStatus}
              defaultValue={transactionStatus}
              options={TransactionStatusOptions}
              placeholder="Transaction status"
              controlClassName="!w-[14.5rem]"
            />
            <SelectDateRange
              query={query}
              fieldName="transactionDate"
              updateQuery={updateQuery}
              label="TXN Date"
            />
          </Form>
        </div>
        {loadingPendingTransactions ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <>
            <div className="flex-1 overflow-auto">
              <Table
                id="table-pending-transactions"
                columns={columns}
                data={pendingTransactions}
                // TODO: this check can be removed when we guarantee there is always a selected issuer
                query={selectedIssuer ? query : {}}
                updateQuery={updateQuery}
                columnVisibility={columnVisibility}
                issuerSelected={selectedIssuer}
              />
            </div>

            <div className={classes.flexboxLayout__pagination}>
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PendingTransactions;
