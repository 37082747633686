import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';
import useIssuers from 'refactored/hooks/useIssuers';
import { selectUserAttributesState } from 'state/selectors/auth';
import LayoutGap from 'components/Common/LayoutGap';
import PageHeader from 'components/Common/PageHeader';
import Table from 'refactored/components/Common/Table';
import { makeSummaryColumns } from 'refactored/utils/pendingTransactions/makeColumns';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import usePendingTransactions from 'refactored/hooks/usePendingTransactions';
import Path from 'enums/path.enum';

const PendingTransactionsSummary = () => {
  const [issuers, loadingIssuers] = useIssuers({ shouldFetch: true });

  const shouldFetch = !loadingIssuers && issuers?.length > 0;
  const [data, loadingPendingTransactions] = usePendingTransactions(
    issuers,
    shouldFetch,
  );

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const moreInfoHandler = useCallback(
    (row) =>
      window.open(
        `${Path.PendingTransactions}/${row.issuerId}`,
        '_blank',
        'noreferrer',
      ),
    [],
  );

  /* table columns */
  const columns = useMemo(
    () => getAllowedColumns(makeSummaryColumns({ moreInfoHandler }), userRoles),
    [userRoles, moreInfoHandler],
  );

  return (
    <LayoutGap direction="column">
      <LayoutGap direction="column" className="m-[2.5rem] mb-0">
        <PageHeader title="Pending Transactions" className="mb-4" />
      </LayoutGap>
      {loadingIssuers || loadingPendingTransactions ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner size="lg" color="gray" data-testid="spinner" />
        </div>
      ) : (
        <div className="flex-1 overflow-auto">
          <Table
            id="table-pending-transactions-summary"
            columns={columns}
            data={data}
            query={{}}
            updateQuery={() => {
              /* empty */
            }}
          />
        </div>
      )}
    </LayoutGap>
  );
};

export default PendingTransactionsSummary;
