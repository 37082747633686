import { ISSUERS_BASE_URL, publicClient } from 'services/kardAPI';

/**
 * @typedef {Object} SubdomainAuthConfig
 * @property {Object} ssoConfig - SSO configuration object
 * @property {string} ssoConfig.vendor - SSO vendor name
 * @property {string} ssoConfig.cognitoAuthProviderName - Cognito auth provider name
 * @property {boolean} emailPasswordEnabled - Indicates whether email password is enabled
 * @property {string} _id - ID of the object
 * @property {string} issuerName - Issuer name
 * @property {string} subdomain - Subdomain
 */

/**
 * Fetches the configuration for a given subdomain.
 *
 * @async
 * @param {string} subdomain - The subdomain for which to fetch the configuration.
 * @returns {Promise<SubdomainAuthConfig>} The configuration for the subdomain. Returns null if an error occurs or if the subdomain is not provided.
 * @throws {Error} Will throw an error if the subdomain is not provided.
 */
const fetchSubdomainConfig = async (subdomain) => {
  try {
    if (!subdomain) throw new Error('Subdomain is required');
    const url = `${ISSUERS_BASE_URL}/portal/authconfig?subdomain=${subdomain}`;
    const subdomainConfig = await publicClient.get(url);
    return subdomainConfig?.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default fetchSubdomainConfig;
