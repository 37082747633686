const dateFormat = (dateString: string): string => {
  const date = new Date(dateString);

  // Extracting the UTC date components
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  const strHours = (hours % 12 || 12).toString().padStart(2, '0');

  // Combine into final format
  return `${month}/${day}/${year}, ${strHours}:${minutes}:${seconds} ${ampm} UTC`;
};

export default dateFormat;
