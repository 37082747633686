import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import { selectFetchMerchantsState } from 'state/selectors/merchants';
import ButtonGroup from 'components/Common/ButtonGroup';
import Button from 'components/Common/Button';
import Input, {
  Type as InputType,
  Size as InputSize,
  IconPosition,
} from 'components/Common/Input';
import { fetchMerchants } from 'state/actions/merchants';
import searchIcon from 'assets/icons/search.svg';

import Pagination from 'components/Common/Pagination';
import classes from './MerchantSelect.module.scss';

const MerchantSelect = ({ onSubmit, onCancel, filters }) => {
  const dispatch = useDispatch();

  const {
    loading,
    merchantsData: { merchants },
  } = useSelector(selectFetchMerchantsState, shallowEqual);

  const [merchantsList, setMerchantsList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = useMemo(() => 200, []);

  const firstItemPagination = useMemo(
    () => (pageIndex + 1) * pageSize - pageSize + 1,
    [pageIndex, pageSize],
  );

  const lastItemPagination = useMemo(
    () =>
      (pageIndex + 1) * pageSize < merchantsList.length
        ? (pageIndex + 1) * pageSize
        : merchantsList.length,
    [pageIndex, pageSize, merchantsList],
  );

  useEffect(() => {
    dispatch(fetchMerchants(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (merchants.length > 0 && merchantsList.length === 0) {
      setMerchantsList(merchants);
    } else if (merchants.length === 0 && merchantsList.length > 0) {
      setMerchantsList([]);
    }
  }, [merchants, merchantsList, searchText]);

  const onPreviousPageHandler = useCallback(() => {
    const filtersQuery = new URLSearchParams();
    filtersQuery.append('page', pageIndex - 1);
    filtersQuery.append('limit', pageSize);
    const pagination = filtersQuery.toString();

    setPageIndex((prevState) => prevState - 1);

    dispatch(fetchMerchants(`?${pagination}`));
  }, [dispatch, pageIndex, pageSize]);

  const onNextPageHandler = useCallback(() => {
    const filtersQuery = new URLSearchParams();
    filtersQuery.append('page', pageIndex + 1);
    filtersQuery.append('limit', pageSize);
    const pagination = filtersQuery.toString();

    setPageIndex((prevState) => prevState + 1);

    dispatch(fetchMerchants(`?${pagination}`));
  }, [dispatch, pageIndex, pageSize]);

  const onGoToPageHandler = useCallback(
    (newPage) => {
      const filtersQuery = new URLSearchParams();
      filtersQuery.append('page', newPage);
      filtersQuery.append('limit', pageSize);
      const pagination = filtersQuery.toString();

      setPageIndex(newPage);

      dispatch(fetchMerchants(`?${pagination}`));
    },
    [dispatch, pageSize],
  );

  const onSearchMerchantHandler = useCallback(() => {
    const filtersQuery = new URLSearchParams();
    if (searchText) {
      filtersQuery.append('name', searchText);
    }
    const pagination = filtersQuery.toString();

    dispatch(fetchMerchants(`?${pagination}`));
  }, [dispatch, searchText]);

  const onSubmitHandler = useCallback(() => {
    onSubmit(selectedMerchant);
  }, [onSubmit, selectedMerchant]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.heading}>
          <Heading size={HeadingSize.M} className={classes.headingText}>
            Other merchants
          </Heading>
          <div className={classes.panelSearch}>
            <Input
              name="search"
              placeholder="Search By Merchant Name"
              type={InputType.Text}
              icon={searchIcon}
              size={InputSize.XS}
              iconPosition={IconPosition.Left}
              onChange={(event) => setSearchText(event.target.value)}
              disabled={loading}
              onKeyPress={() => onSearchMerchantHandler()}
            />
          </div>
          <Body
            size={BodySize.XS}
            color={BodyColor.Gray}
            className={classes.selectedMerchant}
          >
            {selectedMerchant
              ? `Selected merchant: ${selectedMerchant.name}`
              : 'No selected merchant'}
          </Body>
        </div>
        {loading ? (
          <Spinner
            color={SpinnerColor.Black}
            size={SpinnerSize.L}
            className={classes.spinner}
          />
        ) : (
          <>
            <div className={classes.listContainer}>
              {merchantsList.length === 0 ? (
                <Body size={BodySize.S} className={classes.error}>
                  There are no merchants
                </Body>
              ) : (
                merchantsList.map((merchant) => (
                  <div key={merchant.name}>
                    <button
                      type="button"
                      className={classNames(classes.item, {
                        [classes.selected]:
                          merchant.name === selectedMerchant?.name,
                      })}
                      onClick={() => setSelectedMerchant(merchant)}
                    >
                      <Body size={BodySize.S} color={BodyColor.Black}>
                        {merchant.name}
                      </Body>
                    </button>
                    <div className={classes.divider} />
                  </div>
                ))
              )}
            </div>
            <Pagination
              manualPagination
              firstItemPagination={firstItemPagination}
              lastItemPagination={lastItemPagination}
              data={merchantsList}
              pageIndex={pageIndex}
              pageCount={pageSize}
              canPreviousPage={pageIndex > 0}
              previousPage={onPreviousPageHandler}
              canNextPage={merchantsList.length === pageSize}
              nextPage={onNextPageHandler}
              gotoPage={onGoToPageHandler}
              className={classes.pagination}
            />
          </>
        )}
      </div>
      <ButtonGroup reverse className={classes.actionButtons}>
        <Button onClick={onSubmitHandler} disabled={!selectedMerchant}>
          Select
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
};

MerchantSelect.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  filters: PropTypes.string,
};

MerchantSelect.defaultProps = {
  filters: '',
};

export default MerchantSelect;
