import Toast, { Type as ToastType } from 'components/Common/Toast';

const commonToast = (
  success: boolean,
  error: boolean,
  successMessage: string,
  errorMessage: string,
) =>
  (success || error) && (
    <Toast
      id={success ? successMessage : errorMessage}
      text={success ? successMessage : errorMessage}
      type={success ? ToastType.Success : ToastType.Error}
    />
  );

export default commonToast;
