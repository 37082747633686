import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const editMerchant = async (id, body) => {
  try {
    await client.put(`${REWARDS_BASE_URL}/portal/merchant/${id}`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default editMerchant;
