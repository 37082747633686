import React, { Ref, forwardRef } from 'react';
import classNames from 'classnames';
import cancelIcon from 'assets/icons/x.svg';
import { uniqueId } from 'lodash';
import { UseFormRegister, FieldValues } from 'react-hook-form';

export enum Type {
  Text = 'text',
  Number = 'number',
  Password = 'password',
  Submit = 'submit',
  Reset = 'reset',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Button = 'button',
  File = 'file',
  Image = 'image',
  Email = 'email',
  Tel = 'tel',
}

export enum Size {
  M = 'm',
  S = 's',
  XS = 'xs',
}

export enum LabelPosition {
  Top = 'top',
  Left = 'left',
}

export enum IconPosition {
  Left = 'left',
  Right = 'right',
}

export enum Color {
  Black = 'black',
  Gray = 'gray',
  Red = 'red',
  Green = 'green',
}

// Omitting `size` because it is a conflicting type for InputHTMLAttributes
type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  type?: Type;
  label?: string;
  labelPosition?: LabelPosition;
  error?: boolean;
  altIcon?: string;
  icon?: string;
  iconPosition?: IconPosition;
  size?: Size;
  containerClassName?: string;
  onClickIcon?: () => void;
  onEnterDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  color?: Color;
  showCancelIcon?: boolean;
  onCancel?: () => void;
  testId?: string;
  register?: UseFormRegister<FieldValues>;
};

const Input = forwardRef(
  (
    {
      id = uniqueId('input_'),
      type = Type.Text,
      size = Size.S,
      labelPosition = LabelPosition.Left,
      iconPosition = IconPosition.Left,
      altIcon = '',
      color = Color.Gray,
      onCancel,
      containerClassName,
      label,
      icon,
      onClickIcon,
      showCancelIcon,
      name,
      disabled,
      error,
      className,
      testId,
      onKeyDown,
      onEnterDown,
      value,
      register,
      ...rest
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const baseStyle = `bg-gray-50 focus:!border-indigo-500 disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-300`;

    const errorStyle =
      'bg-red-50 text-red-700 border-red-600 border-solid border-3 focus:!border-red-600 focus:!ring-red-500 ';
    return (
      <div className={classNames(containerClassName, 'relative')}>
        {label && (
          <label className="mb-2 text-sm" htmlFor={id}>
            {label}
          </label>
        )}
        <div
          className={classNames('flex', {
            'left-3': iconPosition === IconPosition.Left,
          })}
        >
          {icon &&
            (onClickIcon ? (
              <button
                className={classNames('absolute left-4 top-3 h-4 w-4', {
                  'left-[29rem] top-[2.65rem] w-4':
                    iconPosition === IconPosition.Right,
                })}
                onClick={onClickIcon}
                type="button"
              >
                <img src={icon} alt={altIcon} />
              </button>
            ) : (
              <img
                alt={altIcon}
                className={classNames('absolute', {
                  'left-4 top-[11px] h-4 w-4':
                    iconPosition === IconPosition.Left,
                  'bottom-[0.75rem] right-4 h-4 w-4':
                    iconPosition === IconPosition.Right,
                })}
                src={icon}
              />
            ))}
          <input
            className={classNames(
              className,
              'h-10 w-[499px] rounded-lg border-gray-300 bg-white px-4 py-2 text-sm',
              error ? errorStyle : baseStyle,
              {
                'pl-10': iconPosition === IconPosition.Left,
                'pr-10': iconPosition === IconPosition.Right || showCancelIcon,
              },
            )}
            id={id}
            name={name}
            value={value}
            disabled={disabled}
            type={type}
            ref={ref}
            onKeyDown={(event) => {
              event.persist();
              onKeyDown?.(event);
              if (event.key === 'Enter') {
                onEnterDown?.(event);
              }
            }}
            data-cy={testId}
            {...register}
            {...rest}
          />
          {showCancelIcon && typeof value === 'string' && value.length > 0 && (
            <button
              type="button"
              className="absolute right-[19px] top-[14px] h-2.5 w-2.5"
              onClick={onCancel}
            >
              <img src={cancelIcon} alt={cancelIcon} />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default Input;
