import React from 'react';
import classNames from 'classnames';

import classes from './Heading.module.scss';

export enum Size {
  XXL = 'xxl',
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
}

type Props = {
  size?: Size;
  className?: string;
  children: React.ReactNode;
};

const Heading = ({ size = Size.M, children, className = '' }: Props) => (
  <p className={classNames(classes.heading, classes[size], className)}>
    {children}
  </p>
);

export default Heading;
