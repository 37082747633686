/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchMerchantNetworksInit,
  fetchMerchantNetworksSuccess,
  fetchMerchantNetworksFail,
  createMerchantNetworksInit,
  createMerchantNetworksSuccess,
  createMerchantNetworksFail,
  clearMerchantNetworksErrorsProps,
  clearMerchantNetworksSuccessProps,
} from 'state/actionCreators/merchantNetworks';

export const initialState = {
  fetchMerchantNetworkLoading: false,
  fetchMerchantNetworkSuccess: false,
  fetchMerchantNetworkError: null,
  createMerchantNetworkLoading: false,
  createMerchantNetworkSuccess: false,
  createMerchantNetworkError: null,
  merchantNetworks: [],
};

const persistConfig = {
  key: 'merchantNetwork',
  storage,
  blacklist: [
    'fetchMerchantNetworkLoading',
    'fetchMerchantNetworkSuccess',
    'fetchMerchantNetworkError',
    'createMerchantNetworkLoading',
    'createMerchantNetworkSuccess',
    'createMerchantNetworkError',
    'merchantNetwork',
  ],
};

const merchantNetworksReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchMerchantNetworksInit, (state) => {
      state.fetchMerchantNetworkLoading = true;
    });

    builder.addCase(fetchMerchantNetworksSuccess, (state, action) => {
      const { merchantNetworks } = action.payload;
      state.fetchMerchantNetworkLoading = false;
      state.fetchMerchantNetworkError = null;
      state.fetchMerchantNetworkSuccess = true;
      state.merchantNetworks = merchantNetworks;
    });

    builder.addCase(fetchMerchantNetworksFail, (state, action) => {
      const { error } = action.payload;
      state.fetchMerchantNetworkLoading = false;
      state.fetchMerchantNetworkError = error;
      state.fetchMerchantNetworkSuccess = false;
    });

    builder.addCase(createMerchantNetworksInit, (state) => {
      state.createMerchantNetworkLoading = true;
    });

    builder.addCase(createMerchantNetworksSuccess, (state) => {
      state.createMerchantNetworkLoading = false;
      state.createMerchantNetworkError = null;
      state.createMerchantNetworkSuccess = true;
    });

    builder.addCase(createMerchantNetworksFail, (state, action) => {
      const { error } = action.payload;
      state.createMerchantNetworkLoading = false;
      state.createMerchantNetworkError = error;
      state.createMerchantNetworkSuccess = false;
    });

    builder.addCase(clearMerchantNetworksErrorsProps, (state) => {
      state.createMerchantNetworkLoading = false;
      state.createMerchantNetworkError = null;
      state.createMerchantNetworkSuccess = false;
    });

    builder.addCase(clearMerchantNetworksSuccessProps, (state) => {
      state.createMerchantNetworkLoading = false;
      state.createMerchantNetworkError = null;
      state.createMerchantNetworkSuccess = false;
    });
  }),
);

export default merchantNetworksReducer;
