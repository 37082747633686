import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

/* custom components */
import SelectDropdown from 'refactored/components/Common/SelectDropdown';
import Search from 'refactored/components/Common/Search';
import SelectDateRange from 'refactored/components/Common/SelectDateRange';
import SelectNumberRange from 'refactored/components/Common/SelectNumberRange';

/* merchant networks */
import { selectFetchMerchantNetworksState } from 'state/selectors/merchantNetworks';

const DynamicFilters = ({ activeFilters, query, updateQuery }) => {
  const { merchantNetworks } = useSelector(
    selectFetchMerchantNetworksState,
    shallowEqual,
  );

  const merchantNetworkOptions = merchantNetworks.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  const shouldDisplay = (filterName) =>
    activeFilters.some((item) => item.accessorKey === filterName);

  return (
    <>
      {shouldDisplay('rewardType') && (
        <SelectDropdown
          query={query}
          fieldName="rewardType"
          options={[
            { label: 'Affiliate', value: 'AFFILIATE' },
            { label: 'Cardlinked', value: 'CARDLINKED' },
          ]}
          updateQuery={updateQuery}
          label="Reward Type"
        />
      )}
      {shouldDisplay('validReward') && (
        <SelectDropdown
          query={query}
          fieldName="validReward"
          options={[
            { label: 'True', value: 'TRUE' },
            { label: 'False', value: 'FALSE' },
          ]}
          updateQuery={updateQuery}
          label="Valid Reward"
        />
      )}
      {shouldDisplay('underReview') && (
        <SelectDropdown
          query={query}
          fieldName="underReview"
          options={[
            { label: 'Pending', value: 'PENDING' },
            { label: 'Complete', value: 'COMPLETE' },
          ]}
          updateQuery={updateQuery}
          label="Under Review"
        />
      )}
      {shouldDisplay('status') && (
        <SelectDropdown
          query={query}
          fieldName="status"
          options={[
            { label: 'Approved', value: 'APPROVED' },
            { label: 'Settled', value: 'SETTLED' },
            { label: 'Declined', value: 'DECLINED' },
            { label: 'Reversed', value: 'REVERSED' },
          ]}
          updateQuery={updateQuery}
          label="Status"
        />
      )}
      {shouldDisplay('createdDate') && (
        <SelectDateRange
          query={query}
          updateQuery={updateQuery}
          fieldName="createdDate"
          label="Created Date"
        />
      )}
      {shouldDisplay('receivedPaymentStatus') && (
        <SelectDropdown
          query={query}
          fieldName="receivedPaymentStatus"
          options={[
            { label: 'Pending', value: 'PENDING' },
            { label: 'Paid in full', value: 'PAID_IN_FULL' },
            { label: 'Paid in part', value: 'PAID_IN_PART' },
          ]}
          updateQuery={updateQuery}
          label="Received Payment"
        />
      )}
      {shouldDisplay('paidToIssuer') && (
        <SelectDropdown
          query={query}
          fieldName="paidToIssuer"
          options={[
            { label: 'True', value: 'TRUE' },
            { label: 'False', value: 'FALSE' },
          ]}
          updateQuery={updateQuery}
          label="Paid to issuer"
        />
      )}
      {shouldDisplay('referringPartnerUserId') && (
        <Search
          query={query}
          updateQuery={updateQuery}
          field="referringPartnerUserId"
          placeholder="Referring Partner User ID"
          className="h-[37px] !w-[16rem]"
          showCancelIcon
        />
      )}
      {shouldDisplay('cardBIN') && (
        <Search
          query={query}
          updateQuery={updateQuery}
          field="cardBIN"
          placeholder="Card BIN"
          className="h-[37px] !w-[11rem]"
          showCancelIcon
        />
      )}
      {shouldDisplay('cardLastFour') && (
        <Search
          query={query}
          updateQuery={updateQuery}
          field="cardLast4"
          placeholder="Card Last Four"
          className="h-[37px] !w-[11rem]"
          showCancelIcon
        />
      )}
      {/* {shouldDisplay('merchantName') && (
        <Search // TODO multivalue
          query={query}
          updateQuery={updateQuery}
          field="matchedMerchantName"
          placeholder="Matched Merchant Name"
          className="!w-[11rem] h-[37px]"
          showCancelIcon
        />
      )} */}
      {shouldDisplay('isFirstMerchantOffer') && (
        <SelectDropdown
          query={query}
          fieldName="isFirstMerchantOffer"
          options={[
            { label: 'True', value: 'TRUE' },
            { label: 'False', value: 'FALSE' },
          ]}
          updateQuery={updateQuery}
          label="Is First Merchant Offer"
        />
      )}
      {shouldDisplay('merchantNetwork') && (
        <SelectDropdown
          query={query}
          fieldName="merchantNetwork"
          options={merchantNetworkOptions}
          updateQuery={updateQuery}
          label="Merchant Network"
          searchable
        />
      )}
      {shouldDisplay('offerId') && (
        <Search
          query={query}
          updateQuery={updateQuery}
          field="offerId"
          placeholder="Offer ID"
          className="h-[37px] !w-[11rem]"
          showCancelIcon
        />
      )}
      {shouldDisplay('amount') && (
        <SelectNumberRange
          query={query}
          updateQuery={updateQuery}
          fieldName="transactionAmountInCents"
          label="Transaction Amount"
        />
      )}
      {shouldDisplay('receivedCommissionInCents') && (
        <SelectNumberRange
          query={query}
          updateQuery={updateQuery}
          fieldName="receivedCommissionInCents"
          label="Kard Commission (in cents)"
        />
      )}
      {shouldDisplay('commissionToIssuer') && (
        <SelectNumberRange
          query={query}
          updateQuery={updateQuery}
          fieldName="commissionToIssuer"
          label="Issuer Commission (in cents)"
        />
      )}
      {shouldDisplay('authorizationDate') && (
        <SelectDateRange
          query={query}
          updateQuery={updateQuery}
          fieldName="authorizationDate"
          label="Authorization Date"
        />
      )}
      {shouldDisplay('settledDate') && (
        <SelectDateRange
          query={query}
          updateQuery={updateQuery}
          fieldName="settledDate"
          label="Settled Date"
        />
      )}
      {shouldDisplay('merchantAddrCity') && (
        <Search // TODO multivalue
          query={query}
          updateQuery={updateQuery}
          field="merchantAddrCity"
          placeholder="Merchant City"
          className="h-[37px] !w-[11rem]"
          showCancelIcon
        />
      )}
      {shouldDisplay('merchantAddrState') && (
        <Search // TODO multivalue
          query={query}
          updateQuery={updateQuery}
          field="merchantAddrState"
          placeholder="Merchant State"
          className="h-[37px] !w-[11rem]"
          showCancelIcon
        />
      )}
      {shouldDisplay('merchantAddrCountry') && (
        <Search // TODO multivalue
          query={query}
          updateQuery={updateQuery}
          field="merchantAddrCountry"
          placeholder="Merchant Country"
          className="h-[37px] !w-[11rem]"
          showCancelIcon
        />
      )}
      {shouldDisplay('offer.commissionType') && (
        <SelectDropdown
          query={query}
          fieldName="commissionType"
          options={[
            { label: 'Flat', value: 'FLAT' },
            { label: 'Percentage', value: 'PERCENT' },
          ]}
          updateQuery={updateQuery}
          label="Commission Type"
        />
      )}
    </>
  );
};

export default DynamicFilters;

DynamicFilters.propTypes = {
  activeFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  query: PropTypes.shape({
    filters: PropTypes.objectOf(
      PropTypes.shape({
        selected: PropTypes.arrayOf(PropTypes.string.isRequired),
      }),
    ),
  }).isRequired,
  updateQuery: PropTypes.func.isRequired,
};
