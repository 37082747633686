import client, { ISSUERS_BASE_URL } from 'services/kardAPI';

const fetchMerchantIssuers = async () => {
  try {
    const issuersData = await client.get(`${ISSUERS_BASE_URL}/portal/issuers`);
    const issuers = issuersData.data.map((issuer) => issuer.issuerName);
    return issuers;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchantIssuers;
