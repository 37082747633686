import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

import { AlertOctagonIcon } from 'lucide-react';

const alertVariants = cva(
  'relative w-full rounded-lg border border-neutral-200 px-4 py-3 text-sm',
  {
    variants: {
      variant: {
        default: 'bg-white text-neutral-950',
        destructive: 'border-red-500/50 text-red-500 bg-red-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const AlertBase = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
));
AlertBase.displayName = 'AlertBase';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement> & { children: React.ReactNode }
>(({ className, children, ...props }, ref) => (
  <h5 ref={ref} className={cn('font-medium', className)} {...props}>
    {children}
  </h5>
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('text-sm', className)} {...props} />
));
AlertDescription.displayName = 'AlertDescription';

type AlertDangerProps = {
  title: string;
  description: string;
};

const AlertDanger = ({ title, description }: AlertDangerProps) => (
  <AlertBase variant="destructive">
    <div className="flex items-center gap-3">
      <AlertOctagonIcon className="h-4 w-4" />
      <div className="flex flex-col">
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </div>
    </div>
  </AlertBase>
);

export { AlertBase, AlertTitle, AlertDescription, AlertDanger };
