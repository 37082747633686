/** custom components */
import MoreInfo from 'refactored/components/Common/MoreInfo';

/** enums */
import dateParse from 'utils/common/dateParse';
import Groups from 'enums/users/groups.enum';

/** assets */
import trueIcon from 'assets/icons/boolean-true.svg';
import falseIcon from 'assets/icons/boolean-false.svg';
import { ColumnProps } from 'refactored/components/Common/Table';
import { MouseEventHandler } from 'react';

type makeColumnsProps = {
  moreInfoHandler: MouseEventHandler;
  renderCorrectMerchantDropdown: MouseEventHandler;
  renderCorrectLocationDropdown: MouseEventHandler;
  acceptTransactionHandler: MouseEventHandler;
  rejectTransactionHandler: MouseEventHandler;
};

const makeColumns = ({
  moreInfoHandler,
  renderCorrectMerchantDropdown,
  renderCorrectLocationDropdown,
  acceptTransactionHandler,
  rejectTransactionHandler,
}: makeColumnsProps): ColumnProps[] => [
  {
    header: 'Merchant Name',
    accessorKey: 'merchantName',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'State',
    accessorKey: 'merchantAddrState',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'City',
    accessorKey: 'merchantAddrCity',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Description',
    accessorKey: 'description',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Txn Date',
    accessorKey: 'transactionDate',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }) => (getValue() ? dateParse(getValue()) : '-'),
  },
  {
    header: 'Correct Merchant',
    accessorKey: 'correctMerchant',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => renderCorrectMerchantDropdown(row.index),
    meta: {
      select: true,
    },
  },
  {
    header: 'Correct Location',
    accessorKey: 'correctLocation',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => renderCorrectLocationDropdown(row.index),
    meta: {
      select: true,
    },
  },
  {
    header: '',
    accessorKey: 'accept',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <img
        src={trueIcon}
        alt="accept"
        onClick={() => acceptTransactionHandler(row.index)}
        className="cursor-pointer"
      />
    ),
  },
  {
    header: '',
    accessorKey: 'reject',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <img
        src={falseIcon}
        alt="reject"
        onClick={() => rejectTransactionHandler(row.index)}
        className="cursor-pointer"
      />
    ),
  },
  {
    header: 'More info',
    id: 'moreInfo',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => (
      <MoreInfo onClick={() => moreInfoHandler(row.original)} />
    ),
  },
];

export const makeSummaryColumns = ({
  moreInfoHandler,
}: {
  moreInfoHandler: MouseEventHandler;
}): ColumnProps[] => [
  {
    header: 'Issuer',
    accessorKey: 'issuerName',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Pending Txns',
    accessorKey: 'countTotal',
    allowedRoles: Groups.Administrators,
    cell: ({ row, getValue }) => (row.original.error ? 'Error' : getValue()),
  },
  {
    header: 'View Txns',
    id: 'moreInfo',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => (
      <MoreInfo onClick={() => moreInfoHandler(row.original)} />
    ),
  },
];

export default makeColumns;
