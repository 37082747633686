import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const createMerchantNetwork = async (body) => {
  try {
    await client.post(`${REWARDS_BASE_URL}/portal/network`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createMerchantNetwork;
