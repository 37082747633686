import { Tooltip as ReactTooltip } from 'react-tooltip';
import LayoutGap from 'components/Common/LayoutGap';
import Input, {
  Type as InputType,
  IconPosition as InputIconPosition,
} from 'components/Common/Input';
import { subtract } from 'utils/commission/getCommissionSplit';
import {
  CommissionType,
  CommissionTypeIcon,
} from 'enums/offers/commissionTypes.enum';

import informationIcon from 'assets/icons/information.svg';

const COL_WIDTH = 'w-[9.7rem]';

type InputWithTooltipProps = {
  name: string;
  label: string;
  value?: number;
  icon: string;
  tip: string;
};

const InputWithTooltip = ({ tip, ...rest }: InputWithTooltipProps) => (
  <div className="relative">
    <Input
      type={InputType.Number}
      readOnly
      iconPosition={InputIconPosition.Right}
      width={COL_WIDTH}
      placeholder="--"
      {...rest}
    />
    <img
      className="absolute right-0 top-1 w-4"
      src={informationIcon}
      alt="Information"
      data-tooltip-content={tip}
      data-tooltip-id="commission-label-tooltip"
    />
    <ReactTooltip id="commission-label-tooltip" place="top" />
  </div>
);

type IssuerCommissionsSplitProps = {
  commissionType: CommissionType;
  totalIssuerCommission: number;
  userCommission?: number;
};

const IssuerCommissionsSplit = ({
  commissionType,
  totalIssuerCommission,
  userCommission = 0,
}: IssuerCommissionsSplitProps) => {
  const issuerCommission = subtract(totalIssuerCommission, userCommission);

  const commissionTypeIcon = CommissionTypeIcon[commissionType];

  return (
    <LayoutGap size="small" direction="column">
      <LayoutGap>
        <InputWithTooltip
          name="totalCommission"
          label="Total Commission"
          value={totalIssuerCommission}
          icon={commissionTypeIcon}
          tip="Total commission for the issuer"
        />
        <InputWithTooltip
          name="userCommission"
          label="User Commission"
          value={userCommission}
          icon={commissionTypeIcon}
          tip="The amount rewarded to the cardholder."
        />
        <InputWithTooltip
          name="issuerCommission"
          label="Issuer Commission"
          value={issuerCommission}
          icon={commissionTypeIcon}
          tip="The commission leftover after the cardholder reward."
        />
      </LayoutGap>
    </LayoutGap>
  );
};

export default IssuerCommissionsSplit;
