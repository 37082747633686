/* modules */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { noop } from 'lodash';

/* redux */
import { selectUserAttributesState } from 'state/selectors/auth';

/* utils */
import {
  defaultColumnsList,
  makeColumns,
} from 'refactored/utils/reports/makeColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import allFilters from 'refactored/utils/reports/allFilters';

/* custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import useCurrentIssuer from 'refactored/hooks/useCurrentIssuer';
import useIssuers from 'refactored/hooks/useIssuers';
import useReports from 'refactored/hooks/useReports';

/* common components */
import SearchBy from 'refactored/components/Common/SearchBy';
import Table from 'refactored/components/Common/Table';
import Pagination from 'refactored/components/Common/Pagination2';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Form from 'components/Common/Form';
import FormControlSelect from 'components/Common/FormControlSelect';

/* internal components */
import InfoQuestion from 'assets/icons/info-question.svg?react';
import classNames from 'classnames';
import { fetchMerchantNetworks } from '@/state/actions/merchantNetworks';
import LayoutGap from '@/components/Common/LayoutGap';
import DynamicFilters from './ReportsComponents/DynamicFilters';
import DateFilter from './ReportsComponents/DateFilterComponent';
import SelectOptions from './ReportsComponents/SelectOptions';
import { TableColumns } from './Types';

/* styling */
import classes from './Reports.module.scss';

const today = new Date();
const defaultFilters = ['referringPartnerUserId', 'offerId'];
const DEFAULT_FILTER_DAYS = 3;

const defaultStartDate = new Date(
  new Date().setDate(today.getDate() - DEFAULT_FILTER_DAYS),
);

const getAllowedFilters = (allColumns: TableColumns[]) => {
  const allowedFilters: TableColumns[] = [];
  allColumns.forEach((item) => {
    if (allFilters.some((filter) => filter === item.accessorKey)) {
      allowedFilters.push(item);
    }
  });
  return allowedFilters;
};

const Reports = () => {
  const dispatch = useDispatch();

  const [selectedIssuer, setSelectedIssuer] = useState<string | null>(null);

  /* query framework */
  const [query, updateQuery]: any = useQuery(
    {
      pagination: { page: 0, limit: 100 },
    },
    {
      filters: {
        transactionTimeStamp: {
          type: 'date',
          start: defaultStartDate,
          end: today,
        },
      },
    },
  );

  const { isAdmin, roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const [issuer] = useCurrentIssuer({ shouldFetch: !isAdmin });
  const [issuers, loadingIssuer] = useIssuers({ shouldFetch: isAdmin });

  const issuersOptions = useMemo(
    () =>
      issuers.map(
        ({
          issuerName,
          issuerId,
        }: {
          issuerName: string;
          issuerId: string;
        }) => ({
          label: issuerName,
          value: issuerId,
        }),
      ),

    [issuers],
  );

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchMerchantNetworks());
    }
  }, [dispatch, isAdmin, selectedIssuer]);

  const effectiveIssuerId = selectedIssuer || issuer?.issuerId;
  const filters = useMemo(
    () => Object.fromEntries(queryToSearchParams(query)),
    [query],
  );

  const {
    isRetrying,
    reports,
    countTotal,
    loading: loadingReports,
    error: errorReports,
  } = useReports({
    filters,
    issuerId: effectiveIssuerId,
  });

  const [columns, setColumns] = useState<TableColumns[]>([]);

  const allColumns: TableColumns[] = useMemo(
    () => getAllowedColumns(makeColumns, userRoles),
    [userRoles],
  );

  const allowedFilters = useMemo(
    () => getAllowedFilters(allColumns),
    [allColumns],
  );

  const [activeFilters, setActiveFilters] = useState<TableColumns[]>([]);

  /* column selection */
  const handleColumnsSelection = useCallback((selected: TableColumns[]) => {
    setColumns(selected);
  }, []);

  const handleFiltersSelection = useCallback((selected: TableColumns[]) => {
    setActiveFilters(selected);
  }, []);

  return (
    <>
      {errorReports && (
        <Toast
          id="fetch-reports"
          text="Error fetching reports"
          type={ToastType.Error}
        />
      )}

      <div className={classes.flexboxLayout}>
        <div className={classes.flexboxLayout__header}>
          <span
            className={classes.flexboxLayout__header__pageTitle}
            data-testid="page-header-title"
          >
            Reports
          </span>

          <DateFilter query={query} updateQuery={updateQuery} />

          {isAdmin && (
            <Form onSubmit={noop} className="relative top-2.5">
              <FormControlSelect
                name="issuerId"
                onChangeManual={setSelectedIssuer}
                placeholder={loadingIssuer ? 'Loading...' : 'Select Issuer'}
                options={issuersOptions}
                className="!z-50"
                controlClassName="!w-[14.5rem]"
              />
            </Form>
          )}
        </div>

        {/* Filters */}
        <div className={classes.flexboxLayout__filters}>
          <SearchBy
            idField="transactionId"
            field="transactionId"
            placeholder="Search by transaction ID"
            className="h-[37px] !w-[24.2rem]"
            query={query}
            updateQuery={updateQuery}
            showCancelIcon
          />
          <SelectOptions
            options={allColumns}
            defaultSelection={defaultColumnsList}
            onSelect={handleColumnsSelection}
            name="Show/hide columns"
          />
          <SelectOptions
            options={allowedFilters}
            defaultSelection={defaultFilters}
            onSelect={handleFiltersSelection}
            limit={5}
            name="Change filters"
            limitMessage="(select up to 5 filters)"
            type="filters"
          />

          <div className="flex grow justify-end">
            <span
              className="flex cursor-pointer items-center gap-1 text-sm text-gray-500 hover:text-gray-700"
              data-tooltip-content="Data refreshes once every 12 hours"
              data-tooltip-id="about-data"
              data-place="top"
            >
              <InfoQuestion />
              About the data
            </span>
            <ReactTooltip
              id="about-data"
              arrowColor="white"
              className="max-w-52 hyphens-auto bg-white p-2 text-gray-500 drop-shadow-md"
              disableStyleInjection
            />
          </div>
        </div>

        <div className={classNames(classes.flexboxLayout__filters, 'mb-3')}>
          <DynamicFilters
            query={query}
            updateQuery={updateQuery}
            activeFilters={activeFilters}
          />
        </div>

        {/* Table */}
        {loadingReports ? (
          <LayoutGap
            direction="column"
            className="h-full items-center justify-center"
          >
            <Spinner size="lg" color="gray" />
            <span className="mt-3 text-sm text-gray-500">
              {!isRetrying ? (
                <span>Loading Data... This may take a few moments</span>
              ) : (
                <span>Hang on Tight... Reports take a moment to warm up</span>
              )}
            </span>
            <div className="mt-2 w-48">
              <div className="h-1.5 w-full overflow-hidden rounded-full bg-gray-200">
                <div
                  className={classNames(
                    'h-full rounded-full bg-gray-600',
                    classes.loadingBar,
                  )}
                />
              </div>
            </div>
          </LayoutGap>
        ) : (
          <>
            <div className={classes.flexboxLayout__table}>
              <Table
                id="table-reports"
                columns={columns}
                data={reports}
                query={query}
                updateQuery={updateQuery}
                columnOrder={defaultColumnsList}
                issuerSelected={selectedIssuer}
              />
            </div>
            <div className={classes.flexboxLayout__pagination}>
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Reports;
