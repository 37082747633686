import { Link } from 'react-router-dom';

import MoreInfo from 'refactored/components/Common/MoreInfo';
import Status from 'components/Common/Status';
import Groups from 'enums/users/groups.enum';
import Path from 'enums/path.enum';

type makeColumnsProps = {
  moreInfoHandler: Function;
  issuerId?: string;
};

const transformAuditStatus = (status: string) => {
  switch (status) {
    case 'New':
      return 'new';
    case 'In Progress':
      return 'inProgress';
    case 'Closed':
      return 'closedAudit';
    default:
      return '';
  }
};

const makeColumns = ({ moreInfoHandler, issuerId }: makeColumnsProps) => [
  {
    header: 'Audit Id',
    accessorKey: 'auditId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (
      <Link
        to={
          issuerId
            ? `${Path.TransactionsSupport}/edit/${getValue()}/${issuerId}`
            : `${Path.TransactionsSupport}/edit/${getValue()}/`
        }
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#263FC2] underline"
      >
        {getValue()}
      </Link>
    ),
  },
  {
    header: 'Transaction Id',
    accessorKey: 'transactionId',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Customer ID',
    accessorKey: 'referringPartnerUserId',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => {
      const kind = transformAuditStatus(getValue());

      return kind ? <Status kind={kind} /> : '';
    },
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Issuer',
    accessorKey: 'issuer',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Created Date',
    accessorKey: 'createdDate',
    cell: ({ getValue }) =>
      getValue() ? `${new Date(getValue()).toLocaleString('en-US')} EST` : '-',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Internal Dispute',
    accessorKey: 'internalDispute',
    cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Last Modified',
    accessorKey: 'lastModified',
    cell: ({ getValue }) =>
      getValue() ? `${new Date(getValue()).toLocaleString('en-US')} EST` : '-',
    allowedRoles: Groups.Administrators,
  },
  {
    header: '',
    id: 'moreInfo',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => (
      <MoreInfo onClick={() => moreInfoHandler(row.original)} />
    ),
  },
];

export default makeColumns;
