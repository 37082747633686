import { Option } from '@/components/ui/Form/inputs/Select';
import * as yup from 'yup';

const disable = (option: Option) => ({ ...option, disabled: true });

const activeStatusOption: Option = { value: 'ACTIVE', label: 'Active' };
const inactiveStatusOption: Option = { value: 'INACTIVE', label: 'Inactive' };
const draftStatusOption: Option = { value: 'DRAFT', label: 'Draft' };
const inProgressStatusOption: Option = {
  value: 'IN_PROGRESS',
  label: 'In Progress',
};

export const statusOptions: Option[] = [
  activeStatusOption,
  inactiveStatusOption,
  draftStatusOption,
  inProgressStatusOption,
];

export const draftStatusOptions = [
  draftStatusOption,
  activeStatusOption,
  disable(inactiveStatusOption),
];
export const activeStatusOptions = [
  disable(draftStatusOption),
  activeStatusOption,
  inactiveStatusOption,
];
export const inProgressStatusOptions = [inProgressStatusOption];
export const inactiveStatusOptions = [inactiveStatusOption];

export const segmentTypeOptions: Option[] = [
  { value: 'MUTABLE', label: 'Mutable' },
  { value: 'IMMUTABLE', label: 'Immutable' },
];

export const ruleTypeOptions: Option[] = [
  { value: 'purchases', label: 'Purchases' },
  { value: 'user', label: 'User' },
];

export const operatorOptions: Option[] = [
  { value: 'eq', label: '=' },
  { value: 'gt', label: '>' },
  { value: 'lt', label: '<' },
  { value: 'gte', label: '>=' },
  { value: 'lte', label: '<=' },
];

export const listOperatorOptions: Option[] = [{ value: 'in', label: 'In' }];

export const requiredMessage = `Required`;

export const transactionRuleSchema = yup.object({
  ruleType: yup
    .string()
    .oneOf(['purchases'])
    .required(requiredMessage)
    .default('purchases'),
  transactionCount: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .integer('Must be a whole number')
    .min(0, 'Cannot be negative')
    .required(requiredMessage)
    .default(null),
  operator: yup
    .string()
    .oneOf(operatorOptions.map((option) => option.value))
    .required(requiredMessage)
    .default(''),
  kardMerchantId: yup.string().required(requiredMessage),
  dateRange: yup
    .object({
      from: yup
        .date()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value,
        ),
      to: yup
        .date()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value,
        ),
    })
    .required(requiredMessage)
    .test('range-required', requiredMessage, (value) => {
      const hasBothDates = !!(value.from && value.to);
      return hasBothDates;
    })
    .test('date-range', 'End date must be after start date', (value) => {
      const isInOrder = !!(value.from && value.to && value.from <= value.to);
      return isInOrder;
    })
    .default({
      from: null,
      to: null,
    }),
});

export const userRuleSchema = yup.object({
  ruleType: yup
    .string()
    .oneOf(['user'])
    .required(requiredMessage)
    .default('user'),
  operator: yup.string().oneOf(['in']).required(requiredMessage).default('in'),
  customSegmentId: yup.string().required(requiredMessage).default(''),
});

export const segmentDetailsSchema = yup.object({
  id: yup.string(),
  name: yup.string().required(requiredMessage).default('').trim(),
  status: yup
    .string()
    .oneOf(statusOptions.map((option) => option.value))
    .required(requiredMessage)
    .default('DRAFT'),
  segmentType: yup
    .string()
    .oneOf(segmentTypeOptions.map((option) => option.value))
    .required(requiredMessage)
    .default('IMMUTABLE'),
  rules: yup
    .array(
      yup.lazy((value) => {
        switch (value?.ruleType) {
          case 'purchases':
            return transactionRuleSchema;
          case 'user':
            return userRuleSchema;
          default:
            return yup.mixed().notRequired();
        }
      }),
    )
    .defined()
    .min(1, 'At least one rule is required')
    .default([]),
});

export type SegmentDetailsFormValues = yup.InferType<
  typeof segmentDetailsSchema
>;
