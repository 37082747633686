import Path from 'enums/path.enum';

import Home from 'pages/Home';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import AddOffer from 'pages/AddOffer';
import EditOffer from 'pages/EditOffer';
import MerchantInfo from 'pages/MerchantInfo';
import EditMerchant from 'pages/EditMerchant';
import AddMerchant from 'pages/AddMerchant';
import ReportsRefactor from 'refactored/pages/Reports';
import ForgotPassword from 'pages/ForgotPassword';
import Locations from 'refactored/pages/Locations';
import Merchants from 'refactored/pages/Merchants';
import Offers from 'refactored/pages/Offers';
import PendingTransactionsRefactored from 'refactored/pages/PendingTransactions';
import AudienceRefactored from 'refactored/pages/Audiences';
import SegmentRefactored from 'refactored/pages/Segments';
import PortalUsersRefactored from 'refactored/pages/PortalUsers';
import EditUser from 'pages/EditUser';
import OfferInfo from 'components/Pages/OfferInfo';
import AddAudit from 'pages/AddAudit';
import AuditInfo from 'pages/AuditInfo';
import LocationInfo from 'components/Pages/LocationInfo';
import TransactionsSupportRefactored from 'refactored/pages/TransactionsSupport';
import Profile from 'pages/Profile';
import Settings from 'pages/Settings';
import OfferClone from 'components/Pages/OfferClone';
import MergedOffers from 'pages/MergedOffers';
import PendingTransactionsSummary from 'pages/PendingTransactionsSummary';
import Experiments from 'pages/Experiments';
import CreateExperiment from 'components/Pages/Experiments/ExperimentForm/Create';
import Experiment from 'pages/Experiment';

type getAuthenticatedRoutesProps = {
  isAuthenticated: boolean;
  hasKardAdminRole: boolean;
  isPendingSummaryEnabled: boolean;
};

// NOTE: the order of the routes matters, placing any route AFTER "/" will get matched with "/" and not routed to the new route
export const getAuthenticatedRoutes = ({
  isAuthenticated,
  hasKardAdminRole,
  isPendingSummaryEnabled,
}: getAuthenticatedRoutesProps) => [
  {
    component: AddMerchant,
    path: `${Path.Merchants}/create`,
    condition: isAuthenticated,
  },
  {
    component: EditMerchant,
    path: `${Path.Merchants}/edit/:merchantId?`,
    condition: isAuthenticated,
  },
  {
    component: MerchantInfo,
    path: `${Path.Merchants}/info/:merchantId?`,
    condition: isAuthenticated,
  },
  {
    component: Merchants,
    path: Path.Merchants,
    condition: hasKardAdminRole,
  },
  {
    component: hasKardAdminRole ? Offers : MergedOffers,
    path: `${Path.Offers}/:merchantId?`,
    condition: isAuthenticated,
  },
  {
    component: EditOffer,
    path: `${Path.Offers}/edit/:offerId?`,
    condition: isAuthenticated,
  },
  {
    component: OfferClone,
    path: `${Path.Offers}/clone/:offerId?`,
    condition: isAuthenticated,
  },
  {
    component: OfferInfo,
    path: `${Path.Offers}/info/:offerId?`,
    condition: isAuthenticated,
  },
  {
    component: AddOffer,
    path: `${Path.Offers}/create`,
    condition: isAuthenticated,
  },
  {
    component: Locations,
    path: Path.Locations,
    condition: isAuthenticated,
  },
  {
    component: LocationInfo,
    path: `${Path.Locations}/info/:locationId`,
    condition: isAuthenticated,
  },
  {
    component: TransactionsSupportRefactored,
    path: Path.TransactionsSupport,
    condition: isAuthenticated,
  },
  {
    component: AudienceRefactored,
    path: Path.Audience,
    condition: hasKardAdminRole,
  },
  {
    component: SegmentRefactored,
    path: Path.Segment,
    condition: hasKardAdminRole,
  },
  {
    component: Experiments,
    path: Path.Experiments,
    condition: hasKardAdminRole,
  },
  {
    component: CreateExperiment,
    path: `${Path.Experiments}/create`,
    condition: hasKardAdminRole,
  },
  {
    component: Experiment,
    path: `${Path.Experiments}/:experimentId`,
    condition: hasKardAdminRole,
  },
  {
    component: ReportsRefactor,
    path: Path.Reports,
    condition: isAuthenticated,
  },
  {
    component: isPendingSummaryEnabled
      ? PendingTransactionsSummary
      : PendingTransactionsRefactored,
    condition: hasKardAdminRole,
    path: Path.PendingTransactions,
  },
  {
    component: PendingTransactionsRefactored,
    condition: hasKardAdminRole,
    path: `${Path.PendingTransactions}/:issuerId`,
  },
  {
    component: PortalUsersRefactored,
    condition: hasKardAdminRole,
    path: Path.PortalUsers,
  },
  {
    component: AddAudit,
    condition: isAuthenticated,
    path: `${Path.TransactionsSupport}/create`,
  },
  {
    component: AuditInfo,
    condition: isAuthenticated,
    path: `${Path.TransactionsSupport}/edit/:auditId/:issuerId`,
  },
  {
    component: Profile,
    path: Path.Profile,
    condition: isAuthenticated,
  },
  {
    component: Settings,
    path: Path.Settings,
    condition: !hasKardAdminRole && isAuthenticated,
  },
  {
    component: Home,
    path: Path.Home,
    condition: isAuthenticated,
  },
  {
    component: EditUser,
    condition: hasKardAdminRole,
    path: `${Path.PortalUsers}/edit/:userId`,
  },
];

export const getLoginRoutes = (isAuthenticated: boolean) => [
  {
    component: ForgotPassword,
    condition: !isAuthenticated,
    path: Path.ForgotPassword,
  },
  {
    component: Login,
    condition: !isAuthenticated,
    path: Path.Login,
  },
  {
    component: Logout,
    condition: isAuthenticated,
    path: Path.Logout,
  },
];
