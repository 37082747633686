const tabsTheme = {
  tablist: {
    tabitem: {
      base: 'flex items-center justify-center pb-2 ml-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none',
      styles: {
        default: {
          active: {
            on: 'bg-white text-primaryLight text-sm border-b border-primaryLight',

            off: 'text-gray-500 hover:text-gray-600 ',
          },
        },
      },
    },
  },
};

export default tabsTheme;
