/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Card } from 'flowbite-react';
import ErrorMessage from 'components/Typography/ErrorMessage';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button from 'components/Common/Button';
import { forgotPasswordConfirmation } from 'state/actions/auth';
import {
  selectForgotPasswordState,
  selectForgotPasswordConfirmationState,
} from 'state/selectors/auth';
import showPasswordIcon from 'assets/icons/eye-outline.svg';
import hidePasswordIcon from 'assets/icons/eye-slash-outline.svg';
import PasswordRequirements from 'components/Common/PasswordRequirements';
import kardLogo from 'assets/logos/kard-blue.svg';
import forgotPasswordConfirmationValidationSchema from './ForgotPasswordConfirmation.schema';

const ForgotPasswordConfirmation = () => {
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);

  const { username } = useSelector(selectForgotPasswordState, shallowEqual);

  const { loading, error } = useSelector(
    selectForgotPasswordConfirmationState,
    shallowEqual,
  );

  const [showPassword, setShowPassword] = useState(false);

  const onSubmitHandler = useCallback(
    ({ code, newPassword }) => {
      dispatch(forgotPasswordConfirmation(username, code, newPassword));
    },
    [dispatch, username],
  );

  const onChangeShowPassword = useCallback(
    () => setShowPassword((prevState) => !prevState),
    [],
  );

  return (
    <div className="mb-72 flex w-[499px] flex-col gap-6 p-6">
      <div className="flex items-center justify-center gap-10 pb-6">
        <img className="h-9 w-24" src={kardLogo} alt="kard logo" />
      </div>
      <Card className="rounded-lg shadow-custom">
        <div className="flex flex-col items-start">
          <p className="text-2xl font-[700] leading-tight text-[#111928]">
            Reset your password
          </p>
          <p className="inline text-sm font-medium leading-normal text-gray-500">
            Please enter the 6-digit code found in your email to reset your
            password.
          </p>
        </div>
        <Form
          validationSchema={forgotPasswordConfirmationValidationSchema}
          validationMode={ValidationMode.OnChange}
          onSubmit={onSubmitHandler}
          setValid={setValid}
        >
          <label className="text-sm font-medium text-gray-900">Code</label>
          <FormControl
            name="code"
            render={(props) => (
              <Input
                placeholder=""
                className="mt-2"
                type={InputType.String}
                {...props}
                isFullScreen
              />
            )}
          />
          <label className="text-sm font-medium text-gray-900">
            New password
          </label>
          <div className="relative">
            <FormControl
              name="newPassword"
              render={(props) => (
                <Input
                  placeholder=""
                  className="mt-2"
                  type={showPassword ? InputType.Text : InputType.Password}
                  isFullScreen
                  {...props}
                />
              )}
            />
            <img
              src={showPassword ? hidePasswordIcon : showPasswordIcon}
              alt="hide or show password"
              className="absolute right-[13px] top-[21px] h-4 w-4 cursor-pointer opacity-75"
              onClick={onChangeShowPassword}
            />
          </div>
          <PasswordRequirements />
          <Button
            type="submit"
            loading={loading}
            disabled={!valid}
            className="!m-0"
          >
            Reset Password
          </Button>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPasswordConfirmation;
