/** enums */
import Groups from 'enums/users/groups.enum';

const merchantLocationsMakeColumns = () => [
  {
    header: 'Location ID',
    accessorKey: '_id',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Street',
    accessorKey: 'address.street',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (getValue() ? getValue() : '-'),
  },
  {
    header: 'City',
    accessorKey: 'address.city',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (getValue() ? getValue() : '-'),
  },
  {
    header: 'State',
    accessorKey: 'address.state',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (getValue() ? getValue() : '-'),
  },
  {
    header: 'Zip Code',
    accessorKey: 'address.zipCode',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (getValue() ? getValue() : '-'),
  },
];

export default merchantLocationsMakeColumns;
