import MerchantCategoryCode from 'enums/offers/merchantCategoryCodes.enum';

/**
 * Evaluate whether a given MCC option should be presented when searching.
 * @param {Object} param0 the FormControlSelect option to be filtered.
 * @param {String} input the string to filter by.
 * @returns {Boolean} whether this MCC should be presented as a search option.
 */
export const filterMerchantCategoryCode = ({ label, value }, input) =>
  // suggest nothing if the input is empty.
  input !== '' &&
  (!Number.isNaN(Number(input))
    ? // if search is numeric, only match the beginning.
      value.startsWith(input)
    : // if search is keyword, match within the label.
      label
        .toLowerCase()
        // search just the description
        .split(/\d+\s-\s/)[1]
        .includes(input.trim().toLowerCase()));

/**
 * Formatted MerchantCategoryCode for use in <FormControlSelect>
 */
export const MerchantCategoryCodeOptions = Object.freeze(
  Object.entries(MerchantCategoryCode).map(
    ([mcc, { edited_description: description }]) => ({
      label: `${mcc} - ${description}`,
      value: mcc,
    }),
  ),
);
