import { ElementType } from 'react';
import Path from 'enums/path.enum';
import Redirect from './Redirect';

type RestrictedRouteProps = {
  component: ElementType;
  condition?: boolean;
  redirectTo?: string;
};

const RestrictedRoute = ({
  component: Component,
  condition,
  redirectTo = Path.Login,
}: RestrictedRouteProps) =>
  condition ? <Component /> : <Redirect redirectTo={redirectTo} />;

export default RestrictedRoute;
