import { useState } from 'react';
import { Pagination } from 'flowbite-react';

import Button from 'components/Common/Button';
import LayoutGap from 'components/Common/LayoutGap';
import { Query } from 'types';

type PaginationComponentProps = {
  query: Query;
  updateQuery: Function;
  countTotal: number;
};

const DEFAULT_LIMIT_PAGE = 10;

const PaginationComponent = ({
  query,
  updateQuery,
  countTotal,
}: PaginationComponentProps) => {
  const [currentPage, setCurrentPage] = useState(
    query.pagination?.page ? query.pagination.page + 1 : 1,
  );
  const totalPages = Math.ceil(
    countTotal /
      (query.pagination?.limit ? query.pagination.limit : DEFAULT_LIMIT_PAGE),
  );
  const [navigationPage, setNavigationPage] = useState(currentPage);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    updateQuery({
      ...query,
      pagination: { ...query.pagination, page: page - 1 },
    });
  };

  const getShowingFrom = () => {
    if (countTotal === 0) {
      return 0;
    }

    if (currentPage === 1) {
      return 1;
    }

    const limit = query.pagination?.limit
      ? query.pagination.limit
      : DEFAULT_LIMIT_PAGE;

    return (currentPage - 1) * limit + 1;
  };

  const getShowingTo = () => {
    if (countTotal === 0) {
      return 0;
    }

    if (currentPage === totalPages) {
      return countTotal;
    }

    const limit = query.pagination?.limit
      ? query.pagination.limit
      : DEFAULT_LIMIT_PAGE;

    return currentPage * limit;
  };

  const setPage = (page: number) => {
    if (!page) {
      return;
    }
    if (page < 1) {
      setNavigationPage(1);
      return;
    }
    if (page > totalPages) {
      setNavigationPage(totalPages);
      return;
    }
    setNavigationPage(page);
  };

  return (
    <LayoutGap className="items-center justify-between p-4">
      <div>
        Showing
        <span className="font-bold">
          {' '}
          {getShowingFrom()} - {getShowingTo()}{' '}
        </span>
        of <span className="font-bold">{countTotal}</span> records on page
        <span className="font-bold"> {currentPage}</span> of
        <span className="font-bold"> {totalPages}</span>
      </div>
      <LayoutGap>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          showIcons
          previousLabel=""
          nextLabel=""
        />
        <LayoutGap className="items-center">
          Go to page
          <input
            type="number"
            value={navigationPage}
            className="w-12 appearance-none rounded-md border border-gray-300 px-0 py-1 text-center"
            onChange={(e) => setPage(Number(e.target.value))}
            onBlur={(e) => setPage(Number(e.target.value))}
          />
          <Button
            className="flex-shrink-0"
            variant="tertiary"
            type="button"
            onClick={() => onPageChange(navigationPage)}
            disabled={!navigationPage}
          >
            Go
          </Button>
        </LayoutGap>
      </LayoutGap>
    </LayoutGap>
  );
};

export default PaginationComponent;
