import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const createOffer = async (body) => {
  try {
    const response = await client.post(
      `${REWARDS_BASE_URL}/portal/merchant/offer`,
      body,
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createOffer;
