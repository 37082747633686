import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/* components */
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Button from 'components/Common/Button';
import Checkbox, { Size as CheckboxSize } from 'components/Common/Checkbox';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import FormControlDatePicker from 'components/Common/FormControlDatePicker';
import Textarea from 'components/Common/Textarea';

/* redux */
import { clearOffersSuccess, createOffer } from 'state/actions/offers';
import { selectCreateOfferState } from 'state/selectors/offers';
import { selectFetchMerchantNetworksState } from 'state/selectors/merchantNetworks';
import { fetchMerchantNetworks } from 'state/actions/merchantNetworks';

/* enums */
import StatusValue from 'enums/status/statusValue.enum';
import { CommissionType } from 'enums/offers/commissionTypes.enum';
import Path from 'enums/path.enum';

/* assets */
import dollarIcon from 'assets/icons/dollar.png';
import percentIcon from 'assets/icons/percent.png';

/* utils */
import { defaultStatusOptions } from 'utils/common/values';
import {
  MerchantCategoryCodeOptions,
  filterMerchantCategoryCode,
} from 'utils/offers/merchantCategoryCodes';
import { commissionTypes, offerTypes, sources } from 'utils/offers/values';
import Fieldset from 'components/Common/Fieldset';
import PageHeader from 'components/Common/PageHeader';
import LayoutGap from 'components/Common/LayoutGap';
import CommissionValueField from '../OfferEdit/CommissionValueField';
import {
  shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker,
  shiftDateFromDatePickerBackToUTC,
} from '../OfferEdit/utils';

import validationSchema from '../OfferEdit/OfferEdit.schema';
import classes from '../OfferEdit/OfferEdit.module.scss';

const SELECT_ALL_WHITESPACE_REGEX = /\s/g;

const CommissionTypeIcon = Object.freeze({
  [CommissionType.Percent]: percentIcon,
  [CommissionType.Flat]: dollarIcon,
});

const OfferClone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const offer = location?.state?.currentValue;

  const { merchantNetworks } = useSelector(
    selectFetchMerchantNetworksState,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchMerchantNetworks());
  }, [dispatch]);

  const merchantNetworkOptions = merchantNetworks.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  const {
    loading: loadingCreateOffer,
    error: errorCreate,
    success: successCreateOffer,
    createdOffer,
  } = useSelector(selectCreateOfferState, shallowEqual);

  const [locationMissingError] = useState(!offer.merchantLocationIds?.length);
  const [locationSpecific, setLocationSpecific] = useState(
    offer.isLocationSpecific,
  );
  const [optInRequired, setOptInRequired] = useState(offer.optInRequired);
  const [redeemableOnce, setRedeemableOnce] = useState(offer.redeemableOnce);
  const [displayAlways, setDisplayAlways] = useState(
    offer.displayAlways ?? false,
  );
  const [selectedCommissionType, setSelectedCommissionType] = useState(
    offer?.commissionType,
  );

  // start and expiration date are stored "2021-07-06T20:16:29.693+00:00" as UTC Time
  // we want to display as Date object "Jul 06 2021 20:16:29 " without converting to local time.
  const [expDate, setExpDate] = useState(
    shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker(offer.expirationDate),
  );
  const [startDateUTC, setStartDateUTC] = useState(
    shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker(offer.startDate),
  );

  // navigate to edit form of the newly cloned offer after successful creation
  useEffect(() => {
    if (successCreateOffer) {
      dispatch(clearOffersSuccess());
      navigate(`${Path.Offers}/edit/${createdOffer._id}`);
    }
  }, [dispatch, successCreateOffer, createdOffer, navigate]);

  const formRef = useRef('');

  const onCancelHandler = useCallback(() => {
    // Go back to original offer on cancel
    navigate(`${Path.Offers}/edit/${offer._id}`);
  }, [navigate, offer._id]);

  const onChangeCheckboxStateHandler = useCallback((setState) => {
    setState((prevState) => !prevState);
  }, []);

  const onChangeStatusHandler = useCallback(
    (newStatus) => {
      // If changing status from Active -> Inactive, set the expDate to today
      setExpDate(
        newStatus === StatusValue.Inactive
          ? new Date()
          : shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker(
              offer.expirationDate,
            ),
      );
    },
    [offer.expirationDate],
  );

  const isValidNumber = useCallback(
    (value) => value !== undefined && value >= 0,
    [],
  );

  // Format as Date Object
  const formatStartDate = offer.startDate ? startDateUTC : undefined;

  const formatExpirationDate = offer.expirationDate ? expDate : undefined;

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        ...values,
        status: values.status,
        isLocationSpecific: locationSpecific,
        optInRequired,
        startDate: shiftDateFromDatePickerBackToUTC(values.startDate), // Format as UTC String
        expirationDate: shiftDateFromDatePickerBackToUTC(values.expirationDate), // Format as UTC String
        merchantId:
          values.merchantId.replace(SELECT_ALL_WHITESPACE_REGEX, '') ?? '',
        redeemableOnce,
        commissionValue: Number(values.commissionValue),
        minTransactionAmount: Number(values.minTransactionAmount),
        maxTransactionAmount: Number(values.maxTransactionAmount),
        minRewardAmount: Number(values.minRewardAmount),
        maxRewardAmount: Number(values.maxRewardAmount),
        minUserRewardAmount: Number(values.minUserRewardAmount),
        maxUserRewardAmount: Number(values.maxUserRewardAmount),
        mcc: values.mcc ?? [],
        displayAlways,
        merchantLocationIds: offer.merchantLocationIds,
        qualifiedIssuer: [],
        issuerCommissionsMap: {},
        userCommissionsMap: {},
        isCustom: false,
      };

      if (values.controlGroupPercentage) {
        body.controlGroupPercentage = Number(values.controlGroupPercentage);
      }

      delete body.category;
      delete body.merchantName;
      delete body.offerId;
      delete body._id;
      delete body.createdDate;
      delete body.lastModified;
      delete body.merchant;
      delete body.isTargeted;
      delete body.audiences;
      delete body.segments;
      delete body.controlGroupPercentage;
      delete body.experimentBuildStatus;

      if (!values.terms) {
        delete body.terms;
      }

      if (!isValidNumber(values.minTransactionAmount)) {
        delete body.minTransactionAmount;
      }

      if (!isValidNumber(values.maxTransactionAmount)) {
        delete body.maxTransactionAmount;
      }

      if (!isValidNumber(values.minRewardAmount)) {
        delete body.minRewardAmount;
      }

      if (!isValidNumber(values.maxRewardAmount)) {
        delete body.maxRewardAmount;
      }

      if (!isValidNumber(values.minUserRewardAmount)) {
        delete body.minUserRewardAmount;
      }

      if (!isValidNumber(values.maxUserRewardAmount)) {
        delete body.maxUserRewardAmount;
      }

      if (!values.startDate) {
        delete body.startDate;
      }

      dispatch(createOffer(body));
    },
    [
      offer,
      locationSpecific,
      optInRequired,
      redeemableOnce,
      dispatch,
      isValidNumber,
      displayAlways,
    ],
  );

  const commissionTypeIcon = CommissionTypeIcon[selectedCommissionType];

  return (
    <>
      {errorCreate && (
        <Toast
          id="error create offer"
          text={errorCreate}
          type={ToastType.Error}
        />
      )}
      {successCreateOffer && (
        <Toast
          id="success-creating-offer"
          text="Offer created successfully!"
          type={ToastType.Success}
        />
      )}
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
        className="ml-10 mt-10"
        ref={formRef}
        defaultValues={offer}
      >
        <div>
          <PageHeader title="Clone Offer" className="mr-[2.5rem]">
            <Button type="submit" loading={loadingCreateOffer}>
              Clone offer
            </Button>
            <Button variant="secondary" onClick={onCancelHandler}>
              Cancel
            </Button>
          </PageHeader>
          <Fieldset title="Offer Details">
            <LayoutGap direction="column" size="none">
              <FormControl
                name="offerId"
                render={(props) => (
                  <Input
                    label="Original Offer ID:"
                    labelClassName={classes.labelWidth}
                    value={offer._id}
                    type={InputType.Text}
                    readOnly
                    size={InputSize.S}
                    disabled
                    {...props}
                  />
                )}
              />
              <FormControl
                name="merchantId"
                render={(props) => (
                  <Input
                    label="Merchant ID:"
                    labelClassName={classes.labelWidth}
                    value={offer.merchantId}
                    type={InputType.Text}
                    readOnly
                    size={InputSize.S}
                    disabled
                    {...props}
                  />
                )}
              />
              <>
                <FormControl
                  name="merchantName"
                  render={(props) => (
                    <Input
                      label="Merchant Name:"
                      labelClassName={classes.labelWidth}
                      value={offer.merchantName}
                      type={InputType.Text}
                      readOnly
                      size={InputSize.S}
                      disabled
                      {...props}
                    />
                  )}
                />
              </>
              <FormControl
                name="name"
                render={(props) => (
                  <Input
                    label="Offer Name:"
                    labelClassName={classes.labelWidth}
                    value={offer.name}
                    type={InputType.Text}
                    size={InputSize.S}
                    color={InputColor.Black}
                    {...props}
                  />
                )}
              />
              <FormControlDatePicker
                name="startDate"
                label="Start Date:"
                labelClassName={classes.labelWidth}
                defaultValue={formatStartDate}
                color={SelectColor.Black}
                showTimeSelect
                onChangeManual={setStartDateUTC}
                isUTC
                testId="start-date-picker"
              />

              <FormControlDatePicker
                name="expirationDate"
                label="Expiration Date:"
                labelClassName={classes.labelWidth}
                defaultValue={formatExpirationDate}
                color={SelectColor.Black}
                showTimeSelect
                onChangeManual={setExpDate}
                isUTC
                testId="expiration-date-picker"
              />
              <span
                data-tooltip-id="locationsError"
                data-tooltip-content="Please associate locations with this offer before activating it."
              >
                <FormControlSelect
                  name="status"
                  defaultValue={StatusValue.Inactive}
                  label="Status"
                  labelClassName={classes.labelWidth}
                  readOnly={locationMissingError}
                  options={defaultStatusOptions}
                  color={SelectColor.Black}
                  onChangeManual={onChangeStatusHandler}
                />
              </span>
              <FormControlSelect
                name="source"
                defaultValue={offer.source}
                label="Source:"
                labelClassName={classes.labelWidth}
                options={sources}
                color={SelectColor.Black}
                testId="source-input"
              />
              <FormControlSelect
                name="offerType"
                defaultValue={offer.offerType}
                label="Offer Type:"
                labelClassName={classes.labelWidth}
                options={offerTypes}
                color={SelectColor.Black}
                testId="offer-type-input"
              />
              <FormControlSelect
                name="merchantNetwork"
                label="Merchant Network:"
                labelClassName={classes.labelWidth}
                defaultValue={offer.merchantNetwork}
                options={merchantNetworkOptions}
                color={SelectColor.Black}
                testId="merchant-network-input"
              />
              <Checkbox
                label="Location Specific"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setLocationSpecific)
                }
                selected={locationSpecific}
                className={classes.checkbox}
                size={CheckboxSize.S}
              />

              <FormControlSelect
                filterOption={filterMerchantCategoryCode}
                name="mcc"
                defaultValue={offer.mcc}
                label="Merchant Category Codes:"
                labelClassName={classes.labelWidth}
                options={MerchantCategoryCodeOptions}
                isMulti
                allowSelectAll={false}
                noOptionsMessage={() =>
                  'Start typing MCC number or keyword for suggestions...'
                }
                color={SelectColor.Black}
              />
              <FormControl
                name="terms"
                render={(props) => (
                  <Textarea
                    label="Terms And Conditions:"
                    labelClassName={classes.labelWidth}
                    value={offer.terms}
                    preventNewLines
                    size={InputSize.S}
                    color={InputColor.Black}
                    className="mb-[0]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
          </Fieldset>

          <Fieldset title="Commission Details">
            <LayoutGap>
              <FormControlSelect
                name="commissionType"
                defaultValue={offer.commissionType}
                label="Commission Type"
                labelClassName={classes.labelWidth}
                options={commissionTypes}
                color={SelectColor.Black}
                onChangeManual={setSelectedCommissionType}
                testId="commission-type-input"
                className="mb-[0] w-[15rem]"
              />
              <CommissionValueField
                currentValue={offer}
                isEditing
                commissionTypeIcon={commissionTypeIcon}
              />
            </LayoutGap>
          </Fieldset>
          <Fieldset title="Redemption Details">
            <LayoutGap>
              <FormControl
                name="minTransactionAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min Txn Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={offer.minTransactionAmount}
                    type={InputType.Number}
                    color={InputColor.Black}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxTransactionAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max Txn Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={offer.maxTransactionAmount}
                    type={InputType.Number}
                    color={InputColor.Black}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap>
              <FormControl
                name="minRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={offer.minRewardAmount}
                    type={InputType.Number}
                    color={InputColor.Black}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={offer.maxRewardAmount}
                    type={InputType.Number}
                    color={InputColor.Black}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap>
              <FormControl
                name="minUserRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min User Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={offer.minUserRewardAmount}
                    type={InputType.Number}
                    color={InputColor.Black}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxUserRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max User Reward Amount In Cents"
                    labelClassName={classes.labelWidth}
                    value={offer.maxUserRewardAmount}
                    type={InputType.Number}
                    color={InputColor.Black}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap direction="column" size="none">
              <Checkbox
                label="Opt-in Required"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setOptInRequired)
                }
                selected={optInRequired}
                className={classes.checkbox}
                size={CheckboxSize.S}
              />
              <Checkbox
                label="Redeemable Once"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setRedeemableOnce)
                }
                selected={redeemableOnce}
                className={classes.checkbox}
                size={CheckboxSize.S}
              />
              <Checkbox
                label="Always Appears to Cardholder"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setDisplayAlways)
                }
                selected={displayAlways}
                className={classes.checkbox}
                size={CheckboxSize.S}
              />
            </LayoutGap>
          </Fieldset>
        </div>
      </Form>
      {locationMissingError && <ReactTooltip id="locationsError" />}
    </>
  );
};

export default OfferClone;
