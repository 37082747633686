/* eslint-disable react/button-has-type */
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';

const baseClasses = [
  'inline-flex',
  'overflow-hidden',
  'items-center',
  'justify-center',
  'leading-[1.25]',
  'gap-[0.25em]',
  'px-[0.75em]',
  'py-[0.5em]',
  'rounded-lg',
  'transition-colors',
  'duration-200',
  'ease-in-out',
  'border',
  'border-transparent',
  'cursor-pointer',

  // Focus
  'focus:outline-2',
  'focus:outline-offset-4',
  'focus:outline-primary',

  // Disabled
  'disabled:opacity-50',
  'disabled:cursor-not-allowed',
];

type Size = 'large' | 'medium' | 'small';
type Variant = 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'text';

const variantClasses: Record<Variant, string[]> = {
  primary: [
    'bg-primary text-white',
    'hover:bg-primary600',
    'active:bg-primary700',
    'disabled:bg-primary',
  ],
  secondary: [
    'bg-white text-gray-900 !border-gray-300',
    'hover:bg-gray-100',
    'active:bg-gray-200',
    'disabled:bg-white',
  ],
  tertiary: [
    'bg-gray100 text-gray-900',
    'hover:bg-gray-200',
    'active:bg-gray-300',
    'disabled:bg-gray-100',
  ],
  destructive: [
    'bg-red600 text-white',
    'hover:bg-red700',
    'active:bg-red800',
    'focus:outline-red600',
    'disabled:bg-red600',
  ],
  text: [
    'bg-transparent text-primary !p-0 !rounded-none !border-none',
    'hover:underline',
    'disabled:no-underline',
  ],
};

const sizeClasses: Record<Size, string[]> = {
  large: ['text-lg'],
  medium: ['text-sm'],
  small: ['text-xs'],
};

type Modifiers = {
  fullWidth?: boolean;
};
const modifierClasses = ({ fullWidth }: Modifiers) => ({
  'w-full': fullWidth,
});

// TODO: refactor spinner to inherit text color
const spinnerColor: Record<Variant, SpinnerColor> = {
  primary: SpinnerColor.White,
  secondary: SpinnerColor.Black,
  tertiary: SpinnerColor.Black,
  destructive: SpinnerColor.White,
  text: SpinnerColor.Black,
};

// TODO refactor spinner to inherit text size
const spinnerSize: Record<Size, SpinnerSize> = {
  large: SpinnerSize.L,
  medium: SpinnerSize.M,
  small: SpinnerSize.S,
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Defines the button's visual style
   *
   * # **`primary`**
   * Used for the primary call-to-action on the page.
   *
   * # **`secondary`**
   * Used for secondary/repetitive actions on the page.
   *
   * # **`tertiary`**
   * Used when there is a third action on the page.
   *
   * # **`destructive`**
   * Used for actions that result in irreversible changes or deletion of data, requiring clear user intent.
   *
   * # **`text`**
   * Used for inline actions alongside text content.
   */
  variant?: Variant;
  /** Sets the button's size */
  size?: Size;
  /** Icon to be displayed inside the button */
  icon?: ReactNode;
  /** Shows a loading spinner inside the button and disables it */
  loading?: boolean;
  /**
   * Test ID used by Cypress. Renders as `data-cy` on the button element.
   * @deprecated Pass data-* attributes instead
   */
  testId?: string;
  /** If true, the button will span the full width of its container */
  fullWidth?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'medium',
      variant = 'primary',
      type = 'button',
      className,
      loading,
      disabled,
      icon,
      children,
      testId,
      ...props
    },
    ref,
  ) => (
    <button
      className={classNames(
        ...baseClasses,
        ...variantClasses[variant],
        ...sizeClasses[size],
        modifierClasses(props),
        className,
      )}
      type={type}
      data-cy={testId}
      disabled={disabled || loading}
      ref={ref}
      {...props}
    >
      {loading ? (
        <Spinner
          color={spinnerColor[variant]}
          size={spinnerSize[size]}
          className="px-[0.25em]"
        />
      ) : (
        icon && (
          <span className="flex min-h-[1.25em] flex-shrink items-center">
            {icon}
          </span>
        )
      )}
      {children && <span className="truncate">{children}</span>}
    </button>
  ),
);

export default Button;
