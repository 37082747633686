import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootReducer from 'state/reducers';
import importMeta from 'utils/vite/importMeta';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

const { env, hot } = importMeta();

if (env.MODE === 'development' && hot) {
  hot.accept('state/reducers', async () => {
    // eslint-disable-next-line global-require
    const newRootReducer = await import('state/reducers');
    store.replaceReducer(newRootReducer);
  });
}

export default store;
