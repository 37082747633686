import React from 'react';
import PropTypes from 'prop-types';

/* components */
import { Popover } from '@headlessui/react';

/* styles and assets */
import threeDots from 'assets/icons/dots-horizontal.svg';
import envelope from 'assets/icons/envelope.svg';
import editUser from 'assets/icons/edit-user.svg';
import classes from './MoreInfoComponent.module.scss';

const MoreInfoComponent = ({ editHandler, resendHandler }) => (
  <Popover className={classes.popover}>
    <Popover.Button className={classes.panelButton}>
      <img src={threeDots} alt="More information" />
    </Popover.Button>
    <Popover.Panel className={classes.panelContainer}>
      <div className={classes.panelContent}>
        <button
          onClick={editHandler}
          type="button"
          className={classes.panelItem}
        >
          <img src={editUser} alt="Resend Invitation" />
          Edit invitation
        </button>
        <button
          onClick={resendHandler}
          type="button"
          className={classes.panelItem}
        >
          <img src={envelope} alt="Resend Invitation" />
          Resend invitation
        </button>
      </div>
    </Popover.Panel>
  </Popover>
);

export default MoreInfoComponent;

MoreInfoComponent.propTypes = {
  editHandler: PropTypes.func,
  resendHandler: PropTypes.func,
};

MoreInfoComponent.defaultProps = {
  editHandler: () => {},
  resendHandler: () => {},
};
