import { jwtDecode } from 'jwt-decode';
import { useState, useEffect } from 'react';

import fetchTableauJWT from 'services/tableau/fetchTableauJWT';

function useTableauJWT() {
  const [tableauJWT, setTableauJWT] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchJWT = async () => {
    setIsLoading(true);
    const token = await fetchTableauJWT();
    setTableauJWT(token);
    setIsLoading(false);
  };

  const checkTokenExpiration = async () => {
    if (!tableauJWT || isLoading) return;

    const decodedToken = jwtDecode(tableauJWT);
    const currentTime = Date.now() / 1000;
    const tokenExpiration = decodedToken?.exp;

    if (tokenExpiration && tokenExpiration < currentTime) {
      await fetchJWT();
    }
  };

  useEffect(() => {
    fetchJWT();
  }, []);

  return { tableauJWT, isLoading, checkTokenExpiration };
}

export default useTableauJWT;
