/* modules */
import { Link } from 'react-router-dom';

/* custom components */
import Status from 'components/Common/Status';
import MoreInfo from 'refactored/components/Common/MoreInfo';

/* enums */
import Groups from 'enums/users/groups.enum';

/* utils */
import dateParse from 'utils/common/dateParse';
import Path from 'enums/path.enum';

import { ColumnProps } from 'refactored/components/Common/Table';

type makeColumnsProps = {
  moreInfoHandler: Function;
};

const makeColumns = ({ moreInfoHandler }: makeColumnsProps): ColumnProps[] => [
  {
    header: 'Merchant ID',
    accessorKey: 'merchantId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => (
      <Link
        to={`${Path.Merchants}/edit/${getValue()}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#263FC2] underline"
      >
        {getValue()}
      </Link>
    ),
  },
  {
    header: 'Name',
    accessorKey: 'name',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Source',
    accessorKey: 'source',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Merchant Network',
    accessorKey: 'merchantNetwork',
    allowedRoles: Groups.Administrators,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Created Date',
    accessorKey: 'createdDate',
    allowedRoles: Groups.Internal,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }: any) => (getValue() ? dateParse(getValue()) : '-'),
  },
  {
    header: 'Status',
    accessorKey: 'status',
    allowedRoles: Groups.Everyone,
    meta: {
      tooltip: () => '',
      sortable: true,
    },
    cell: ({ getValue }: any) =>
      getValue() ? <Status kind={getValue().toLowerCase()} /> : '',
  },
  {
    header: 'Website URL',
    accessorKey: 'websiteURL',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Category',
    accessorKey: 'category',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'More info',
    id: 'moreInfo',
    allowedRoles: Groups.Administrators,
    cell: ({ row }: any) => (
      <MoreInfo onClick={() => moreInfoHandler(row.original)} />
    ),
  },
];

export default makeColumns;
