import classNames from 'classnames';
import classes from './Status.module.scss';

export const Types = Object.freeze({
  active: 'Active',
  inactive: 'Inactive',
  closed: 'Closed',
  deleted: 'Deleted',
  draft: 'Draft',
  error: 'Error',
  disabled: 'Disabled',
  enabled: 'Enabled',
  new: 'New',
  inProgress: 'In Progress',
  closedAudit: 'Closed', // this was necessary since they want to use a different color for closed audits
  upcoming: 'Upcoming',
} as const);

type StatusProps = {
  kind: keyof typeof Types;
  className?: string;
};

const Status = ({ kind = 'active', className }: StatusProps) => (
  /**
   * dev notes: i'm using nested span because first span is using pseudo-class ::before
   * which displays a dot (for style proposes only) and needed to move label to the right
   */
  <span className={classNames(classes.status, classes[kind], className)}>
    <span title={Types[kind]} className={classes.label}>
      {Types[kind]}
    </span>
  </span>
);

export default Status;
