import {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseFormRegister, FieldValues } from 'react-hook-form';

/* components */
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  IconPosition,
} from 'refactored/components/Common/Input';
import Button from 'components/Common/Button';
import Checkbox, { Size as CheckboxSize } from 'components/Common/Checkbox';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Textarea, { Color as TextareaColor } from 'components/Common/Textarea';
import InputImage from 'components/Common/InputImage';

/* redux */
import {
  createMerchant,
  fetchMerchantCategories,
  fetchMerchantIssuers,
} from 'state/actions/merchants';
import {
  selectCreateMerchantState,
  selectFetchMerchantCategoriesState,
  selectFetchMerchantIssuersState,
} from 'state/selectors/merchants';
import { selectFetchMerchantNetworksState } from 'state/selectors/merchantNetworks';
import { fetchMerchantNetworks } from 'state/actions/merchantNetworks';

/* utils */
import {
  merchantSources,
  merchantTypes,
  merchantAcceptedCards,
} from 'utils/merchants/values';
import { defaultStatusOptions } from 'utils/common/values';

import type { SelectableOption } from 'types';
import type {
  MerchantNetworkResponse,
  EditCreateMerchantResponse,
  IssuersResponse,
  CategoriesResponse,
} from 'types/responses';

/* enums/constants */
import LabelPosition from 'enums/inputProps';
import Path from 'enums/path.enum';

import PageHeader from 'components/Common/PageHeader';
import DisplayRegex from '@/components/ui/DisplayRegex';
import LayoutGap from '@/components/Common/LayoutGap';
import validationSchema from './MerchantCreate.schema';
import classes from './MerchantCreate.module.scss';

type MerchantCreateProps = {
  onCancel: () => void;
};

type RenderType = {
  name: string;
  error: boolean;
  register: UseFormRegister<FieldValues>;
};

type SubmitValuesType = {
  name: string;
  description: string;
  source: string | null;
  type: string | null;
  merchantNetwork: string | null;
  acceptedCards: string[] | null;
  qualifiedIssuer: string[] | null;
  category: string | null;
  websiteURL: string | null;
  imgUrl: string | null;
  fraudWarningAmountInCents: number | null;
  [key: string]: unknown | undefined; // the remaining not required fields
};

const MerchantCreate = ({ onCancel }: MerchantCreateProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allowSave, setAllowSave] = useState(false);

  // selectors
  const { merchantNetworks }: MerchantNetworkResponse = useSelector(
    selectFetchMerchantNetworksState,
    shallowEqual,
  );

  const { categories, loading: loadingMerchantCategories }: CategoriesResponse =
    useSelector(selectFetchMerchantCategoriesState, shallowEqual);

  const { issuers }: IssuersResponse = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual,
  );

  const {
    loading: loadingCreate,
    success: createMerchantSuccess,
    error: errorCreate,
  }: EditCreateMerchantResponse = useSelector(
    selectCreateMerchantState,
    shallowEqual,
  );

  // internal states
  const [merchantDesc, setMerchantDesc] = useState('');
  const [realTimeMatch, setRealTimeMatch] = useState(true);
  const [reviewMatches, setReviewMatches] = useState(false);
  const [categoriesOptions, setCategoriesOptions] = useState<
    SelectableOption[]
  >([]);
  const [issuersOptions, setIssuersOptions] = useState<SelectableOption[]>([]);
  const [associatedNames, setAssociatedNames] = useState<string>('');
  const [excludedNames, setExcludedNames] = useState<string>('');

  // use effects
  useEffect(() => {
    dispatch(fetchMerchantNetworks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantCategories());
    dispatch(fetchMerchantIssuers());
  }, [dispatch]);

  useEffect(() => {
    if (categories.length > 0 && categoriesOptions.length === 0) {
      const transformedCategories = categories.map((category) => ({
        label: category,
        value: category,
      }));
      setCategoriesOptions(transformedCategories);
    }
  }, [categories, categoriesOptions]);

  useEffect(() => {
    if (issuers.length > 0 && issuersOptions.length === 0) {
      const transformedIssuers = issuers.map((issuer) => ({
        label: issuer,
        value: issuer,
      }));
      setIssuersOptions(transformedIssuers);
    }
  }, [issuers, issuersOptions]);

  useEffect(() => {
    if (createMerchantSuccess) {
      navigate(Path.Merchants);
    }
  }, [createMerchantSuccess, navigate]);

  // const
  const merchantNetworkOptions = merchantNetworks.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  const defaultMerchantAcceptedCards = useMemo(
    () => merchantAcceptedCards.map(({ value }) => value),
    [],
  );

  // handlers
  const onChangeCheckboxStateHandler = useCallback(
    (setState: Dispatch<SetStateAction<boolean>>) =>
      setState((prevState) => !prevState),
    [],
  );

  const onChangeMerchantDesc = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setMerchantDesc(e.target.value);

  const onSubmitHandler = useCallback(
    (values: SubmitValuesType) => {
      const valuesCopy = { ...values };

      delete valuesCopy.associatedName;
      delete valuesCopy.excludedNames;
      // TODO: can the form validation remove this field instead of manually removing empty optional fields here?
      if (valuesCopy.bannerImgUrl === '') {
        delete valuesCopy.bannerImgUrl;
      }

      if ((realTimeMatch && values?.associatedName) || !realTimeMatch) {
        const body = {
          ...valuesCopy,
          realTimeMatch,
          nameMatchRegex: values?.associatedName
            ? [values?.associatedName]
            : [],
          excludeRegex: values?.excludedNames ? [values?.excludedNames] : [],
          specificIssuers: false,
          reviewMatches,
          merchantNameReviewed: false,
        };
        dispatch(createMerchant(body));
      }
    },
    [dispatch, realTimeMatch, reviewMatches],
  );

  return (
    <>
      {errorCreate && (
        <Toast
          id="error create merchant"
          text={errorCreate}
          type={ToastType.Error}
        />
      )}
      <Form
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnBlur}
        onValidationUpdate={setAllowSave}
      >
        <div>
          <PageHeader title="Create Merchant" className="mb-[1rem] mr-[2.5rem]">
            <Button type="submit" loading={loadingCreate} disabled={!allowSave}>
              Create merchant
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </PageHeader>
          <FormControl
            name="name"
            render={(props: RenderType) => (
              <Input
                label="Name*"
                iconPosition={IconPosition.Right}
                {...props}
              />
            )}
          />
          <FormControl
            name="description"
            render={(props: RenderType) => (
              <Textarea
                label="Description*"
                preventNewLines
                value={merchantDesc}
                onChange={onChangeMerchantDesc}
                color={TextareaColor.Gray}
                showCharacterLength
                {...props}
              />
            )}
          />
          <FormControlSelect
            name="status"
            label="Status*"
            color={SelectColor.Gray}
            options={defaultStatusOptions}
            placeholder="Select status..."
          />
          <FormControlSelect
            name="source"
            label="Source*"
            options={merchantSources}
            color={SelectColor.Gray}
            testId="source-input"
            placeholder="Select source..."
          />
          <FormControlSelect
            name="merchantNetwork"
            label="Merchant Network*"
            options={merchantNetworkOptions}
            color={SelectColor.Gray}
            testId="merchant-network-input"
            placeholder="Select merchant network..."
          />
          <FormControlSelect
            name="type"
            label="Type*"
            options={merchantTypes}
            color={SelectColor.Gray}
            testId="type-input"
            placeholder="Select type..."
          />
          <FormControlSelect
            name="category"
            label="Category*"
            options={categoriesOptions}
            disabled={loadingMerchantCategories}
            color={SelectColor.Gray}
            testId="category-input"
            placeholder="Select category..."
          />
          <FormControlSelect
            name="acceptedCards"
            label="Accepted Cards"
            defaultValue={defaultMerchantAcceptedCards}
            options={merchantAcceptedCards}
            isMulti
            color={SelectColor.Gray}
            testId="accepted-cards-input"
          />
          <FormControlSelect
            name="qualifiedIssuer"
            label="Qualified Issuers*"
            options={issuersOptions}
            isMulti
            color={SelectColor.Gray}
            testId="qualified-issuer-input"
            placeholder="Select issuers..."
          />
          <FormControl
            name="websiteURL"
            render={(props: RenderType) => (
              <Input
                label="Website URL*"
                iconPosition={IconPosition.Right}
                {...props}
              />
            )}
          />
          <FormControl
            name="fraudWarningAmountInCents"
            className={classes.formControl}
            render={(props: RenderType) => (
              <>
                <Input
                  label="Max Allowable Transaction Amount In Cents for Notification*"
                  type={InputType.Number}
                  iconPosition={IconPosition.Right}
                  min={0}
                  {...props}
                />
                <span className="text-14 text-gray-500">
                  Flag for potential fraud when a transaction is over this
                  amount
                </span>
              </>
            )}
          />
          <LayoutGap className="flex-col lg:flex-row">
            <FormControl
              name="associatedName"
              render={(props: RenderType) => (
                <Input
                  label="Matching Regular Expressions"
                  defaultValue={associatedNames}
                  type={InputType.Text}
                  iconPosition={IconPosition.Right}
                  placeholder="No names created"
                  {...props}
                  onChange={(e) => setAssociatedNames(e.target.value)}
                />
              )}
            />
            <div>
              <DisplayRegex value={associatedNames} />
            </div>
          </LayoutGap>
          <LayoutGap className="flex-col lg:flex-row">
            <FormControl
              name="excludedNames"
              render={(props: RenderType) => (
                <Input
                  label="Excluded Regular Expressions"
                  type={InputType.Text}
                  iconPosition={IconPosition.Right}
                  defaultValue={excludedNames}
                  onChange={(e) => setExcludedNames(e.target.value)}
                  placeholder="No expressions created"
                  {...props}
                />
              )}
            />
            <div>
              <DisplayRegex value={excludedNames} />
            </div>
          </LayoutGap>
          <Checkbox
            label="Manual Review"
            testId="manual-review-checkbox"
            setSelected={() => onChangeCheckboxStateHandler(setReviewMatches)}
            selected={reviewMatches}
            className={classes.checkbox}
            size={CheckboxSize.XS}
          />
          <Checkbox
            label="Real Time Match"
            setSelected={() => onChangeCheckboxStateHandler(setRealTimeMatch)}
            selected={realTimeMatch}
            className={classes.checkbox}
            size={CheckboxSize.XS}
            associatedNames={associatedNames ? [associatedNames] : []}
          />
        </div>
        <hr className={classes.sectionDivider} />
        <FormControl
          name="imgUrl"
          render={(props: RenderType) => (
            <InputImage
              label="Image URL*"
              labelPosition={LabelPosition.Left}
              {...props}
            />
          )}
        />
        <FormControl
          name="bannerImgUrl"
          render={(props: RenderType) => (
            <InputImage
              label="Banner Image URL"
              labelPosition={LabelPosition.Left}
              {...props}
            />
          )}
        />
      </Form>
    </>
  );
};

export default MerchantCreate;
