import { createAction } from '@reduxjs/toolkit';

export const fetchMerchantNetworksInit = createAction(
  '[Merchant Networks] Fetch Merchant Network Init',
);
export const fetchMerchantNetworksSuccess = createAction(
  '[Merchant Networks] Fetch Merchant Network Success',
);
export const fetchMerchantNetworksFail = createAction(
  '[Merchant Networks] Fetch Merchant Network Fail',
);

export const createMerchantNetworksInit = createAction(
  '[Merchant Networks] Create Merchant Network Init',
);
export const createMerchantNetworksSuccess = createAction(
  '[Merchant Networks] Create Merchant Network Success',
);
export const createMerchantNetworksFail = createAction(
  '[Merchant Networks] Create Merchant Network Fail',
);

export const clearMerchantNetworksErrorsProps = createAction(
  '[Merchant Networks] Clear Merchant Network Errors Props',
);

export const clearMerchantNetworksSuccessProps = createAction(
  '[Merchant Networks] Clear Merchant Network Success Props',
);
