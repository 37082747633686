enum Path {
  Home = '/',
  Users = '/users',
  User = '/users/:id',
  Login = '/login',
  Logout = '/logout',
  ForgotPassword = '/forgot-password',
  PendingTransactions = '/pending-transactions',
  Reports = '/reports',
  Profile = '/profile',
  Settings = '/settings',
  Help = '/help',
  PortalUsers = '/users',
  Offers = '/offers',
  Merchants = '/merchants',
  Locations = '/locations',
  TransactionsSupport = '/transactions-support',
  Audience = '/audience',
  Segment = '/segment',
  Experiments = '/experiments',
}

export default Path;
