import client, { REWARDS_BASE_URL } from 'services/kardAPI';

type Response = {
  data: 'string';
};

const fetchTableauJWT = async () => {
  try {
    const response: Response = await client.get(
      `${REWARDS_BASE_URL}/portal/token/tableau`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export default fetchTableauJWT;
