import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import AuditInfoForm from 'components/Pages/AuditInfoForm';

import { AuditStatus } from 'enums/status/auditStatus';

import { fetchAuditById } from 'state/actions/audits';
import { selectFetchAuditByIdState } from 'state/selectors/audits';

const AuditInfo = () => {
  const { auditId, issuerId } = useParams();
  const dispatch = useDispatch();

  const {
    audit,
    loading: loadingFetchAudit,
    error: errorFetchAudit,
  } = useSelector(selectFetchAuditByIdState, shallowEqual);

  useEffect(() => {
    dispatch(fetchAuditById(auditId, issuerId));
  }, [dispatch, auditId]);

  const data = audit
    ? {
        ...audit,
        submitterName: audit.submitterName || '',
        merchantId: audit.merchantId || '',
        referringPartnerUserId: audit.referringPartnerUserId || '',
        matchedMerchant: audit.matchedMerchant || '',
        resolutionDescription: audit.resolutionDescription || '',
        auditId: audit._id,
        status: AuditStatus[audit.status],
      }
    : null;

  return (
    <>
      {errorFetchAudit && (
        <Toast
          id="fetch-location"
          text="Error fetching audit"
          type={ToastType.Error}
        />
      )}
      {loadingFetchAudit || !data ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner size="lg" color="gray" />
        </div>
      ) : (
        <div>
          <AuditInfoForm
            title="Audit Information"
            audit={data}
            issuerId={issuerId}
          />
        </div>
      )}
    </>
  );
};

export default AuditInfo;
