import client, { REWARDS_BASE_URL } from 'services/kardAPI';

/**
 * @deprecated Use refactored/hooks/useOffers instead
 */
const fetchOffers = async (filters = '') => {
  try {
    const offers = await client.get(
      `${REWARDS_BASE_URL}/portal/merchant/offers${filters}`,
    );
    return offers.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchOffers;
