/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

import toDollarFormat from 'utils/toDollarFormat';
import centsToDollars from 'utils/centsToDollars';
import dateParse from 'utils/common/dateParse';
import Groups from 'enums/users/groups.enum';

export const reprocessColumns = [
  {
    header: 'Txn ID',
    accessorKey: 'transactionId',
    allowedRoles: Groups.Everyone,
    columnName: 'transactionId',
    sortable: false,
    filterable: false,
  },
  {
    header: 'Merchant Id',
    accessorKey: 'merchantId',
    columnName: 'merchantId',
    allowedRoles: Groups.Everyone,
    isVisible: false,
  },
  {
    header: 'Merchant Name',
    accessorKey: 'merchantName',
    columnName: 'merchantName',
    allowedRoles: Groups.Everyone,
    sortable: false,
    filterable: false,
  },
  {
    header: 'Merchant Address State',
    accessorKey: 'merchantAddrState',
    columnName: 'merchantAddrState',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Merchant Zip code',
    accessorKey: 'merchantAddrStreet',
    columnName: 'merchantAddrStreet',
    allowedRoles: Groups.Everyone,
    isVisible: false,
  },
  {
    header: 'Merchant Zip code',
    accessorKey: 'merchantAddrZipcode',
    columnName: 'merchantAddrZipcode',
    allowedRoles: Groups.Everyone,
    isVisible: false,
  },
  {
    header: 'Txn Amount',
    accessorKey: 'amount',
    columnName: 'amount',
    allowedRoles: Groups.Everyone,
    sortable: false,
    filterable: false,
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <span
          style={{
            width: '100%',
            marginTop: '0px',
            marginBottom: '0px',
          }}
          data-tooltip-content={toDollarFormat(centsToDollars(value))}
          data-tooltip-id="tooltiptable"
          data-html
        >
          {toDollarFormat(centsToDollars(value))}
        </span>
      );
    },
  },
  {
    header: 'City',
    accessorKey: 'merchantAddrCity',
    columnName: 'merchantAddrCity',
    allowedRoles: Groups.Everyone,
    sortable: false,
    filterable: false,
  },
  {
    header: 'Description',
    accessorKey: 'description',
    columnName: 'description',
    allowedRoles: Groups.Everyone,
    sortable: false,
    filterable: false,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    columnName: 'status',
    allowedRoles: Groups.Everyone,
    sortable: false,
    filterable: false,
  },
  {
    header: 'Txn Date',
    accessorKey: 'transactionDate',
    columnName: 'transactionDate',
    allowedRoles: Groups.Everyone,
    sortable: false,
    filterable: false,
    cell: ({ getValue }) => {
      const value = getValue();
      if (value) {
        return dateParse(value);
      }
      return '-';
    },
  },
];
