import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const fetchGenerateCommissionMaps = async (
  qualifiedIssuer: string[],
  commissionValue: number,
  merchantNetwork: string,
) => {
  try {
    const body = {
      qualifiedIssuer,
      commissionValue,
      merchantNetwork,
    };
    const offers = await client.post(
      `${REWARDS_BASE_URL}/portal/offer/commission-map`,
      body,
    );
    return offers.data;
  } catch {
    throw new Error('Failed to generate commission maps');
  }
};

const CONFIRMATION_MESSAGE =
  'Are you sure you want to generate new commission maps? Custom values will be overwritten.';

const useGenerateCommissionMaps = (
  qualifiedIssuer: string[],
  commissionValue: number,
  merchantNetwork: string,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { setValue, trigger, watch, resetField } = useFormContext();
  const isCustom = watch('isCustom');

  const generateCommissionMaps = async () => {
    // eslint-disable-next-line no-alert
    if (isCustom && !window.confirm(CONFIRMATION_MESSAGE)) return;

    setError(null);
    setIsLoading(true);

    try {
      const data = await fetchGenerateCommissionMaps(
        qualifiedIssuer,
        commissionValue,
        merchantNetwork,
      );

      // Reset issuer and user commission fields individually to keep form state in sync
      qualifiedIssuer.forEach((issuerName) => {
        const issuerCommission = data.issuerCommissionsMap[issuerName];
        const userCommission = data.userCommissionsMap[issuerName];

        if (issuerCommission !== undefined) {
          resetField(`issuerCommissionsMap.${issuerName}`, {
            defaultValue: issuerCommission,
          });
        }

        if (userCommission !== undefined) {
          resetField(`userCommissionsMap.${issuerName}`, {
            defaultValue: userCommission,
          });
        }
      });

      // Reset defaults to new values after generating commission maps to undirty the fields
      resetField('commissionValue', { defaultValue: commissionValue });
      resetField('qualifiedIssuer', { defaultValue: qualifiedIssuer });

      setValue('isCustom', false);
      trigger(['issuerCommissionsMap', 'userCommissionsMap']);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, generateCommissionMaps };
};

export default useGenerateCommissionMaps;
