/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Select from 'components/Common/Select';
import Body, { Color as BodyColor } from 'components/Typography/Body';
import ErrorMessage from 'components/Typography/ErrorMessage';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
  XS: 'xs',
});

export const LabelPosition = Object.freeze({
  Top: 'top',
  Left: 'left',
});

export const Color = Object.freeze({
  Black: 'black',
  Gray: 'gray',
});

const SELECT_ALL = { label: 'Select All', value: 'all' };

const FormControlSelect = ({
  name,
  label,
  labelClassName,
  controlClassName,
  placeholder,
  className,
  options: selectOptions,
  value: manualValue,
  defaultValue,
  disabled,
  readOnly,
  size,
  onChangeManual,
  isMulti,
  allowSelectAll,
  labelPosition,
  color,
  noErrorMessage,
  testId,
  tooltip,
  ...props
}) => {
  const {
    formState: { errors },
    control,
    setValue: setFormValue,
  } = useFormContext();
  const error = errors[name];

  useEffect(() => {
    if (defaultValue) {
      setFormValue(name, defaultValue);
    }
  }, [setFormValue, name, defaultValue]);

  const borderColor = useMemo(
    () => (error ? '1px solid red' : '1px solid #D1D5DB'),
    [error],
  );

  const backgroundColor = useMemo(
    () => (disabled ? '#F9FAFB' : 'white'),
    [disabled],
  );

  const options = useMemo(
    () =>
      isMulti && allowSelectAll
        ? [SELECT_ALL, ...selectOptions]
        : selectOptions,
    [isMulti, allowSelectAll, selectOptions],
  );

  const getValue = useCallback(
    (value) => {
      if (value) {
        if (options.length > 0 && options[0]?.options) {
          if (isMulti) {
            const allOptions = [];
            options.forEach((op) => {
              const newOptions =
                op.options.filter((c) => value?.includes(c.value)) || [];
              allOptions.concat(newOptions);
            });
            return allOptions;
          }
          let selectedOption = null;
          options.forEach((op) => {
            const selected = op.options.find((c) => c.value === value) || null;
            if (selected) {
              selectedOption = selected;
            }
          });
          return selectedOption;
        }
        if (isMulti) {
          return options.filter((c) => value?.includes(c.value)) || [];
        }
        const selectedOption =
          options.find((c) => {
            if (Array.isArray(value)) return c.value === value[0];
            return c.value === value;
          }) || null;
        return selectedOption;
      }
      return null;
    },
    [isMulti, options],
  );

  const tooltipId = `select-${name}-tooltip`;

  return (
    <div
      className={classNames(className, 'mb-5', {
        'mb-1': !!error,
        'cursor-not-allowed': disabled,
      })}
    >
      {label && (
        <Body
          size={size}
          className={classNames(
            labelClassName,
            'mb-2 ml-1 mr-2 !w-fit text-sm font-medium',
            {
              'mt-0': labelPosition === LabelPosition.Top,
            },
          )}
          color={BodyColor.Black}
        >
          {label}
        </Body>
      )}
      <div
        className={classNames('flex max-w-[499px] flex-col', controlClassName)}
        data-tooltip-content={tooltip}
        data-tooltip-id={name}
      >
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          render={({ field }) => {
            const actualValue = manualValue || field.value;

            return (
              <span data-tooltip-id={tooltipId} data-tooltip-content={tooltip}>
                <Select
                  {...field}
                  classNames={{
                    option: (state) => (state.isDisabled ? 'text-gray400' : ''),
                  }}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: '100%',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      color: 'red',
                      border: state.isFocused
                        ? '1px solid #E5E7EB'
                        : borderColor,
                      borderRadius: '0.5rem',
                      minHeight: '2.5rem',
                      fontSize: '0.875rem',
                      '&:hover': {
                        backgroundColor: state.isDisabled
                          ? undefined
                          : '#F9FAFB',
                        border: state.isFocused
                          ? '1px solid #E5E7EB'
                          : borderColor,
                      },
                      backgroundColor:
                        state.isDisabled || readOnly
                          ? backgroundColor
                          : 'white', // input
                      boxShadow: 'none',
                    }),
                    multiValueLabel: (base, state) =>
                      state.data.isFixed
                        ? {
                            ...base,
                            fontWeight: 'bold',
                            color: readOnly ? '#9CA3AF' : '#4B5563',
                            paddingRight: 6,
                            backgroundColor:
                              state.isDisabled || readOnly
                                ? backgroundColor
                                : 'white',
                          }
                        : {
                            backgroundColor: '#F3F4F6',
                            color: readOnly ? '#9CA3AF' : '#4B5563',
                            paddingRight: 6,
                            paddingLeft: 6,
                          },
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '150%',
                      border: 'red',
                      color: disabled ? '#7b88a8' : '#111928',
                    }),
                    placeholder: (provided) => ({
                      ...provided,

                      color: color === disabled ? '#7b88a8' : '#111928',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '0.875rem',
                      color: state.isDisabled ? undefined : '#111928',
                      backgroundColor: state.isFocused ? '#F3F4F6' : undefined,
                      '&:hover': {
                        backgroundColor: state.isFocused
                          ? '#F3F4F6'
                          : undefined,
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: 'max-content',
                      minWidth: '100%',
                    }),
                    multiValueRemove: (base, state) =>
                      state.data.isFixed
                        ? { ...base, display: 'none' }
                        : {
                            backgroundColor: '#F3F4F6',
                            color: readOnly ? '#9CA3AF' : '#4B5563',
                          },
                    indicatorSeparator: () => ({ display: 'none' }),
                    indicatorContainer: (provided) => ({
                      ...provided,
                      color: disabled ? '#7b88a8' : '#111928',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: disabled ? '#7b88a8' : '#111928',

                      transform: state.selectProps.menuIsOpen
                        ? 'rotate(180deg)'
                        : null,
                      transition: 'transform  0.2s ease',
                    }),
                  }}
                  options={options}
                  value={getValue(actualValue)}
                  defaultValue={
                    options.filter((c) =>
                      isMulti
                        ? defaultValue?.includes(c.value)
                        : c.value === defaultValue,
                    ) || null
                  }
                  onChange={(val) => {
                    let result;
                    if (isMulti) {
                      let selected;
                      // if this is a "select all operation"
                      if (
                        allowSelectAll &&
                        val.some((option) => option === SELECT_ALL)
                      ) {
                        // then it is all the options, less the synthetic "select all" option at index 0
                        selected = options.slice(1);
                      } else {
                        // otherwise it is just the values we have already selected
                        selected = val;
                      }
                      // get the values for the selected items
                      result = selected.map((option) => option.value);
                    } else {
                      // get the value for the selected item
                      result = val?.value || null;
                    }
                    if (onChangeManual) {
                      onChangeManual?.(result);
                    }
                    field.onChange(result);
                  }}
                  closeMenuOnSelect={!isMulti}
                  placeholder={placeholder}
                  isDisabled={disabled || readOnly}
                  isMulti={isMulti}
                  className={className}
                  testId={testId}
                  {...props}
                />
              </span>
            );
          }}
        />
        {!noErrorMessage && error && (
          <ErrorMessage className="pt-2">{error.message}</ErrorMessage>
        )}
        {tooltip && <ReactTooltip id={tooltipId} float />}
      </div>
    </div>
  );
};

FormControlSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(Size)),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  readOnly: PropTypes.bool,
  isMulti: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  onChangeManual: PropTypes.func,
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  labelClassName: PropTypes.string,
  color: PropTypes.oneOf(Object.values(Color)),
  noErrorMessage: PropTypes.bool,
  testId: PropTypes.string,
  tooltip: PropTypes.string,
};

FormControlSelect.defaultProps = {
  label: null,
  placeholder: null,
  className: '',
  controlClassName: '',
  disabled: false,
  size: Size.S,
  defaultValue: null,
  readOnly: false,
  isMulti: false,
  allowSelectAll: true,
  onChangeManual: null,
  labelPosition: LabelPosition.Left,
  value: null,
  color: Color.Gray,
  labelClassName: '',
  noErrorMessage: false,
  testId: null,
  tooltip: '',
};

export default FormControlSelect;
