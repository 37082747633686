import { useSelector, shallowEqual } from 'react-redux';
import { Tabs, Spinner } from 'flowbite-react';
import {
  selectFetchKnockTokenState,
  selectFetchUserProfileState,
} from 'state/selectors/users/index';
import UserProfile from 'components/Pages/UserProfile';
import Preferences from 'components/Pages/Preferences';
import tabsTheme from './theme';

const Settings = () => {
  const { userProfile, loading: userLoading } = useSelector(
    selectFetchUserProfileState,
    shallowEqual,
  );

  const { token, loading: tokenLoading } = useSelector(
    selectFetchKnockTokenState,
    shallowEqual,
  );

  return (
    <div className="px-10 pt-10">
      <h1 className="mb-4 text-xl font-semibold">Settings</h1>
      {/* eslint-disable-next-line */}
      <Tabs aria-label="Notification tabs" style="default" theme={tabsTheme}>
        <Tabs.Item active title="Notifications">
          {tokenLoading ? (
            <div className="flex h-screen items-center justify-center">
              <Spinner size="lg" color="gray" />
            </div>
          ) : (
            <Preferences token={token} />
          )}
        </Tabs.Item>
        <Tabs.Item title="Profile">
          {userLoading ? (
            <div className="flex h-screen items-center justify-center">
              <Spinner size="lg" color="gray" />
            </div>
          ) : (
            <UserProfile user={userProfile} title="Profile" />
          )}
        </Tabs.Item>
      </Tabs>
    </div>
  );
};

export default Settings;
