import { useCallback, useState } from 'react';
import { Query } from 'types';

/**
 * Custom hook for managing the filter, sort, pagination,
 * and other state of requests for table data.
 *
 * {@link https://kardfinancial.atlassian.net/l/cp/6jGm0pHy}
 * @param defaults describes the constant values
 * to which the query will be reset when necessary.
 * @param initial describes the values which the query will be initialized with.
 * @returns an array containing the query and its updater function.
 */
const useQuery = <T extends Query = Query>(
  givenDefaults: Partial<T> = {},
  initial: Partial<T> = {},
): [T, (next: Partial<T>, reset?: string[]) => void] => {
  // if you useQuery({...}) then givenDefaults will be different on every render.
  // this isn't a problem for defining query. but since updateQuery depends on defaults,
  // if the defaults were not memoized here, then updateQuery would update on every render.
  const [defaults] = useState<T>(givenDefaults as T);
  const [query, setQuery] = useState<T>({ ...defaults, ...initial } as T);
  /**
   * This function accepts a partial update to the query,
   * and optionally resets specified properties to their defaults.
   */
  const updateQuery = useCallback(
    (next: Partial<T>, reset: string[] = []) =>
      setQuery((last: T) => {
        let nextQuery = { ...last };
        reset.forEach((key) => {
          delete nextQuery[key as keyof T];
          if (key in defaults) {
            nextQuery[key as keyof T] = defaults[key as keyof T];
          }
        });
        nextQuery = { ...nextQuery, ...next };
        return nextQuery;
      }),
    [defaults],
  );
  return [query, updateQuery];
};

export default useQuery;
