/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchAuditsInit,
  fetchAuditsSuccess,
  fetchAuditsFail,
  fetchAuditByIdInit,
  fetchAuditByIdSuccess,
  fetchAuditByIdFail,
  editAuditFail,
  editAuditInit,
  editAuditSuccess,
  createPublicAuditsInit,
  createPublicAuditsSuccess,
  createPublicAuditsFail,
  clearAuditsErrorsProps,
  createAuditFail,
  createAuditInit,
  createAuditSuccess,
  clearAuditsData,
} from 'state/actionCreators/audits';

export const initialState = {
  fetchAuditsLoading: false,
  fetchAuditsSuccess: false,
  fetchAuditsError: null,
  fetchAuditByIdLoading: false,
  fetchAuditByIdSuccess: false,
  fetchAuditByIdError: null,
  editAuditLoading: false,
  editAuditSuccess: false,
  editAuditError: null,
  createPublicAuditsLoading: false,
  createPublicAuditsSuccess: false,
  createPublicAuditsError: null,
  createAuditLoading: false,
  createAuditSuccess: false,
  createAuditError: null,
  audits: [],
  audit: null,
  createdAudit: [],
};

const persistConfig = {
  key: 'audits',
  storage,
  blacklist: [
    'fetchAuditsLoading',
    'fetchAuditsSuccess',
    'fetchAuditsError',
    'fetchAuditByIdLoading',
    'fetchAuditByIdSuccess',
    'fetchAuditByIdError',
    'editAuditLoading',
    'editAuditSuccess',
    'editAuditError',
    'createPublicAuditsLoading',
    'createPublicAuditsSuccess',
    'createPublicAuditsError',
    'audits',
    'audit',
    'createdAudit',
    'createAuditLoading',
    'createAuditSuccess',
    'createAuditError',
  ],
};

const auditsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchAuditsInit, (state) => {
      state.fetchAuditsLoading = true;
      state.fetchAuditsSuccess = false;
      state.fetchAuditsError = null;
      state.audits = [];
      state.audit = null;
      state.fetchAuditByIdError = null;
    });

    builder.addCase(fetchAuditsSuccess, (state, action) => {
      const { audits } = action.payload;
      state.fetchAuditsLoading = false;
      state.fetchAuditsError = null;
      state.fetchAuditsSuccess = true;
      state.audits = audits;
    });

    builder.addCase(fetchAuditsFail, (state, action) => {
      const { error } = action.payload;
      state.fetchAuditsLoading = false;
      state.fetchAuditsError = error;
      state.fetchAuditsSuccess = false;
    });

    builder.addCase(fetchAuditByIdInit, (state) => {
      state.fetchAuditByIdLoading = true;
      state.fetchAuditByIdSuccess = false;
      state.fetchAuditByIdError = null;
      state.audit = null;
    });

    builder.addCase(fetchAuditByIdSuccess, (state, action) => {
      const { audit } = action.payload;
      state.fetchAuditByIdLoading = false;
      state.fetchAuditByIdError = null;
      state.fetchAuditByIdSuccess = true;
      state.audit = audit;
    });

    builder.addCase(fetchAuditByIdFail, (state, action) => {
      const { error } = action.payload;
      state.fetchAuditByIdLoading = false;
      state.fetchAuditByIdError = error;
      state.fetchAuditByIdSuccess = false;
      state.audit = null;
    });

    builder.addCase(editAuditInit, (state) => {
      state.editAuditLoading = true;
      state.editAuditError = null;
      state.editAuditSuccess = false;
    });

    builder.addCase(editAuditSuccess, (state) => {
      state.editAuditLoading = false;
      state.editAuditError = null;
      state.editAuditSuccess = true;
    });

    builder.addCase(editAuditFail, (state, action) => {
      const { error } = action.payload;
      state.editAuditLoading = false;
      state.editAuditError = error;
      state.editAuditSuccess = false;
    });

    builder.addCase(createPublicAuditsInit, (state) => {
      state.createPublicAuditsLoading = true;
      state.createPublicAuditsSuccess = false;
      state.createPublicAuditsError = null;
      state.createdAudit = [];
    });

    builder.addCase(createPublicAuditsSuccess, (state, action) => {
      const { audit } = action.payload;
      state.createPublicAuditsLoading = false;
      state.createPublicAuditsError = null;
      state.createPublicAuditsSuccess = true;
      state.createdAudit = audit;
    });

    builder.addCase(createPublicAuditsFail, (state, action) => {
      const { error } = action.payload;
      state.createPublicAuditsLoading = false;
      state.createPublicAuditsError = error;
      state.createPublicAuditsSuccess = false;
      state.createdAudit = null;
    });

    builder.addCase(createAuditInit, (state) => {
      state.createAuditLoading = true;
      state.createAuditError = null;
      state.createAuditSuccess = false;
    });

    builder.addCase(createAuditSuccess, (state) => {
      state.createAuditLoading = false;
      state.createAuditError = null;
      state.createAuditSuccess = true;
    });

    builder.addCase(createAuditFail, (state, action) => {
      const { error } = action.payload;
      state.createAuditLoading = false;
      state.createAuditError = error;
      state.createAuditSuccess = false;
    });

    builder.addCase(clearAuditsErrorsProps, (state) => {
      state.fetchAuditByIdError = null;
      state.editAuditError = null;
      state.createAuditError = null;
      state.editAuditSuccess = false;
      state.createAuditSuccess = false;
    });
    builder.addCase(clearAuditsData, (state) => {
      state.fetchAuditsError = null;
      state.fetchAuditsSuccess = false;
      state.audits = [];
    });
  }),
);

export default auditsReducer;
