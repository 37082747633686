/* modules */
import React, { useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';

/* components */
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'refactored/components/Common/Input';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';

/* const */
import SortDirection from 'refactored/enums/query/sortDirection.enum';

/* assets */
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import searchIcon from 'assets/icons/search.svg';
import clearIcon from 'assets/icons/x.svg';

/* styles  */
import classes from './SelectDropdown.module.scss';

/**
 * SelectDropdown: Search filter component with dropdown and checkbox options
 * @param {object} query current query, example { pagination: { page: 0, limit: 50 }, sort: { direction: -1, columnName: 'createdDate' }}
 * @param {string} fieldName name of field to filter on, example "status" for {query: {filters: {status: { selected: ["enabled"], type: "string"}}}}
 * @param {array} options of objects with label and value properties, example [{label: "Enabled", value: "enabled"}]
 * @param {function} updateQuery to update query, function usually from useQuery hook
 * @param {string} filterType of filter, string example "string" for {query: {filters: {status: { selected: ["enabled"], type: "string"}}}}
 * @param {array} defaultSelection state of selection, also used to reset, array of strings of options' values example ["enabled"]
 * @param {string} label label for dropdown button example "Status"
 * @param {boolean} searchable whether or not to show search input, example true
 * @returns {JSX.Element}
 */
const SelectDropdown = ({
  query,
  fieldName,
  options,
  updateQuery,
  defaultSelection,
  label,
  searchable,
  filterType,
  loading,
  dataCy,
}) => {
  const DEFAULT_VALUE = query?.filters?.[fieldName]
    ? query.filters[fieldName].selected
    : defaultSelection;

  const [selected, setSelected] = useState(DEFAULT_VALUE);

  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilteredOptions(filtered);
  };

  const handleSelect = (option) => {
    setSelected(option);

    const newQuery = {
      ...query,
      pagination: { page: 0, limit: 100 },
      filters: {
        ...query.filters,
        [fieldName]: { selected: [option.value], type: filterType },
      },
    };
    setFilteredOptions(options);
    updateQuery(newQuery);
  };

  const onClearDropdown = () => {
    const newQuery = { ...query };

    if (selected.value) {
      delete newQuery.filters[fieldName]; // remove current filter if it's empty

      if (Object.keys(newQuery.filters).length === 0) {
        delete newQuery.filters; // remove filters if empty
      }

      updateQuery(newQuery);
      setSelected(DEFAULT_VALUE);
    }
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(classes.button, {
              [classes.loading]: loading,
            })}
            data-cy={dataCy}
          >
            {loading ? (
              <Spinner
                color={SpinnerColor.Black}
                size={SpinnerSize.L}
                className={classes.spinner}
              />
            ) : (
              <>
                <div className={classes.labelContainer}>
                  <div className={classes.label}>{`${label}${
                    selected.value ? `: ${selected.label}` : ''
                  }`}</div>
                </div>
                {selected.value && (
                  <button
                    type="button"
                    onClick={onClearDropdown}
                    aria-label="clear-filter"
                  >
                    <img alt="clear" src={clearIcon} />
                  </button>
                )}
                <img
                  alt="arrow"
                  className={classNames(classes.arrowIcon, {
                    [classes.arrowIconOpen]: open,
                  })}
                  src={arrowDownIcon}
                />
              </>
            )}
          </Popover.Button>
          <Popover.Panel className={classes.panelContainer}>
            {searchable && (
              <div className={classes.panelSearch}>
                <Input
                  name="search"
                  onChange={handleSearch}
                  placeholder="Search"
                  type={InputType.Text}
                  icon={searchIcon}
                  size={InputSize.S}
                  className="!w-[10rem]"
                />
              </div>
            )}
            <div className={classes.panelContent}>
              {filteredOptions.map((option) => (
                <Popover.Button
                  key={option.label}
                  type="button"
                  className={classes.option}
                  onClick={() => handleSelect(option)}
                  data-tooltip-content={option.label}
                  data-tooltip-id={`${option.label}-tooltip`}
                >
                  <p className={classes.optionLabel}>{option.label}</p>
                  {option.label.length > 12 && (
                    <ReactTooltip id={`${option.label}-tooltip`} />
                  )}
                </Popover.Button>
              ))}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default SelectDropdown;

SelectDropdown.propTypes = {
  query: PropTypes.shape({
    pagination: PropTypes.shape({
      limit: PropTypes.number,
      page: PropTypes.number,
    }),
    filters: PropTypes.shape({}),
    sort: PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.oneOf(Object.values(SortDirection)),
    }),
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateQuery: PropTypes.func.isRequired,
  defaultSelection: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  searchable: PropTypes.bool,
  filterType: PropTypes.string,
  loading: PropTypes.bool,
  dataCy: PropTypes.string,
};

SelectDropdown.defaultProps = {
  defaultSelection: [],
  label: '',
  searchable: false,
  filterType: 'string',
  loading: false,
  dataCy: 'SelectDropdown',
};
