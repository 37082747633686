/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchPortalUsersInit,
  fetchPortalUsersSuccess,
  fetchPortalUsersFail,
  editPortalUserInit,
  editPortalUserSuccess,
  editPortalUserFail,
  clearEditPortalUserData,
  createPortalUserInit,
  createPortalUserSuccess,
  createPortalUserFail,
  clearPortalUserErrorsData,
  fetchUserProfileInit,
  fetchUserProfileSuccess,
  fetchUserProfileFail,
  fetchKnockTokenInit,
  fetchKnockTokenSuccess,
  fetchKnockTokenFail,
} from 'state/actionCreators/users';

export const initialState = {
  fetchPortalUsersLoading: false,
  fetchPortalUsersSuccess: false,
  fetchPortalUsersError: null,
  editPortalUserLoading: false,
  editPortalUserSuccess: false,
  editPortalUserError: null,
  createPortalUserLoading: false,
  createPortalUserSuccess: false,
  createPortalUserError: null,
  fetchUserProfileLoading: false,
  fetchUserProfileSuccess: false,
  fetchUserProfileError: null,
  fetchKnockTokenLoading: false,
  fetchKnockTokenSuccess: false,
  fetchKnockTokenError: null,
  portalUsers: [],
  userProfile: null,
  token: null,
};

const persistConfig = {
  key: 'users',
  storage,
  blacklist: [
    'fetchPortalUsersLoading',
    'fetchPortalUsersSuccess',
    'fetchPortalUsersError',
    'editPortalUserLoading',
    'editPortalUserSuccess',
    'editPortalUserError',
    'createPortalUserLoading',
    'createPortalUserSuccess',
    'createPortalUserError',
    'fetchUserProfileLoading',
    'fetchUserProfileSuccess',
    'fetchUserProfileError',
    'fetchKnockTokenLoading',
    'fetchKnockTokenSuccess',
    'fetchKnockTokenError',
    'userProfile',
    'token',
    'portalUsers',
  ],
};

const usersReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchPortalUsersInit, (state) => {
      state.fetchPortalUsersLoading = true;
      state.editPortalUserSuccess = false;
      state.createPortalUserSuccess = false;
      state.portalUsers = [];
    });

    builder.addCase(fetchPortalUsersSuccess, (state, action) => {
      const { users } = action.payload;
      state.fetchPortalUsersLoading = false;
      state.fetchPortalUsersError = null;
      state.fetchPortalUsersSuccess = true;
      state.portalUsers = users;
    });

    builder.addCase(fetchPortalUsersFail, (state, action) => {
      const { error } = action.payload;
      state.fetchPortalUsersLoading = false;
      state.fetchPortalUsersError = error;
      state.fetchPortalUsersSuccess = false;
      state.portalUsers = [];
    });

    builder.addCase(fetchKnockTokenInit, (state) => {
      state.fetchKnockTokenLoading = true;
    });

    builder.addCase(fetchKnockTokenFail, (state, action) => {
      const { error } = action.payload;
      state.fetchKnockTokenLoading = false;
      state.fetchKnockTokenError = error;
      state.fetchKnockTokenSuccess = false;
    });

    builder.addCase(fetchKnockTokenSuccess, (state, action) => {
      const token = action.payload;
      state.fetchKnockTokenLoading = false;
      state.fetchKnockTokenError = null;
      state.fetchKnockTokenSuccess = true;
      state.token = token;
    });

    builder.addCase(editPortalUserInit, (state) => {
      state.editPortalUserLoading = true;
    });

    builder.addCase(editPortalUserSuccess, (state) => {
      state.editPortalUserLoading = false;
      state.editPortalUserError = null;
      state.editPortalUserSuccess = true;
    });

    builder.addCase(editPortalUserFail, (state, action) => {
      const { error } = action.payload;
      state.editPortalUserLoading = false;
      state.editPortalUserError = error;
      state.editPortalUserSuccess = false;
    });

    builder.addCase(clearEditPortalUserData, (state) => {
      state.editPortalUserLoading = false;
      state.editPortalUserError = null;
      state.editPortalUserSuccess = false;
    });

    builder.addCase(createPortalUserInit, (state) => {
      state.createPortalUserLoading = true;
    });

    builder.addCase(createPortalUserSuccess, (state) => {
      state.createPortalUserLoading = false;
      state.createPortalUserError = null;
      state.createPortalUserSuccess = true;
    });

    builder.addCase(createPortalUserFail, (state, action) => {
      const { error } = action.payload;
      state.createPortalUserLoading = false;
      state.createPortalUserError = error;
      state.createPortalUserSuccess = false;
    });

    builder.addCase(clearPortalUserErrorsData, (state) => {
      state.createPortalUserLoading = false;
      state.createPortalUserError = null;
      state.createPortalUserSuccess = false;
      state.editPortalUserLoading = false;
      state.editPortalUserError = null;
      state.editPortalUserSuccess = false;
    });

    builder.addCase(fetchUserProfileInit, (state) => {
      state.fetchUserProfileLoading = true;
    });

    builder.addCase(fetchUserProfileSuccess, (state, action) => {
      const { user } = action.payload;
      state.fetchUserProfileLoading = false;
      state.fetchUserProfileError = null;
      state.fetchUserProfileSuccess = true;
      state.userProfile = user;
    });

    builder.addCase(fetchUserProfileFail, (state, action) => {
      const { error } = action.payload;
      state.fetchUserProfileLoading = false;
      state.fetchUserProfileError = error;
      state.fetchUserProfileSuccess = false;
    });
  }),
);

export default usersReducer;
