import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

/* components */
import UserForm from 'refactored/components/Pages/UserForm';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';

/* selectors */
import { selectEditPortalUserState } from 'state/selectors/users';

const EditUser = () => {
  const { error: errorEdit, success: successEdit } = useSelector(
    selectEditPortalUserState,
    shallowEqual,
  );

  const [user, setUser] = useState(null);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      // If username field exists, it's coming from the MessageEvent sent from PortalUsers
      if (event?.data?.username) {
        setUser(event.data);
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [setUser]);

  return (
    <>
      {errorEdit && (
        <Toast id="error create user" text={errorEdit} type={ToastType.Error} />
      )}
      {successEdit && (
        <Toast
          id="success-updating-user"
          text="Changes saved"
          type={ToastType.Success}
        />
      )}
      {user ? (
        <UserForm title="Profile" user={user} />
      ) : (
        <Spinner color={SpinnerColor.Black} size={SpinnerSize.L} />
      )}
    </>
  );
};

export default EditUser;
