/* eslint-disable prefer-destructuring */
/* import.meta must not be destructured in order for the values to be injected */
const importMeta = () => {
  if (typeof window !== 'undefined' && 'importMeta' in window) {
    return window.importMeta;
  }

  const env = import.meta.env;
  const hot = import.meta.hot;

  return { env, hot };
};

export default importMeta;
