import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const fetchMerchantById = async (id) => {
  try {
    const merchant = await client.get(
      `${REWARDS_BASE_URL}/portal/merchant/${id}`,
    );
    return merchant.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchantById;
