import * as yup from 'yup';
import Validations from 'enums/schemaValidations/validations.enum';
import partnerOfferIdSchema from 'utils/schema/partnerOfferIdSchema';

const validationSchema = yup.object().shape({
  // when we are making a completely new offer, this will be a string;
  // else it is null because this field is disabled.
  // so it needs to be nullable (meaning we can't use .required),
  // but have a min length of 1 - i.e., not be an empty string - when it is not null.
  merchantId: yup.string().nullable().min(1, Validations.RequiredMerchantId),
  name: yup.string().required(Validations.RequiredName),
  source: yup.string().nullable().required(Validations.RequiredSource),
  offerType: yup.string().nullable().required(Validations.RequiredType),
  merchantNetwork: yup
    .string()
    .nullable()
    .required(Validations.RequiredNetwork),
  qualifiedIssuer: yup
    .array()
    .nullable()
    .required(Validations.RequiredQualifiedIssuers)
    .default([]),
  mcc: yup.array().nullable(),
  commissionType: yup
    .string()
    .nullable()
    .required(Validations.RequiredCommissionType),
  startDate: yup.date().nullable().required(Validations.RequiredStartDate),
  expirationDate: yup
    .date()
    .nullable()
    .required(Validations.RequiredExpirationDate)
    .when('startDate', (startDate, schema) =>
      schema.test({
        test: (expirationDate: Date) => expirationDate > startDate,
        message: Validations.ExpirationDateGreaterThanStartDate,
      }),
    ),
  commissionValue: yup
    .number()
    .min(0, Validations.CommissionValueMinValue)
    .max(100, Validations.CommissionValueMaxValue)
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(Validations.RequiredCommissionValue),
  issuerCommissionsMap: yup.lazy((value, { parent }) =>
    yup
      .object(
        Object.keys(value || {}).reduce((shape, key) => {
          const rules = yup
            .number()
            .nullable()
            .transform((v) => (Number.isNaN(v) ? null : v))
            .min(0, 'Must be greater than or equal to 0')
            .max(
              parent.commissionValue,
              'Must be less than or equal to commission value',
            )
            .required(`Total commission is required`);
          return { ...shape, [key]: rules };
        }, {}),
      )
      .default({}),
  ),
  userCommissionsMap: yup.lazy((value, { parent }) =>
    yup
      .object(
        Object.keys(value || {}).reduce((shape, key) => {
          const rules = yup
            .number()
            .nullable()
            .transform((v) => (Number.isNaN(v) ? null : v))
            .min(0, 'Must be greater than or equal to 0')
            .max(
              parent.issuerCommissionsMap?.[key] || 0,
              'Must be less than or equal to total commission',
            );
          return { ...shape, [key]: rules };
        }, {}),
      )
      .default({}),
  ),
  minTransactionAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MinTransactionAmountMinValue),
  maxTransactionAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MaxTransactionAmountMinValue),
  minRewardAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MinRewardAmountMinValue),
  maxRewardAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MaxRewardAmountMinValue),
  minUserRewardAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MinUserRewardAmountMinValue),
  maxUserRewardAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, Validations.MaxUserRewardAmountMinValue),
  controlGroupPercentage: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(5, Validations.MinControlGroupPercentage)
    .max(95, Validations.MaxControlGroupPercentage),
  partnerOfferId: partnerOfferIdSchema,
});

export default validationSchema;
