import { ChangeEvent, useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';

import Button from 'components/Common/Button';
import { SelectOptionsProps, TableColumns } from '../Types';

/* styles  */
import classes from './SelectOptions.module.scss';

const convertOptionsToSelection = (
  options: TableColumns[],
  defaultSelection: string[],
) =>
  options.reduce((acc: Record<string, boolean>, option) => {
    acc[option.accessorKey] = defaultSelection.includes(option.accessorKey);
    return acc;
  }, {});

const SelectOptions = ({
  options,
  defaultSelection,
  onSelect,
  limit,
  name,
  type,
  limitMessage,
}: SelectOptionsProps) => {
  const [selected, setSelected] = useState(
    convertOptionsToSelection(options, defaultSelection),
  );
  const sortedOptions = options.sort((a, b) =>
    a.header.localeCompare(b.header),
  );
  const [filteredOptions, setFilteredOptions] = useState(sortedOptions);
  const [countTotal, setCountTotal] = useState(defaultSelection.length);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    const filtered = options.filter((option) =>
      option.header.toLowerCase().includes(searchValue.toLowerCase()),
    );
    const sorted = filtered.sort((a, b) => a.header.localeCompare(b.header));
    setFilteredOptions(sorted);
  };

  const toggleSelected = (
    accessorKey: string,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setSelected((prevState) => ({
      ...prevState,
      [accessorKey]: e.target.checked,
    }));
  };

  const reset = () => {
    setSelected(convertOptionsToSelection(options, defaultSelection));
  };

  // update parent component when selection changess
  useEffect(() => {
    const updatedSelection = options.filter(
      (option) => selected[option.accessorKey],
    );
    setCountTotal(updatedSelection.length);
    onSelect(updatedSelection);
  }, [onSelect, options, selected]);

  const disableOption = (accessorKey: string) =>
    (limit && limit > 0 && countTotal >= limit && !selected[accessorKey]) ||
    accessorKey === 'transactionId';

  return (
    <Popover>
      <Popover.Button className={classes.button}>
        <div>{name}</div>
      </Popover.Button>

      <Popover.Panel className={classes.panelContainer}>
        <div className="m-4 flex justify-center">
          <input
            type="text"
            placeholder="Search"
            className="w-[430px] rounded-lg border border-gray-300 bg-white"
            onChange={handleSearch}
          />
        </div>
        {limitMessage && (
          <div className="text-center text-xs text-gray-500">
            {limitMessage}
          </div>
        )}
        {limit && limit > 0 && countTotal >= limit && (
          <div className={classes.errorMsg}>
            Maximum {limit} active {type}
          </div>
        )}
        <div className={classes.panelContent}>
          {filteredOptions.map(({ accessorKey, header }) => (
            <button
              type="button"
              key={`report-option-${accessorKey}`}
              className={classes.panelItem}
              disabled={disableOption(accessorKey)}
            >
              <label htmlFor={`checkbox-${accessorKey}`}>
                <input
                  type="checkbox"
                  id={`checkbox-${accessorKey}`}
                  checked={selected[accessorKey]}
                  className={classes.checkbox}
                  onChange={(e) => toggleSelected(accessorKey, e)}
                  disabled={disableOption(accessorKey)}
                />
                {header}
              </label>
            </button>
          ))}
        </div>
        <div className="flex justify-around bg-white">
          <Button
            variant="secondary"
            type="reset"
            onClick={reset}
            className="my-4"
          >
            Revert to default
          </Button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default SelectOptions;
