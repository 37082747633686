import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Button from 'components/Common/Button';

import TransactionStatus from 'enums/transactions/transactionStatus.enum';
import toDollarFormat from 'utils/toDollarFormat';
import centsToDollars from 'utils/centsToDollars';

import dateParse from 'utils/common/dateParse';
import classes from './TransactionData.module.scss';

const TransactionData = ({ transaction, onCancel, merchants }) => {
  const getMatchedMerchants = useCallback(() => {
    if (!transaction?.matchedMerchantIds || !merchants) {
      return [];
    }

    return transaction.matchedMerchantIds.map((merchantId) => {
      const matchedMerchant = merchants.find(({ _id }) => merchantId === _id);

      return (
        <span key={merchantId} className={classes.matchedMerchants}>
          {!matchedMerchant ? (
            <span>{`UNKNOWN MERCHANT ID ${merchantId}`}</span>
          ) : (
            <>
              <span>{merchantId}</span>
              <span>{matchedMerchant.name}</span>
            </>
          )}
        </span>
      );
    });
  }, [transaction, merchants]);

  return (
    <>
      {transaction && (
        <div className={classes.container}>
          <div className={classes.heading}>
            <Heading size={HeadingSize.M} className={classes.headingText}>
              {TransactionStatus[transaction.reviewStatus]} transaction
            </Heading>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Transaction Id
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.transactionId || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Transaction Date
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {dateParse(transaction.transactionDate || '')}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Merchant Name
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantName || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Description
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.description || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Amount
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.amount
                ? toDollarFormat(centsToDollars(transaction.amount))
                : ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Currency
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.currency || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Issuer Id
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.issuerId || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Authorization Date
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {dateParse(transaction.authorizationDate || '')}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Created Date
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {dateParse(transaction.createdDate || '')}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Batch Id
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.batchId || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Last Modified
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {dateParse(transaction.lastModified || '')}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Matched Merchant Ids
            </Body>
            <Body
              size={BodySize.XS}
              className={classNames(
                classes.value,
                classes.matchedMerchantsBody,
              )}
            >
              {getMatchedMerchants()}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              MCC
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.mcc || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              City
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantAddrCity || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Country
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantAddrCountry || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              State
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantAddrState || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Street
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantAddrStreet || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Zip Code
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantAddrZipcode || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Latitude
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantLat || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Longitude
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.merchantLong || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Real Time Match Status
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.realTimeMatchStatus || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Referring Partner User Id
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.referringPartnerUserId || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Review Status
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.reviewStatus || ''}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Settled Date
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {dateParse(transaction.settledDate || '')}
            </Body>
          </div>
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              color={BodyColor.Gray}
              className={classes.label}
            >
              Status
            </Body>
            <Body size={BodySize.XS} className={classes.value}>
              {transaction.status || ''}
            </Body>
          </div>
          <div className={classes.actionButtons}>
            <Button
              variant="secondary"
              className={classes.button}
              onClick={onCancel}
            >
              Go Back
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

TransactionData.propTypes = {
  transaction: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  onCancel: PropTypes.func.isRequired,
  merchants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

TransactionData.defaultProps = {
  transaction: null,
};

export default TransactionData;
