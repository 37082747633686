import { REWARDS_BASE_URL, useAxios } from 'services/kardAPI';

const useOffers = (queryParams: string = '') => {
  const [{ data, loading, error }] = useAxios({
    // TODO: refactor to use params option instead of manually attaching query params
    url: `${REWARDS_BASE_URL}/portal/merchant/offers?${queryParams}`,
  });

  return [data, loading, error];
};

export default useOffers;
