/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchReportsInit,
  fetchReportsSuccess,
  fetchReportsFail,
  clearReportsData,
} from 'state/actionCreators/reports';

export const initialState = {
  fetchReportsLoading: false,
  fetchReportsSuccess: false,
  fetchReportsError: null,
  reports: [],
};

const persistConfig = {
  key: 'reports',
  storage,
  blacklist: [
    'fetchReportsLoading',
    'fetchReportsSuccess',
    'fetchReportsError',
    'reports',
  ],
};

const reportsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchReportsInit, (state) => {
      state.fetchReportsLoading = true;
      state.reports = [];
      state.fetchReportsError = null;
    });

    builder.addCase(fetchReportsSuccess, (state, action) => {
      const { reports } = action.payload;
      state.fetchReportsLoading = false;
      state.fetchReportsError = null;
      state.fetchReportsSuccess = true;
      state.reports = reports;
    });

    builder.addCase(fetchReportsFail, (state, action) => {
      const { error } = action.payload;
      state.fetchReportsLoading = false;
      state.fetchReportsError = error;
      state.fetchReportsSuccess = false;
      state.reports = [];
    });

    builder.addCase(clearReportsData, (state) => {
      state.fetchReportsLoading = false;
      state.fetchReportsError = null;
      state.fetchReportsSuccess = false;
      state.reports = [];
    });
  }),
);

export default reportsReducer;
