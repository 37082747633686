/* eslint-disable import/prefer-default-export */
import {
  fetchLocationsInit,
  fetchLocationsSuccess,
  fetchLocationsFail,
  editLocationInit,
  editLocationSuccess,
  editLocationFail,
  createLocationInit,
  createLocationSuccess,
  createLocationFail,
  clearLocationSuccessProp,
  clearLocationFailProp,
  uploadLocationsInit,
  uploadLocationsSuccess,
  uploadLocationsFail,
  clearUploadLocationsData,
  fetchLocationsByMerchantIdInit,
  fetchLocationsByMerchantIdSuccess,
  fetchLocationsByMerchantIdFail,
  clearLocationsByMerchantIdData,
  fetchLocationByIdInit,
  fetchLocationByIdSuccess,
  fetchLocationByIdFail,
  fetchMerchantLocationsFail,
  fetchMerchantLocationsInit,
  fetchMerchantLocationsSuccess,
  clearMerchantLocationsData,
} from 'state/actionCreators/locations';

import fetchLocationsService from 'services/locations/fetchLocations';
import fetchLocationByIdService from 'services/locations/fetchLocationById';
import fetchLocationsByMerchantIdService from 'services/locations/fetchLocationsByMerchantId';
import editLocationService from 'services/locations/editLocation';
import createLocationService from 'services/locations/createLocation';
import uploadLocationsService from 'services/locations/uploadLocations';

export const fetchLocations =
  (filters = '') =>
  async (dispatch) => {
    dispatch(fetchLocationsInit());

    try {
      const locations = await fetchLocationsService(filters);
      return dispatch(fetchLocationsSuccess({ locations }));
    } catch (error) {
      return dispatch(fetchLocationsFail({ error: error.message }));
    }
  };

export const fetchLocationById = (id) => async (dispatch) => {
  dispatch(fetchLocationByIdInit());

  try {
    const location = await fetchLocationByIdService(id);
    return dispatch(fetchLocationByIdSuccess({ location }));
  } catch (error) {
    return dispatch(fetchLocationByIdFail({ error: error.message }));
  }
};

export const fetchLocationsByMerchantId = (id) => async (dispatch) => {
  dispatch(fetchLocationsByMerchantIdInit());

  try {
    const locations = await fetchLocationsByMerchantIdService(id);
    return dispatch(fetchLocationsByMerchantIdSuccess({ locations }));
  } catch (error) {
    return dispatch(fetchLocationsByMerchantIdFail({ error: error.message }));
  }
};

export const clearLocationsByMerchantId = () => async (dispatch) => {
  dispatch(clearLocationsByMerchantIdData());
};

export const editLocation = (id, body) => async (dispatch) => {
  dispatch(editLocationInit());

  try {
    await editLocationService(id, body);
    return dispatch(editLocationSuccess());
  } catch (error) {
    return dispatch(editLocationFail({ error: error.message }));
  }
};

export const createLocation = (body) => async (dispatch) => {
  dispatch(createLocationInit());

  try {
    await createLocationService(body);
    return dispatch(createLocationSuccess());
  } catch (error) {
    return dispatch(createLocationFail({ error: error.message }));
  }
};

export const clearLocationsSuccess = () => async (dispatch) => {
  dispatch(clearLocationSuccessProp());
};

export const clearLocationsFail = () => async (dispatch) => {
  dispatch(clearLocationFailProp());
};

export const uploadLocations =
  (file, PI_257_LOCATIONS_UPLOAD_FLAG = false) =>
  async (dispatch) => {
    dispatch(uploadLocationsInit());

    try {
      const response = await uploadLocationsService(
        file,
        PI_257_LOCATIONS_UPLOAD_FLAG,
      );
      return dispatch(uploadLocationsSuccess(response));
    } catch (error) {
      return dispatch(uploadLocationsFail({ error }));
    }
  };

export const clearUploadLocations = () => async (dispatch) => {
  dispatch(clearUploadLocationsData());
};

export const fetchMerchantLocations =
  (filters = '') =>
  async (dispatch) => {
    dispatch(fetchMerchantLocationsInit());

    try {
      const locations = await fetchLocationsService(filters);
      return dispatch(fetchMerchantLocationsSuccess({ locations }));
    } catch (error) {
      return dispatch(fetchMerchantLocationsFail({ error: error.message }));
    }
  };

export const clearMerchantLocations = () => async (dispatch) => {
  dispatch(clearMerchantLocationsData());
};
