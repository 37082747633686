import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

/** custom components */
import InviteUserForm from 'components/Pages/UserForm/InviteUserForm';

/* styles  */
import classes from './AddNewUserModal.module.scss';

const AddNewUserModal = ({ isOpen, onCancel }) => (
  <Transition appear show={isOpen} as={Fragment} onClose={onCancel}>
    <Dialog as="div" className={classes.background}>
      <div className={classes.formContainer}>
        <InviteUserForm title="Invite User" isCreating onCancel={onCancel} />
      </div>
    </Dialog>
  </Transition>
);

export default AddNewUserModal;

AddNewUserModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
};

AddNewUserModal.defaultProps = {
  isOpen: false,
  onCancel: () => {},
};
