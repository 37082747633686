import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Flowbite, Sidebar } from 'flowbite-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';

import {
  LayoutDashboardIcon as DashboardIcon,
  UsersIcon as AudiencesIcon,
  FlaskConicalIcon as ExperimentsIcon,
  UserSearchIcon as PortalUsersIcon,
  ListTodoIcon as PendingTransactionsIcon,
  TextSearchIcon as ReportsIcon,
  MapPinIcon as LocationsIcon,
  TicketPercentIcon as OffersIcon,
  StoreIcon as MerchantsIcon,
  ReceiptIcon as TransactionsSupportIcon,
  MailIcon as EnvelopeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'lucide-react';

import { selectSidebarExpandedState } from 'state/selectors/layout';
import { selectUserAttributesState } from 'state/selectors/auth';
import { toggleSidebar } from 'state/actionCreators/layout';
import useFlag from 'hooks/useFlag';
import Path from 'enums/path.enum';

import kardLogo from 'assets/logos/kard-blue.svg';
import logo from 'assets/logos/logo.svg';

import theme from './theme';

const SideBar = () => {
  const experimentsFlag = useFlag('experiments');
  const dispatch = useDispatch();

  const { isAdmin } = useSelector(selectUserAttributesState, shallowEqual);

  const sidebarExpanded = useSelector(selectSidebarExpandedState, shallowEqual);

  const onToggleCollapse = useCallback(() => {
    dispatch(toggleSidebar());
  }, [dispatch]);

  const location = useLocation();

  const itemClassName = (path: string) =>
    classNames('text-gray-900 hover:bg-gray-100 mb-2', {
      'bg-gray-100 !text-[#111928]': location.pathname === path,
      'pl-6 ': sidebarExpanded,
    });

  return (
    <div className={classNames('lg:!block')}>
      <Flowbite theme={{ theme }}>
        <Sidebar
          aria-label="Sidebar"
          collapsed={!sidebarExpanded}
          className="border-r border-gray-200 bg-white p-2 pt-0 duration-500"
        >
          <div className="flex h-full flex-col border-gray-200 bg-white p-0">
            <div className="bg-white">
              <Sidebar.Items>
                <Sidebar.ItemGroup>
                  <Link
                    to={Path.Home}
                    className={classNames({
                      'mb-4 flex justify-center pt-5': !sidebarExpanded,
                    })}
                  >
                    <img
                      className={
                        sidebarExpanded ? 'w-17 mb-4 ml-5 mt-8 h-8' : 'mb-0 w-8'
                      }
                      src={sidebarExpanded ? kardLogo : logo}
                      alt="Kard Financial Header Logo"
                    />
                  </Link>
                  <Link
                    to={Path.Home}
                    className={classNames({
                      'flex justify-center': !sidebarExpanded,
                    })}
                  >
                    <Sidebar.Item
                      icon={() => <DashboardIcon className="h-5 w-5" />}
                      className={itemClassName(Path.Home)}
                      key={Path.Home}
                    >
                      Dashboard
                    </Sidebar.Item>
                  </Link>

                  {isAdmin && (
                    <Link
                      to={Path.Audience}
                      className={classNames({
                        'flex justify-center': !sidebarExpanded,
                      })}
                    >
                      <Sidebar.Item
                        icon={() => <AudiencesIcon className="h-5 w-5" />}
                        className={itemClassName(Path.Audience)}
                        key={Path.Audience}
                      >
                        Audiences
                      </Sidebar.Item>
                    </Link>
                  )}
                  {isAdmin && (
                    <Link
                      to={Path.Segment}
                      className={classNames({
                        'flex justify-center': !sidebarExpanded,
                      })}
                    >
                      <Sidebar.Item
                        icon={() => <AudiencesIcon className="h-5 w-5" />}
                        className={itemClassName(Path.Segment)}
                        key={Path.Segment}
                      >
                        Segments
                      </Sidebar.Item>
                    </Link>
                  )}
                  {isAdmin && experimentsFlag.enabled && (
                    <Link
                      to={Path.Experiments}
                      className={classNames({
                        'flex justify-center': !sidebarExpanded,
                      })}
                    >
                      <Sidebar.Item
                        icon={() => <ExperimentsIcon className="h-5 w-5" />}
                        className={itemClassName(Path.Experiments)}
                        key={Path.Experiments}
                      >
                        Experiments
                      </Sidebar.Item>
                    </Link>
                  )}
                  {isAdmin && (
                    <Link
                      to={Path.PendingTransactions}
                      className={classNames({
                        'flex justify-center': !sidebarExpanded,
                      })}
                    >
                      <Sidebar.Item
                        icon={() => (
                          <PendingTransactionsIcon className="h-5 w-5" />
                        )}
                        className={itemClassName(Path.PendingTransactions)}
                        key={Path.PendingTransactions}
                      >
                        Pending Transactions
                      </Sidebar.Item>
                    </Link>
                  )}
                  <Link
                    to={Path.Reports}
                    className={classNames({
                      'flex justify-center': !sidebarExpanded,
                    })}
                  >
                    <Sidebar.Item
                      icon={() => <ReportsIcon className="h-5 w-5" />}
                      className={itemClassName(Path.Reports)}
                    >
                      Reports
                    </Sidebar.Item>
                  </Link>
                  {isAdmin && (
                    <Link
                      to={Path.PortalUsers}
                      className={classNames({
                        'flex justify-center': !sidebarExpanded,
                      })}
                    >
                      <Sidebar.Item
                        icon={() => <PortalUsersIcon className="h-5 w-5" />}
                        className={itemClassName(Path.PortalUsers)}
                        key={Path.PortalUsers}
                      >
                        Portal Users
                      </Sidebar.Item>
                    </Link>
                  )}
                  <Link
                    to={Path.Offers}
                    className={classNames({
                      'flex justify-center': !sidebarExpanded,
                    })}
                  >
                    <Sidebar.Item
                      icon={() => <OffersIcon className="h-5 w-5" />}
                      className={itemClassName(Path.Offers)}
                      key={Path.Offers}
                    >
                      Offers
                    </Sidebar.Item>
                  </Link>
                  {isAdmin && (
                    <Link
                      to={Path.Merchants}
                      className={classNames({
                        'flex justify-center': !sidebarExpanded,
                      })}
                    >
                      <Sidebar.Item
                        icon={() => <MerchantsIcon className="h-5 w-5" />}
                        className={itemClassName(Path.Merchants)}
                        key={Path.Merchants}
                      >
                        Merchants
                      </Sidebar.Item>
                    </Link>
                  )}
                  <Link
                    to={Path.Locations}
                    className={classNames({
                      'flex justify-center': !sidebarExpanded,
                    })}
                  >
                    <Sidebar.Item
                      icon={() => <LocationsIcon className="h-5 w-5" />}
                      className={itemClassName(Path.Locations)}
                      key={Path.Locations}
                    >
                      Locations
                    </Sidebar.Item>
                  </Link>
                  <Link
                    to={Path.TransactionsSupport}
                    className={classNames({
                      'flex justify-center': !sidebarExpanded,
                    })}
                  >
                    <Sidebar.Item
                      icon={() => (
                        <TransactionsSupportIcon className="h-5 w-5" />
                      )}
                      className={itemClassName(Path.TransactionsSupport)}
                      key={Path.TransactionsSupport}
                    >
                      Transactions Support
                    </Sidebar.Item>
                  </Link>
                </Sidebar.ItemGroup>
              </Sidebar.Items>
            </div>

            <div className="flex h-full flex-col justify-end">
              <Sidebar.Items>
                <Sidebar.ItemGroup>
                  <a
                    href="mailto:support@getkard.com"
                    className={classNames({
                      'flex justify-center': !sidebarExpanded,
                    })}
                  >
                    <Sidebar.Item
                      icon={() => <EnvelopeIcon className="h-5 w-5" />}
                      className={itemClassName('')}
                    >
                      Send Feedback
                    </Sidebar.Item>
                  </a>
                </Sidebar.ItemGroup>
              </Sidebar.Items>
              <div
                className={classNames('flex justify-end', {
                  'z-20 flex justify-center': !sidebarExpanded,
                })}
              >
                <button onClick={onToggleCollapse} type="button">
                  {sidebarExpanded ? (
                    <ChevronLeftIcon className="h-5 w-5" />
                  ) : (
                    <ChevronRightIcon className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            <ReactTooltip
              delayHide={100}
              place="right"
              id="sidebarTip"
              render={({ content }) => `${content}`}
            />
          </div>
        </Sidebar>
      </Flowbite>
    </div>
  );
};

export default SideBar;
