import client, { TXN_BASE_URL } from 'services/kardAPI';

const editPendingTransactions = async (pendingTransactionId, params) => {
  try {
    const config = {
      headers: {
        'X-Kard-IssuerID': params.issuerId,
      },
    };

    const modifiedParams = { ...params };
    delete modifiedParams.issuerId;
    const { data } = await client.put(
      `${TXN_BASE_URL}/portal/pending/${pendingTransactionId}`,
      modifiedParams,
      config,
    );

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default editPendingTransactions;
