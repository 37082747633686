import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

import Button from 'components/Common/Button';
import Form from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import MultiValueInput from 'components/Common/MultiValueInput';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Checkbox from 'components/Common/Checkbox';
import Status from 'enums/status/status.enum';
import Types from 'enums/type/type.enum';
import { clearEditLocationSuccessProp } from 'state/actionCreators/locations';
import { editLocation } from 'state/actions/locations';
import { selectEditLocationState } from 'state/selectors/locations';
import selectFeatureFlagsState from 'state/selectors/featureFlags';

import { closedStatusOptions, defaultStatusOptions } from 'utils/common/values';
import { addressFields, informationFields } from './formFields';
import classes from './LocationForm.module.scss';
import validationSchema from './LocationForm.schema';

const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const LocationForm = ({ title, location }) => {
  const dispatch = useDispatch();

  const {
    loading: loadingEditLocation,
    error: editLocationError,
    success: successLocationEdit,
  } = useSelector(selectEditLocationState, shallowEqual);

  const [shouldResetForm, setShouldResetForm] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(location);
  const [permanentlyClosed, setPermanentlyClosed] = useState(
    location.permanentlyClosed ?? false,
  );

  const [closedStatus, setClosedStatus] = useState(
    location.status === Status.closed,
  );

  const [presenterMids, setPresenterMids] = useState(
    currentValue.presenterMids || [],
  );

  const onEditHandler = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onCancelHandler = useCallback(() => {
    setIsEditing(false);
    setPermanentlyClosed(currentValue.permanentlyClosed);
    setPresenterMids(currentValue.presenterMids);
    setShouldResetForm(true);
  }, [currentValue]);

  useEffect(() => {
    // Reset shouldResetForm back to false, since shouldReset has already reset form to defaultValues
    if (shouldResetForm) {
      setShouldResetForm(false);
    }
  }, [setShouldResetForm, shouldResetForm]);

  const onChangeCheckboxStateHandler = useCallback(
    (setState) => {
      if (isEditing) {
        setState((prevState) => !prevState);
      }
    },
    [isEditing],
  );

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        ...location,
        name: values.name,
        address: {
          street: values.street ?? '',
          city: values.city ?? '',
          state: values.state ?? '',
          zipCode: values.zipCode ?? '',
        },
        source: values.source,
        locationType: values.locationType,
        phone: values.phone ?? '',
        geoLocation: {
          latitude: Number(values.latitude) ?? '',
          longitude: Number(values.longitude) ?? '',
        },
        permanentlyClosed,
        status: values.status,
        googleId: values.googleId ?? '',
        operationHours: {
          MONDAY: values.monday ?? '',
          TUESDAY: values.tuesday ?? '',
          WEDNESDAY: values.wednesday ?? '',
          THURSDAY: values.thursday ?? '',
          FRIDAY: values.friday ?? '',
          SATURDAY: values.saturday ?? '',
          SUNDAY: values.sunday ?? '',
        },
        ...(presenterMids.length === 0 ? {} : { presenterMids }),
        // merchantID is not editable so pulling from location prop
        merchantId: location.merchant
          ? location.merchant._id
          : location.merchantId,
      };

      setCurrentValue(body);

      delete body.category;
      delete body.locationId;
      delete body.__v;
      delete body._id;
      delete body.createdDate;
      delete body.lastModified;
      delete body.merchant;
      delete body.offers;
      delete body.locationName;
      delete body.merchantName;
      delete body.city;
      delete body.state;
      delete body.street;
      delete body.zipCode;
      delete body.latitude;
      delete body.longitude;
      delete body.monday;
      delete body.tuesday;
      delete body.wednesday;
      delete body.thursday;
      delete body.friday;
      delete body.saturday;
      delete body.sunday;

      if (body.locationType === Types.ONLINE) {
        delete body.address;
        delete body.geoLocation;
        delete body.operationHours;
      }
      dispatch(editLocation(location.locationId, body));
    },
    [location, permanentlyClosed, presenterMids, dispatch],
  );

  // setting isEditing in useEffect to avoid state updates during Toast render
  useEffect(() => {
    if (successLocationEdit) {
      setIsEditing(false);
      dispatch(clearEditLocationSuccessProp());
    }
  }, [successLocationEdit, dispatch]);

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);

  const { IS_1917_REWARDS_MAINTANENCE } = flags;

  return (
    <>
      {editLocationError && (
        <Toast
          id="error edit location"
          text={editLocationError}
          type={ToastType.Error}
        />
      )}
      {successLocationEdit && (
        <Toast
          id="update-location-success"
          text="Location updated successfully"
          type={ToastType.Success}
        />
      )}
      {IS_1917_REWARDS_MAINTANENCE && (
        <Toast
          id="rewards-maintainence-flag"
          text="REWARDS MAINTANENCE ONGOING. RESTRICTING ACCESS TO LOCATION EDIT/CREATE FUNCTIONALITY"
          type={ToastType.Error}
        />
      )}

      <Form
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
        className="ml-10 mt-10"
        shouldReset={shouldResetForm}
        defaultValues={currentValue}
      >
        <div className="flex justify-between">
          <h1 className="mb-6 text-xl font-semibold">{title}</h1>
          <div className="mr-10 gap-4 text-right">
            {isEditing ? (
              <>
                <Button
                  variant="secondary"
                  onClick={onCancelHandler}
                  className="mr-4"
                >
                  Cancel
                </Button>
                <span
                  data-tooltip-content="Closed locations cannot be saved. <br /> Please change Status to Active"
                  data-tooltip-id="noSaveMessageTooltip"
                >
                  <Button
                    type="submit"
                    className="!w-[7rem]"
                    loading={loadingEditLocation}
                    disabled={closedStatus || IS_1917_REWARDS_MAINTANENCE}
                  >
                    Save changes
                  </Button>
                  {closedStatus ? (
                    <ReactTooltip id="noSaveMessageTooltip" multiline />
                  ) : null}
                </span>
              </>
            ) : (
              <div>
                <Button
                  variant="secondary"
                  onClick={onEditHandler}
                  className="h-[37px] w-[107px]"
                >
                  Edit location
                </Button>
              </div>
            )}
          </div>
        </div>
        <div>
          {informationFields.map(
            ({ name, renderProps: { isEditable, ...renderProps } }) => (
              <Fragment key={name}>
                <FormControl
                  key={name}
                  name={name}
                  render={(props) => (
                    <Input
                      readOnly={!isEditable || !isEditing}
                      disabled={!isEditable && isEditing}
                      color={
                        isEditable && isEditing
                          ? InputColor.Black
                          : InputColor.Gray
                      }
                      size={InputSize.S}
                      value={currentValue[name]}
                      {...renderProps}
                      {...props}
                    />
                  )}
                />
              </Fragment>
            ),
          )}
          <FormControlSelect
            name="status"
            label="Status*"
            readOnly={!isEditing}
            color={isEditing ? SelectColor.Black : SelectColor.Gray}
            defaultValue={currentValue.status}
            disabled={!isEditing}
            labelClassName={classes.labelWidth}
            options={
              location.status === Status.closed
                ? closedStatusOptions
                : defaultStatusOptions
            }
            onChangeManual={(value) => {
              setClosedStatus(value === Status.closed);
            }}
          />
          {addressFields.map(({ name, renderProps, ...rest }) => (
            <Fragment key={name}>
              {renderProps ? (
                <FormControl
                  name={name}
                  render={(props) => (
                    <Input
                      readOnly={!isEditing}
                      color={isEditing ? InputColor.Black : InputColor.Gray}
                      size={InputSize.S}
                      value={currentValue[name]}
                      disabled={!isEditing}
                      {...renderProps}
                      {...props}
                    />
                  )}
                />
              ) : (
                <FormControlSelect
                  name={name}
                  readOnly={!isEditing}
                  color={isEditing ? SelectColor.Black : SelectColor.Gray}
                  defaultValue={currentValue[name]}
                  disabled={!isEditing}
                  {...rest}
                />
              )}
            </Fragment>
          ))}
          <Checkbox
            label="Permanently closed"
            setSelected={() =>
              onChangeCheckboxStateHandler(setPermanentlyClosed)
            }
            selected={permanentlyClosed}
            className={classes.checkbox}
          />
          <FormControl
            name="presenterMids"
            render={(props) => (
              <MultiValueInput
                label="Presenter MIDs"
                emptyLabel="No Presenter MIDs created"
                setValues={setPresenterMids}
                values={presenterMids}
                disabled={!isEditing}
                readOnly={!isEditing}
                {...props}
              />
            )}
          />
          <hr className={classes.sectionDivider} />
          <h1 className="mb-6 text-xl font-semibold">Business Hours</h1>
          {WEEK_DAYS.map((day) => (
            <FormControl
              name={day.toLowerCase()}
              key={day}
              render={(props) => (
                <Input
                  label={`${day}*`}
                  placeholder={day}
                  type={InputType.Text}
                  readOnly={!isEditing}
                  size={InputSize.S}
                  labelClassName={classes.days}
                  color={isEditing ? InputColor.Black : InputColor.Gray}
                  {...props}
                />
              )}
            />
          ))}
        </div>
      </Form>
    </>
  );
};

LocationForm.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};

LocationForm.defaultProps = {
  location: {},
};

export default LocationForm;
