import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const createMerchant = async (body) => {
  try {
    const merchant = await client.post(
      `${REWARDS_BASE_URL}/portal/merchant`,
      body,
    );
    return merchant.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default createMerchant;
