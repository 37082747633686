/* modules */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'flowbite-react';

/* redux */
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

/* merchant */
import { fetchMerchantById } from 'state/actions/merchants';
import {
  selectFetchMerchantByIdState,
  selectEditMerchantState,
} from 'state/selectors/merchants';

/* components */
import Toast, { Type as ToastType } from 'components/Common/Toast';
import MerchantEdit from 'components/Pages/MerchantEdit';

/* enums */
import Status from 'enums/status/status.enum';

import type {
  MerchantByIdResponse,
  EditCreateMerchantResponse,
} from 'types/responses';

import type { Merchant } from 'types';

const EditMerchant = () => {
  const { merchantId } = useParams();
  const dispatch = useDispatch();

  const {
    loading: fetchMerchantLoading,
    merchant,
    error: fetchMerchantError,
  }: MerchantByIdResponse = useSelector(
    selectFetchMerchantByIdState,
    shallowEqual,
  );

  const {
    success: editMerchantSuccess,
    error: errorEdit,
  }: EditCreateMerchantResponse = useSelector(
    selectEditMerchantState,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchMerchantById(merchantId));
  }, [dispatch, merchantId]);

  const transformedData: Merchant = {
    ...merchant,
    merchantId: merchant?._id?.toString(),
  };

  delete transformedData.validOfferForIssuer;
  delete transformedData.__v;

  return (
    <div className="h-screen">
      {fetchMerchantError && (
        <Toast
          id="fetch-merchant"
          type={ToastType.Error}
          text="Error fetching merchant"
        />
      )}
      {errorEdit && (
        <Toast
          id="error-edit-merchant"
          text="Error updating a Merchant!"
          type={ToastType.Error}
        />
      )}
      {editMerchantSuccess && (
        <Toast
          id="success-updating-merchant"
          text="Merchant updated successfully!"
          type={ToastType.Success}
        />
      )}

      {fetchMerchantLoading ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner size="lg" color="gray" />
        </div>
      ) : (
        <MerchantEdit
          merchant={transformedData}
          offers={merchant?.offers?.filter(
            (offer) => (offer.status as unknown as Status) !== Status.closed,
          )}
          closedOffers={merchant?.offers?.filter(
            (offer) => (offer.status as unknown as Status) === Status.closed,
          )}
        />
      )}
    </div>
  );
};

export default EditMerchant;
