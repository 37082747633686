import { useMemo } from 'react';

import LayoutGap from '@/components/Common/LayoutGap';
import getRegexFromDoubleEscapedRegexString from '@/lib/getRegexFromString';
import classNames from 'classnames';

type RegexPreviewProps = {
  value: string;
  isError?: boolean;
};

const RegexPreview = ({ value, isError }: RegexPreviewProps) => (
  <LayoutGap direction="column" size="small">
    <h2 className="text-sm font-medium text-gray-700">Regex Preview</h2>
    <pre
      className={classNames(
        'whitespace-pre-wrap break-all rounded-md bg-gray-100 p-2 text-xs text-gray-500',
        isError && 'bg-red-100 text-red-500',
      )}
    >
      {value}
    </pre>
  </LayoutGap>
);

type DisplayRegexProps = {
  value: string;
};

const DisplayRegex = ({ value }: DisplayRegexProps) => {
  const [error, regex] = useMemo(
    () => getRegexFromDoubleEscapedRegexString(value),
    [value],
  );

  if (value === '') {
    return <RegexPreview value="-" />;
  }

  if (error) {
    return <RegexPreview value={error.message} isError />;
  }

  return <RegexPreview value={regex.toString()} isError={false} />;
};

export default DisplayRegex;
