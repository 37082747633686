export const statuses = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
];

export const rewardTypes = Object.freeze({
  static: 'STATIC',
  dynamic: 'DYNAMIC',
});

export const behaviorOptions = [
  // { label: 'Redemptions', value: 'Redemptions' },
  { label: 'Purchases', value: 'Purchases' },
];

export const behaviorRangeValues = Object.freeze({
  ExactlyEqualTo: 'exactly equal to',
  LessThanOrEqualTo: 'less than or Equal to',
  GreaterThanOrEqualTo: 'greater than or Equal to',
});

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const behaviorRangeOptions = [
  {
    label: capitalizeFirstLetter(behaviorRangeValues.ExactlyEqualTo),
    value: behaviorRangeValues.ExactlyEqualTo,
  },
  {
    label: capitalizeFirstLetter(behaviorRangeValues.LessThanOrEqualTo),
    value: behaviorRangeValues.LessThanOrEqualTo,
  },
  {
    label: capitalizeFirstLetter(behaviorRangeValues.GreaterThanOrEqualTo),
    value: behaviorRangeValues.GreaterThanOrEqualTo,
  },
];

export const behaviorTimeframeValues = Object.freeze({
  LastMonth: {
    label: 'Last month',
    value: 'Last_1_months',
  },
  LastThreeMonths: {
    label: 'Last 3 months',
    value: 'Last_3_months',
  },
  LastSixMonths: {
    label: 'Last 6 months',
    value: 'Last_6_months',
  },
  LastYear: {
    label: 'Last year',
    value: 'Last_1_years',
  },
  LastCustom: {
    label: 'Last (Custom)',
    value: 'Last_custom',
  },
});

export const logicOperatorOptions = [
  { label: 'and', value: 'and' },
  { label: 'or', value: 'or' },
];

export const periodOptions = [
  { label: 'Days', value: 'days' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];

export const timescaleOptions = [
  // { label: 'Equal to', value: 'Equal to' },
  { label: 'Last', value: 'Last' },
];

export const audienceType = Object.freeze({
  Dynamic: 'DYNAMIC',
  Static: 'STATIC',
});

export const DEFAULT_SORT_BY = Object.freeze({
  ascending: false,
  descending: true,
  columnName: 'createdDate',
});

export const behaviorRangeMap = {
  'exactly equal to': 'eq',
  'less than or Equal to': 'lte',
  'greater than or Equal to': 'gte',
};
