import React from 'react';
import checkIcon from 'assets/icons/check.svg';
import arrowLeft from 'assets/icons/arrow-left.svg';

import { Card } from 'flowbite-react';

import Link from 'components/Typography/Link';
import Path from 'enums/path.enum';
import kardLogo from 'assets/logos/kard-blue.svg';

const PasswordSuccess = () => (
  <div className="flex w-[28rem] flex-col gap-6 p-6">
    <div className="flex items-center justify-center gap-10 pb-6">
      <img className="h-9 w-24" src={kardLogo} alt="kard logo" />
    </div>
    <Card className="rounded-lg shadow-custom">
      <div className="flex justify-center">
        <img
          src={checkIcon}
          alt="check icon"
          className="rounded-full bg-green-100 p-3"
        />
      </div>

      <div className="flex flex-col items-center">
        <p className="pb-3 text-2xl font-[700] leading-tight text-[#111928]">
          Password reset
        </p>
        <p className="inline text-center text-base leading-normal text-gray-500">
          Your password has been successfully reset. Click below to login.
        </p>
      </div>

      <div className="flex w-full items-center justify-center self-stretch text-right font-[500] text-blue-800">
        <img src={arrowLeft} alt="arrow left" />
        <Link to={Path.Home}>
          <p className="w-[100px] pr-1 text-sm leading-normal text-black hover:!decoration-transparent">
            Back to login
          </p>
        </Link>
      </div>
    </Card>
  </div>
);

export default PasswordSuccess;
