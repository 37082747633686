import { ReactNode } from 'react';
import classNames from 'classnames';

import LayoutGap from '../LayoutGap';

export type ButtonGroupProps = {
  /**
   * Class overrides for the button group.
   */
  className?: string;
  /**
   * Align the buttons to the right and reverse the button order
   * while preserving focus priority.
   */
  reverse?: boolean;

  /**
   * Buttons to be rendered inside the group.
   */
  children: ReactNode;
};

const ButtonGroup = ({ className, reverse, ...props }: ButtonGroupProps) => (
  <LayoutGap
    className={classNames(
      // TODO use size prop once LayoutGap supports this size.
      'gap-[0.75rem]',
      'flex-grow self-start',
      {
        'flex-row-reverse': reverse,
      },
      className,
    )}
    {...props}
  />
);

export default ButtonGroup;
