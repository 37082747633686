/* eslint-disable no-param-reassign */
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { Auth } from 'aws-amplify';
import importMeta from 'utils/vite/importMeta';

const { env } = importMeta();
export const AUDIENCE_BASE_URL = env.VITE_AUDIENCE_BASE_API || '/audience';
export const SEGMENT_BASE_URL = env.VITE_SEGMENT_BASE_API || '/segmentation';
export const TXN_BASE_URL = env.VITE_TXN_BASE_API || '/transactions';
export const ISSUERS_BASE_URL = env.VITE_ISSUERS_BASE_API || '/issuers';
export const REWARDS_BASE_URL = env.VITE_REWARDS_BASE_API || '/rewards';
export const CATEGORIES_BASE_URL =
  env.VITE_CATEGORIES_BASE_API || '/categories';
export const USERS_BASE_URL = env.VITE_USERS_BASE_API || '/users';

const baseURL = env.VITE_KARD_BASE_API;
const client = axios.create({ baseURL });

client.interceptors.request.use(
  async (config) => {
    const auth = await Auth.currentSession();

    if (auth) {
      config.headers.Authorization = auth.getIdToken().getJwtToken();
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export const useAxios = makeUseAxios({
  axios: client,
  defaultOptions: {
    useCache: false,
    autoCancel: false,
  },
});

export const publicClient = axios.create({ baseURL });

export default client;
