/* eslint-disable no-unused-vars */

const removeEmptyFieldsFromObject = (obj: Record<string | number, unknown>) =>
  Object.entries(obj)
    .filter(
      ([, value]) => value !== undefined && value !== null && value !== '',
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export default removeEmptyFieldsFromObject;
