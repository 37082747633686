import Validations from 'enums/schemaValidations/validations.enum';
import * as yup from 'yup';

const ruleBuilderRefactoredSchema = yup.object().shape({
  behavior: yup.string().nullable().required(Validations.RequiredBehavior),
  merchant: yup.string().nullable().required(Validations.RequiredMerchant),
  // behaviorRange is an array of validations
  // but name is behaviorRange.0, behaviorRange.1
  // and is not matching the message
  behaviorRange: yup
    .array()
    .of(yup.string().nullable().required(Validations.RequiredPurchaseRange)),
  behaviorValue: yup.array().of(
    yup
      .number()
      .min(0)
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required(Validations.RequiredPurchaseCountValue),
  ),
  timescale: yup
    .array()
    .of(yup.string().nullable().required(Validations.RequiredTimescale)),
  period: yup
    .array()
    .of(yup.string().nullable().required(Validations.RequiredPeriod)),
  timescaleValue: yup.array().of(
    yup
      .number()
      .min(0)
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required(Validations.RequiredTimescaleValue)
      .when('period', {
        is: (timescale) => timescale === 'days',
        then: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .max(730)
          .min(7),
      })
      .when('period', {
        is: (timescale) => timescale === 'months',
        then: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .max(24)
          .min(1),
      })
      .when('period', {
        is: (timescale) => timescale === 'years',
        then: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .max(2)
          .min(1),
      }),
  ),
});

export default ruleBuilderRefactoredSchema;
