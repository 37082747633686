import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const fetchFeatureFlags = async () => {
  try {
    const response = await client.get(
      `${REWARDS_BASE_URL}/portal/featureflags`,
    );
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data);
    }
    throw error;
  }
};

export default fetchFeatureFlags;
