const theme = {
  navbar: {
    root: {
      base: 'z-40 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 ',

      inner: {
        base: 'flex justify-end items-center pr-5  bg-white dark:bg-gray-800',
      },
    },
  },
  dropdown: {
    floating: {
      arrow: {
        base: '!text-red-500',
        style: {
          dark: '!text-red-500',
        },
      },
      base: 'w-[224px] h-[144px] shadow-small rounded-md relative !top-2 !left-5',
      item: {
        base: 'flex bg-transparent items-center justify-start py-2 px-4 text-sm text-[#6B7280] cursor-pointer w-full hover:bg-gray-100 focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 focus:outline-none dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white',
      },
    },
    inlineWrapper: 'flex items-center bg-transparent',
    initials: {
      text: 'text-gray-900',
    },
  },
};

export default theme;
