import { useEffect } from 'react';
import { Flowbite, Toast } from 'flowbite-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  clearExportSuccessField,
  clearInitExportField,
} from 'state/actions/exportData';
import fetchWssURL from 'services/downloads/fetchWssURL';
import {
  exportDataFail,
  exportDataSuccess,
} from 'state/actionCreators/exportData';
import selectExportDataState from 'state/selectors/exportData';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import ExportInProgress from './ExportInProgress';
import ExportCompleted from './ExportCompleted';
import ExportFailed from './ExportFailed';
import theme from './theme';

let client: W3CWebSocket | null = null;

const ExportBanner = () => {
  const dispatch = useDispatch();

  const {
    error: exportFailed,
    loading: exportInProgress,
    success,
    show,
    exportCompleted,
    filters,
    model,
    idToken,
    initExport,
  } = useSelector(selectExportDataState, shallowEqual);

  useEffect(() => {
    const webSocketConnection = async () => {
      const request = JSON.stringify({
        action: 'createFile',
        message: {
          queryData: {
            model,
            ...filters,
          },
          idToken,
        },
      });

      try {
        const URL = await fetchWssURL(); // request URL for wss connection

        if (URL) {
          client = new W3CWebSocket(URL);

          client.onopen = () => client.send(request);

          client.onmessage = (e: MessageEvent) => {
            const { data } = e;

            if (data) {
              const { statusCode, message } = JSON.parse(data) || {};

              if (statusCode === 200) {
                dispatch(exportDataSuccess({ data: message }));
              }

              if (statusCode !== 200) {
                dispatch(exportDataFail({ error: message }));
              }
            }

            if (!data) {
              dispatch(exportDataFail({ error: 'Error exporting the file' }));
            }

            client.close();
          };

          client.onerror = (error: ErrorEvent) => {
            dispatch(exportDataFail({ error: error.message }));
          };
        }

        if (!URL) {
          return dispatch(
            exportDataFail({ error: 'Error requesting WSS url' }),
          );
        }

        return true;
      } catch (error: any) {
        return dispatch(
          exportDataFail({ error: error?.message || 'Failed to export' }),
        );
      }
    };

    if (initExport) {
      webSocketConnection();
      dispatch(clearInitExportField());
    }
  }, [dispatch, filters, idToken, initExport, model]);

  useEffect(() => {
    if (success) {
      dispatch(clearExportSuccessField());
    }
  }, [dispatch, success]);

  return show ? (
    <Flowbite theme={{ theme }}>
      <div className="absolute bottom-12 left-[50rem] z-50 w-fit p-4">
        <Toast>
          <div>
            {exportInProgress && <ExportInProgress />}
            {exportCompleted && <ExportCompleted />}
            {exportFailed && <ExportFailed />}
          </div>
        </Toast>
      </div>
    </Flowbite>
  ) : null;
};

export default ExportBanner;
