import { useEffect, useState } from 'react';

import notFound from 'assets/icons/notFound.png';

type ImageProps = {
  value: string;
  label?: string;
};

const Image = ({ value, label }: ImageProps) => {
  const [imageData, setImageData] = useState({
    src: value,
    hint: '',
  });

  useEffect(() => {
    if (value) {
      setImageData({ src: value, hint: '' });
    }

    if (!value) {
      setImageData({ src: notFound, hint: 'No image available' });
    }
  }, [value]);

  const onImgError = () =>
    setImageData({ src: notFound, hint: 'Unable to display image' });

  return (
    <div className="!m-0 flex">
      <div className="flex flex-col">
        {value ? (
          <>
            <h1 className="mb-2 text-xs font-semibold text-gray-500">
              {label}
            </h1>
            <div className="flex h-[9.3rem] w-[44rem] justify-center justify-self-center border-2 border-gray-100 p-4">
              <img
                src={imageData.src}
                alt="imgPreview"
                onError={onImgError}
                className="!h-[110px] !w-[160px] justify-center"
              />
            </div>
          </>
        ) : (
          <>
            <h1 className="mb-3 text-xs font-semibold text-gray-500">
              {label}
            </h1>
            <div className="flex h-[9.3rem] w-[44rem] justify-center border-2 border-gray-100 bg-gray-100 p-4">
              <p className="self-center text-xs font-normal italic text-gray-500">
                {imageData.hint}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Image;
