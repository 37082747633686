/* eslint-disable import/prefer-default-export */
import {
  fetchAudiencesFail,
  fetchAudiencesInit,
  fetchAudiencesSuccess,
  clearAudiencesErrorsProps,
  addAudienceRule,
  saveAudienceData,
  selectedAudienceRule,
  clearSelectedRule,
  createAudienceInit,
  createAudienceSuccess,
  createAudienceFail,
  editAudienceInit,
  editAudienceSuccess,
  editAudienceFail,
  addAudienceToOfferInit,
  addAudienceToOfferSuccess,
  addAudienceToOfferFail,
  removeAudienceToOfferInit,
  removeAudienceToOfferSuccess,
  removeAudienceToOfferFail,
  fetchAudiencesDataPointsInit,
  fetchAudiencesDataPointsSuccess,
  fetchAudiencesDataPointsFail,
  clearAddAndRemoveAudienceToOffer,
  updateAudienceRule,
  clearAudience,
  editingAudience,
} from 'state/actionCreators/audiences';

import createAudienceService from 'services/audiences/createAudience';
import editAudienceService from 'services/audiences/editAudience';
import fetchAudiencesService from 'services/audiences/fetchAudiences';
import fetchAudienceByIdService from 'services/audiences/fetchAudienceById';
import updateAudienceToOfferService from 'services/audiences/updateAudienceOffers';
import fetchAudienceSummaryDataPoints from 'services/audiences/fetchAudienceSummaryDataPoints';

export const fetchAudiences =
  (filters = '') =>
  async (dispatch) => {
    dispatch(fetchAudiencesInit());

    try {
      const audiences = await fetchAudiencesService(filters);
      return dispatch(fetchAudiencesSuccess({ audiences }));
    } catch (error) {
      return dispatch(fetchAudiencesFail({ error: error.message }));
    }
  };

export const fetchAudienceById = (id) => async (dispatch) => {
  dispatch(fetchAudiencesInit());

  try {
    const audiences = await fetchAudienceByIdService(id);
    return dispatch(fetchAudiencesSuccess({ audiences }));
  } catch (error) {
    return dispatch(fetchAudiencesFail({ error: error.message }));
  }
};

export const clearAudiencesErrors = () => clearAudiencesErrorsProps();

export const addNewAudienceRule = (rule) => addAudienceRule({ rule });

export const saveAudienceInformation = (audience) =>
  saveAudienceData({ audience });

export const saveSelectedAudienceRule = (rule, index) =>
  selectedAudienceRule({ rule, index });

export const updateSelectedAudienceRule = (rule, index) =>
  updateAudienceRule({ rule, index });

export const clearSelectedAudienceRule = () => clearSelectedRule();

export const createAudience = (body) => async (dispatch) => {
  dispatch(createAudienceInit());

  try {
    const resp = await createAudienceService(body);
    return dispatch(createAudienceSuccess(resp));
  } catch (error) {
    return dispatch(createAudienceFail({ error: error.message }));
  }
};

export const editAudience = (id, body) => async (dispatch) => {
  dispatch(editAudienceInit());

  try {
    await editAudienceService(id, body);
    return dispatch(editAudienceSuccess());
  } catch (error) {
    return dispatch(editAudienceFail({ error: error.message }));
  }
};

export const addAudienceToOffer = (idAudience, body) => async (dispatch) => {
  dispatch(addAudienceToOfferInit());

  try {
    await updateAudienceToOfferService(idAudience, body);
    return dispatch(addAudienceToOfferSuccess());
  } catch (error) {
    return dispatch(addAudienceToOfferFail({ error: error.message }));
  }
};

export const removeAudienceToOffer = (idAudience, body) => async (dispatch) => {
  dispatch(removeAudienceToOfferInit());

  try {
    await updateAudienceToOfferService(idAudience, body);
    return dispatch(removeAudienceToOfferSuccess());
  } catch (error) {
    return dispatch(removeAudienceToOfferFail({ error: error.message }));
  }
};

export const clearAddAndRemoveAudienceToOfferService =
  () => async (dispatch) => {
    try {
      return dispatch(clearAddAndRemoveAudienceToOffer());
    } catch (error) {
      return dispatch(removeAudienceToOfferFail({ error: error.message }));
    }
  };

export const fetchAudiencesSummaryDataPoints = () => async (dispatch) => {
  dispatch(fetchAudiencesDataPointsInit());

  try {
    const audiences = await fetchAudienceSummaryDataPoints();
    return dispatch(fetchAudiencesDataPointsSuccess({ audiences }));
  } catch (error) {
    return dispatch(fetchAudiencesDataPointsFail({ error: error.message }));
  }
};

export const clearAudienceData = () => clearAudience();

export const isEditingAudience = () => editingAudience();
