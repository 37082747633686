'use client';

import { addMonths, format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange, DayPickerProps } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { Calendar } from '@/components/ui/Calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/Popover';

import { forwardRef, useState } from 'react';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/ToggleGroup';
import LayoutGap from '../../Common/LayoutGap';

type DatePickerProps = {
  onChange: (date?: DateRange) => void;
  value?: DateRange;
  placeholder?: string;
  disabled?: boolean;
} & DayPickerProps;

const DatePickerRange = forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    { onChange, value, placeholder = 'Select date range...', disabled },
    ref,
  ) => {
    const [preset, setPreset] = useState<string>();

    return (
      <Popover>
        <PopoverTrigger asChild>
          <button
            id="date"
            type="button"
            className={cn(
              'flex h-9 w-full items-center justify-start gap-1 rounded-md border border-neutral-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors focus:border-primary-500 focus:outline-none disabled:cursor-not-allowed disabled:border-neutral-200 disabled:opacity-50',
              (!value?.from || !value?.to) && 'text-muted-foreground',
            )}
            ref={ref}
            disabled={disabled}
          >
            <CalendarIcon className="size-4" />
            {value?.from && value?.to ? (
              <>
                {format(value.from, 'LLL dd, y')} -{' '}
                {format(value.to, 'LLL dd, y')}
              </>
            ) : (
              <span>{placeholder}</span>
            )}
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <LayoutGap direction="column" size="none" className="items-start">
            {/* TODO: pass preset options as props */}
            <ToggleGroup
              className="p-2"
              type="single"
              size="sm"
              onValueChange={(monthCount) => {
                if (monthCount) {
                  setPreset(monthCount);
                  onChange({
                    from: addMonths(new Date(), -parseInt(monthCount, 10)),
                    to: new Date(),
                  });
                }
              }}
              value={preset}
            >
              <ToggleGroupItem value="1">Last month</ToggleGroupItem>
              <ToggleGroupItem value="3">Last 3 months</ToggleGroupItem>
              <ToggleGroupItem value="6">Last 6 months</ToggleGroupItem>
              <ToggleGroupItem value="12">Last year</ToggleGroupItem>
            </ToggleGroup>
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={value?.from || new Date()}
              selected={value}
              onSelect={(date) => {
                onChange(date);
                setPreset('');
              }}
              numberOfMonths={2}
            />
          </LayoutGap>
        </PopoverContent>
      </Popover>
    );
  },
);

export default DatePickerRange;
