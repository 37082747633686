import { AxiosError } from 'axios';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@/components/Common/Button';
import LayoutGap from '@/components/Common/LayoutGap';
import Form from '@/components/ui/Form';
import { Separator } from '@/components/ui/Separator';
import EmptyState from '@/components/Common/EmptyState';
import CloseIcon from 'assets/icons/x.svg?react';

import useFlag from '@/hooks/useFlag';
import { useMemo } from 'react';
import {
  SegmentDetailsFormValues,
  segmentDetailsSchema,
  segmentTypeOptions,
  ruleTypeOptions,
  operatorOptions,
  listOperatorOptions,
  transactionRuleSchema,
  userRuleSchema,
  activeStatusOptions,
  inactiveStatusOptions,
  inProgressStatusOptions,
  draftStatusOptions,
} from '../schema';

type SegmentDetailsFormProps = {
  defaultValues?: SegmentDetailsFormValues;
  onSubmit: SubmitHandler<SegmentDetailsFormValues>;
  isSubmitting: boolean;
  submitError?: AxiosError | null;
  isEditing?: boolean;
};

const SegmentDetailsForm = ({
  defaultValues = segmentDetailsSchema.cast({}),
  onSubmit,
  isSubmitting,
  submitError,
  isEditing = false,
}: SegmentDetailsFormProps) => {
  const isReadOnly = useMemo(
    () => ['INACTIVE', 'IN_PROGRESS'].includes(defaultValues.status),
    [defaultValues.status],
  );

  const form = useForm<SegmentDetailsFormValues>({
    resolver: yupResolver(segmentDetailsSchema),
    defaultValues,
    mode: 'onTouched',
    disabled: isReadOnly,
  });

  const isActive = defaultValues.status === 'ACTIVE' || form.formState.disabled;

  const rules = useFieldArray({
    control: form.control,
    name: 'rules',
  });

  const changeRuleType = (index: number, value: string) => {
    if (value === 'purchases') {
      rules.update(index, transactionRuleSchema.cast({}));
    } else if (value === 'user') {
      rules.update(index, userRuleSchema.cast({}));
    }
  };

  const segmentUserRulesFlag = useFlag('segment-user-rules');

  /**
   * This is a workaround to check if the form is dirty because the formState.isDirty
   * is not working as expected and always returns true
   */
  const isDirty = Object.keys(form.formState.dirtyFields).length > 0;

  const segmentStatus = form.watch('status');

  const isSubmitDisabled = useMemo(() => {
    // Active segment cannot be edited except for changing the status to INACTIVE
    const isRemainingActive =
      defaultValues.status === 'ACTIVE' && segmentStatus === 'ACTIVE';

    return !isDirty || isRemainingActive || form.formState.disabled;
  }, [form.formState.disabled, defaultValues.status, segmentStatus, isDirty]);

  const statusOptions = useMemo(() => {
    if (defaultValues.status === 'ACTIVE') {
      return activeStatusOptions;
    }
    if (defaultValues.status === 'INACTIVE') {
      return inactiveStatusOptions;
    }
    if (defaultValues.status === 'IN_PROGRESS') {
      return inProgressStatusOptions;
    }
    return draftStatusOptions;
  }, [defaultValues.status]);

  const rulesArrayError = useMemo(
    () =>
      form.formState.errors.rules?.message ||
      form.formState.errors.rules?.root?.message,
    [form.formState.errors.rules],
  );

  return (
    <Form.Provider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <LayoutGap className="max-h-[70vh] items-stretch overflow-auto">
          <LayoutGap direction="column" className="sticky top-0 flex-grow">
            {isEditing && (
              <Form.Input label="ID" name="id" type="text" disabled />
            )}
            <Form.Input
              label="Name"
              name="name"
              type="text"
              autoComplete="off"
              placeholder="Enter segment name"
              disabled={isActive}
            />
            <Form.Select
              label="Status"
              name="status"
              placeholder="Select status"
              options={statusOptions}
            />
            <Form.Select
              name="segmentType"
              label="Type"
              placeholder="Select segment type"
              options={segmentTypeOptions}
              disabled={isActive}
            />
            {isEditing && (
              <>
                <Form.Input label="Size" name="size" type="text" disabled />
                <Form.Input
                  label="Creation Date"
                  name="created"
                  type="text"
                  disabled
                />
              </>
            )}
          </LayoutGap>
          <LayoutGap direction="column" className="w-2/3">
            {rules.fields.length === 0 && (
              <EmptyState
                title="No rules added"
                description="Add rules to segment"
                className="my-10"
              />
            )}
            {rules.fields.length > 0 &&
              rules.fields.map((ruleField, index) => (
                <LayoutGap key={ruleField.id} className="items-center">
                  <LayoutGap direction="column" className="flex-grow">
                    <LayoutGap
                      size="small"
                      className="grid flex-grow grid-cols-6"
                    >
                      <Form.Select
                        label="Rule Type"
                        name={`rules.${index}.ruleType`}
                        className="col-span-2"
                        options={ruleTypeOptions.filter(
                          (ruleType) =>
                            segmentUserRulesFlag.enabled ||
                            ruleType.value !== 'user',
                        )}
                        onValueChange={(value) => changeRuleType(index, value)}
                        disabled={isActive}
                      />
                      {ruleField.ruleType === 'purchases' && (
                        <>
                          <Form.Select
                            name={`rules.${index}.operator`}
                            label="Operator"
                            options={operatorOptions}
                            disabled={isActive}
                          />
                          <Form.Input
                            className="col-span-3"
                            name={`rules.${index}.transactionCount`}
                            label="Transaction Count"
                            placeholder="Count"
                            type="number"
                            step={1}
                            min={0}
                            disabled={isActive}
                          />
                        </>
                      )}
                      {ruleField.ruleType === 'user' && (
                        <>
                          <Form.Select
                            name={`rules.${index}.operator`}
                            label="Operator"
                            options={listOperatorOptions}
                            defaultValue="in"
                            disabled={isActive}
                          />
                          <Form.Input
                            className="col-span-3"
                            name={`rules.${index}.customSegmentId`}
                            label="Custom Segment ID"
                            placeholder="Enter custom segment ID"
                            disabled={isActive}
                          />
                        </>
                      )}
                    </LayoutGap>
                    {ruleField.ruleType === 'purchases' && (
                      <LayoutGap size="small" className="grid grid-cols-2">
                        <Form.DateRange
                          label="Date Range"
                          name={`rules.${index}.dateRange`}
                          disabled={isActive}
                        />
                        <Form.Input
                          name={`rules.${index}.kardMerchantId`}
                          label="Merchant ID"
                          placeholder="Enter merchant ID"
                          type="text"
                          className="w-full"
                          disabled={isActive}
                        />
                      </LayoutGap>
                    )}
                    <Separator />
                  </LayoutGap>
                  {!form.formState.disabled && !isActive && (
                    <Button
                      type="button"
                      variant="tertiary"
                      icon={<CloseIcon />}
                      onClick={() => rules.remove(index)}
                    />
                  )}
                </LayoutGap>
              ))}
            <LayoutGap className="items-center">
              <Button
                type="button"
                variant="secondary"
                onClick={() => rules.append(transactionRuleSchema.cast({}))}
                disabled={form.formState.disabled || isActive}
              >
                Add Rule
              </Button>
              {rulesArrayError && (
                <p className="text-red-500">{rulesArrayError}</p>
              )}
            </LayoutGap>
            <div className="sticky -bottom-[1px] flex justify-end bg-white py-4">
              <LayoutGap className="items-center">
                {submitError && (
                  <p className="text-red-500">{submitError.message}</p>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  loading={isSubmitting}
                  disabled={isSubmitDisabled}
                >
                  {isEditing ? 'Save' : 'Create'} segment
                </Button>
              </LayoutGap>
            </div>
          </LayoutGap>
        </LayoutGap>
      </form>
    </Form.Provider>
  );
};

export default SegmentDetailsForm;
