import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navbar, Dropdown, Avatar, Flowbite } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { selectUserAttributesState } from 'state/selectors/auth';
import {
  selectFetchUserProfileState,
  selectFetchKnockTokenState,
} from 'state/selectors/users';
import { fetchUserProfile, fetchKnockToken } from 'state/actions/users';
import selectFeatureFlagsState from 'state/selectors/featureFlags';

import { logout } from 'state/actions/auth';

import getNameInitials from 'utils/getNameInitials';
import NotificationFeed from 'components/NotificationFeed';
import Path from 'enums/path.enum';
import theme from './theme';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fullName, email, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );
  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);
  const { token } = useSelector(selectFetchKnockTokenState, shallowEqual);

  const { userProfile } = useSelector(
    selectFetchUserProfileState,
    shallowEqual,
  );

  const { PORTAL_NOTIFICATION } = flags;

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (userProfile?.username && !userProfile?.isAdmin) {
      dispatch(fetchKnockToken(userProfile.username));
    }
  }, [dispatch, userProfile]);

  const onClickLogoutHandler = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const onClickEditProfileHandler = useCallback(() => {
    navigate(Path.Profile);
  }, [navigate]);

  const onClickSettingsHandler = useCallback(() => {
    navigate(Path.Settings);
  }, [navigate]);

  const dropdownItems = useMemo(
    () => [
      PORTAL_NOTIFICATION && !isAdmin
        ? { name: 'Settings', onClick: onClickSettingsHandler }
        : { name: 'Profile', onClick: onClickEditProfileHandler },
      {
        name: 'Log Out',
        onClick: onClickLogoutHandler,
        testId: 'logout-button',
      },
    ],
    [
      onClickEditProfileHandler,
      onClickLogoutHandler,
      onClickSettingsHandler,
      isAdmin,
      PORTAL_NOTIFICATION,
    ],
  );

  const nameInitials = useMemo(() => getNameInitials(fullName), [fullName]);

  return (
    <Flowbite theme={{ theme }}>
      <Navbar className="flex items-center justify-end p-2 !pr-4">
        {PORTAL_NOTIFICATION && !isAdmin && token && (
          <div className="mr-1">
            <NotificationFeed token={token} />
          </div>
        )}

        <div className="flex items-end justify-between">
          <div
            className="flex items-center lg:gap-3"
            data-cy="profile-dropdown"
            data-testid="account-menu-button"
          >
            <Dropdown
              className="z-50 bg-white"
              arrowIcon
              inline
              label={
                <span>
                  <span className="sr-only">User menu</span>
                  <Avatar
                    placeholderInitials={nameInitials}
                    rounded
                    size="md"
                    className="bg-white"
                  />
                </span>
              }
            >
              <Dropdown.Header>
                <span className="block text-sm font-bold">
                  {fullName || 'Admin User'}
                </span>
                <span className="block truncate text-sm font-medium">
                  {email}
                </span>
              </Dropdown.Header>

              {dropdownItems.map((item) => (
                <Dropdown.Item
                  onClick={item.onClick}
                  key={item}
                  data-testid={item.testId}
                >
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        </div>
      </Navbar>
    </Flowbite>
  );
};

Header.propTypes = {};

export default Header;
