/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  exportDataInit,
  exportDataSuccess,
  exportDataFail,
  exportDataHide,
  exportDataClearSuccess,
  exportDataClearInit,
  exportDataCancelExport,
} from 'state/actionCreators/exportData';

export const initialState = {
  exportDataLoading: false,
  exportDataSuccess: false,
  exportDataError: null,
  data: null,
  show: false,
  model: null,
  filters: null,
  idToken: null,
  exportCompleted: false,
  initExport: false,
  cancelExport: false,
};

const persistConfig = {
  key: 'exportData',
  storage,
  blacklist: [
    'exportDataLoading',
    'exportDataSuccess',
    'exportDataError',
    'data',
    'show',
    'model',
    'filters',
    'idToken',
    'exportCompleted',
    'initExport',
    'cancelExport',
  ],
};

const exportDataReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(exportDataInit, (state, action) => {
      const { model, filters, idToken } = action.payload;
      state.show = true;
      state.exportDataLoading = true;
      state.exportDataError = null;
      state.exportDataSuccess = false;
      state.model = model;
      state.filters = filters;
      state.idToken = idToken;
      state.exportCompleted = false;
      state.initExport = true;
      state.cancelExport = false;
    });

    builder.addCase(exportDataSuccess, (state, action) => {
      const { data } = action.payload;
      state.exportDataLoading = false;
      state.exportDataError = null;
      state.exportDataSuccess = true;
      state.data = data;
      state.exportCompleted = true;
    });

    builder.addCase(exportDataFail, (state, action) => {
      const { error } = action.payload;
      state.exportDataLoading = false;
      state.exportDataError = error;
      state.exportDataSuccess = false;
      state.exportCompleted = false;
    });

    builder.addCase(exportDataHide, () => (state) => {
      state.show = false;
    });

    builder.addCase(exportDataClearSuccess, (state) => {
      state.exportDataSuccess = false;
    });

    builder.addCase(exportDataClearInit, (state) => {
      state.initExport = false;
    });

    builder.addCase(exportDataCancelExport, (state) => {
      state.cancelExport = true;
      state.show = false;
    });
  }),
);

export default exportDataReducer;
