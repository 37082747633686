import { useEffect } from 'react';
import { REWARDS_BASE_URL, useAxios } from 'services/kardAPI';

const useMerchantNetworks = (shouldFetch?: boolean) => {
  const [
    { data: merchantNetworks = [], loading, error },
    fetchMerchantNetworks,
  ] = useAxios({ url: `${REWARDS_BASE_URL}/portal/network` }, { manual: true });

  useEffect(() => {
    if (shouldFetch) {
      fetchMerchantNetworks();
    }
  }, [shouldFetch, fetchMerchantNetworks]);

  return [merchantNetworks, loading, error];
};

export default useMerchantNetworks;
