import Switch, { Size as SwitchSize } from 'components/Common/Switch';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import {
  statuses,
  resolutionCodes,
  sources,
  merchantSources,
  auditCodes,
} from 'utils/transactionsSupport/values';
import { AuditStatusOriginal, AuditStatus } from 'enums/status/auditStatus';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import msg from 'utils/commonMessages';

import dateParse from 'utils/common/dateParse';
import classes from './AuditForm.module.scss';

export const getInputFields = ({ readOnly, isEditing, audit }) => [
  {
    name: 'transactionId',
    renderProps: {
      label: `Transaction ID*`,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      labelClassName: classes.labelWidth,
      disabled: isEditing,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'auditCode',
    defaultValue: audit.auditCode,
    label: `Audit Code*`,
    labelClassName: classes.labelWidth,
    options: auditCodes,
    type: InputType.Text,
    readOnly,
    disabled: isEditing,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
    component: FormControlSelect,
    placeholder: 'Select code...',
  },
  {
    name: 'merchantName',
    renderProps: {
      label: `Merchant Name*`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      disabled: isEditing,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'merchantId',
    renderProps: {
      label: `Kard Merchant ID`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'transactionAmountInCents',
    renderProps: {
      label: `Transaction Amount In Cents`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'preferredContactEmail',
    renderProps: {
      label: `Preferred Contact Email*`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      disabled: isEditing,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'referringPartnerUserId',
    renderProps: {
      label: `Referring Partner User ID`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'auditDescription',
    type: 'textarea',
    renderProps: {
      label: `Audit Description*`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly,
      disabled: isEditing,
      placeholder: msg.DescriptionSupportMessage,
      color: isEditing ? InputColor.Black : InputColor.Gray,
      preventNewLines: true,
      showCharacterLength: true,
    },
  },
  {
    name: 'submitterName',
    renderProps: {
      label: `Submitter Name`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
];

export const getEditingFields = ({
  audit,
  isEditing,
  readOnly,
  internalDispute,
  onSetInternalDisputeHandler,
  validDispute,
  onSetValidDispute,
}) => [
  {
    component: FormControlSelect,
    name: 'status',
    defaultValue: AuditStatusOriginal[audit.status],
    label: 'Status',
    labelClassName: classes.labelWidth,
    readOnly,
    options: statuses,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
    isOptionDisabled: ({ label }) => isEditing && label === AuditStatus.NEW,
  },
  {
    component: FormControlSelect,
    name: 'resolutionCode',
    defaultValue: audit.resolutionCode,
    label: 'Resolution Code',
    labelClassName: classes.labelWidth,
    readOnly,
    options: resolutionCodes,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
  },
  {
    component: Input,
    name: 'resolutionDescription',
    renderProps: {
      label: 'Resolution Description',
      labelClassName: classes.labelWidth,
      value: audit.resolutionDescription,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: Switch,
    name: 'internalDispute',
    enabled: internalDispute,
    setEnabled: onSetInternalDisputeHandler,
    label: 'Internal Dispute:',
    labelClassName: classes.labelWidth,
    leftText: 'No',
    rightText: 'Yes',
    size: SwitchSize.S,
    disabled: readOnly,
    className: classes.switch,
  },
  {
    component: FormControlSelect,
    name: 'source',
    defaultValue: audit.source,
    label: 'Source',
    labelClassName: classes.labelWidth,
    readOnly,
    options: sources,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
  },
  {
    component: Input,
    name: 'transactionDate',
    renderProps: {
      label: 'Transaction Date',
      labelClassName: classes.labelWidth,
      value: `${dateParse(audit.createdDate)}`,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: Input,
    name: 'lastModified',
    renderProps: {
      label: 'Last Modified',
      labelClassName: classes.labelWidth,
      value: `${dateParse(audit.lastModified)}`,
      type: InputType.Text,
      readOnly,
      disabled: isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: Switch,
    name: 'validDispute',
    enabled: validDispute,
    setEnabled: onSetValidDispute,
    label: 'Valid Dispute:',
    labelClassName: classes.labelWidth,
    leftText: 'Invalid',
    rightText: 'Valid',
    size: SwitchSize.S,
    disabled: readOnly,
    className: classes.switch,
  },
  {
    component: Input,
    name: 'matchedMerchant',
    renderProps: {
      label: 'Matched Merchant',
      labelClassName: classes.labelWidth,
      value: audit.matchedMerchant,
      type: InputType.Text,
      readOnly,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: FormControlSelect,
    name: 'merchantSource',
    defaultValue: audit.merchantSource,
    label: 'Merchant Source',
    labelClassName: classes.labelWidth,
    readOnly,
    options: merchantSources,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
  },
];
