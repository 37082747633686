import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Button from 'components/Common/Button';
import Checkbox, { Size as CheckboxSize } from 'components/Common/Checkbox';
import FormControlSelect from 'components/Common/FormControlSelect';
import FormControlDatePicker from 'components/Common/FormControlDatePicker';
import Textarea from 'components/Common/Textarea';
import { clearOffersSuccess, createOffer } from 'state/actions/offers';
import selectFeatureFlagsState from 'state/selectors/featureFlags';

import {
  commissionTypes,
  offerTypes,
  sources,
  newOfferStatusOptions,
} from 'utils/offers/values';
import { CommissionType } from 'enums/offers/commissionTypes.enum';
import { selectCreateOfferState } from 'state/selectors/offers';
import dollarIcon from 'assets/icons/dollar.png';
import percentIcon from 'assets/icons/percent.png';
import {
  MerchantCategoryCodeOptions,
  filterMerchantCategoryCode,
} from 'utils/offers/merchantCategoryCodes';

import { selectFetchMerchantNetworksState } from 'state/selectors/merchantNetworks';
import { fetchMerchantNetworks } from 'state/actions/merchantNetworks';
import { fetchMerchantIssuers } from 'state/actions/merchants';
import StatusValue from 'enums/status/statusValue.enum';
import PageHeader from 'components/Common/PageHeader';
import LayoutGap from 'components/Common/LayoutGap';
import Fieldset from 'components/Common/Fieldset';
import AdminCommissionSplit from 'components/Forms/AdminCommissionSplit';
import QualifiedIssuersField from '../OfferEdit/QualifiedIssuersField';
import CommissionValueField from '../OfferEdit/CommissionValueField';
import validationSchema from './OfferCreate.schema';
import { shiftDateFromDatePickerBackToUTC } from './utils';
import classes from './OfferCreate.module.scss';
import { MerchantNetwork, OfferCreatePageProps, Values, Body } from './types';

const SELECT_ALL_WHITESPACE_REGEX = /\s/g;

const OfferCreate = ({ onCancel }: OfferCreatePageProps) => {
  const dispatch = useDispatch();

  const CommissionTypeIcon = Object.freeze({
    [CommissionType.Percent]: percentIcon,
    [CommissionType.Flat]: dollarIcon,
  });

  const { merchantNetworks } = useSelector(
    selectFetchMerchantNetworksState,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchMerchantNetworks());
    dispatch(fetchMerchantIssuers());
  }, [dispatch]);

  const merchantNetworkOptions = merchantNetworks.map(
    (item: MerchantNetwork) => ({
      label: item?.name,
      value: item?.name,
    }),
  );

  const {
    loading: loadingCreateOffer,
    error: errorCreate,
    success: successCreateOffer,
  } = useSelector(selectCreateOfferState, shallowEqual);

  const [locationSpecific, setLocationSpecific] = useState(false);
  const [optInRequired, setOptInRequired] = useState(false);
  const [redeemableOnce, setRedeemableOnce] = useState(false);
  const [displayAlways, setDisplayAlways] = useState(false);
  const [selectedCommissionType, setSelectedCommissionType] = useState();

  const commissionTypeIcon = CommissionTypeIcon[selectedCommissionType];

  const formRef = useRef<HTMLInputElement>(null);

  const isValidNumber = useCallback<(value: number) => boolean>(
    (value) => value !== undefined && value >= 0,
    [],
  );

  const onSubmitHandler = useCallback<(values: Values) => void>(
    (values) => {
      const body: Body = {
        ...values,
        isLocationSpecific: locationSpecific,
        optInRequired,
        startDate: shiftDateFromDatePickerBackToUTC(values.startDate), // Format as UTC String
        expirationDate: shiftDateFromDatePickerBackToUTC(values.expirationDate), // Format as UTC String
        merchantId: values.merchantId.replace(SELECT_ALL_WHITESPACE_REGEX, ''),
        redeemableOnce,
        commissionValue: Number(values.commissionValue),
        minTransactionAmount: Number(values.minTransactionAmount),
        maxTransactionAmount: Number(values.maxTransactionAmount),
        minRewardAmount: Number(values.minRewardAmount),
        maxRewardAmount: Number(values.maxRewardAmount),
        minUserRewardAmount: Number(values.minUserRewardAmount),
        maxUserRewardAmount: Number(values.maxUserRewardAmount),
        mcc: values.mcc ?? [],
        displayAlways,
      };

      delete body.category;

      if (!values.terms) {
        delete body.terms;
      }

      if (!values.partnerOfferId) {
        delete body.partnerOfferId;
      }

      if (!isValidNumber(values.minTransactionAmount)) {
        delete body.minTransactionAmount;
      }

      if (!isValidNumber(values.maxTransactionAmount)) {
        delete body.maxTransactionAmount;
      }

      if (!isValidNumber(values.minRewardAmount)) {
        delete body.minRewardAmount;
      }

      if (!isValidNumber(values.maxRewardAmount)) {
        delete body.maxRewardAmount;
      }
      if (!isValidNumber(values.minUserRewardAmount)) {
        delete body.minUserRewardAmount;
      }

      if (!isValidNumber(values.maxUserRewardAmount)) {
        delete body.maxUserRewardAmount;
      }

      if (!values.startDate) {
        delete body.startDate;
      }

      dispatch(createOffer(body));
    },
    [
      locationSpecific,
      optInRequired,
      redeemableOnce,
      dispatch,
      isValidNumber,
      displayAlways,
    ],
  );

  useEffect(() => {
    if (successCreateOffer) {
      dispatch(clearOffersSuccess());
      onCancel();
    }
  }, [successCreateOffer, onCancel, dispatch]);

  const onChangeCheckboxStateHandler = useCallback((setState) => {
    setState((prevState) => !prevState);
  }, []);

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);
  const { REWARDS_2653_UPCOMING_OFFERS_FLAG } = flags;
  const statusOptionsWithoutUpcoming = newOfferStatusOptions.filter(
    (option) => option.value !== StatusValue.Upcoming,
  );

  return (
    <div className="p-10">
      {errorCreate && (
        <Toast
          id="error create offer"
          text={errorCreate}
          type={ToastType.Error}
        />
      )}
      {successCreateOffer && (
        <Toast
          id="success-creating-offer"
          text="Offer created successfully!"
          type={ToastType.Success}
        />
      )}

      <Form
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
        ref={formRef}
      >
        <PageHeader title="Create Offer">
          <Button
            type="submit"
            loading={loadingCreateOffer}
            testId="create-offer-button"
          >
            Create offer
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </PageHeader>
        <LayoutGap direction="column">
          <Fieldset title="Offer Details">
            <LayoutGap direction="column" size="none">
              <FormControl
                name="merchantId"
                render={(props) => (
                  <Input
                    label="Merchant ID"
                    type={InputType.Text}
                    size={InputSize.S}
                    {...props}
                  />
                )}
              />
              <FormControl
                name="name"
                render={(props) => (
                  <Input
                    label="Offer Name"
                    type={InputType.Text}
                    size={InputSize.S}
                    {...props}
                  />
                )}
              />
              <FormControlDatePicker
                name="startDate"
                label="Start Date"
                showTimeSelect
                isUTC
                testId="start-date-picker"
              />

              <FormControlDatePicker
                name="expirationDate"
                label="Expiration Date"
                showTimeSelect
                isUTC
                testId="expiration-date-picker"
              />
              {REWARDS_2653_UPCOMING_OFFERS_FLAG ? (
                <FormControlSelect
                  name="status"
                  defaultValue={StatusValue.Inactive}
                  label="Status"
                  options={newOfferStatusOptions}
                  testId="status-input"
                />
              ) : (
                <FormControlSelect
                  name="status"
                  defaultValue={StatusValue.Inactive}
                  label="Status"
                  disabled
                  readOnly
                  options={statusOptionsWithoutUpcoming}
                  testId="status-input"
                />
              )}
              <FormControlSelect
                name="source"
                label="Source"
                options={sources}
                testId="source-input"
              />
              <FormControlSelect
                name="offerType"
                label="Offer Type"
                options={offerTypes}
                testId="offer-type-input"
              />
              <FormControlSelect
                name="merchantNetwork"
                label="Merchant Network"
                options={merchantNetworkOptions}
                testId="merchant-network-input"
              />
              <FormControl
                name="partnerOfferId"
                render={(props) => (
                  <Input
                    label="Partner Offer ID"
                    type={InputType.Text}
                    size={InputSize.S}
                    {...props}
                  />
                )}
              />
              <Checkbox
                label="Location Specific"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setLocationSpecific)
                }
                selected={locationSpecific}
                className={classes.checkbox}
                size={CheckboxSize.S}
              />
              <FormControlSelect
                filterOption={filterMerchantCategoryCode}
                name="mcc"
                label="Merchant Category Codes"
                options={MerchantCategoryCodeOptions}
                isMulti
                allowSelectAll={false}
                noOptionsMessage={() =>
                  'Start typing MCC number or keyword for suggestions...'
                }
                testId="mcc-input"
              />
              <FormControl
                name="terms"
                className="mb-[0]"
                render={(props) => (
                  <Textarea
                    label="Terms And Conditions"
                    preventNewLines
                    size={InputSize.S}
                    testId="terms-input"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
          </Fieldset>
          <Fieldset title="Commission Details">
            <QualifiedIssuersField isEditing />
            <LayoutGap>
              <FormControlSelect
                name="commissionType"
                label="Commission Type"
                options={commissionTypes}
                onChangeManual={setSelectedCommissionType}
                testId="commission-type-input"
                className="mb-[0] w-[15rem]"
              />
              <CommissionValueField
                isEditing
                commissionTypeIcon={commissionTypeIcon}
              />
            </LayoutGap>
            <AdminCommissionSplit
              commissionType={selectedCommissionType}
              isEditing
            />
          </Fieldset>
          <Fieldset title="Redemption Details">
            <LayoutGap>
              <FormControl
                name="minTransactionAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min Txn Amount In Cents"
                    type={InputType.Number}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxTransactionAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max Txn Amount In Cents"
                    type={InputType.Number}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap>
              <FormControl
                name="minRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min Reward Amount In Cents"
                    type={InputType.Number}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max Reward Amount In Cents"
                    type={InputType.Number}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap>
              <FormControl
                name="minUserRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Min User Reward Amount In Cents"
                    type={InputType.Number}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
              <FormControl
                name="maxUserRewardAmount"
                className="mb-[0]"
                render={(props) => (
                  <Input
                    label="Max User Reward Amount In Cents"
                    type={InputType.Number}
                    width="w-[15rem]"
                    {...props}
                  />
                )}
              />
            </LayoutGap>
            <LayoutGap direction="column" size="none">
              <Checkbox
                label="Opt-in Required"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setOptInRequired)
                }
                selected={optInRequired}
                className={classes.checkbox}
              />
              <Checkbox
                label="Redeemable Once"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setRedeemableOnce)
                }
                selected={redeemableOnce}
                className={classes.checkbox}
                size={CheckboxSize.S}
              />
              <Checkbox
                label="Always Appears to Cardholder"
                setSelected={() =>
                  onChangeCheckboxStateHandler(setDisplayAlways)
                }
                selected={displayAlways}
                className={classes.checkbox}
                size={CheckboxSize.S}
              />
            </LayoutGap>
          </Fieldset>
        </LayoutGap>
      </Form>
    </div>
  );
};

OfferCreate.propTypes = {
  onCancel: PropTypes.func,
};
OfferCreate.defaultProps = {
  onCancel: noop,
};

export default OfferCreate;
