import { useState } from 'react';
import { toast } from 'sonner';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ExperimentRequest,
  experimentRequestSchema,
  ExperimentResponse,
  useUpdateExperiment,
} from '@/hooks/api/experiments';

import Form from '@/components/ui/Form';
import PageHeader from '@/components/Common/PageHeader';
import Button from '@/components/Common/Button';
import LayoutGap from '@/components/Common/LayoutGap';
import { DisplayValue } from '@/components/Common/DisplayData';

import { tryCatch } from '@/utils/tryCatch';

type UpdateExperimentFormProps = ExperimentResponse & {
  onSuccess?: (response: ExperimentResponse) => void;
};

const UpdateExperimentForm = ({
  id,
  attributes,
  createdAt,
  updatedAt,
  onSuccess,
}: UpdateExperimentFormProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updateExperimentState, updateExperiment] = useUpdateExperiment(id);

  const form = useForm<ExperimentRequest>({
    resolver: zodResolver(experimentRequestSchema),
    defaultValues: {
      attributes,
    },
  });

  const onSubmit = async (data: ExperimentRequest) => {
    const [error, response] = await tryCatch(updateExperiment({ data }));

    if (error) {
      toast.error('Error updating experiment', {
        description: error.message,
      });
    } else {
      setIsEditing(false);
      onSuccess?.(response.data);
      toast.success('Success!', {
        description: 'Experiment updated successfully!',
      });
    }
  };

  return (
    <LayoutGap direction="column">
      <PageHeader title="Experiment">
        {isEditing ? (
          <>
            <Button
              type="button"
              disabled={updateExperimentState.loading}
              onClick={form.handleSubmit(onSubmit)}
            >
              Save Changes
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setIsEditing(false);
                form.reset();
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button type="button" onClick={() => setIsEditing(true)}>
            Edit Experiment
          </Button>
        )}
      </PageHeader>
      <Form.Provider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <LayoutGap direction="column">
            <DisplayValue label="Experiment ID" value={id} type="id" />
            <DisplayValue
              label="Created At"
              value={createdAt}
              type="datetime"
            />
            <DisplayValue
              label="Updated At"
              value={updatedAt}
              type="datetime"
            />
            <Form.Input
              name="attributes.name"
              label="Experiment Name"
              placeholder="Enter experiment name"
              disabled={!isEditing}
            />
          </LayoutGap>
        </form>
      </Form.Provider>
    </LayoutGap>
  );
};

export default UpdateExperimentForm;
