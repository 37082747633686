import { createAction } from '@reduxjs/toolkit';

export type FlagsState = {
  error: string | null;
  flags: Record<string, boolean>;
};

export const fetchFeatureFlagsInit = createAction(
  '[Auth] Fetch Feature Flags Init',
);
export const fetchFeatureFlagsSuccess = createAction<Pick<FlagsState, 'flags'>>(
  '[Auth] Fetch Feature Flags Success',
);
export const fetchFeatureFlagsFail = createAction<Pick<FlagsState, 'error'>>(
  '[Auth] Fetch Feature Flags Fail',
);
