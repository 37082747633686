enum CognitoUserAttributes {
  fullName = 'name',
  email = 'email',
  title = 'custom:title',
  roles = 'custom:auth_role',
  phoneNumber = 'phone_number',
  issuerName = 'custom:issuerName',
}

export default CognitoUserAttributes;
