import { useCallback, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'flowbite-react';
import { noop } from 'lodash';

/** Redux and custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import { fetchPortalUsers } from 'state/actions/users';
import {
  clearResendConfirmation,
  resendConfirmationCode,
} from 'state/actions/auth';
import {
  selectUserAttributesState,
  selectResendConfirmationCodeState,
} from 'state/selectors/auth';
import {
  selectFetchPortalUsersState,
  selectCreatePortalUserState,
} from 'state/selectors/users';
import useIssuers from 'refactored/hooks/useIssuers';

/** custom components */
import SearchBy from 'refactored/components/Common/SearchBy';
import Table from 'refactored/components/Common/Table';
import Pagination from 'refactored/components/Common/Pagination2';
import PageHeader from 'components/Common/PageHeader';
import Button from 'components/Common/Button';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import commonToast from 'components/Common/commonToast';
import AddNewUserModal from 'refactored/pages/PortalUsers/PortalUsersComponents/AddNewUserModal';
import SelectDropdown from 'refactored/components/Common/SelectDropdown';
import FormControlSelect from 'components/Common/FormControlSelect';
import Form from 'components/Common/Form';

/* utils */
import makeColumns from 'refactored/utils/users/makeColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import msg from 'utils/commonMessages';
import { getRoleLabelForId } from 'utils/users/roles';

/* assets */
import AddIcon from 'assets/icons/plus.svg?react';

/* enums */
import Path from 'enums/path.enum';
import Status from 'enums/status/status.enum';

/* styles  */
import MultiSelectDropdown from 'refactored/components/Common/MultiSelectDropdown';
import classes from './PortalUsers.module.scss';

/* portal users main component */
const PortalUsers = () => {
  const dispatch = useDispatch();

  const {
    success: successResendingConfirmation,
    error: errorResendingConfirmation,
  } = useSelector(selectResendConfirmationCodeState, shallowEqual);

  /* internal state */
  const [data, setData] = useState([]);
  const [displayAddUserModal, setDisplayAddUserModal] = useState(false);
  const [selectedIssuer, setSelectedIssuer] = useState(null);

  /* search options */
  const searchStatusOptions = [
    { label: 'Active', value: 'enabled' },
    { label: 'Inactive', value: 'disabled' },
  ];

  const searchRolesOptions = [
    { label: 'Kard admin', value: 'admin.kard' },
    { label: 'Kard read only', value: 'reporter.kard' },
    { label: 'Issuer read only', value: 'reporter.issuer' },
    { label: 'Data Admin', value: 'data.admin' },
  ];

  /* query framework */
  const [query, updateQuery] = useQuery({
    pagination: { page: 0, limit: 50 },
    sort: { direction: -1, columnName: 'createdDate' },
  });

  /* selectors */
  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const {
    portalUsersData,
    loading: loadingFetchPortalUsers,
    error: errorFetchUsers,
  } = useSelector(selectFetchPortalUsersState, shallowEqual);

  // create  user watcher
  const { success: successCreate } = useSelector(
    selectCreatePortalUserState,
    shallowEqual,
  );

  const [issuers, loadingIssuer] = useIssuers({ shouldFetch: true });

  /* const */
  const { countTotal, portalUsers } = portalUsersData;

  /* methods */
  const onAddUserHandler = useCallback(() => {
    setDisplayAddUserModal(true);
  }, []);

  const onEditHandler = useCallback((user) => {
    const transformedUser = {
      ...user,
      fullName: user.Attributes[1]?.Value,
      title: user.Attributes[0]?.Value,
      roles: [user.Attributes[2]?.Value],
      issuerName: user.Attributes[4]?.Value,
      email: user.Attributes[5]?.Value,
      phoneNumber: user.Attributes[3]?.Value,
      status: user.Enabled ? Status.ACTIVE : Status.INACTIVE,
      username: user.Username,
    };
    const editUserTab = window.open(
      `${Path.PortalUsers}/edit/${user.Username}`,
      '_blank',
    );

    // TODO: This is a temp solution, eventually we will fetch the user by ID from the EditUser page
    // Emitting a MessageEvent for the EditUser page to listen to and grab user data
    editUserTab.onload = () => {
      editUserTab.postMessage(transformedUser);
    };
  }, []);

  const onResendHandler = useCallback(
    (user) => {
      const body = {
        email: user.email,
        name: user.fullName,
        title: user.title,
        phone_number: user.phoneNumber,
        issuerName: user.issuerName,
        role: user.Attributes[2]?.Value,
        isResend: true,
      };

      dispatch(resendConfirmationCode(body));
    },
    [dispatch],
  );

  /* table columns */
  const columns = useMemo(
    () =>
      getAllowedColumns(
        makeColumns({ onEditHandler, onResendHandler }),
        userRoles,
      ),
    [userRoles, onEditHandler, onResendHandler],
  );

  const memoizedQuery = useMemo(
    () => queryToSearchParams(query).toString(),
    [query],
  );

  /* fetch users filtered/sorted by query params */
  useEffect(() => {
    dispatch(fetchPortalUsers(`?${memoizedQuery}`));
  }, [dispatch, memoizedQuery]);

  /** transform users data */
  useEffect(() => {
    const transformedData = portalUsers.map((user) => ({
      ...user,
      fullName: user.Attributes[1]?.Value,
      title: user.Attributes[0]?.Value,
      role: getRoleLabelForId(user.Attributes[2]?.Value),
      issuerName: user.Attributes[4]?.Value,
      email: user.Attributes[5]?.Value,
      phoneNumber: user.Attributes[3]?.Value,
      status: user.Enabled ? Status.ACTIVE : Status.INACTIVE,
    }));

    setData(transformedData);
  }, [portalUsers]);

  useEffect(() => {
    if (successCreate) {
      setDisplayAddUserModal(false);
    }
  }, [successCreate]);

  useEffect(() => {
    if (successResendingConfirmation || errorResendingConfirmation) {
      dispatch(clearResendConfirmation());
    }
  }, [dispatch, successResendingConfirmation, errorResendingConfirmation]);

  useEffect(() => {
    if (selectedIssuer) {
      updateQuery({
        filters: {
          ...query.filters,
          issuer: { selected: [selectedIssuer], type: 'string' },
        },
      });
    }
  }, [query.filters, selectedIssuer, updateQuery]);

  const issuersOptions = [
    ...issuers.map(({ issuerName }: { issuerName: string }) => ({
      label: issuerName,
      value: issuerName,
    })),
  ];

  return (
    <>
      <AddNewUserModal
        isOpen={displayAddUserModal}
        onCancel={() => setDisplayAddUserModal(false)}
      />
      {errorFetchUsers && (
        <Toast
          id="fetch users"
          text="Error fetching the users"
          type={ToastType.Error}
        />
      )}
      {commonToast(
        successResendingConfirmation,
        !!errorResendingConfirmation,
        msg.ReSendInvitationSuccessMsg,
        msg.ReSendInvitationErrorMsg,
      )}

      <div className={classes.flexboxLayout}>
        <PageHeader title="Portal Users" className="m-[2.5rem] mb-[1rem]">
          <div className="flex w-full items-center justify-between">
            <Form onSubmit={noop}>
              <FormControlSelect
                name="issuer"
                onChangeManual={setSelectedIssuer}
                placeholder={loadingIssuer ? 'Loading...' : 'Select Issuer'}
                options={issuersOptions}
                className="!z-50 !mb-0"
                controlClassName="!w-[14.5rem]"
              />
            </Form>
            <Button onClick={onAddUserHandler} icon={<AddIcon />}>
              Add user
            </Button>
          </div>
        </PageHeader>

        <div className={classes.flexboxLayout__filters}>
          <SearchBy
            field="email"
            idField="userId"
            placeholder="Search by Email"
            query={query}
            updateQuery={updateQuery}
            className="h-[37px] !w-[24.2rem]"
            showCancelIcon
            onCancel
          />

          <MultiSelectDropdown
            query={query}
            fieldName="status"
            options={searchStatusOptions}
            defaultSelection={searchStatusOptions.map((option) => option.value)}
            updateQuery={updateQuery}
            filterType="string"
            label="Status"
          />

          <SelectDropdown
            query={query}
            fieldName="role"
            options={searchRolesOptions}
            updateQuery={updateQuery}
            filterType="string"
            label="Roles"
          />
        </div>

        {/* Table */}
        {loadingFetchPortalUsers ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner size="lg" color="gray" />
          </div>
        ) : (
          <>
            <div className={classes.flexboxLayout__table}>
              <Table
                id="table-users"
                columns={columns}
                data={data}
                query={query}
                updateQuery={updateQuery}
              />
            </div>
            <div className={classes.flexboxLayout__pagination}>
              <Pagination
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PortalUsers;
