const theme = {
  toast: {
    root: {
      base: 'flex w-fit max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow',
      closed: 'opacity-0 ease-out',
    },
  },
  spinner: {
    base: 'inline animate-spin text-gray-200',
    color: {
      custom: 'fill-primary600',
    },
  },
  toggle: {
    base: '!relative !bottom-4 -mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300',
    icon: 'h-5 w-5 shrink-0 ',
  },
};

export default theme;
