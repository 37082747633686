import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { IOffer } from '@kardfinancial/models';

import {
  selectEditMerchantState,
  selectFetchMerchantCategoriesState,
  selectFetchMerchantIssuersState,
} from 'state/selectors/merchants';
import selectFeatureFlagsState from 'state/selectors/featureFlags';
import { selectFetchMerchantNetworksState } from 'state/selectors/merchantNetworks';
import { fetchMerchantNetworks } from 'state/actions/merchantNetworks';

import {
  clearMerchantsErrors,
  editMerchant,
  fetchMerchantCategories,
  fetchMerchantIssuers,
} from 'state/actions/merchants';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input from 'components/Common/Input';
import Button from 'components/Common/Button';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import OffersAndLocationsTab from 'components/Pages/OffersAndLocationsTab';

// import merchantPropTypes from 'utils/propTypes/merchants';
import Sources from 'enums/sources/sources.enum';
import Status from 'enums/status/status.enum';

import type {
  MerchantNetworkResponse,
  EditCreateMerchantResponse,
  FlagsResponse,
  IssuersResponse,
  CategoriesResponse,
} from 'types/responses';
import type { Merchant } from 'types';

import DisplayRegex from '@/components/ui/DisplayRegex';
import validationSchema from './MerchantEdit.schema';
import getInputFields, { getMerchantAssetFields } from './getInputFields';
import classes from './MerchantEdit.module.scss';

type MerchantEditType = {
  merchant?: Merchant;
  offers?: IOffer[];
  closedOffers?: IOffer[];
};

type SubmitValuesType = {
  name: string;
  description: string;
  source: string | null;
  type: string | null;
  merchantNetwork: string | null;
  acceptedCards: string[] | null;
  qualifiedIssuer: string[] | null;
  category: string | null;
  websiteURL: string | null;
  imgUrl: string | null;
  fraudWarningAmountInCents: number | null;
  [key: string]: unknown | undefined; // the remaining not required fields
};

const MerchantEdit = ({
  merchant,
  offers = [],
  closedOffers = [],
}: MerchantEditType) => {
  const dispatch = useDispatch();

  // internal states
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(merchant);
  const [priorityOffers, setPriorityOffers] = useState(offers);
  const [realTimeMatchSelected, setRealTimeMatchSelected] = useState(
    merchant?.realTimeMatch ?? false,
  );
  const [manualReviewSelected, setManualReviewSelected] = useState(
    merchant?.reviewMatches ?? false,
  );
  const [associatedNames, setAssociatedNames] = useState<string>(
    merchant?.nameMatchRegex?.[0] ?? '',
  );
  const [excludedNames, setExcludedNames] = useState<string>(
    merchant?.excludeRegex?.[0] ?? '',
  );

  // redux
  const {
    loading: loadingEdit,
    success: editMerchantSuccess,
  }: EditCreateMerchantResponse = useSelector(
    selectEditMerchantState,
    shallowEqual,
  );

  const { categoriesOptions }: CategoriesResponse = useSelector(
    selectFetchMerchantCategoriesState,
    shallowEqual,
  );

  const { issuersOptions }: IssuersResponse = useSelector(
    selectFetchMerchantIssuersState,
    shallowEqual,
  );

  const { merchantNetworks }: MerchantNetworkResponse = useSelector(
    selectFetchMerchantNetworksState,
    shallowEqual,
  );

  const { flags }: FlagsResponse = useSelector(
    selectFeatureFlagsState,
    shallowEqual,
  );
  const { IS_1917_REWARDS_MAINTANENCE } = flags;

  // hooks
  useEffect(() => {
    dispatch(fetchMerchantCategories());
    dispatch(fetchMerchantIssuers());
  }, [dispatch, merchant]);

  // setting isEditing in useEffect to avoid state updates during Toast render
  useEffect(() => {
    if (editMerchantSuccess) {
      setIsEditing(false);
      dispatch(clearMerchantsErrors());
    }
  }, [dispatch, editMerchantSuccess]);

  useEffect(() => {
    // Reset shouldResetForm back to false, since shouldReset has already reset form to defaultValues
    if (shouldResetForm) {
      setShouldResetForm(false);
    }
  }, [setShouldResetForm, shouldResetForm]);

  useEffect(() => {
    dispatch(fetchMerchantNetworks());
  }, [dispatch]);

  // handlers
  const onChangeCheckboxStateHandler = useCallback(
    (setState: Dispatch<SetStateAction<boolean>>) => {
      if (isEditing) {
        setState((prevState: boolean) => !prevState);
      }
    },
    [isEditing],
  );

  const onSubmitHandler = (values: SubmitValuesType) => {
    const valuesCopy = { ...values };
    const savedOffers = [...priorityOffers, ...closedOffers];

    delete valuesCopy.associatedName;
    delete valuesCopy.excludedNames;

    // This check is needed because real time matching relies on a regex expression to be provided
    if ((realTimeMatchSelected && associatedNames) || !realTimeMatchSelected) {
      const updateBody: Merchant = {
        ...merchant,
        ...valuesCopy,
        nameMatchRegex: associatedNames ? [associatedNames] : [],
        excludeRegex: excludedNames ? [excludedNames] : [],
        status: values.status,
        offers: savedOffers.map(({ _id }) => _id?.toString()) ?? [],
        realTimeMatch: realTimeMatchSelected,
        reviewMatches: manualReviewSelected,
      };

      setCurrentValue(updateBody);

      const { merchantId } = merchant;

      const { fraudWarningAmountInCents } = updateBody;
      updateBody.fraudWarningAmountInCents = Number(fraudWarningAmountInCents);
      delete updateBody.merchantId;
      delete updateBody.__v;
      delete updateBody._id;
      delete updateBody.createdDate;
      delete updateBody.lastModified;

      dispatch(editMerchant(merchantId, updateBody));
    }
  };

  const onCancelHandler = useCallback(() => {
    setIsEditing(false);
    setShouldResetForm(true);
    setRealTimeMatchSelected(currentValue?.realTimeMatch ?? false);
    setManualReviewSelected(currentValue?.reviewMatches ?? false);
    setAssociatedNames(currentValue?.nameMatchRegex?.[0] ?? '');
    setExcludedNames(currentValue?.excludeRegex?.[0] ?? '');
  }, [currentValue]);

  const merchantNetworkOptions = merchantNetworks.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  // Getting initial input fields
  const inputFields = getInputFields({
    isEditing,
    categoriesOptions,
    issuersOptions,
    merchant: currentValue,
    onChangeCheckboxStateHandler,
    status: merchant?.status as unknown as Status,
    realTimeMatch: realTimeMatchSelected,
    reviewMatches: manualReviewSelected,
    setRealTimeMatch: setRealTimeMatchSelected,
    setReviewMatches: setManualReviewSelected,
    associatedNames,
    setAssociatedNames,
    excludedNames,
    setExcludedNames,
    merchantNetworkOptions,
  });

  const merchantAssetFields = getMerchantAssetFields({ isEditing, merchant });

  const isNational =
    (merchant?.source as unknown as Sources) === Sources.NATIONAL;

  return (
    <div>
      {IS_1917_REWARDS_MAINTANENCE && (
        <Toast
          id="rewards-maintainence-flag"
          text="REWARDS MAINTANENCE ONGOING. RESTRICTING ACCESS TO MERCHANT EDIT/CREATE FUNCTIONALITY"
          type={ToastType.Error}
        />
      )}

      <Form
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validationMode={ValidationMode.OnChange}
        shouldReset={shouldResetForm}
        defaultValues={currentValue}
        className="p-10"
      >
        <div className="flex justify-between">
          <h1 className="mb-6 text-xl font-semibold">Merchant Information</h1>
          <div>
            {isEditing ? (
              <div className="flex gap-4">
                <Button variant="secondary" onClick={onCancelHandler}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loading={loadingEdit}
                  disabled={loadingEdit || IS_1917_REWARDS_MAINTANENCE}
                >
                  Save changes
                </Button>
              </div>
            ) : (
              (merchant?.status as unknown as Status) !== Status.deleted && (
                <Button variant="secondary" onClick={() => setIsEditing(true)}>
                  Edit Merchant
                </Button>
              )
            )}
          </div>
        </div>
        <>
          {inputFields.map(({ fields, rowId }) => (
            <div key={rowId} className={classes.row}>
              {fields.map(
                ({
                  id,
                  name,
                  component: Component,
                  renderProps,
                  ...otherProps
                }) => (
                  <div
                    key={id}
                    className={classNames(
                      classes.field,
                      'flex flex-col gap-4 lg:flex-row',
                    )}
                  >
                    <div className="max-w-[500px] flex-grow">
                      <FormControl
                        name={name}
                        className="w-full"
                        render={(props) =>
                          renderProps ? (
                            <Input {...renderProps} {...props} />
                          ) : (
                            <Component {...otherProps} {...props} />
                          )
                        }
                      />
                    </div>
                    {name === 'excludedNames' && (
                      <DisplayRegex value={excludedNames} />
                    )}
                    {name === 'associatedName' && (
                      <DisplayRegex value={associatedNames} />
                    )}
                  </div>
                ),
              )}
            </div>
          ))}
        </>
        <hr className={classes.sectionDivider} />
        <h1 className="text-gray900 pb-3 text-xl font-semibold leading-relaxed">
          Merchant Assets
        </h1>
        <>
          {merchantAssetFields.map(({ fields, rowId }) => (
            <div key={rowId} className={classes.row}>
              {fields.map(
                ({
                  id,
                  name,
                  component: Component,
                  renderProps,
                  ...otherProps
                }) => (
                  <div key={id} className={classNames(classes.field)}>
                    <FormControl
                      name={name}
                      render={(props) =>
                        renderProps ? (
                          <Input {...renderProps} {...props} />
                        ) : (
                          <Component {...otherProps} {...props} />
                        )
                      }
                    />
                  </div>
                ),
              )}
            </div>
          ))}
        </>
        {isNational ? (
          <OffersAndLocationsTab
            priorityOffers={priorityOffers}
            isEditing={isEditing}
            merchantId={merchant?._id}
            setPriorityOffers={setPriorityOffers}
          />
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
};

export default MerchantEdit;
