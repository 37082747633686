/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchOffersInit,
  fetchOffersSuccess,
  fetchOffersFail,
  createOfferInit,
  createOfferSuccess,
  createOfferFail,
  editOfferInit,
  editOfferSuccess,
  editOfferFail,
  clearOffersErrorsData,
  fetchOffersByMerchantIdInit,
  fetchOffersByMerchantIdSuccess,
  fetchOffersByMerchantIdFail,
  fetchOfferByIdInit,
  fetchOfferByIdSuccess,
  fetchOfferByIdFail,
  clearOffersSuccessProp,
  buildExperimentOfferInit,
  buildExperimentOfferSuccess,
  buildExperimentOfferFail,
  saveOfferInfo,
} from 'state/actionCreators/offers';

export const initialState = {
  fetchOffersLoading: false,
  fetchOffersSuccess: false,
  fetchOffersError: null,
  fetchOffersByMerchantIdLoading: false,
  fetchOffersByMerchantIdSuccess: false,
  fetchOffersByMerchantIdError: null,
  fetchOfferByIdLoading: false,
  fetchOfferByIdSuccess: false,
  fetchOfferByIdError: null,
  createOfferLoading: false,
  createOfferSuccess: false,
  createOfferError: null,
  editOfferLoading: false,
  editOfferSuccess: false,
  editOfferError: null,
  buildExperimentOfferLoading: false,
  buildExperimentOfferSuccess: false,
  buildExperimentOfferError: null,
  msgOfferStatus: null,
  offersByMerchant: [],
  offers: [],
  offer: null,
  createdOffer: null,
  offerInfo: null,
};

const persistConfig = {
  key: 'offers',
  storage,
  blacklist: [
    'fetchOffersLoading',
    'fetchOffersSuccess',
    'fetchOffersError',
    'fetchOffersByMerchantIdLoading',
    'fetchOffersByMerchantIdSuccess',
    'fetchOffersByMerchantIdError',
    'fetchOfferByIdLoading',
    'fetchOfferByIdSuccess',
    'fetchOfferByIdError',
    'createOfferLoading',
    'createOfferSuccess',
    'createOfferError',
    'editOfferLoading',
    'editOfferSuccess',
    'editOfferError',
    'buildExperimentOfferLoading',
    'buildExperimentOfferSuccess',
    'buildExperimentOfferError',
    'offers',
    'offersByMerchant',
    'offer',
    'createdOffer',
    'msgOfferStatus',
  ],
};

const offersReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchOffersInit, (state) => {
      state.fetchOffersLoading = true;
      state.fetchOffersSuccess = false;
      state.fetchOffersError = null;
      state.offers = [];
      state.offer = null;
      state.fetchOfferByIdError = null;
    });

    builder.addCase(fetchOffersSuccess, (state, action) => {
      const { offers } = action.payload;
      state.fetchOffersLoading = false;
      state.fetchOffersError = null;
      state.fetchOffersSuccess = true;
      state.offers = offers;
    });

    builder.addCase(fetchOffersFail, (state, action) => {
      const { error } = action.payload;
      state.fetchOffersLoading = false;
      state.fetchOffersError = error;
      state.fetchOffersSuccess = false;
    });

    builder.addCase(fetchOffersByMerchantIdInit, (state) => {
      state.fetchOffersByMerchantIdLoading = true;
      state.fetchOffersByMerchantIdSuccess = false;
      state.fetchOffersByMerchantIdError = null;
      state.offersByMerchant = [];
    });

    builder.addCase(fetchOffersByMerchantIdSuccess, (state, action) => {
      const { offers } = action.payload;
      state.fetchOffersByMerchantIdLoading = false;
      state.fetchOffersByMerchantIdError = null;
      state.fetchOffersByMerchantIdSuccess = true;
      state.offersByMerchant = offers;
    });

    builder.addCase(fetchOffersByMerchantIdFail, (state, action) => {
      const { error } = action.payload;
      state.fetchOffersByMerchantIdLoading = false;
      state.fetchOffersByMerchantIdError = error;
      state.fetchOffersByMerchantIdSuccess = false;
    });

    builder.addCase(fetchOfferByIdInit, (state) => {
      state.fetchOfferByIdLoading = true;
      state.fetchOfferByIdSuccess = false;
      state.fetchOfferByIdError = null;
      state.offer = null;
    });

    builder.addCase(fetchOfferByIdSuccess, (state, action) => {
      const { offer } = action.payload;
      state.fetchOfferByIdLoading = false;
      state.fetchOfferByIdError = null;
      state.fetchOfferByIdSuccess = true;
      state.offer = offer;
    });

    builder.addCase(fetchOfferByIdFail, (state, action) => {
      const { error } = action.payload;
      state.fetchOfferByIdLoading = false;
      state.fetchOfferByIdError = error;
      state.fetchOfferByIdSuccess = false;
      state.offer = null;
    });

    builder.addCase(createOfferInit, (state) => {
      state.createOfferLoading = true;
      state.createOfferError = null;
      state.createOfferSuccess = false;
      state.createdOffer = null;
    });

    builder.addCase(createOfferSuccess, (state, action) => {
      const { offer } = action.payload;
      state.createOfferLoading = false;
      state.createOfferError = null;
      state.createOfferSuccess = true;
      state.createdOffer = offer;
    });

    builder.addCase(createOfferFail, (state, action) => {
      const { error } = action.payload;
      state.createOfferLoading = false;
      state.createOfferError = error;
      state.createOfferSuccess = false;
      state.createdOffer = null;
    });

    builder.addCase(editOfferInit, (state) => {
      state.editOfferLoading = true;
      state.editOfferError = null;
      state.editOfferSuccess = false;
    });

    builder.addCase(editOfferSuccess, (state) => {
      state.editOfferLoading = false;
      state.editOfferError = null;
      state.editOfferSuccess = true;
    });

    builder.addCase(editOfferFail, (state, action) => {
      const { error } = action.payload;
      state.editOfferLoading = false;
      state.editOfferError = true;
      state.editOfferSuccess = false;
      state.msgOfferStatus = error;
    });

    builder.addCase(clearOffersErrorsData, (state) => {
      state.createOfferLoading = false;
      state.createOfferError = null;
      state.editOfferLoading = false;
      state.editOfferError = null;
      state.editOfferSuccess = false;
      state.msgOfferStatus = null;
      state.fetchOfferByIdError = null;
      state.buildExperimentOfferLoading = false;
      state.buildExperimentOfferError = null;
      state.buildExperimentOfferSuccess = false;
    });

    builder.addCase(clearOffersSuccessProp, (state) => {
      state.createOfferSuccess = false;
      state.buildExperimentOfferSuccess = false;
    });

    builder.addCase(buildExperimentOfferInit, (state) => {
      state.buildExperimentOfferLoading = true;
      state.buildExperimentOfferError = null;
      state.buildExperimentOfferSuccess = false;
    });

    builder.addCase(buildExperimentOfferSuccess, (state) => {
      state.buildExperimentOfferLoading = false;
      state.buildExperimentOfferError = null;
      state.buildExperimentOfferSuccess = true;
    });

    builder.addCase(buildExperimentOfferFail, (state, action) => {
      const { error } = action.payload;
      state.buildExperimentOfferLoading = false;
      state.buildExperimentOfferError = true;
      state.buildExperimentOfferSuccess = false;
      state.msgOfferStatus = error;
    });

    builder.addCase(saveOfferInfo, (state, action) => {
      const { offer } = action.payload;
      state.offerInfo = offer;
    });
  }),
);

export default offersReducer;
