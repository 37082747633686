/** modules */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'flowbite-react';

/** state */
import {
  selectCreateAuditState,
  selectEditAuditState,
  selectFetchAuditsState,
} from 'state/selectors/audits';
import { selectUserAttributesState } from 'state/selectors/auth';
import {
  clearAuditsErrors,
  clearAuditsStateData,
  fetchAudits,
} from 'state/actions/audits';

/** custom components */
import PaginationComponent from 'refactored/components/Common/Pagination2';
import Table from 'refactored/components/Common/Table';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import Filters from 'refactored/utils/transactionsSupport/filters';
import SelectDropdown from 'refactored/components/Common/SelectDropdown';
import PageHeader from '@/components/Common/PageHeader';
import LayoutGap from '@/components/Common/LayoutGap';

import Button from 'components/Common/Button';

/** custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import useIssuers from 'refactored/hooks/useIssuers';
import useCurrentIssuer from 'refactored/hooks/useCurrentIssuer';

/** enums */
import { AuditStatus } from 'enums/status/auditStatus';
import Path from 'enums/path.enum';

/** utils */
import makeColumns from 'refactored/utils/transactionsSupport/makeColumns';
import getAllowedColumns from 'utils/getAllowedColumns/getAllowedColumns';
import queryToSearchParams from 'refactored/utils/query/queryToSearchParams';
import getIssuerId from 'refactored/utils/getIssuerId';

/** assets */
import AddIcon from 'assets/icons/plus.svg?react';

/** styling */
import classes from './TransactionsSupport.module.scss';

const TransactionsSupport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** internal state */
  const [data, setData] = useState([]);

  /** selectors */
  const {
    auditsData,
    loading: loadingFetchAudits,
    error: errorFetchAudits,
  } = useSelector(selectFetchAuditsState, shallowEqual);

  const { roles: userRoles, isAdmin } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const { error: errorCreatingAudit, success: successCreatingAudit } =
    useSelector(selectCreateAuditState, shallowEqual);

  const { error: errorEditingAudit, success: successEditingAudit } =
    useSelector(selectEditAuditState, shallowEqual);

  const [issuer] = useCurrentIssuer({ shouldFetch: !isAdmin });
  const [issuers, loadingIssuers] = useIssuers({ shouldFetch: isAdmin });

  const issuersOptions = useMemo(
    () =>
      issuers.map(({ issuerName }) => ({
        label: issuerName,
        value: issuerName,
      })),
    [issuers],
  );

  /** query framework */
  const [query, updateQuery] = useQuery({
    pagination: { page: 0, limit: 100 },
    sort: { direction: -1, columnName: 'createdDate' },
    ...(isAdmin && {
      filters: {
        status: { selected: ['NEW', 'IN_PROGRESS'], type: 'string' },
      },
    }),
  });

  const selectedIssuer =
    (query.filters?.issuer?.selected[0] || issuer?.issuerName) ?? false;

  /** const */
  const { countTotal, audits } = auditsData;

  const issuerId = isAdmin
    ? getIssuerId(issuers, selectedIssuer)
    : issuer?.issuerId;

  /** methods */
  const moreInfoHandler = useCallback(
    (audit) => {
      const url = `${Path.TransactionsSupport}/edit/${audit.auditId}/${issuerId}`;
      window.open(url, '_blank', 'noreferrer');
    },
    [issuerId],
  );

  /** table columns */
  const columns = useMemo(
    () =>
      getAllowedColumns(
        makeColumns({
          moreInfoHandler,
          issuerId,
        }),
        userRoles,
      ),
    [userRoles, moreInfoHandler, issuerId],
  );

  /** effects */
  useEffect(() => {
    const id = issuer ? issuer?.issuerId : getIssuerId(issuers, selectedIssuer);

    if (selectedIssuer || issuer) {
      dispatch(fetchAudits(`?${queryToSearchParams(query).toString()}`, id));
    }

    if (!selectedIssuer || !issuer) {
      dispatch(clearAuditsStateData());
    }
  }, [dispatch, query, issuer]);

  /** transform audits data */
  useEffect(() => {
    const transformedData = audits.map((audit) => ({
      ...audit,
      auditId: audit._id,
      status: AuditStatus[audit.status],
    }));

    setData(transformedData);
  }, [audits]);

  useEffect(() => {
    if (successCreatingAudit || successEditingAudit) {
      dispatch(clearAuditsErrors());

      dispatch(fetchAudits(`?${queryToSearchParams(query).toString()}`));
    }
  }, [dispatch, query, successCreatingAudit, successEditingAudit]);

  useEffect(() => {
    if (errorCreatingAudit || errorEditingAudit) {
      dispatch(clearAuditsErrors());
    }
  }, [errorCreatingAudit, errorEditingAudit, dispatch]);

  const onAddAuditHandler = useCallback(
    () => navigate(`${Path.TransactionsSupport}/create`),
    [navigate],
  );

  return (
    <>
      {errorFetchAudits && (
        <Toast
          id="fetch-audits"
          text="Error fetching audits"
          type={ToastType.Error}
        />
      )}
      {(errorEditingAudit || errorCreatingAudit) && (
        <Toast
          id={
            errorCreatingAudit ? 'error creating audit' : 'error editing audit'
          }
          text={errorEditingAudit || errorCreatingAudit}
          type={ToastType.Error}
        />
      )}
      <div className={classes.flexboxLayout}>
        <div className={classes.flexboxLayout__header}>
          <PageHeader title="Transactions Support" className="w-full">
            <LayoutGap className="w-full items-center justify-between">
              {isAdmin && (
                <SelectDropdown
                  query={query}
                  updateQuery={updateQuery}
                  fieldName="issuer"
                  options={issuersOptions}
                  filterType="string"
                  label="Issuer"
                  searchable
                  loading={loadingIssuers}
                />
              )}

              <Button
                className="ml-auto"
                onClick={onAddAuditHandler}
                icon={<AddIcon />}
                data-testid="create-audit-button"
              >
                Create audit
              </Button>
            </LayoutGap>
          </PageHeader>
        </div>

        <div className={classes.flexboxLayout__filters}>
          <Filters query={query} updateQuery={updateQuery} isAdmin={isAdmin} />
        </div>

        {loadingFetchAudits ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner size="lg" color="gray" />
          </div>
        ) : (
          <>
            <div className={classes.flexboxLayout__table}>
              <Table
                id="table-audits"
                columns={columns}
                data={data}
                query={query}
                updateQuery={updateQuery}
                issuerSelected={selectedIssuer}
              />
            </div>
            <div className={classes.flexboxLayout__pagination}>
              <PaginationComponent
                query={query}
                updateQuery={updateQuery}
                countTotal={countTotal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TransactionsSupport;
