import { createAction } from '@reduxjs/toolkit';

export const fetchAuditsInit = createAction('[Audits] Fetch Audits Init');
export const fetchAuditsSuccess = createAction('[Audits] Fetch Audits Success');
export const fetchAuditsFail = createAction('[Audits] Fetch Audits Fail');

export const fetchAuditByIdInit = createAction(
  '[Audits] Fetch Audit By Id Init',
);
export const fetchAuditByIdSuccess = createAction(
  '[Audits] Fetch Audit By Id Success',
);
export const fetchAuditByIdFail = createAction(
  '[Audits] Fetch Audit By Id Fail',
);

export const editAuditInit = createAction('[Audits] Edit Audit Init');
export const editAuditSuccess = createAction('[Audits] Edit Audit Success');
export const editAuditFail = createAction('[Audits] Edit Audit Fail');

export const clearAuditsErrorsProps = createAction(
  '[Audits] Clear Audits Errors Property',
);

export const clearAuditsData = createAction('[Audits] Clear Audits Data');

export const createPublicAuditsInit = createAction(
  '[Audits] Create Public Audits Init',
);
export const createPublicAuditsSuccess = createAction(
  '[Audits] Create Public Audits Success',
);
export const createPublicAuditsFail = createAction(
  '[Audits] Create Public Audits Fail',
);

export const createAuditInit = createAction('[Audit] Create Audit Init');
export const createAuditSuccess = createAction('[Audit] Create Audit Success');
export const createAuditFail = createAction('[Audit] Create Audit Fail');
