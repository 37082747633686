import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import Body, { Color as BodyColor } from 'components/Typography/Body';

import classes from './Checkbox.module.scss';

export enum Size {
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs',
}

export type CheckboxProps = {
  label?: string | null;
  disabled?: boolean;
  selected: boolean;
  setSelected: MouseEventHandler;
  size?: Size;
  associatedNames?: string[];
  className?: string;
};

const Checkbox = ({
  label = null,
  selected,
  setSelected,
  className = '',
  size = Size.S,
  disabled = false,
  associatedNames = [],
}: CheckboxProps) => (
  <div className={classNames(className, classes.container)}>
    <div className={classes.panelItem}>
      <input
        type="checkbox"
        checked={selected}
        disabled={disabled}
        onClick={setSelected}
        className={classes.button}
      />
    </div>
    {label && (
      <Body size={size} color={BodyColor.Gray} className={classes.label}>
        {label}
      </Body>
    )}
    {/* TODO: we should refactor this to make it more generic and support any kind of error message to be shown */}
    {label === 'Real Time Match' &&
      selected &&
      associatedNames.length === 0 && (
        <Body className={classes.message}>
          Real time match requires at least one matching regular expression,
          please add them above.
        </Body>
      )}
  </div>
);

export default Checkbox;
