import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/Dialog';
import Button from '@/components/Common/Button';
import Toast, { Type as ToastType } from '@/components/Common/Toast';
import { useEditSegment, useSegment } from '@/refactored/hooks/useSegment';
import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from '@/components/Common/Spinner';
import { Alert } from 'flowbite-react';
import { omit } from 'lodash';
import SegmentDetailsForm from '../CreateSegment/SegmentDetailsForm';
import { SegmentDetailsFormValues } from '../schema';

const EditSegment = ({
  segmentId,
  onSuccess,
}: {
  segmentId: string;
  onSuccess: () => void;
}) => {
  const [editSegmentSuccess, setEditSegmentSuccess] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [
    { data: segment, loading: segmentLoading, error: segmentError },
    getSegment,
  ] = useSegment(segmentId);
  const [{ loading, error }, editSegment] = useEditSegment(segmentId);

  useEffect(() => {
    if (isDialogOpen) {
      getSegment();
    }
  }, [getSegment, isDialogOpen]);

  const handleSubmit: SubmitHandler<SegmentDetailsFormValues> = async (
    data,
  ) => {
    const segmentDetails = omit(data, [
      'size',
      'created',
      'entity',
      'modified',
      ...(segment?.status !== 'DRAFT' ? ['rules', 'segmentType'] : []),
    ]);

    const newData = {
      ...segmentDetails,
      rules: segmentDetails.rules?.map((rule) => {
        const newRule = omit(rule, [
          'id',
          'entity',
          'modified',
          'created',
          'segmentId',
        ]);

        if (newRule.ruleType === 'purchases') {
          const { dateRange, ...rest } = newRule;

          return {
            ...rest,
            startDate: dateRange.from,
            endDate: dateRange.to,
          };
        }

        return newRule;
      }),
    };

    try {
      // TODO: fix types for createSegment
      await editSegment({ data: newData });

      setEditSegmentSuccess(true);
      setIsDialogOpen(false);
      onSuccess();
    } catch (err) {
      // no-dd-sa:typescript-best-practices/no-console
      console.error(err);
    }
  };

  const currentSegment = useMemo(
    () => ({
      ...segment,
      rules: segment?.rules.map((rule) => {
        if (rule.ruleType === 'purchases') {
          return {
            ...rule,
            dateRange: {
              from: rule.startDate,
              to: rule.endDate,
            },
          };
        }

        return rule;
      }),
    }),
    [segment],
  );

  return (
    <>
      <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
        <DialogTrigger asChild>
          <Button
            onClick={() => setEditSegmentSuccess(false)}
            variant="secondary"
            size="small"
          >
            View/Edit
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Segment</DialogTitle>
          </DialogHeader>
          <div className="grid min-h-[270px]">
            {segmentLoading && (
              <Spinner
                size={SpinnerSize.L}
                color={SpinnerColor.Black}
                className="self-center justify-self-center"
              />
            )}
            {!segmentLoading && segment && (
              <SegmentDetailsForm
                onSubmit={handleSubmit}
                isSubmitting={loading}
                submitError={error}
                defaultValues={currentSegment as any}
                isEditing
              />
            )}
            {segmentError && (
              <div>
                <Alert color="failure">Error loading segment</Alert>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {editSegmentSuccess && (
        <Toast
          id="edit-segment-success"
          text="Saved segment changes"
          type={ToastType.Success}
        />
      )}
    </>
  );
};
export default EditSegment;
