export const errors = {
  NotAuthorizedException: 'Incorrect email or password. Please try again.',
  PasswordResetRequiredException:
    'Unknown error. Please, contact your administrator.',
  TooManyRequestsException: 'Too many requests. Try again later.',
  UserNotConfirmedException:
    'Unknown error. Please, contact your administrator.',
  UserNotFoundException: 'Incorrect email or password. Please try again.',
  InternalErrorException: 'Unknown error. Please, contact your administrator.',
  ExpiredCodeException: 'Invalid code provided, please request a code again.',
};

const amplifyErrorMessage = (error) =>
  errors[error.code] ??
  errors[error.message] ??
  'Unknown error. Please, contact your administrator.';

export default amplifyErrorMessage;
