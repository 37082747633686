import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonGroup from 'components/Common/ButtonGroup';
import Button from 'components/Common/Button';
import Form from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import {
  clearMerchantNetworksErrors,
  clearMerchantNetworksSuccess,
  createMerchantNetworks,
  fetchMerchantNetworks,
} from 'state/actions/merchantNetworks';
import { selectCreateMerchantNetworkState } from 'state/selectors/merchantNetworks';
import Toast, { Type as ToastType } from 'components/Common/Toast';

const MerchantNetworkForm = ({ onCancel }) => {
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector(
    selectCreateMerchantNetworkState,
    shallowEqual,
  );

  useEffect(() => {
    if (error) {
      dispatch(clearMerchantNetworksErrors());
    }

    if (success) {
      dispatch(clearMerchantNetworksSuccess());
      dispatch(fetchMerchantNetworks());
      onCancel();
    }
  }, [dispatch, error, success, onCancel]);

  const onSubmitHandler = useCallback(
    (values) => {
      dispatch(createMerchantNetworks(values));
    },
    [dispatch],
  );

  const [valid, setValid] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      {error && <Toast id={error} text={error} type={ToastType.Error} />}
      {success && (
        <Toast
          id={success}
          text="Merchant Network created successfully!"
          type={ToastType.Success}
        />
      )}
      <h1 className="text-gray900 text-xl font-medium not-italic">
        Create merchant network
      </h1>
      <Form onSubmit={onSubmitHandler} setValid={setValid}>
        <FormControl
          name="name"
          render={(props) => (
            <Input
              label="Name"
              placeholder="Merchant Network Name"
              type={InputType.Text}
              size={InputSize.S}
              color={InputColor.Gray}
              className="mb-4"
              {...props}
            />
          )}
        />
        <ButtonGroup reverse>
          <Button type="submit" loading={loading} disabled={!valid}>
            Create merchant network
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Form>
    </div>
  );
};

MerchantNetworkForm.propTypes = {
  onCancel: PropTypes.func,
};

MerchantNetworkForm.defaultProps = {
  onCancel: () => {},
};

export default MerchantNetworkForm;
