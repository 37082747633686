import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Radio from 'components/Common/Radio';
import {
  statuses,
  resolutionCodes,
  sources,
  merchantSources,
  auditCodes,
} from 'utils/transactionsSupport/values';
import { AuditStatusOriginal, AuditStatus } from 'enums/status/auditStatus';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import msg from 'utils/commonMessages';

import dateParse from 'utils/common/dateParse';
import classes from './AuditForm.module.scss';

export const getInputFields = ({ isEditing, audit }) => [
  {
    name: 'transactionId',
    renderProps: {
      label: `Transaction ID*`,
      type: InputType.Text,
      readOnly: true,
      disabled: true,
      size: InputSize.S,
      labelClassName: classes.labelWidth,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'auditCode',
    defaultValue: audit.auditCode,
    label: `Audit Code*`,
    labelClassName: classes.labelWidth,
    options: auditCodes,
    type: InputType.Text,
    readOnly: true,
    disabled: true,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
    component: FormControlSelect,
  },
  {
    name: 'merchantName',
    renderProps: {
      label: `Merchant Name*`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly: true,
      disabled: true,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'merchantId',
    renderProps: {
      label: `Kard Merchant ID`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly: !isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'transactionAmountInCents',
    renderProps: {
      label: `Transaction Amount (cents)`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly: !isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'preferredContactEmail',
    renderProps: {
      label: `Preferred Contact Email*`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly: true,
      disabled: true,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'referringPartnerUserId',
    renderProps: {
      label: `Referring Partner User ID`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly: !isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    name: 'auditDescription',
    type: 'textarea',
    renderProps: {
      label: `Audit Description*`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly: true,
      disabled: true,
      placeholder: msg.DescriptionSupportMessage,
      color: isEditing ? InputColor.Black : InputColor.Gray,
      preventNewLines: true,
    },
  },
  {
    name: 'submitterName',
    renderProps: {
      label: `Submitter Name`,
      labelClassName: classes.labelWidth,
      type: InputType.Text,
      readOnly: !isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
];

export const getEditingFields = ({
  audit,
  isEditing,
  internalDispute,
  onSetInternalDisputeHandler,
  validDispute,
  onSetValidDispute,
}) => [
  {
    component: FormControlSelect,
    name: 'status',
    defaultValue: AuditStatusOriginal[audit.status],
    label: 'Status',
    labelClassName: classes.labelWidth,
    disabled: !isEditing,
    readOnly: !isEditing,
    options: statuses,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
    isOptionDisabled: ({ label }) => isEditing && label === AuditStatus.NEW,
  },
  {
    component: FormControlSelect,
    name: 'resolutionCode',
    defaultValue: audit.resolutionCode,
    label: 'Resolution Code',
    labelClassName: classes.labelWidth,
    disabled: !isEditing,
    readOnly: !isEditing,
    options: resolutionCodes,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
  },
  {
    component: Input,
    name: 'resolutionDescription',
    renderProps: {
      label: 'Resolution Description',
      labelClassName: classes.labelWidth,
      value: audit.resolutionDescription,
      type: InputType.Text,
      disabled: !isEditing,
      readOnly: !isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: Radio,
    name: 'internalDispute',
    selected: internalDispute,
    setSelected: onSetInternalDisputeHandler,
    label: 'Internal Dispute',
    labelClassName: classes.labelWidth,
    falsy: 'No',
    truthy: 'Yes',
    disabled: !isEditing,
  },
  {
    component: FormControlSelect,
    name: 'source',
    defaultValue: audit.source,
    label: 'Source',
    labelClassName: classes.labelWidth,
    disabled: !isEditing,
    readOnly: !isEditing,
    options: sources,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
  },
  {
    component: Input,
    name: 'transactionDate',
    renderProps: {
      label: 'Transaction Date',
      labelClassName: classes.labelWidth,
      value: `${dateParse(audit.createdDate)}`,
      type: InputType.Text,
      readOnly: !isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: Input,
    name: 'lastModified',
    renderProps: {
      label: 'Last Modified',
      labelClassName: classes.labelWidth,
      value: `${dateParse(audit.lastModified)}`,
      type: InputType.Text,
      readOnly: true,
      disabled: true,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: Radio,
    name: 'validDispute',
    selected: validDispute,
    setSelected: onSetValidDispute,
    label: 'Valid Dispute:',
    labelClassName: classes.labelWidth,
    falsy: 'Invalid',
    truthy: 'Valid',
    disabled: !isEditing,
  },
  {
    component: Input,
    name: 'matchedMerchant',
    renderProps: {
      label: 'Matched Merchant',
      labelClassName: classes.labelWidth,
      value: audit.matchedMerchant,
      type: InputType.Text,
      readOnly: !isEditing,
      size: InputSize.S,
      color: isEditing ? InputColor.Black : InputColor.Gray,
    },
  },
  {
    component: FormControlSelect,
    name: 'merchantSource',
    defaultValue: audit.merchantSource,
    label: 'Merchant Source',
    labelClassName: classes.labelWidth,
    disabled: !isEditing,
    options: merchantSources,
    color: isEditing ? SelectColor.Black : SelectColor.Gray,
  },
];
