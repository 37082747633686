/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  fetchMerchantsInit,
  fetchMerchantsSuccess,
  fetchMerchantsFail,
  fetchMerchantByIdInit,
  fetchMerchantByIdSuccess,
  fetchMerchantByIdFail,
  createMerchantInit,
  createMerchantSuccess,
  createMerchantFail,
  editMerchantInit,
  editMerchantSuccess,
  editMerchantFail,
  clearCreateMerchantData,
  clearMerchantsErrorsData,
  fetchMerchantCategoriesInit,
  fetchMerchantCategoriesSuccess,
  fetchMerchantCategoriesFail,
  fetchMerchantIssuersInit,
  fetchMerchantIssuersSuccess,
  fetchMerchantIssuersFail,
  fetchMerchantsFromCacheInit,
  fetchMerchantsFromCacheSuccess,
  fetchMerchantsFromCacheFail,
} from 'state/actionCreators/merchants';

export const initialState = {
  fetchMerchantsLoading: false,
  fetchMerchantsSuccess: false,
  fetchMerchantsError: null,
  merchants: [],
  fetchMerchantByIdLoading: false,
  fetchMerchantByIdSuccess: false,
  fetchMerchantByIdError: null,
  merchant: null,
  createMerchantLoading: false,
  createMerchantSuccess: false,
  createMerchantError: null,
  createdMerchant: null,
  editMerchantLoading: false,
  editMerchantSuccess: false,
  editMerchantError: null,
  fetchMerchantCategoriesLoading: false,
  fetchMerchantCategoriesSuccess: false,
  fetchMerchantCategoriesError: null,
  categories: [],
  fetchMerchantIssuersLoading: false,
  fetchMerchantIssuersSuccess: false,
  fetchMerchantIssuersError: null,
  issuers: [],
  cachedMerchants: [],
  lastUpdated: null,
  fetchMerchantsFromCacheLoading: false,
  fetchMerchantsFromCacheSuccess: false,
  fetchMerchantsFromCacheError: null,
};

const persistConfig = {
  key: 'merchants',
  storage,
  blacklist: [
    'fetchMerchantsLoading',
    'fetchMerchantsSuccess',
    'fetchMerchantsError',
    'merchants',
    'fetchMerchantByIdLoading',
    'fetchMerchantByIdSuccess',
    'fetchMerchantByIdError',
    'merchant',
    'createMerchantLoading',
    'createMerchantSuccess',
    'createMerchantError',
    'editMerchantLoading',
    'editMerchantSuccess',
    'editMerchantError',
    'fetchMerchantCategoriesLoading',
    'fetchMerchantCategoriesSuccess',
    'fetchMerchantCategoriesError',
    'categories',
    'fetchMerchantIssuersLoading',
    'fetchMerchantIssuersSuccess',
    'fetchMerchantIssuersError',
    'issuers',
    'fetchMerchantsFromCacheLoading',
    'fetchMerchantsFromCacheSuccess',
    'fetchMerchantsFromCacheError',
  ],
};

const merchantsReducer = persistReducer(
  persistConfig,
  createReducer(initialState, (builder) => {
    builder.addCase(fetchMerchantsInit, (state) => {
      state.fetchMerchantsLoading = true;
      state.createMerchantSuccess = false;
      state.editMerchantSuccess = false;
      state.merchants = [];
    });

    builder.addCase(fetchMerchantsSuccess, (state, action) => {
      const { merchants } = action.payload;
      state.fetchMerchantsLoading = false;
      state.fetchMerchantsError = null;
      state.fetchMerchantsSuccess = true;
      state.merchants = merchants;
    });

    builder.addCase(fetchMerchantsFail, (state, action) => {
      const { error } = action.payload;
      state.fetchMerchantsLoading = false;
      state.fetchMerchantsError = error;
      state.fetchMerchantsSuccess = false;
      state.merchants = [];
    });

    builder.addCase(fetchMerchantByIdInit, (state) => {
      state.fetchMerchantByIdLoading = true;
    });

    builder.addCase(fetchMerchantByIdSuccess, (state, action) => {
      const { merchant } = action.payload;
      state.fetchMerchantByIdLoading = false;
      state.fetchMerchantByIdError = null;
      state.fetchMerchantByIdSuccess = true;
      state.merchant = merchant;
    });

    builder.addCase(fetchMerchantByIdFail, (state, action) => {
      const { error } = action.payload;
      state.fetchMerchantByIdLoading = false;
      state.fetchMerchantByIdError = error;
      state.fetchMerchantByIdSuccess = false;
    });

    builder.addCase(createMerchantInit, (state) => {
      state.createMerchantLoading = true;
    });

    builder.addCase(createMerchantSuccess, (state, action) => {
      const { merchant } = action.payload;
      state.createMerchantLoading = false;
      state.createMerchantError = null;
      state.createMerchantSuccess = true;
      state.createdMerchant = merchant;
    });

    builder.addCase(createMerchantFail, (state, action) => {
      const { error } = action.payload;
      state.createMerchantLoading = false;
      state.createMerchantError = error;
      state.createMerchantSuccess = false;
      state.createdMerchant = null;
    });

    builder.addCase(clearCreateMerchantData, (state) => {
      state.createMerchantLoading = false;
      state.createMerchantError = null;
      state.createMerchantSuccess = false;
      state.createdMerchant = null;
    });

    builder.addCase(editMerchantInit, (state) => {
      state.editMerchantLoading = true;
    });

    builder.addCase(editMerchantSuccess, (state) => {
      state.editMerchantLoading = false;
      state.editMerchantError = null;
      state.editMerchantSuccess = true;
    });

    builder.addCase(editMerchantFail, (state, action) => {
      const { error } = action.payload;
      state.editMerchantLoading = false;
      state.editMerchantError = error;
      state.editMerchantSuccess = false;
    });

    builder.addCase(clearMerchantsErrorsData, (state) => {
      state.createMerchantLoading = false;
      state.createMerchantError = null;
      state.createMerchantSuccess = false;
      state.editMerchantLoading = false;
      state.editMerchantError = null;
      state.editMerchantSuccess = false;
    });

    builder.addCase(fetchMerchantCategoriesInit, (state) => {
      state.fetchMerchantCategoriesLoading = true;
    });

    builder.addCase(fetchMerchantCategoriesSuccess, (state, action) => {
      const { categories } = action.payload;
      state.fetchMerchantCategoriesLoading = false;
      state.fetchMerchantCategoriesError = null;
      state.fetchMerchantCategoriesSuccess = true;
      state.categories = categories;
    });

    builder.addCase(fetchMerchantCategoriesFail, (state, action) => {
      const { error } = action.payload;
      state.fetchMerchantCategoriesLoading = false;
      state.fetchMerchantCategoriesError = error;
      state.fetchMerchantCategoriesSuccess = false;
    });

    builder.addCase(fetchMerchantIssuersInit, (state) => {
      state.fetchMerchantIssuersLoading = true;
    });

    builder.addCase(fetchMerchantIssuersSuccess, (state, action) => {
      const { issuers } = action.payload;
      state.fetchMerchantIssuersLoading = false;
      state.fetchMerchantIssuersError = null;
      state.fetchMerchantIssuersSuccess = true;
      state.issuers = issuers;
    });

    builder.addCase(fetchMerchantIssuersFail, (state, action) => {
      const { error } = action.payload;
      state.fetchMerchantIssuersLoading = false;
      state.fetchMerchantIssuersError = error;
      state.fetchMerchantIssuersSuccess = false;
    });

    builder.addCase(fetchMerchantsFromCacheInit, (state) => {
      state.fetchMerchantsFromCacheLoading = true;
      state.fetchMerchantsFromCacheSuccess = false;
      state.fetchMerchantsFromCacheError = null;
    });

    builder.addCase(fetchMerchantsFromCacheSuccess, (state, action) => {
      const { merchants, lastUpdated } = action.payload;
      state.fetchMerchantsFromCacheLoading = false;
      state.fetchMerchantsFromCacheError = null;
      state.fetchMerchantsFromCacheSuccess = true;
      state.cachedMerchants = merchants;
      state.lastUpdated = lastUpdated;
    });

    builder.addCase(fetchMerchantsFromCacheFail, (state, action) => {
      const { error } = action.payload;
      state.fetchMerchantsFromCacheLoading = false;
      state.fetchMerchantsFromCacheError = error;
      state.fetchMerchantsFromCacheSuccess = false;
      state.cachedMerchants = [];
      state.lastUpdated = null;
    });
  }),
);

export default merchantsReducer;
