/** custom components */
import Status from 'components/Common/Status';
import MoreInfo from 'refactored/components/Common/MoreInfo';

/** enums */
import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';
import { MouseEventHandler } from 'react';

const transformAudienceStatus = (status: string) => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'inProgress';
    default:
      return status.toLowerCase();
  }
};

const makeColumns = ({
  moreInfoHandler,
}: {
  moreInfoHandler: MouseEventHandler;
}) => [
  {
    header: 'ID',
    accessorKey: 'audienceId',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Name',
    accessorKey: 'name',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    allowedRoles: Groups.Administrators,
    meta: {
      tooltip: () => '',
      sortable: true,
    },
    cell: ({ getValue }) =>
      getValue() ? <Status kind={transformAudienceStatus(getValue())} /> : '',
  },
  {
    header: 'Size',
    accessorKey: 'audienceSize',
    allowedRoles: Groups.Administrators,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Creation Date',
    accessorKey: 'createdDate',
    allowedRoles: Groups.Administrators,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => (getValue() ? dateParse(getValue()) : '-'),
  },
  {
    header: 'More info',
    id: 'moreInfo',
    allowedRoles: Groups.Administrators,
    cell: ({ row }) => (
      <MoreInfo onClick={() => moreInfoHandler(row.original)} />
    ),
  },
];

export default makeColumns;
