import { Query } from 'types';
import transformFilter from '../queryToSearchParams/transformFilter';

// the backend for some resources like merchants use sortBy and sortDirection instead of ${columnName}Sort
const sortByQueryToSearchParams = ({
  pagination = {},
  sort = {},
  filters = {},
}: Query) =>
  new URLSearchParams([
    // we use 2D array because object-based constructor doesn't allow duplicate entries
    ...Object.entries(pagination),
    ...(sort?.columnName
      ? [
          ['sortBy', sort.columnName],
          ['sortDirection', sort.direction === 1 ? '1' : '-1'],
        ]
      : []),
    ...Object.entries(filters)
      .map((args) => transformFilter(...args))
      .flat()
      .filter(([, value]) => value !== undefined),
  ]);

export default sortByQueryToSearchParams;
