import React from 'react';
import RestrictedRoute from '../RestrictedRoute';

const LoginRoute = (props) => (
  <main className="mt-20 flex h-screen items-baseline justify-center">
    <RestrictedRoute {...props} />
  </main>
);

export default LoginRoute;
