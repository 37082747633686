/* eslint-disable no-console */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { datadogRum } from '@datadog/browser-rum';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import importMeta from 'utils/vite/importMeta';
import { Toaster } from '@/components/ui/sonner';
import amplifyConfig from './utils/amplify-config';
import OldToaster from './components/Common/Toaster';
import Router from './pages/Router';
import store from './state/store';

import 'react-dates/initialize';
import './assets/styles/datepicker.scss';
import './index.scss';

// TODO: add types for importMeta().env
const {
  VITE_DATADOG_RUM_APPLICATION_ID = null,
  VITE_DATADOG_RUM_CLIENT_TOKEN = null,
  VITE_DATADOG_RUM_ENVIRONMENT = null,
  VITE_STAGE = null,
} = importMeta().env;

// Datadog RUM
if (
  VITE_DATADOG_RUM_ENVIRONMENT === 'prod' &&
  VITE_DATADOG_RUM_APPLICATION_ID &&
  VITE_DATADOG_RUM_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: VITE_DATADOG_RUM_APPLICATION_ID,
    clientToken: VITE_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'rewards-portal',
    env: VITE_DATADOG_RUM_ENVIRONMENT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

/**
 * Flagsmith client keys: https://app.flagsmith.com/project/21685
 * These are public keys, so no need to keep them secret
 */
const envToFlagIdMap = {
  dev: '7NRszHYjBiVCiWCVyVQZi2',
  qa: 'XdGvfpioXHY4gNYDrXZ7y6',
  test: 'SjCxCrceFxqGKg3uqsNhF4',
  prod: 'jzVSL4yh3LtDMYnv3sE8Ri',
} as const;

const flagsmithEnvironmentId = envToFlagIdMap[VITE_STAGE];

Amplify.configure(amplifyConfig);
Auth.configure();

const persistor = persistStore(store);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <OldToaster />
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID: flagsmithEnvironmentId,
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
      <Toaster />
    </FlagsmithProvider>
  </React.StrictMode>,
);
