import { InputHTMLAttributes, ReactNode } from 'react';
import LayoutGap from '@/components/Common/LayoutGap';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

import FormField from '../helpers/FormField';
import FormItem from '../helpers/FormItem';
import FormLabel from '../helpers/FormLabel';
import FormDescription from '../helpers/FormDescription';
import FormMessage from '../helpers/FormMessage';

import { Input } from '../../Input';
import FormControl from '../helpers/FormControl';

type InputFieldProps<T extends FieldValues> = {
  label: string;
  name: Path<T>;
  description?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

const InputField = <T extends FieldValues>({
  label,
  description,
  name,
  className,
  ...props
}: InputFieldProps<T>) => {
  const form = useFormContext<T>();
  return (
    <FormField
      name={name}
      control={form.control}
      render={({ field, fieldState }) => (
        <FormItem className={className}>
          <LayoutGap direction="column" size="small">
            <FormLabel>{label}</FormLabel>
            <FormControl>
              <Input
                {...field}
                {...props}
                value={field.value ?? ''}
                // Must set required dynamically because user-invalid:* is not supported yet in tailwind
                required={fieldState.error?.type === 'required'}
              />
            </FormControl>
            {description && <FormDescription>{description}</FormDescription>}
            <FormMessage />
          </LayoutGap>
        </FormItem>
      )}
    />
  );
};

export default InputField;
