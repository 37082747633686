import { createAction } from '@reduxjs/toolkit';

export const exportDataInit = createAction('[Export] Export Data Init');
export const exportDataSuccess = createAction(
  '[Export] Export Data Success',
  ({ data }) => ({
    payload: {
      data,
    },
  }),
);
export const exportDataFail = createAction(
  '[Export] Export Data Fail',
  ({ error }) => ({
    payload: {
      error,
    },
  }),
);
export const exportDataHide = createAction('[Export] Export Data Hide');
export const exportDataClearSuccess = createAction(
  '[Export] Export Data Clear Success',
);
export const exportDataCancel = createAction('[Export] Export Data Cancel');
export const exportDataClearInit = createAction(
  '[Export] Export Data Clear Init',
);
export const exportDataCancelExport = createAction(
  '[Export] Export Data Cancel Export',
);
