type MongoId = string;

enum LocationType {
  Instore = 'INSTORE',
  Online = 'ONLINE',
}

enum SourceType {
  National = 'NATIONAL',
  Local = 'LOCAL',
}

enum StatusType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Closed = 'CLOSED',
}

export type LocationCsv = {
  'address.street'?: string;
  'address.city'?: string;
  'address.state'?: string;
  'address.zipCode'?: string;
  'geoLocation.longitude'?: number;
  'geoLocation.latitude'?: number;
  'operationHours.SUNDAY'?: string;
  'operationHours.MONDAY'?: string;
  'operationHours.TUESDAY'?: string;
  'operationHours.WEDNESDAY'?: string;
  'operationHours.THURSDAY'?: string;
  'operationHours.FRIDAY'?: string;
  'operationHours.SATURDAY'?: string;
  phone: string;
  websiteURL?: string;
  merchantId?: MongoId;
  name?: string;
  offerId?: MongoId;
  locationType: LocationType;
  status?: StatusType;
  'networkData.0.network'?: string;
  'networkData.0.networkMerchantId'?: string;
  locationId?: MongoId;
  isValidated?: boolean;
  permanentlyClosed?: boolean;
  googleId?: string;
  storeId?: string;
  source: SourceType;
};

const csvData = [
  [
    'address.street',
    'address.city',
    'address.state',
    'address.zipCode',
    'geoLocation.longitude',
    'geoLocation.latitude',
    'operationHours.SUNDAY',
    'operationHours.MONDAY',
    'operationHours.TUESDAY',
    'operationHours.WEDNESDAY',
    'operationHours.THURSDAY',
    'operationHours.FRIDAY',
    'operationHours.SATURDAY',
    'phone',
    'websiteURL',
    'merchantId',
    'name',
    'offerId',
    'locationType',
    'status',
    'networkData.0.network',
    'locationId',
    'isValidated',
    'permanentlyClosed',
    'googleId',
    'storeId',
    'source',
  ],
  [
    '3413 Test St',
    'Charlotte',
    'NC',
    '28226',
    '',
    '',
    'Closed',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    '9:00 AM - 5:00 PM',
    'Closed',
    '(999) 999-9999',
    'https://stores.com',
    '621551be128e130009e14bc4',
    'Matthews Avos',
    '62155375d170920009d34210',
    'INSTORE',
    'ACTIVE',
    'KARD',
    '',
    'TRUE',
    'FALSE',
    'fake',
    '1111STOREID1111',
    'NATIONAL',
  ],
];

export default csvData;
