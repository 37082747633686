import importMeta from 'utils/vite/importMeta';

function getRedirectLinks() {
  const redirectSignIn = `${window.location.origin}/login`;
  const redirectSignOut = `${window.location.origin}/logout`;
  return { redirectSignIn, redirectSignOut };
}

const {
  VITE_AWS_COGNITO_DOMAIN,
  VITE_AWS_COGNITO_IDENTITY_POOL_ID,
  VITE_AWS_USER_POOLS_ID,
  VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
} = importMeta().env;

const amplifyConfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: VITE_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: VITE_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
    domain: VITE_AWS_COGNITO_DOMAIN,
    responseType: 'code',
    ...getRedirectLinks(),
  },
};

export default amplifyConfig;
