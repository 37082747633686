import { CommissionType } from 'enums/offers/commissionTypes.enum';

const getTotalCommission = (
  commissionType: CommissionType | null,
  totalCommission: number | null,
) =>
  commissionType === CommissionType.Percent
    ? `${totalCommission ?? '-'}%`
    : `$${totalCommission ?? '-'}`;

export default getTotalCommission;
