const toggleTheme = {
  toggle: {
    base: 'toggle-bg rounded-full border group-focus:ring-0',
    checked: {
      color: {
        blue: 'bg-primary',
      },
    },
  },
};

export default toggleTheme;
