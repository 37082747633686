/** modules */
import React from 'react';
import { Link } from 'react-router-dom';

/** custom components */
import Status from 'components/Common/Status';
import MoreInfo from 'refactored/components/Common/MoreInfo';

import caretIcon from 'assets/icons/caret-right.svg';
import caretDownIcon from 'assets/icons/caret-down.svg';

/** enums */
import Groups from 'enums/users/groups.enum';
import dateFormat from 'utils/common/dateFormat';
import Path from 'enums/path.enum';

const customSubRowComponent = (count, merchantId) => (
  <div className="flex gap-1">
    <p className="flex gap-1 text-gray-500">
      Showing <span className="font-semibold text-black">10</span> of
      <span className="font-semibold text-black">{count}</span> records
    </p>
    <a
      className="text-[#263FC2]"
      href={`${Path.Merchants}/info/${merchantId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      View all offers
    </a>
  </div>
);

const makeColumns = ({ moreInfoHandler }) => [
  {
    header: 'Offer ID',
    accessorKey: 'offerId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (
      <Link
        to={`${Path.Offers}/edit/${getValue()}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#263FC2] underline"
      >
        {getValue()}
      </Link>
    ),
  },
  {
    header: 'Offer Name',
    accessorKey: 'name',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Offer Type',
    accessorKey: 'offerType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Merchant Network',
    accessorKey: 'merchantNetwork',
    allowedRoles: Groups.Administrators,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Start Date',
    accessorKey: 'startDate',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => (getValue() ? dateFormat(getValue()) : '-'),
  },
  {
    header: 'Expiration Date',
    accessorKey: 'expirationDate',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => (getValue() ? dateFormat(getValue()) : '-'),
  },
  {
    header: 'Offer Status',
    accessorKey: 'status',
    allowedRoles: Groups.Everyone,
    meta: {
      tooltip: () => '',
      sortable: true,
    },
    cell: ({ getValue }) =>
      getValue() ? <Status kind={getValue().toLowerCase()} /> : '',
  },
  {
    header: 'Experiment Status',
    accessorKey: 'experimentBuildStatus',
    allowedRoles: Groups.Administrators,
  },
  {
    header: 'Commission Type',
    accessorKey: 'commissionType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'Commission Value',
    accessorKey: 'commissionValue',
    allowedRoles: Groups.Administrators,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Total Commission',
    accessorKey: 'totalCommission',
    allowedRoles: Groups.Reporters,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'Targeted Offer',
    accessorKey: 'isTargeted',
    allowedRoles: Groups.Everyone,
    meta: {
      tooltip: () => '',
    },
    cell: ({ getValue }) => (getValue() ? 'Targeted' : 'Not Targeted'),
  },
  {
    header: 'Creation Date',
    accessorKey: 'createdDate',
    allowedRoles: Groups.Internal,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => (getValue() ? dateFormat(getValue()) : '-'),
  },
  {
    header: 'More info',
    id: 'moreInfo',
    allowedRoles: Groups.Everyone,
    cell: ({ row }) => (
      <MoreInfo onClick={() => moreInfoHandler(row.original)} />
    ),
  },
];

export const makeMergedOffersColumns = () => [
  {
    header: 'Offer ID',
    accessorKey: 'offerId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue, row }) => {
      const hasSubRows = row?.subRows?.length > 0;
      const value = getValue();

      // when offers are grouped by merchant it need to be a toggle button
      if (hasSubRows) {
        return (
          <button
            type="button"
            className="flex gap-2 text-sm font-medium text-[#111928]"
            onClick={row.getToggleExpandedHandler()}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={row.getIsExpanded() ? caretDownIcon : caretIcon}
              alt="icon"
              className="relative top-[5px]"
            />
            {value}
          </button>
        );
      }

      // when the merchant has more than 10 offers it should render a custom component.
      if (value?.isComponent) {
        return customSubRowComponent(value?.count, value?.merchantId);
      }

      return (
        <Link
          to={`${Path.Offers}/info/${getValue()}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#263FC2] underline"
        >
          {value}
        </Link>
      );
    },
  },
  {
    header: 'OFFER NAME',
    accessorKey: 'name',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'OFFER TYPE',
    accessorKey: 'offerType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'START DATE',
    accessorKey: 'startDate',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => (getValue() ? dateFormat(getValue()) : ''),
  },
  {
    header: 'EXPIRATION DATE',
    accessorKey: 'expirationDate',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) => (getValue() ? dateFormat(getValue()) : ''),
  },
  {
    header: 'OFFER STATUS',
    accessorKey: 'status',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
    cell: ({ getValue }) =>
      getValue() ? <Status kind={getValue().toLowerCase()} /> : '',
  },
  {
    header: 'COMMISSION TYPE',
    accessorKey: 'commissionType',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'TOTAL COMMISSION',
    accessorKey: 'totalCommission',
    allowedRoles: Groups.Reporters,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'TARGETED OFFER',
    accessorKey: 'isTargeted',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => {
      const value = getValue();
      if (value === undefined) {
        return '';
      }
      return value ? 'Targeted' : 'Not Targeted';
    },
  },
  {
    header: 'MERCHANT ID',
    accessorKey: 'merchantId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (
      <Link
        to={`${Path.Merchants}/info/${getValue()}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#263FC2] underline"
      >
        {getValue()}
      </Link>
    ),
  },
  {
    header: 'MERCHANT NAME',
    accessorKey: 'merchantName',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'MERCHANT SOURCE',
    accessorKey: 'merchantSource',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'MERCHANT CATEGORY',
    accessorKey: 'merchantCategory',
    allowedRoles: Groups.Everyone,
    meta: {
      sortable: true,
    },
  },
  {
    header: 'WEBSITE URL',
    accessorKey: 'websiteURL',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }) => (
      <Link
        to={getValue()}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#263FC2] underline"
      >
        {getValue()}
      </Link>
    ),
  },
];

export default makeColumns;
