import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Popover } from '@headlessui/react';
import PropTypes from 'prop-types';
import { MAX_OFFERS_PER_MERCHANT } from 'utils/offers/values';

import listIcon from 'assets/icons/list.svg';
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import classes from './SelectGroup.module.scss';
import { fieldStyle, sortStyle } from './utils';

const SelectGroup = ({
  label,
  query,
  handler,
  onClear,
  updateQuery,
  disabled,
}) => {
  const [displayLabel, setDisplayLabel] = useState(label);
  const [sort, setSort] = useState('1');

  useEffect(() => {
    setDisplayLabel('Group view by');
  }, []);

  const handleApply = () => {
    setDisplayLabel(`Grouped by: Merchant Name`);
    const newQuery = {
      ...query,
      filters: {
        ...query.filters,
        groupBy: { selected: ['MERCHANT'], type: 'string' },
        groupsPerPage: { selected: ['100'], type: 'string' },
        offersPerGroup: { selected: [MAX_OFFERS_PER_MERCHANT], type: 'string' },
        groupSortDirection: { selected: [sort], type: 'string' },
      },
    };
    updateQuery(newQuery);
    handler();
  };

  const handleClear = () => {
    setDisplayLabel('Group view by');

    const newQuery = {
      ...query,
      filters: {
        ...query.filters,
      },
      pagination: {
        page: 0,
        limit: 100,
      },
    };

    delete newQuery.filters.groupBy;
    delete newQuery.filters.groupsPerPage;
    delete newQuery.filters.offersPerGroup;

    updateQuery(newQuery);
    onClear();
  };
  const onChangeHandler = (e) => {
    setSort(e.value);
  };

  const options = [{ value: 'Merchant Name', label: 'merchantName' }];
  const sortOptions = [
    { value: '1', label: 'A-Z' },
    { value: '-1', label: 'Z-A' },
  ];

  return (
    <Popover>
      <Popover.Button
        className={`flex items-center gap-2 rounded-lg border border-gray-200 px-5 py-2.5 active:border-[3px] ${
          disabled ? 'cursor-not-allowed' : ''
        }`}
        disabled={disabled}
      >
        <img src={listIcon} alt="list icon" />
        <span className="text-sm font-medium">{displayLabel}</span>
        <img src={arrowDownIcon} alt="arrow down" />
      </Popover.Button>
      <Popover.Panel className={classes.panelContainer}>
        <div className="flex gap-3">
          <div className="w-[168px]">
            <p>Field</p>
            <div className="flex">
              <Select
                options={options}
                styles={fieldStyle}
                isDisabled
                placeholder="Merchant Name"
              />
            </div>
          </div>

          <div className="w-[150px]">
            <p>Sort By</p>
            <div className="flex">
              <Select
                defaultValue={sortOptions[sort === '-1' ? 1 : 0]}
                options={sortOptions}
                styles={sortStyle}
                onChange={onChangeHandler}
              />
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          <Popover.Button
            type="button"
            className={classes.secondaryButton}
            onClick={handleClear}
          >
            Clear
          </Popover.Button>
          <Popover.Button
            type="button"
            className={classes.actionButton}
            onClick={handleApply}
          >
            Apply
          </Popover.Button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

SelectGroup.propTypes = {
  label: PropTypes.string,
  query: PropTypes.objectOf({}).isRequired,
  handler: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectGroup.defaultProps = {
  label: '',
  disabled: false,
};

export default SelectGroup;
