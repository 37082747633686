/* modules */
import { useEffect, useState, useCallback } from 'react';
import { noop } from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/* redux */
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { selectUserAttributesState } from 'state/selectors/auth';
import { closeExportBanner, exportData } from 'state/actions/exportData';
import selectExportDataState from 'state/selectors/exportData';

/* components */
import Table from 'refactored/components/Common/Table';
import Input, { Type as InputType } from 'refactored/components/Common/Input';
import Button from 'components/Common/Button';

import { merchantsDetailsLocationsColumns } from 'refactored/utils/merchants/merchantsDetailsColumns';
import searchIcon from 'assets/icons/search.svg';
import ExportIcon from 'assets/icons/download.svg?react';

/* utils */
import getIdToken from 'utils/getIdToken';

/* enums */
import StatusType from 'enums/status/statusValue.enum';
import ModelType from 'enums/export/modelType.enum';

import { ILocation } from '@kardfinancial/models';
import type { UserAttributesResponse, ExportResponse } from 'types/responses';

type ExtendedLocations = ILocation & {
  street?: string;
  city?: string;
  state?: string;
  zipCode?: string;
};

type LocationsTabType = {
  merchantId: string;
  locations?: ExtendedLocations[];
};

const columns = merchantsDetailsLocationsColumns();

const LocationsTab = ({ locations = [], merchantId }: LocationsTabType) => {
  const dispatch = useDispatch();
  const { isAdmin }: UserAttributesResponse = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const [merchantLocations, setMerchantLocations] = useState<ILocation[]>([]);
  const [searchText, setSearchText] = useState('');

  const { loading: exportInProgress }: ExportResponse = useSelector(
    selectExportDataState,
    shallowEqual,
  );

  const noActiveLocations =
    merchantLocations?.every(
      (location) =>
        (location.status as unknown as StatusType) !== StatusType.Active,
    ) ?? true;

  const disableExportButton = exportInProgress || noActiveLocations;

  const getExportOffersTooltip = () => {
    if (exportInProgress) {
      return 'There is another export in progress. Try again after!';
    }

    if (noActiveLocations) {
      return 'There are no active locations to export';
    }

    return '';
  };

  useEffect(() => {
    if (locations && searchText === '') {
      setMerchantLocations(locations);
    }
  }, [locations, searchText]);

  // Close the export banner when the component is unmounted
  useEffect(
    () => () => {
      dispatch(closeExportBanner());
    },
    [dispatch],
  );

  const onExportDataHandler = async () => {
    const idToken = await getIdToken();

    const filters: {
      merchantId: string;
      locationSearch?: string;
    } = { merchantId };

    if (searchText !== '') {
      filters.locationSearch = searchText;
    }

    const exportInfo = {
      idToken,
      filters,
      model: ModelType.Location,
    };

    dispatch(exportData(exportInfo));
  };

  const onSearchHandler = useCallback(
    (text: string) => {
      setSearchText(text);
      if (text === '') {
        return setMerchantLocations([]);
      }
      const filteredLocations = locations.filter(
        (location) =>
          location.googleId
            ?.toLocaleLowerCase()
            .includes(text.toLocaleLowerCase()) ||
          location.street
            ?.toLocaleLowerCase()
            .includes(text.toLocaleLowerCase()) ||
          location.city
            ?.toLocaleLowerCase()
            .includes(text.toLocaleLowerCase()) ||
          location.state
            ?.toLocaleLowerCase()
            .includes(text.toLocaleLowerCase()) ||
          location.zipCode?.includes(text.toLocaleLowerCase()),
      );
      return setMerchantLocations(filteredLocations);
    },
    [locations, setSearchText],
  );

  return (
    <>
      <div className="mx-10 my-6 flex justify-between">
        <Input
          name="search"
          value={searchText}
          onChange={(e) => onSearchHandler(e.target.value)}
          placeholder="Search by Google ID or Address"
          type={InputType.Text}
          icon={searchIcon}
          showCancelIcon
          onCancel={() => setSearchText('')}
          className="!w-[23.25rem]"
        />
        {!isAdmin && (
          <span
            data-tooltip-content={getExportOffersTooltip()}
            data-tooltip-id="exportOffers"
          >
            <Button
              variant="secondary"
              icon={<ExportIcon />}
              disabled={disableExportButton}
              onClick={onExportDataHandler}
            >
              Export view as CSV
            </Button>
          </span>
        )}
      </div>
      <Table
        query={{}}
        updateQuery={noop}
        data={merchantLocations}
        columns={columns}
        id="offers"
      />
      <ReactTooltip id="exportOffers" />
    </>
  );
};

export default LocationsTab;
