import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useMultipleStepFormProgress from 'hooks/useMultipleStepFormProgress';

const MultipleStepForm = ({
  steps,
  onSubmit,
  onCancel,
  setCurrentStep,
  position,
  ...rest
}) => {
  const [currentStep, goForward, goBack] =
    useMultipleStepFormProgress(position);

  const isFirst = useMemo(() => currentStep === 0, [currentStep]);
  const isLast = useMemo(
    () => currentStep === steps.length - 1,
    [currentStep, steps.length],
  );

  const CurrentStepComponent = steps[currentStep];
  setCurrentStep(currentStep);

  const currentStepProps = useMemo(() => {
    const props = {
      goForward,
      onCancel,
    };

    if (isLast) {
      props.onSubmit = onSubmit;
    }

    if (!isFirst) {
      props.goBack = goBack;
    }

    return { ...props, ...rest };
  }, [goForward, isLast, onSubmit, onCancel, isFirst, goBack, rest]);

  return <CurrentStepComponent {...currentStepProps} />;
};

MultipleStepForm.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.func).isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  setCurrentStep: PropTypes.func,
  position: PropTypes.number,
};

MultipleStepForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  setCurrentStep: () => {},
  position: 0,
};

export default MultipleStepForm;
