import React from 'react';

const PasswordRequirements = React.memo(() => {
  const requirements = [
    '8 characters',
    'At least 1 number',
    'At least 1 uppercase letter',
    'At least 1 lowercase letter',
    'At least one special character, such as: ^$*.[]()?-”!@#%&/,><’:;|_~`+=',
  ];

  return (
    <div className="mb-6 text-sm font-normal text-gray-500">
      <h4 className="text-sm font-normal leading-5">Password Requirements:</h4>
      <ul>
        {requirements.map((requirement) => (
          <li key={requirement} className="text-sm font-normal">
            • {requirement}
          </li>
        ))}
      </ul>
    </div>
  );
});

export default PasswordRequirements;
