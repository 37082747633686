import type { SelectableOption } from 'types';

export const dashboardDateRangeOptions: SelectableOption[] = [
  { label: 'Last 30 days', value: 'is_trailing_30days' },
  { label: 'Last 60 days', value: 'is_trailing_60days' },
  { label: 'Last 90 days', value: 'is_trailing_90days' },
];

export const viewByOptions: SelectableOption[] = [
  { label: 'Offer Type', value: 'offer_type' },
  { label: 'Merchant Source', value: 'merchant_source' },
];
