import Button from '@/components/Common/Button';
import LayoutGap from '@/components/Common/LayoutGap';
import { Badge } from '@/components/ui/badge';
import importMeta from '@/utils/vite/importMeta';
import { Link } from 'react-router-dom';

const EnvironmentBanner = () => {
  const environment = importMeta().env.VITE_STAGE;

  if (environment === 'prod') {
    return null;
  }

  return (
    <LayoutGap className="items-center bg-[repeating-linear-gradient(45deg,#222238_0px,#222238_30px,#333348_30px,#333348_60px)] p-2 text-white">
      <Badge variant="secondary" className="uppercase">
        <span className="font-semibold">{environment}</span>
      </Badge>
      <span>Actions performed here will not affect production.</span>

      <Link to="https://portal.getkard.com">
        <Button variant="secondary" size="small">
          Switch to Production
        </Button>
      </Link>
    </LayoutGap>
  );
};

export default EnvironmentBanner;
