import { SEGMENT_BASE_URL, useAxios } from 'services/kardAPI';

type SegmentType = 'MUTABLE' | 'IMMUTABLE';
type SegmentStatus = 'DRAFT' | 'ACTIVE' | 'INACTIVE' | 'IN_PROGRESS';

type QuantityOperator = 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
type SetOperator = 'in';

type PurchasesSegmentRule = {
  ruleType: 'purchases';
  field: string;
  operator: QuantityOperator;
  transactionCount: number;
  startDate: string;
  endDate: string;
  kardMerchantId: string;
};

type UserSegmentRule = {
  ruleType: 'user';
  operator: SetOperator;
  customSegmentId: string;
};

type SegmentRule = PurchasesSegmentRule | UserSegmentRule;

export type CreateSegmentData = {
  name: string;
  segmentType: SegmentType;
  status: SegmentStatus;
  rules: SegmentRule[];
};

export type SegmentResponse = CreateSegmentData & {
  id: string;
  size: number;
  created: string;
};

const useCreateSegment = () => {
  const createSegment = useAxios<string, string>(
    {
      url: `${SEGMENT_BASE_URL}/v2/portal/segment/create`,
      method: 'POST',
    },
    { manual: true },
  );

  return createSegment;
};

export default useCreateSegment;

export const useEditSegment = (segmentId: string) => {
  const editSegment = useAxios<string, string>(
    {
      url: `${SEGMENT_BASE_URL}/v2/portal/segment/${segmentId}`,
      method: 'PUT',
    },
    { manual: true },
  );

  return editSegment;
};

export const useSegment = (segmentId: string) => {
  const segment = useAxios<SegmentResponse, string>(
    {
      url: `${SEGMENT_BASE_URL}/v2/portal/segment/${segmentId}`,
      method: 'GET',
    },
    { manual: true },
  );

  return segment;
};

type SegmentsParams = {
  limit?: number;
  startKey?: string;
  includeRules?: boolean;
};

type SegmentsResponse = {
  results: SegmentResponse[];
  nextKey: string;
  previousKey: string;
};

export const useSegments = (params?: SegmentsParams) => {
  const segments = useAxios<SegmentsResponse, string>(
    {
      url: `${SEGMENT_BASE_URL}/v2/portal/segment`,
      params,
      method: 'GET',
    },
    { useCache: true },
  );

  return segments;
};
