/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* modules */
import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* components */
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'refactored/components/Common/Input';

/* const */
import SortDirection from 'refactored/enums/query/sortDirection.enum';

/* assets */
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import clearIcon from 'assets/icons/x.svg';
import searchIcon from 'assets/icons/search.svg';

/* styles  */
import classes from './SearchFilter.module.scss';

/**
 * SearchFilter: Search filter component with dropdown and checkbox options
 * @param {query} object current query, example { pagination: { page: 0, limit: 50 }, sort: { direction: -1, columnName: 'createdDate' }}
 * @param {fieldName} string name of field to filter on, example "status" for {query: {filters: {status: { selected: ["enabled"], type: "string"}}}}
 * @param {updateQuery} function to update query, function usually from useQuery hook
 * @param {filterType} type of filter, string example "string" for {query: {filters: {status: { selected: ["enabled"], type: "string"}}}}
 * @param {label} string label for dropdown button example "Status"
 * @returns {JSX.Element}
 */
const SearchFilter = ({ query, fieldName, updateQuery, label, filterType }) => {
  const [searchValue, setSearchValue] = useState('');

  const currentQueryFilter = query?.filters?.[fieldName]?.selected[0];

  const removeFilterFromQuery = (currentQuery) => {
    const updatedQuery = { ...currentQuery };
    delete updatedQuery.filters[fieldName]; // remove current filter if it's empty

    if (Object.keys(updatedQuery.filters).length === 0) {
      delete updatedQuery.filters; // remove filters if empty
    }

    return updateQuery;
  };

  const onChangeHandler = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleSearch = () => {
    let newQuery = { ...query };

    if (searchValue) {
      newQuery = {
        ...query,
        filters: {
          ...query.filters,
          [fieldName]: { selected: [searchValue], type: filterType },
        },
      };
    }

    if (!searchValue) {
      newQuery = removeFilterFromQuery(newQuery);
    }

    updateQuery(newQuery);
  };

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const onClearDropdown = () => {
    let newQuery = { ...query };

    if (currentQueryFilter) {
      newQuery = removeFilterFromQuery(newQuery);
      updateQuery(newQuery);
      setSearchValue('');
    }
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className={classes.button}>
            <div className={classes.labelContainer}>
              <div className={classes.label}>{`${label}${
                currentQueryFilter ? `: ${currentQueryFilter}` : ''
              }`}</div>
            </div>
            {currentQueryFilter && (
              <img alt="clear" src={clearIcon} onClick={onClearDropdown} />
            )}
            <img
              alt="arrow"
              className={classNames(classes.arrowIcon, {
                [classes.arrowIconOpen]: open,
              })}
              src={arrowDownIcon}
            />
          </Popover.Button>
          <Popover.Panel className={classes.panelContainer}>
            <div className={classes.panelSearch}>
              <Input
                name="search"
                onChange={onChangeHandler}
                placeholder="Search"
                type={InputType.Text}
                icon={searchIcon}
                size={InputSize.S}
                className="!w-[10rem]"
                onKeyDown={onKeyDownHandler}
              />
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default SearchFilter;

SearchFilter.propTypes = {
  query: PropTypes.shape({
    pagination: PropTypes.shape({
      limit: PropTypes.number,
      page: PropTypes.number,
    }),
    filters: PropTypes.shape({}),
    sort: PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.oneOf(Object.values(SortDirection)),
    }),
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
  label: PropTypes.string,
  filterType: PropTypes.string,
};

SearchFilter.defaultProps = {
  label: '',
  filterType: 'string',
};
