import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Radio = ({ label, selected, setSelected, truthy, falsy, disabled }) => {
  const handleSelection = () => setSelected();

  return (
    <div className="mb-1 flex flex-col">
      <h1 className="mb-3 text-sm font-medium text-gray-900">{label}</h1>
      <div className="flex flex-row">
        <button
          type="button"
          onClick={handleSelection}
          className="mb-3 flex flex-row"
        >
          <input
            type="radio"
            checked={selected === false}
            className={classNames('text-primary700', {
              '!text-gray500': disabled,
            })}
          />
          <p
            className={classNames(
              `relative bottom-0.5 ml-2 text-sm ${
                disabled ? 'text-gray-500' : 'text-gray-900'
              }`,
            )}
          >
            {falsy}
          </p>
        </button>
      </div>
      <div className="flex flex-row">
        <button
          type="button"
          onClick={handleSelection}
          className="mb-3 flex flex-row"
        >
          <input
            type="radio"
            checked={selected === true}
            className={classNames('text-primary700', {
              '!text-gray500': disabled,
            })}
          />
          <p
            className={classNames(
              `relative bottom-0.5 ml-2 text-sm ${
                disabled ? 'text-gray-500' : 'text-gray-900'
              }`,
            )}
          >
            {truthy}
          </p>
        </button>
      </div>
    </div>
  );
};

export default Radio;

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  truthy: PropTypes.string.isRequired,
  falsy: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  disabled: false,
};
