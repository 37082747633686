import { useState, useRef } from 'react';
import {
  KnockProvider,
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from '@knocklabs/react';
import '@knocklabs/react/dist/index.css';
import importMeta from 'utils/vite/importMeta';
import NoFeedComponent from './NoFeedComponent';
import './index.scss';

const { env } = importMeta();

type NotificationFeedProps = {
  token: {
    knockToken: string;
    userId: string;
  };
};

const NotificationFeed = ({
  token: { userId, knockToken },
}: NotificationFeedProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const notifButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <KnockProvider
      apiKey={env.VITE_KNOCK_PUBLIC_API_KEY}
      userId={userId}
      userToken={knockToken}
    >
      <KnockFeedProvider feedId={env.VITE_KNOCK_FEED_CHANNEL_ID}>
        <>
          <NotificationIconButton
            ref={notifButtonRef}
            onClick={() => setIsVisible(!isVisible)}
          />
          <NotificationFeedPopover
            EmptyComponent={NoFeedComponent}
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </>
      </KnockFeedProvider>
    </KnockProvider>
  );
};

export default NotificationFeed;
