import { useState, useCallback } from 'react';

type UseModalArgs = {
  type: string | null;
  // if migrating other files you need new keys, add them in the next line as optional
  [key: string]: unknown;
};

const useModal = () => {
  const [modal, setModal] = useState<UseModalArgs>({
    type: null,
  });

  const onOpenModalHandler = useCallback(
    (modalType: string, props?: Partial<UseModalArgs>) =>
      setModal({
        type: modalType,
        ...props,
      }),
    [],
  );

  const onCloseModalHandler = useCallback(
    () =>
      setModal({
        type: null,
      }),
    [],
  );

  return {
    modal,
    onOpenModalHandler,
    onCloseModalHandler,
  };
};

export default useModal;
