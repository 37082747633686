import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Card } from 'flowbite-react';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button from 'components/Common/Button';
import Link from 'components/Typography/Link';
import ErrorMessage from 'components/Typography/ErrorMessage';
import Path from 'enums/path.enum';
import { fetchSubdomain, fetchAuthConfig, signIn } from 'state/actions/auth';
import {
  selectSignInState,
  selectSubdomainConfig,
  selectSubdomain,
} from 'state/selectors/auth';
import oktaLogo from 'assets/logos/okta.png';
import kardLogo from 'assets/logos/kard-blue.svg';
import showPasswordIcon from 'assets/icons/eye-outline.svg';
import hidePasswordIcon from 'assets/icons/eye-slash-outline.svg';

import robinhoodLogo from 'assets/logos/issuers/robinhood.svg';
import oneLogo from 'assets/logos/issuers/one.svg';
import dailypayLogo from 'assets/logos/issuers/dailypay.svg';
import albertLogo from 'assets/logos/issuers/albert.svg';
import testLogo from 'assets/logos/issuers/test.svg';
import LayoutGap from '@/components/Common/LayoutGap';

import { Separator } from '@/components/ui/Separator';
import classes from './LoginForm.module.scss';
import validationSchema from './LoginFormSchema.schema';

const issuerLogos: Record<string, string | null> = {
  robinhood: robinhoodLogo,
  one: oneLogo,
  dailypay: dailypayLogo,
  teamalbert: albertLogo,
  'test-issuer': testLogo,
};

const getIssuerLogo = () => {
  const { hostname } = document.location;
  const hasSubdomain = hostname.split('.').length > 3;

  if (!hasSubdomain) return null;

  const subdomain = hostname.split('.')[0];

  const isRecognizedIssuer = Object.keys(issuerLogos).includes(subdomain);

  if (!isRecognizedIssuer) {
    console.error(`Unrecognized issuer subdomain`, { subdomain });
    return null;
  }

  return issuerLogos[subdomain];
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(false);
  const [validInputs, setValidInputs] = useState(false);

  const { loading, error } = useSelector(selectSignInState, shallowEqual);
  const [ssoIsLoading, setSSOIsLoading] = useState(false);
  const subdomainConfig = useSelector(selectSubdomainConfig, shallowEqual);
  const subdomain = useSelector(selectSubdomain, shallowEqual);
  const { emailPasswordEnabled, ssoConfig } = subdomainConfig || {};
  const isEmailFormEnabled = emailPasswordEnabled ?? true;
  const isSsoLoginEnabled =
    (ssoConfig?.vendor || '').toLowerCase() === 'okta' ?? false;

  type LoginFields = {
    email: string;
    password: string;
  };

  const onSubmitHandler = useCallback(
    ({ email, password }: LoginFields) => {
      dispatch(signIn(email, password));
    },
    [dispatch],
  );
  const onChangeShowPassword = () => setShowPassword((prevState) => !prevState);

  useEffect(() => {
    dispatch(fetchSubdomain());
    if (subdomain) {
      dispatch(fetchAuthConfig(subdomain));
    }
    const code = new URLSearchParams(window.location.search).get('code');
    if (code) {
      setSSOIsLoading(true);
    }
  }, [dispatch, ssoIsLoading, subdomain]);

  const login = () => {
    if (!ssoConfig.cognitoAuthProviderName) {
      return;
    }
    Auth.federatedSignIn({ provider: ssoConfig.cognitoAuthProviderName });
  };

  const issuerLogo = getIssuerLogo();

  return (
    <div className="flex w-[500px] flex-col gap-9">
      <LayoutGap className="items-center justify-center gap-9">
        <img className="h-9" src={kardLogo} alt="kard logo" />
        {issuerLogo && (
          <>
            <Separator
              className="!h-auto bg-neutral-300"
              orientation="vertical"
            />
            <img className="h-7" src={issuerLogo} alt="issuer logo" />
          </>
        )}
      </LayoutGap>
      <Card className="!gap-6 rounded-lg border border-gray-200 bg-white !pb-0 shadow-custom">
        <div className="flex flex-col items-start">
          <p className="text-2xl font-[700] leading-tight text-gray-900">
            Welcome back
          </p>
          <p className="inline text-sm leading-normal text-gray-500">
            Log in to your account
          </p>
        </div>
        {isEmailFormEnabled && (
          <Form
            validationSchema={validationSchema}
            validationMode={ValidationMode.OnBlur}
            onSubmit={onSubmitHandler}
            setValid={setValid}
            onValidationUpdate={setValidInputs}
          >
            <FormControl
              name="email"
              render={(props) => (
                <div className="flex flex-col gap-2">
                  <h1 className="text-sm text-gray-900">Email</h1>
                  <Input
                    placeholder=""
                    labelClassName={error ? '!text-red-500' : ''}
                    type={InputType.Email}
                    isFullScreen
                    {...props}
                  />
                </div>
              )}
            />
            <div className="text-primaryLight relative left-[0.5rem] top-[1.1rem] z-50 flex justify-end font-medium">
              <Link to={Path.ForgotPassword}>
                <p className="w-32 text-sm leading-normal">Forgot Password?</p>
              </Link>
            </div>
            <div className="relative">
              <FormControl
                name="password"
                render={(props) => (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-sm">Password</h1>
                    <Input
                      placeholder=""
                      type={showPassword ? InputType.Text : InputType.Password}
                      isFullScreen
                      {...props}
                    />
                  </div>
                )}
              />
              <button
                aria-label="Toggle password visibility"
                className="absolute right-[13px] top-[20px] h-5 w-5 cursor-pointer border-none bg-transparent p-0 opacity-75 focus-visible:outline-0"
                onClick={onChangeShowPassword}
                type="button"
              >
                <img
                  src={showPassword ? hidePasswordIcon : showPasswordIcon}
                  alt="hide or show password"
                  className="absolute right-[5px] top-[20px] h-4 w-4 cursor-pointer opacity-75"
                />
              </button>
            </div>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <Button
              type="submit"
              loading={loading}
              data-cy="loginButton"
              disabled={!valid || !validInputs}
              fullWidth
            >
              Log in
            </Button>
          </Form>
        )}
        {isEmailFormEnabled && isSsoLoginEnabled && (
          <div className="relative left-[3.1rem] my-0.5 w-[22.5rem]">
            <hr className={classes.separator} />
          </div>
        )}
        {isSsoLoginEnabled && (
          <Button
            variant="secondary"
            onClick={login}
            icon={<img src={oktaLogo} alt="okta logo" className="w-[38px]" />}
          >
            Log in with Okta
          </Button>
        )}
      </Card>
    </div>
  );
};

export default LoginForm;
