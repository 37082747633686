import {
  fetchFeatureFlagsInit,
  fetchFeatureFlagsSuccess,
  fetchFeatureFlagsFail,
} from 'state/actionCreators/featureFlags';

import fetchFeatureFlagsService from 'services/featureFlags/fetchFeatureFlags';

export const fetchFeatureFlags = () => async (dispatch) => {
  dispatch(fetchFeatureFlagsInit());

  try {
    const flags = await fetchFeatureFlagsService();
    return dispatch(fetchFeatureFlagsSuccess({ flags }));
  } catch (error) {
    return dispatch(fetchFeatureFlagsFail({ error: error.message }));
  }
};

export const x = () => {};
