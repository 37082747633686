import React from 'react';

import PropTypes from 'prop-types';
import timerIcon from '../../../assets/icons/timer.svg';
import classes from './IdleMessage.module.scss';

const IdleMessage = ({ timer, show }) =>
  show ? (
    <div className={classes.idleMessageOverlay}>
      <div className={classes.idleMessageContainer}>
        <div className={classes.idleMessageIcon}>
          <img src={timerIcon} alt="timeout icon" />
        </div>
        <div className={classes.idleMessageTitle}>Are you there?</div>
        <div className={classes.idleMessageText}>
          For security reasons, you will be logged out after certain inactivity
          time. Any input will dismiss this message, otherwise you will be asked
          to log back in.
        </div>
        <div className={classes.idleMessageTimer}>{timer}</div>
      </div>
    </div>
  ) : null;

IdleMessage.propTypes = {
  timer: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};
export default IdleMessage;
