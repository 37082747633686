import client, { AUDIENCE_BASE_URL } from 'services/kardAPI';

const editAudience = async (id, body) => {
  try {
    await client.put(`${AUDIENCE_BASE_URL}/portal/audience/${id}`, body);
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default editAudience;
