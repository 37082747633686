// Our react time component only works with local timezones, so every one sees a different timezone even though we store it in UTC in the database.
// this causes a problem because they might enter a time without remembering to make it relative to UTC
// Since the component only displays local time we must shift the date, so that the local time "looks" like what will be stored in UTC
// This function shifts the datetime by the timezone of the user, so that even though the time is shown in their timezone it "looks like" utc.  It will then need shifted back after it is updated by the component.

export function shiftUTCDateToAppearLikeLocalTimeZoneInDatePicker(date) {
  if (!date) {
    return null;
  }
  // New Date Object will convert a UTC time to local time ( 4:16pm EST is 8:16pm UTC).
  // We don't want that.
  // We want to display the local time as a UTC time.
  const localTime = new Date(date);

  // We want to adjust that local time back to UTC
  // So '2021-07-06T20:16:29.693+00:00' as UTC Time.
  // Will be display Jul 06 2021 20:16:29 as Local Time.
  // By removing or adding the timezone difference, we will get the UTC time back.
  // 8:16pm UTC will stay 8:16pm EST.
  const adjustedTime = new Date(
    localTime.getTime() + localTime.getTimezoneOffset() * 60000,
  );

  return adjustedTime;
}

// Since we're storing it as UTC we need to reconvert the same way.
// The New Date will try to convert it to local time, so we need to adjust it back to UTC.
export function shiftDateFromDatePickerBackToUTC(date) {
  if (!date) {
    return null;
  }
  // We received Jul 06 2021 20:16:29 as UTC.
  // We want to stringify it as '2021-07-06T20:16:29.693+00:00'.
  // By removing or adding the timezone difference, we will get the UTC time back.
  // Output is as a string.
  const adjustedTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toISOString();

  return adjustedTime;
}
