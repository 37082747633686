import { useParams } from 'react-router-dom';
import { useGetExperiment } from '@/hooks/api/experiments';
import { toast } from 'sonner';
import { Loader2 } from 'lucide-react';

import { tryCatch } from '@/utils/tryCatch';

import { AlertDanger } from '@/components/ui/alert';
import UpdateExperimentForm from '@/components/Pages/Experiments/ExperimentForm/Update';

const Experiment = ({ id }: { id: string }) => {
  const [{ error, data, loading }, getExperiment] = useGetExperiment(id);

  if (error) {
    return (
      <AlertDanger
        title={`Error (Experiment ID: ${id})`}
        description={error.message}
      />
    );
  }

  if (loading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin self-center text-neutral-400" />
      </div>
    );
  }

  if (!data) {
    return (
      <AlertDanger title="Error" description={`Experiment not found: ${id}`} />
    );
  }

  return (
    <UpdateExperimentForm
      {...data}
      onSuccess={async () => {
        const [refetchError] = await tryCatch(getExperiment());
        if (refetchError) {
          toast.error('Error fetching experiment', {
            description: refetchError.message,
          });
        }
      }}
    />
  );
};

const ExperimentPage = () => {
  const { experimentId } = useParams();

  if (!experimentId) {
    return <AlertDanger title="Error" description="Missing experiment ID" />;
  }

  return (
    <div className="flex h-full w-full flex-col gap-4 p-4">
      <Experiment id={experimentId} />
    </div>
  );
};

export default ExperimentPage;
