import Groups from 'enums/users/groups.enum';
import dateParse from 'utils/common/dateParse';
import { TableColumns } from 'refactored/pages/Reports/Types';

import trueIcon from 'assets/icons/boolean-true.svg';
import falseIcon from 'assets/icons/boolean-false.svg';

export const defaultColumnsList: string[] = [
  'transactionId',
  'status',
  'transactionDate',
  'referringPartnerUserId',
  'amount',
  'validReward',
  'underReview',
  'offerId',
  'commissionToIssuer',
  'commissionToUser',
];

export const makeColumns: TableColumns[] = [
  {
    header: 'Valid Reward',
    accessorKey: 'validReward',
    allowedRoles: Groups.Everyone,
    meta: {
      tooltip: () => '',
    },
    cell: ({ getValue }: any) =>
      getValue() ? (
        <img src={trueIcon} alt="valid" />
      ) : (
        <img src={falseIcon} alt="invalid" />
      ),
  },
  {
    header: 'Under Review',
    accessorKey: 'underReview',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Status',
    accessorKey: 'status',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Transaction ID',
    accessorKey: 'transactionId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Transaction Date',
    accessorKey: 'transactionDate',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => {
      if (getValue()) {
        return dateParse(getValue());
      }
      return '-';
    },
    meta: {
      sortable: true,
      tooltip: () => '',
    },
  },
  {
    header: 'Created Date',
    accessorKey: 'createdDate',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => {
      if (getValue()) {
        return dateParse(getValue());
      }
      return '-';
    },
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Received Payment',
    accessorKey: 'receivedPaymentStatus',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Paid To Issuer',
    accessorKey: 'paidToIssuer',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) =>
      getValue() ? (
        <img src={trueIcon} alt="valid" />
      ) : (
        <img src={falseIcon} alt="invalid" />
      ),
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'ReferringPartner User ID',
    accessorKey: 'referringPartnerUserId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Card BIN',
    accessorKey: 'cardBIN',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Card Last 4',
    accessorKey: 'cardLastFour',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Matched Merchant Name',
    accessorKey: 'merchantName',
    allowedRoles: Groups.Internal,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'First Offer',
    accessorKey: 'isFirstMerchantOffer',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) =>
      getValue() ? (
        <img src={trueIcon} alt="valid" />
      ) : (
        <img src={falseIcon} alt="invalid" />
      ),
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Merchant Network',
    accessorKey: 'merchantNetwork',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Offer ID',
    accessorKey: 'offerId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Transaction Amount (in cents)',
    accessorKey: 'amount',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
      sortable: true,
    },
  },
  {
    header: 'Kard Commission (in cents)',
    accessorKey: 'receivedCommissionInCents',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
      sortable: true,
    },
  },
  {
    header: 'Issuer Commission (in cents)',
    accessorKey: 'commissionToIssuer',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
      sortable: true,
    },
  },
  {
    header: 'Authorization Date',
    accessorKey: 'authorizationDate',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => {
      if (getValue()) {
        return dateParse(getValue());
      }
      return '-';
    },
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Settled Date',
    accessorKey: 'settledDate',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => {
      if (getValue()) {
        return dateParse(getValue());
      }
      return '-';
    },
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Issuer ID',
    accessorKey: 'issuerId',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Merchant City',
    accessorKey: 'merchantAddrCity',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Merchant Street Address',
    accessorKey: 'merchantAddrStreet',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Transaction Description',
    accessorKey: 'description',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Merchant Latitude',
    accessorKey: 'merchantLat',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Merchant Longitude',
    accessorKey: 'merchantLong',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Merchant State',
    accessorKey: 'merchantAddrState',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Merchant MCC',
    accessorKey: 'mcc',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Merchant Country',
    accessorKey: 'merchantAddrCountry',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'Merchant Zip Code',
    accessorKey: 'merchantAddrZipcode',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Provided Merchant ID',
    accessorKey: 'merchantId',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Offer Total Commission',
    accessorKey: 'totalCommission',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
  },
  {
    header: 'Offer Commission Type',
    accessorKey: 'commissionType',
    allowedRoles: Groups.Administrators,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
  {
    header: 'User Commission (In Cents)',
    accessorKey: 'commissionToUser',
    allowedRoles: Groups.Everyone,
    cell: ({ getValue }: any) => getValue() || '-',
    meta: {
      tooltip: () => '',
    },
  },
];
