import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const RedirectToLogin = () => {
  const { pathname } = window.location;
  useEffect(() => {
    localStorage.setItem('redirectAfterLogin', pathname);
  });

  return <Navigate to="/login" />;
};

export default RedirectToLogin;
