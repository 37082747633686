import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from '../pagination';

type PaginationCursorProps = {
  onNextPage: () => void;
  onPreviousPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  isLoading?: boolean;
  className?: string;
};

export function PaginationCursor({
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  isLoading,
  className,
}: PaginationCursorProps) {
  return (
    <div className={className}>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              onClick={onPreviousPage}
              disabled={!hasPreviousPage || isLoading}
            />
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              onClick={onNextPage}
              disabled={!hasNextPage || isLoading}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
}
