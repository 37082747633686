import { useSelector, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';

import LoginForm from 'components/Forms/Login';
import {
  selectPasswordChangeRequiredState,
  selectSignInState,
} from 'state/selectors/auth';
import PasswordChangeRequiredForm from 'components/Forms/PasswordChangeRequired';
import Path from 'enums/path.enum';

const Login = () => {
  const { passwordChangeRequired, passwordChangeSuccess } = useSelector(
    selectPasswordChangeRequiredState,
    shallowEqual,
  );

  const { success: signInSuccess } = useSelector(
    selectSignInState,
    shallowEqual,
  );

  return (
    <>
      {passwordChangeRequired ? <PasswordChangeRequiredForm /> : <LoginForm />}
      {passwordChangeSuccess && <Navigate to={Path.Home} />}
      {signInSuccess && <Navigate to={Path.Home} />}
    </>
  );
};

export default Login;
