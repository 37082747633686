import { JSX, forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, FormProvider, DefaultValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';

export enum ValidationMode {
  OnChange = 'onChange',
  OnBlur = 'onBlur',
  OnSubmit = 'onSubmit',
  OnTouched = 'onTouched',
}

type FormType = {
  children: JSX.Element | JSX.Element[];
  onSubmit: Function;
  validationSchema?: AnyObjectSchema;
  defaultValues?: DefaultValues<unknown>;
  validationMode?: ValidationMode;
  className?: string;
  shouldReset?: boolean;
  setFormErrors?: Function;
  validateInputs?: string[];
  setValid?: Function;
  onValidationUpdate?: Function;
};

const Form = forwardRef(
  (
    {
      children,
      validationSchema,
      validationMode,
      onSubmit,
      className,
      shouldReset,
      defaultValues,
      setFormErrors,
      validateInputs,
      setValid,
      onValidationUpdate,
    }: FormType,
    ref,
  ) => {
    const formMethods = useForm({
      resolver: validationSchema && yupResolver(validationSchema),
      mode: validationMode,
      defaultValues,
    });

    const {
      handleSubmit,
      reset,
      getValues,
      setValue,
      trigger,
      formState: { errors, isDirty, isValid },
    } = formMethods;

    useEffect(() => {
      // check if the form has been modified
      if (isDirty && setValid) {
        setValid(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    onValidationUpdate?.(isValid);

    useImperativeHandle(ref, () => ({
      setFormValues(string: string, value: unknown) {
        setValue(string, value);
      },
      getFormValues() {
        return getValues();
      },
      resetForm() {
        reset();
      },
    }));

    useEffect(() => {
      if (shouldReset) {
        if (defaultValues) {
          reset(defaultValues);
        } else {
          reset();
        }
      }
    }, [shouldReset, reset, defaultValues]);

    const onSubmitWrapper = async (data: unknown) => {
      await onSubmit(data);

      // reset form state after submission.
      reset(undefined, {
        keepValues: true,
        keepDirty: false,
        keepDefaultValues: false,
      });

      if (setValid) {
        // reset the form validation state
        setValid(false);
      }
    };

    useEffect(() => {
      // If there is an error with the validation itself,
      // then it will be set as errors[undefined].
      // We want to be aware of that validation error.
      if (errors.undefined !== undefined) {
        // eslint-disable-next-line no-console
        console.warn('Error while validating:', errors.undefined.message);
      }
      if (setFormErrors) {
        setFormErrors(errors);
      }
    }, [errors, setFormErrors]);

    useEffect(() => {
      if (validateInputs && validateInputs.length > 0) {
        trigger(validateInputs);
      }
    }, [validateInputs, trigger]);

    return (
      <FormProvider {...formMethods}>
        <form className={className} onSubmit={handleSubmit(onSubmitWrapper)}>
          {children}
        </form>
      </FormProvider>
    );
  },
);

export default Form;
