export const merchantSources = [
  {
    label: 'Local',
    value: 'LOCAL',
  },
  {
    label: 'National',
    value: 'NATIONAL',
  },
];

export const merchantTypes = [
  {
    label: 'Card Linked',
    value: 'CARDLINKED',
  },
  {
    label: 'Affiliate',
    value: 'AFFILIATE',
  },
];

export const merchantAcceptedCards = [
  {
    label: 'Visa',
    value: 'VISA',
  },
  {
    label: 'Master Card',
    value: 'MASTERCARD',
  },
  {
    label: 'American Express',
    value: 'AMERICAN EXPRESS',
  },
];
