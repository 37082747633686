import { createAction } from '@reduxjs/toolkit';

export const fetchAudiencesInit = createAction(
  '[Audiences] Fetch Audiences Init',
);
export const fetchAudiencesSuccess = createAction(
  '[Audiences] Fetch Audiences Success',
);
export const fetchAudiencesFail = createAction(
  '[Audiences] Fetch Audiences Fail',
);

export const fetchAudienceByIdInit = createAction(
  '[Audiences] Fetch Audience By Id Init',
);
export const fetchAudienceByIdSuccess = createAction(
  '[Audiences] Fetch Audience By Id Success',
);
export const fetchAudienceByIdFail = createAction(
  '[Audiences] Fetch Audience By Id Fail',
);

export const clearAudiencesErrorsProps = createAction(
  '[Audiences] Clear Audiences Errors Props',
);

export const addAudienceRule = createAction(
  '[Audiences] Add rule to the audience',
);
export const saveAudienceData = createAction(
  '[Audiences] Save the audience data',
);
export const selectedAudienceRule = createAction(
  '[Audiences] Selected an audience rule',
);
export const updateAudienceRule = createAction(
  '[Audiences] Update an audience rule',
);

export const clearSelectedRule = createAction(
  '[Audiences] Clear the selected audience rule',
);

export const createAudienceInit = createAction(
  '[Audience] Create Audience Init',
);
export const createAudienceSuccess = createAction(
  '[Audience] Create Audience Success',
);
export const createAudienceFail = createAction(
  '[Audience] Create Audience Fail',
);

export const editAudienceInit = createAction('[Audiences] Edit Audience Init');
export const editAudienceSuccess = createAction(
  '[Audiences] Edit Audience Success',
);
export const editAudienceFail = createAction('[Audiences] Edit Audience Fail');

export const addAudienceToOfferInit = createAction(
  '[Audience] Add Audience to Offer Init',
);
export const addAudienceToOfferSuccess = createAction(
  '[Audience] Add Audience to Offer Success',
);
export const addAudienceToOfferFail = createAction(
  '[Audience] Add Audience to Offer Fail',
);

export const removeAudienceToOfferInit = createAction(
  '[Audience] Remove Audience to Offer Init',
);
export const removeAudienceToOfferSuccess = createAction(
  '[Audience] Remove Audience to Offer Success',
);
export const removeAudienceToOfferFail = createAction(
  '[Audience] Remove Audience to Offer Fail',
);
export const clearAddAndRemoveAudienceToOffer = createAction(
  '[Audience] Clear and remove audience to offer',
);

export const fetchAudiencesDataPointsInit = createAction(
  '[Audiences] Fetch Audiences Data Points Init',
);
export const fetchAudiencesDataPointsSuccess = createAction(
  '[Audiences] Fetch Audiences Data Points Success',
);

export const fetchAudiencesDataPointsFail = createAction(
  '[Audiences] Fetch Audiences Data Points Fail',
);

export const clearAudience = createAction('[Audiences] Clear the newAudience');

export const editingAudience = createAction('[Audiences] Editing audience');
