import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import checkIcon from 'assets/icons/check-circle.svg';
import errorIcon from 'assets/icons/x-circle.svg';
import CancelIcon from 'assets/icons/x.svg?react';

export const Type = Object.freeze({
  Error: 'error',
  Success: 'success',
});

const toastStyles = {
  [Type.Success]: {
    bgColor: 'bg-green-50',
    textColor: 'text-green-800',
    icon: checkIcon,
    buttonColor: '#03543F',
  },
  [Type.Error]: {
    bgColor: 'bg-red-50',
    textColor: 'text-red-800',
    icon: errorIcon,
    buttonColor: '#9B1C1C',
  },
};

const CustomToast = ({ text, type, visible, id }) => {
  const style = toastStyles[type];

  useEffect(() => {
    let timer;
    if (visible) {
      timer = setTimeout(() => {
        toast.dismiss(id);
      }, 4000);
    }
    return () => clearTimeout(timer);
  }, [visible, id]);

  return (
    <div
      className={`${
        style.bgColor
      } flex w-[79rem] justify-between rounded-md px-6 pb-2.5 pt-4 shadow-md ${
        visible ? 'animate-enter' : 'animate-leave'
      }`}
    >
      <div className="flex gap-2">
        <img src={style.icon} alt={`${type} icon`} />
        <span className={`${style.textColor} self-center font-semibold`}>
          {text}
        </span>
      </div>
      <button
        type="button"
        onClick={() => toast.remove(id)}
        aria-label="Close Toast"
      >
        <CancelIcon />
      </button>
    </div>
  );
};

CustomToast.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default CustomToast;
