/* modules */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

/* redux */
import { fetchUserProfile } from 'state/actions/users';
import {
  addNewAudienceRule,
  clearAudienceData,
  clearSelectedAudienceRule,
  saveSelectedAudienceRule,
} from 'state/actions/audiences';
import { selectCurrentAudienceRule } from 'state/selectors/audiences';
import selectFeatureFlagsState from 'state/selectors/featureFlags';

/* custom components */
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import MerchantsSelect from 'components/Pages/MerchantsSelect';
import Modal from 'components/Common/Modal';
import Button from 'components/Common/Button';

/* enums */
import ModalType from 'enums/modal/modalType.enum';
import Status from 'enums/status/status.enum';

/* custom hooks */
import useModal from 'hooks/useModal';

/* utils */
import { audienceType, behaviorTimeframeValues } from 'utils/audiences/values';
import ButtonGroup from 'components/Common/ButtonGroup';
import ruleBuilderSchema from './RuleBuilder.schema';
import ruleBuilderRefactoredSchema from './RuleBuilderRefactored.schema';
import getInputFields from './inputFields';

/* styling */
import classes from './RuleBuilder.module.scss';

const MERCHANT_FILTERS = '?source=NATIONAL';

const RuleBuilder = ({ onCancel, goForward, isCreating, audience }) => {
  const dispatch = useDispatch();
  const { setValue } = useForm();

  const formRef = useRef(null);

  const [audienceIsEditable, setAudienceIsEditable] = useState(false);

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);

  const { selectedRule, isEditing } = useSelector(
    selectCurrentAudienceRule,
    shallowEqual,
  );

  useEffect(() => {
    if (selectedRule?.merchant) {
      setValue('merchant', selectedRule?.merchant);
    }
  }, [selectedRule, setValue]);

  const [refreshAudience, setRefreshAudience] = useState(
    (selectedRule.refreshAudience || audience?.type === audienceType.Dynamic) ??
      false,
  );

  const [enrollNewUser, setEnrollNewUser] = useState(
    selectedRule.enrollNewUser ?? false,
  );

  const [selectedMerchant, setSelectedMerchant] = useState(
    selectedRule?.merchant || null,
  );

  const [validateInputs, setValidateInputs] = useState([]);

  const [inputVisible, setInputVisible] = useState([false, false]);
  const [showDeleteButtons, setShowDeleteButtons] = useState(
    selectedRule?.timescaleValue?.length === 2 ?? false,
  );

  const [formErrors, setFormErrors] = useState({});

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    if (flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
      const values = formRef?.current.getFormValues();
      // need to loop over the two criterias that's why we use that array
      const visibility = [0, 1].map((index) => {
        let visibilityState = false;
        if (values.timescale[index] === 'Last') {
          formRef?.current.setFormValues(
            `timescale.${index}`,
            behaviorTimeframeValues.LastCustom.value,
          );

          visibilityState = true;
        }

        if (
          values.timescale[index] === behaviorTimeframeValues.LastCustom.value
        ) {
          visibilityState = true;
        }

        if (
          values.timescale[index] &&
          values.timescale[index] !== behaviorTimeframeValues.LastCustom.value
        ) {
          const [, timescaleValue, period] = values.timescale[index].split('_');

          formRef?.current.setFormValues(
            `timescaleValue.${index}`,
            timescaleValue,
          );
          formRef?.current.setFormValues(`period.${index}`, period);
        }

        return visibilityState;
      });

      setInputVisible(visibility);
    }
  }, [
    flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND,
    formRef,
    setInputVisible,
  ]);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setAudienceIsEditable(audience?.status === Status.draft);
  }, [audience?.status]);

  const OnSubmitRuleBuilder = (values) => {
    const newRule = {
      ...values,
      enrollNewUser,
      refreshAudience,
      merchant: selectedMerchant,
    };

    if (isCreating) {
      dispatch(addNewAudienceRule(newRule));
    }
    dispatch(saveSelectedAudienceRule(newRule, 0));
    goForward();
  };

  const onChangeCheckBoxHandler = (setState) => {
    setState((prevState) => !prevState);
  };

  const onChangeSelectedMerchantHandler = useCallback(
    (newValue) => {
      if (newValue === 'allMerchants') {
        onOpenModalHandler(ModalType.ALL_MERCHANTS_SELECT);
      }
    },
    [onOpenModalHandler],
  );

  const onChangePeriodValueHandler = useCallback(() => {
    setValidateInputs(['timescaleValue']);
    setFormErrors({});
  }, [setValidateInputs, setFormErrors]);

  const onSelectMerchantHandler = useCallback(
    (merchant) => {
      setSelectedMerchant(merchant);
      onCloseModalHandler();
    },
    [onCloseModalHandler],
  );

  const onSelectTimeframe = useCallback(
    (evt, index) => {
      if (flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        let visibility = false;

        switch (evt) {
          case behaviorTimeframeValues.LastMonth.value:
          case behaviorTimeframeValues.LastThreeMonths.value:
          case behaviorTimeframeValues.LastSixMonths.value:
          case behaviorTimeframeValues.LastYear.value: {
            const [, timescaleValue, period] = evt.split('_');

            formRef?.current.setFormValues(
              `timescaleValue.${index}`,
              timescaleValue,
            );
            formRef?.current.setFormValues(`period.${index}`, period);

            visibility = false;
            break;
          }
          case behaviorTimeframeValues.LastCustom.value: {
            formRef?.current.setFormValues(`timescaleValue.${index}`, null);
            formRef?.current.setFormValues(`period.${index}`, null);

            visibility = true;
            break;
          }
          default: {
            visibility = true;
            break;
          }
        }

        setInputVisible((prevState) => {
          const newState = [...prevState];
          newState[index] = visibility;

          return newState;
        });
      }
    },
    [
      flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND,
      formRef,
      setInputVisible,
    ],
  );

  /* handler to show/hide new criteria */
  const onDeleteButtonHandler = useCallback(
    (index) => {
      if (flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
        const values = formRef?.current.getFormValues();

        formRef?.current.setFormValues('logicOperator', null);
        formRef?.current.setFormValues(
          'behaviorRange',
          values.behaviorRange?.filter((_, i) => i !== index),
        );
        formRef?.current.setFormValues(
          'behaviorValue',
          values.behaviorValue?.filter((_, i) => i !== index),
        );
        formRef?.current.setFormValues(
          'timescale',
          values.timescale?.filter((_, i) => i !== index),
        );
        formRef?.current.setFormValues(
          'timescaleValue',
          values.timescaleValue?.filter((_, i) => i !== index),
        );
        formRef?.current.setFormValues(
          'period',
          values.period?.filter((_, i) => i !== index),
        );

        setShowDeleteButtons(false);
      }
    },
    [flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND, formRef],
  );

  const onAddNewCriteria = useCallback(
    () => setShowDeleteButtons(true),
    [setShowDeleteButtons],
  );

  const onCancelHandler = useCallback(() => {
    dispatch(clearAudienceData());
    dispatch(clearSelectedAudienceRule());
    onCancel();
  }, [dispatch, onCancel]);

  const inputFields = useMemo(
    () =>
      getInputFields({
        refreshAudience,
        selectedRule,
        onSelectRefreshAudienceCheckBoxHandler: () =>
          onChangeCheckBoxHandler(setRefreshAudience),
        onSelectEnrollNewUserCheckBoxHandler: () =>
          onChangeCheckBoxHandler(setEnrollNewUser),
        selectedMerchant,
        onChangeSelectedMerchantHandler,
        onChangePeriodValueHandler,
        audienceIsEditable: isCreating || audienceIsEditable,
        enrollNewUser,
        inputVisible,
        onSelectTimeframe,
        flags,
        showDeleteButtons,
        onDeleteButtonHandler,
        onAddNewCriteria,
      }),
    [
      flags,
      onChangeSelectedMerchantHandler,
      onChangePeriodValueHandler,
      refreshAudience,
      selectedMerchant,
      selectedRule,
      isCreating,
      audienceIsEditable,
      enrollNewUser,
      inputVisible,
      onSelectTimeframe,
      showDeleteButtons,
      onDeleteButtonHandler,
      onAddNewCriteria,
    ],
  );

  return (
    <>
      <Modal
        isOpen={modal.type === ModalType.ALL_MERCHANTS_SELECT}
        onClose={onCloseModalHandler}
        className={classes.modalWidth}
      >
        <MerchantsSelect
          onCancel={onCloseModalHandler}
          onSubmit={onSelectMerchantHandler}
          filters={MERCHANT_FILTERS}
        />
      </Modal>

      <Form
        onSubmit={OnSubmitRuleBuilder}
        validationMode={ValidationMode.OnChange}
        className={classes.form}
        validationSchema={
          flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND
            ? ruleBuilderRefactoredSchema
            : ruleBuilderSchema
        }
        setFormErrors={setFormErrors}
        validateInputs={validateInputs}
        ref={formRef}
      >
        <Heading size={HeadingSize.M}>New Audience - Rule Builder</Heading>

        <div className="h-[22rem]">
          {inputFields.map(
            ({ id, label, fields, mainWrapperClassName = {} }) => (
              <div
                key={id}
                className={classNames(classes.row, mainWrapperClassName)}
              >
                {label && <span className={classes.label}>{label}</span>}

                {fields.map(
                  ({
                    hidden,
                    title,
                    component: Component,
                    renderProps,
                    wrapperClassName,
                    ...otherProps
                  }) => {
                    if (hidden) {
                      return <></>;
                    }

                    const Wrapper = !label ? 'div' : Fragment;

                    const wrapperProps = {
                      key: otherProps.name,
                      ...(!label && {
                        className: classNames(classes.field, wrapperClassName),
                      }),
                    };

                    return (
                      <Wrapper {...wrapperProps}>
                        {title && <span>{title}</span>}

                        {renderProps ? (
                          <FormControl
                            {...otherProps}
                            className={classes.formControl}
                            render={(props) => (
                              <Component {...renderProps} {...props} />
                            )}
                          />
                        ) : (
                          <Component {...otherProps} />
                        )}
                      </Wrapper>
                    );
                  },
                )}
              </div>
            ),
          )}
        </div>

        <div className={classes.errorMessages}>
          {Object.values(formErrors).map(({ message }) => (
            <div key={message}>{message}</div>
          ))}
        </div>

        <div className={classes.actionButtons}>
          <ButtonGroup reverse>
            <Button type="submit">
              {isEditing && audienceIsEditable ? 'Update' : 'Next'}
            </Button>
            {!isEditing && (
              <Button variant="secondary" onClick={onCancelHandler}>
                Cancel
              </Button>
            )}
          </ButtonGroup>
        </div>
      </Form>
    </>
  );
};

RuleBuilder.propTypes = {
  audience: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
  isCreating: PropTypes.bool,
  onCancel: PropTypes.func,
  goForward: PropTypes.func.isRequired,
};

RuleBuilder.defaultProps = {
  audience: {},
  isCreating: true,
  onCancel: () => {},
};

export default RuleBuilder;
