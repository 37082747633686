import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

/* components */
import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Button from 'components/Common/Button';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';

/* actions */
import { clearEditPortalUser, editPortalUser } from 'state/actions/users';

/* selectors */
import { selectEditPortalUserState } from 'state/selectors/users';

/* enums */
import Status from 'enums/status/status.enum';

/* utils */
import { RoleOptionsForFormControlSelect } from 'utils/users/roles';

import portalUserSchema from './UserForm.schema';
import classes from './UserForm.module.scss';

const UserForm = ({ title, user }) => {
  const dispatch = useDispatch();
  const isUserIssuer = user.roles.includes('reporter.issuer');
  const [currentValue, setCurrentValue] = useState(user);
  const [shouldResetForm, setShouldResetForm] = useState(false);

  const [isEditing, setIsEditing] = useState(true);
  const [userRoles, setUserRoles] = useState(user.roles ?? []);
  const [status, setStatus] = useState(user.status);

  const { loading: loadingEdit, success: successEdit } = useSelector(
    selectEditPortalUserState,
    shallowEqual,
  );

  const onEditHandler = useCallback(() => setIsEditing(true), []);

  const onCancelHandler = useCallback(() => {
    setIsEditing(false);
    setStatus(currentValue.status);
    setShouldResetForm(true);
  }, [currentValue]);

  useEffect(() => {
    // Reset shouldResetForm back to false, since shouldReset has already reset form to defaultValues
    if (shouldResetForm) {
      setShouldResetForm(false);
    }
  }, [setShouldResetForm, shouldResetForm]);

  const onSubmitHandler = useCallback(
    (values) => {
      const body = {
        email: values.email,
        role: values.roles.join(','), // note: the API is not updated to use 'roles'
        title: values.title,
        name: values.fullName,
        phone_number: values.phoneNumber,
        issuerName: values.issuerName,
      };
      const editBody = {
        ...body,
        username: user.username,
        enabled: status === Status.ACTIVE,
      };

      setCurrentValue(editBody);

      if (editBody.name === 'N/A') {
        delete editBody.name;
      }
      if (editBody.title === 'N/A') {
        delete editBody.title;
      }
      if (!editBody.phone_number) {
        delete editBody.phone_number;
      }

      dispatch(editPortalUser(editBody));
    },
    [dispatch, user.username, status],
  );

  // setting isEditing in useEffect to avoid state updates during Toast render
  useEffect(() => {
    if (successEdit) {
      setIsEditing(false);
      dispatch(clearEditPortalUser());
    }
  }, [successEdit, dispatch]);

  const onChangeStatusHandler = useCallback(() => {
    if (isEditing) {
      setStatus((prevState) =>
        prevState === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE,
      );
    }
  }, [isEditing]);

  return (
    <Form
      onSubmit={onSubmitHandler}
      validationSchema={portalUserSchema}
      validationMode={ValidationMode.OnChange}
      shouldReset={shouldResetForm}
      defaultValues={currentValue}
    >
      <div className={classes.pageHeader}>
        <h1 className={classes.title}>{title}</h1>
        <div className={classes.actionButtons}>
          {isEditing ? (
            <>
              <Button variant="secondary" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button
                data-testid="save"
                type="submit"
                className={classes.submitButton}
                loading={loadingEdit}
              >
                Save changes
              </Button>
            </>
          ) : (
            <Button
              variant="secondary"
              data-testid="edit"
              className={classes.button}
              onClick={onEditHandler}
            >
              Edit profile
            </Button>
          )}
        </div>
      </div>

      <div className={classes.form}>
        <FormControl
          name="fullName"
          render={(props) => (
            <Input
              label="Full Name*"
              labelClassName={classes.inputLabel}
              value={currentValue.fullName}
              placeholder="John Smith"
              type={InputType.Text}
              readOnly={!isEditing}
              size={InputSize.S}
              color={isEditing ? InputColor.Black : InputColor.Gray}
              {...props}
            />
          )}
        />
        <FormControl
          name="title"
          render={(props) => (
            <Input
              label="Title"
              labelClassName={classes.inputLabel}
              value={currentValue.title}
              placeholder="Project Manager"
              type={InputType.Text}
              readOnly={!isEditing}
              size={InputSize.S}
              color={isEditing ? InputColor.Black : InputColor.Gray}
              {...props}
            />
          )}
        />
        <FormControlSelect
          name="roles"
          defaultValue={userRoles}
          label="Roles*"
          labelClassName={classes.inputLabel}
          placeholder="Roles"
          readOnly={!isEditing}
          disabled={!isEditing}
          options={RoleOptionsForFormControlSelect}
          color={isEditing ? SelectColor.Black : SelectColor.Gray}
          isMulti
          onChangeManual={setUserRoles}
        />
        <FormControl
          name="email"
          render={(props) => (
            <Input
              label="Email*"
              labelClassName={classes.inputLabel}
              className={
                isEditing ? classes.disabledInput : classes.enabledInput
              }
              value={currentValue.email}
              placeholder="name@example.com"
              type={InputType.Email}
              readOnly
              disabled
              size={InputSize.S}
              color={isEditing ? InputColor.Black : InputColor.Gray}
              {...props}
            />
          )}
        />
        {isUserIssuer && (
          <FormControl
            name="Issuer"
            render={(props) => (
              <Input
                label="Issuer"
                labelClassName={classes.inputLabel}
                value={currentValue.issuerName}
                placeholder="Issuer Name"
                type={InputType.Text}
                readOnly
                disabled
                size={InputSize.S}
                color={isEditing ? InputColor.Black : InputColor.Gray}
                {...props}
              />
            )}
          />
        )}
        <FormControl
          name="phoneNumber"
          render={(props) => (
            <Input
              label="Phone"
              labelClassName={classes.inputLabel}
              value={currentValue.phoneNumber}
              placeholder="+1555-555-5555"
              type={InputType.Tel}
              readOnly={!isEditing}
              size={InputSize.S}
              color={isEditing ? InputColor.Black : InputColor.Gray}
              {...props}
            />
          )}
        />
        <p className={classes.inputLabel}>Status</p>
        <div className={classes.checkboxContainer}>
          <input
            type="checkbox"
            checked={status === Status.ACTIVE}
            onChange={onChangeStatusHandler}
            className={classes.checkbox}
            disabled={!isEditing}
          />
          <span>ACTIVE</span>
        </div>
      </div>
    </Form>
  );
};

UserForm.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  ),
};

UserForm.defaultProps = {
  user: {},
};

export default UserForm;
