import { ReactNode } from 'react';
import classNames from 'classnames';
import LayoutGap from 'components/Common/LayoutGap';
import ButtonGroup from '../ButtonGroup';

export type PageHeaderProps = {
  /**
   * Title of the page.
   */
  title: string;
  /**
   * Class overrides for the header.
   */
  className?: string;
  /**
   * Call to action buttons to be rendered inside the header.
   */
  children?: ReactNode;
};

const PageHeader = ({ title, className, children }: PageHeaderProps) => (
  <LayoutGap size="large" className={classNames('justify-between', className)}>
    {/* TODO: replace with refactored Heading component */}
    <h1
      className="min-w-[5em] truncate text-[1.75rem] font-bold"
      data-testid="page-header-title"
    >
      {title}
    </h1>
    <ButtonGroup reverse>{children}</ButtonGroup>
  </LayoutGap>
);

export default PageHeader;
