import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, { Color as BodyColor } from 'components/Typography/Body';

export const Type = Object.freeze({
  Text: 'text',
  Number: 'number',
  Password: 'password',
  Submit: 'submit',
  Reset: 'reset',
  Radio: 'radio',
  Checkbox: 'checkbox',
  Button: 'button',
  File: 'file',
  Image: 'image',
  Email: 'email',
  Tel: 'tel',
});

export const Size = Object.freeze({
  L: 'large',
  M: 'medium',
  S: 'small',
  XS: 'extra-small',
});

export const LabelPosition = Object.freeze({
  Top: 'top',
  Left: 'left',
});

export const IconPosition = Object.freeze({
  Left: 'left',
  Right: 'right',
  None: 'none',
});

export const Color = Object.freeze({
  Black: 'black',
  Gray: 'gray',
  Red: 'red',
  Green: 'green',
  Blue: 'blue',
});

const Input = ({
  type,
  label,
  labelPosition,
  name,
  value,
  required,
  placeholder,
  disabled,
  readOnly,
  error,
  register,
  onChange,
  onKeyUp,
  altIcon,
  icon,
  iconPosition,
  size,
  className,
  containerClassName,
  labelClassName,
  iconClassName,
  minNumber,
  onClickIcon,
  onKeyPress,
  color,
  isSearch,
  inputFocus,
  testId,
  isFullScreen,
  width,
  ...rest
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputFocus) {
      inputRef.current?.focus();
    }
  }, [inputFocus]);

  const baseStyle = `bg-gray-50 ${
    readOnly ? 'text-gray-400' : 'text-gray900 bg-white'
  }`;

  const errorStyle =
    'bg-red-50 text-red-700 border-red-600 border-solid border-3 focus:!border-red-600 focus:!ring-red-500 ';
  return (
    <div className={classNames('relative')}>
      {label && (
        <Body
          color={BodyColor.Black}
          className="leading-100 mb-2 text-sm font-medium"
        >
          {label}
        </Body>
      )}
      <div
        className={classNames(
          'relative flex',
          isFullScreen ? 'w-full' : width,
          {
            'left-3': iconPosition === IconPosition.Left,
          },
        )}
      >
        {icon && onClickIcon ? (
          <button
            type="button"
            onClick={onClickIcon}
            className={classNames('absolute left-4 top-3 h-4 w-4', {
              'left-auto right-4 top-2 w-4':
                iconPosition === IconPosition.Right,
            })}
          >
            {icon}
          </button>
        ) : (
          <img
            alt={altIcon}
            src={icon}
            className={classNames('absolute', {
              'left-4 top-3 h-4 w-4': iconPosition === IconPosition.Left,
              'right-4 top-3 h-4': iconPosition === IconPosition.Right,
              'w-4': icon,
            })}
          />
        )}
        <input
          className={classNames(
            className,
            'h-10 w-full rounded-lg border-gray-300 bg-gray-50 px-4 py-2 text-sm',
            disabled ? 'focus:border-gray-300' : 'focus:border-indigo-500',
            error ? errorStyle : baseStyle,
            {
              'cursor-not-allowed focus:!border-gray-300': readOnly,
              'pl-10': iconPosition === IconPosition.Left,
            },
          )}
          id={name}
          name={name}
          defaultValue={isSearch ? undefined : value}
          value={isSearch ? value : undefined}
          {...register}
          onChange={(e) => {
            register?.onChange(e);
            onChange(e);
          }}
          type={type}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          aria-label={name}
          disabled={disabled}
          readOnly={readOnly}
          min={minNumber}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onKeyPress();
            }
          }}
          data-cy={testId}
          {...rest}
        />
      </div>
    </div>
  );
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Type)),
  isFullScreen: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  altIcon: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  register: PropTypes.shape({
    name: PropTypes.string,
    ref: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  minNumber: PropTypes.number,
  onClickIcon: PropTypes.func,
  onKeyPress: PropTypes.func,
  iconPosition: PropTypes.oneOf(Object.values(IconPosition)),
  labelPosition: PropTypes.oneOf(Object.values(LabelPosition)),
  color: PropTypes.oneOf(Object.values(Color)),
  isSearch: PropTypes.bool,
  inputFocus: PropTypes.bool,
  testId: PropTypes.string,
  width: PropTypes.string,
};

Input.defaultProps = {
  type: Type.Text,
  register: null,
  required: false,
  isFullScreen: false,
  placeholder: '',
  altIcon: '',
  icon: null,
  onChange: () => {},
  onKeyUp: () => {},
  onKeyPress: () => {},
  onClickIcon: null,
  error: false,
  disabled: false,
  size: Size.M,
  className: '',
  containerClassName: '',
  labelClassName: '',
  iconClassName: '',
  readOnly: false,
  value: null,
  label: null,
  minNumber: null,
  iconPosition: 'none',
  labelPosition: LabelPosition.Left,
  color: Color.Gray,
  isSearch: false,
  inputFocus: false,
  testId: null,
  width: 'w-[499px]',
};

export default Input;
