import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchOfferById } from 'state/actions/offers';
import {
  selectEditOfferState,
  selectFetchOfferByIdState,
} from 'state/selectors/offers';

import { Spinner } from 'flowbite-react';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import OfferEdit from 'components/Pages/OfferEdit';

const EditOffer = () => {
  const { offerId } = useParams();
  const dispatch = useDispatch();

  const {
    loading: fetchOfferByIdLoading,
    offer,
    error: fetchOfferByIdError,
  } = useSelector(selectFetchOfferByIdState, shallowEqual);

  const { error: errorEdit, success: editOfferSuccess } = useSelector(
    selectEditOfferState,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchOfferById(offerId));
  }, [dispatch, offerId]);

  const offerData = {
    ...offer,
    offerId: offer?.id,
    merchantId: offer?.merchant._id,
    merchantName: offer?.merchant.name,
  };

  return (
    <>
      {fetchOfferByIdError && (
        <Toast
          id="fetch offer"
          text="Error Fetch Offer"
          type={ToastType.Error}
        />
      )}
      {errorEdit && (
        <Toast id="error edit offer" text={errorEdit} type={ToastType.Error} />
      )}
      {editOfferSuccess && (
        <Toast
          id="success-updating-offer"
          text="Offer updated successfully!"
          type={ToastType.Success}
        />
      )}

      {fetchOfferByIdLoading ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner size="lg" color="gray" />
        </div>
      ) : (
        <OfferEdit offer={offerData} />
      )}
    </>
  );
};

export default EditOffer;
