import Status from 'components/Common/Status';
import Groups from 'enums/users/groups.enum';
import MoreInfoComponent from './MoreInfoComponent';

type makeColumnsProps = {
  onEditHandler: Function;
  onResendHandler: Function;
};

const makeColumns = ({ onEditHandler, onResendHandler }: makeColumnsProps) => [
  {
    header: 'FULL NAME',
    accessorKey: 'fullName',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'TITLE',
    accessorKey: 'title',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'ROLE',
    accessorKey: 'role',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'ISSUER',
    accessorKey: 'issuerName',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'EMAIL',
    accessorKey: 'email',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'PHONE NUMBER',
    accessorKey: 'phoneNumber',
    allowedRoles: Groups.Everyone,
  },
  {
    header: 'STATUS',
    accessorKey: 'status',
    allowedRoles: Groups.Everyone,
    meta: {
      tooltip: () => '',
    },
    cell: ({ getValue }: any) => <Status kind={getValue().toLowerCase()} />,
  },
  {
    header: '',
    accessorKey: 'moreInfo',
    allowedRoles: Groups.Everyone,
    allowOverflow: true,
    cell: ({ row }: any) => (
      <MoreInfoComponent
        editHandler={() => onEditHandler(row.original)}
        resendHandler={() => onResendHandler(row.original)}
      />
    ),
  },
];

export default makeColumns;
