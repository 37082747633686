import { useState } from 'react';
import { CopyIcon, CheckIcon } from 'lucide-react';
import { Button } from './button';

type CopyButtonProps = {
  text: string;
};

export const CopyButton = ({ text }: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <Button
      variant="ghost"
      type="button"
      size="icon"
      className="ml-1 h-6 w-6 hover:bg-muted"
      onClick={copy}
      title={copied ? 'Copied!' : 'Copy to clipboard'}
    >
      {copied ? (
        <CheckIcon className="h-3 w-3 text-green-500" />
      ) : (
        <CopyIcon className="h-3 w-3" />
      )}
    </Button>
  );
};
