import { Link } from 'react-router-dom';
import { PlusIcon } from 'lucide-react';
import LayoutGap from '@/components/Common/LayoutGap';
import PageHeader from '@/components/Common/PageHeader';
import Button from '@/components/Common/Button';
import { ColumnDef, DataTable } from '@/components/ui/data-table';
import { useGetExperiments, ExperimentResponse } from '@/hooks/api/experiments';
import { DisplayId, DisplayTimestamp } from '@/components/Common/DisplayData';

const columns: ColumnDef<ExperimentResponse>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ row }) => <DisplayId value={row.original.id} />,
  },
  {
    header: 'Name',
    accessorKey: 'attributes.name',
  },
  {
    header: 'Created At',
    accessorKey: 'createdAt',
    cell: ({ row }) => <DisplayTimestamp timestamp={row.original.createdAt} />,
  },
  {
    header: 'Updated At',
    accessorKey: 'updatedAt',
    cell: ({ row }) => <DisplayTimestamp timestamp={row.original.updatedAt} />,
  },
  {
    header: 'More Info',
    cell: ({ row }) => (
      <Link to={`/experiments/${row.original.id}`}>
        <Button variant="secondary" size="small">
          View/Edit
        </Button>
      </Link>
    ),
  },
];

const Experiments = () => {
  const [experiments] = useGetExperiments();

  return (
    <LayoutGap direction="column">
      <PageHeader title="Experiments" className="p-4">
        <Link to="/experiments/create">
          <Button icon={<PlusIcon size="1em" />}>Create Experiment</Button>
        </Link>
      </PageHeader>
      <DataTable
        columns={columns}
        {...experiments}
        data={experiments.data?.data}
      />
    </LayoutGap>
  );
};

export default Experiments;
