import { createSelector } from '@reduxjs/toolkit';

const selectExportDataState = createSelector(
  (state) => state.exportData,
  ({
    exportDataLoading,
    exportDataSuccess,
    exportDataError,
    data,
    show,
    model,
    filters,
    exportCompleted,
    idToken,
    initExport,
    cancelExport,
  }) => ({
    loading: exportDataLoading,
    success: exportDataSuccess,
    error: exportDataError,
    data,
    show,
    model,
    filters,
    exportCompleted,
    idToken,
    initExport,
    cancelExport,
  }),
);

export default selectExportDataState;
