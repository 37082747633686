type ValidResult = [null, RegExp];
type InvalidResult = [Error, null];
type Result = ValidResult | InvalidResult;

const getRegexFromString = (value: string): Result => {
  try {
    const regex = new RegExp(value, 'i');

    return [null, regex];
  } catch (error: unknown) {
    return [error as Error, null];
  }
};

export default getRegexFromString;
