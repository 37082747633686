import React from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/Typography/ErrorMessage';

import { get } from 'lodash';
import classes from './FormControl.module.scss';

const FormControl = ({
  name,
  validations,
  render,
  className,
  noErrorMessage,
}) => {
  const { register, formState } = useFormContext();
  const error = get(formState.errors, name);
  const isImageInput = name === 'bannerImgUrl' || name === 'imgUrl';
  const isDescriptionInput = name === 'description';

  return (
    <div
      className={classNames(classes.formControl, className, {
        [classes.formControlWithError]: error,
      })}
    >
      {render({
        name,
        register: { ...register(name, validations) },
        error: !!error?.message,
      })}
      {!noErrorMessage && error && (
        <ErrorMessage
          className={classNames('pt-2', {
            'relative bottom-48': isImageInput,
            'text-red700 text-xs': isDescriptionInput,
          })}
        >
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  render: PropTypes.any.isRequired,
  validations: PropTypes.shape({}),
  className: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};

FormControl.defaultProps = {
  validations: {},
  className: '',
  noErrorMessage: false,
};

export default FormControl;
