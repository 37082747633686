import client, { REWARDS_BASE_URL } from 'services/kardAPI';

const fetchOfferById = async (id) => {
  try {
    const offer = await client.get(
      `${REWARDS_BASE_URL}/portal/merchant/offer/${id}`,
    );
    return offer.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchOfferById;
