import React, { useState } from 'react';
import classNames from 'classnames';
import { UseFormRegister, FieldValues } from 'react-hook-form';

import Body, {
  Color as BodyColor,
  Size as Bodysize,
} from 'components/Typography/Body';
import replaceNewLinesToSpaces from 'utils/replaceNewLinesToSpaces';

export enum LabelPosition {
  Top = 'top',
  Left = 'left',
}

export enum Color {
  Black = 'black',
  Gray = 'gray',
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
}

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  required?: boolean;
  placeholder?: string;
  onChange?: Function;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  readOnly?: boolean;
  value?: string;
  label?: string;
  register?: UseFormRegister<FieldValues>;
  labelPosition?: LabelPosition;
  color?: Color;
  testId?: string;
  preventNewLines?: boolean;
  showCharacterLength?: boolean;
};

const Textarea = ({
  label,
  labelPosition = LabelPosition.Left,
  color = Color.Gray,
  name,
  value,
  required,
  placeholder,
  disabled,
  readOnly,
  error,
  onChange,
  className,
  containerClassName,
  labelClassName,
  register,
  testId,
  preventNewLines,
  showCharacterLength,
  ...rest
}: TextareaProps) => {
  const MAX_CHARS = 500;

  const [text, setText] = useState(value || '');

  const onHandleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) {
      return;
    }

    if (!preventNewLines) {
      onChange(event);
      return;
    }

    const modifiedEvent = event;
    modifiedEvent.target.value = replaceNewLinesToSpaces(event.target.value);
    setText(modifiedEvent.target.value);
    onChange(modifiedEvent);
  };

  return (
    <div
      className={classNames(containerClassName, 'flex-col', {
        flex: labelPosition === LabelPosition.Left,
      })}
    >
      {' '}
      <>
        <div>
          {label && (
            <Body
              className={classNames(labelClassName, 'mb-2 !w-fit text-sm', {
                'm-0 mr-2': labelPosition === LabelPosition.Left,
              })}
              color={BodyColor.Black}
              size={Bodysize.XS}
            >
              {label}
            </Body>
          )}
        </div>
        <textarea
          className={classNames(
            className,
            'text-gray500 h-[9.3rem] w-[31.1rem] resize-y rounded-lg border-gray-300 text-sm',
            readOnly
              ? 'focus:border-1 bg-gray50 cursor-not-allowed focus:border-gray-300 focus:outline-none'
              : 'focus:text-gray900 bg-white',
            {
              'border-3 border-solid border-red-600 bg-red-50 text-red-700 focus:!border-red-600 focus:!ring-red-500':
                error,
              'neutral-500': disabled,
            },
          )}
          id={name}
          name={name}
          defaultValue={value}
          {...register}
          placeholder={placeholder}
          onChange={onHandleChange}
          disabled={disabled}
          readOnly={readOnly}
          data-cy={testId}
          {...rest}
        />
      </>
      {showCharacterLength && (
        <span
          className={classNames('mt-2 text-xs', {
            '!text-red-700': error,
          })}
        >
          {' '}
          {Number(text.length)}/{MAX_CHARS} characters
        </span>
      )}
    </div>
  );
};

export default Textarea;
