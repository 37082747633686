import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import Checkbox, { Size as CheckboxSize } from 'components/Common/Checkbox';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
  IconPosition,
} from 'components/Common/Input';
import Textarea from 'components/Common/Textarea';
import InputImage from 'components/Common/InputImage';

import type { Merchant, SelectableOption } from 'types';

import {
  closedStatusOptions,
  defaultStatusOptions,
  deletedStatusOptions,
} from 'utils/common/values';
import {
  merchantSources,
  merchantTypes,
  merchantAcceptedCards,
} from 'utils/merchants/values';
import LabelPosition from 'enums/inputProps';
import Status from 'enums/status/status.enum';

import classes from './MerchantEdit.module.scss';

type getInputFieldsType = {
  isEditing: boolean;
  merchant?: Merchant;
  categoriesOptions: SelectableOption[];
  issuersOptions: SelectableOption[];
  onChangeCheckboxStateHandler: (
    setState: Dispatch<SetStateAction<boolean>>,
  ) => void;
  realTimeMatch: boolean;
  setRealTimeMatch: Dispatch<SetStateAction<boolean>>;
  reviewMatches: boolean;
  setReviewMatches: Dispatch<SetStateAction<boolean>>;
  status?: Status;
  associatedNames: string;
  setAssociatedNames: Dispatch<SetStateAction<string>>;
  excludedNames: string;
  setExcludedNames: Dispatch<SetStateAction<string>>;
  merchantNetworkOptions: SelectableOption[];
};

type getMerchantAssetFieldsType = {
  isEditing: boolean;
  merchant?: Merchant;
};

const getStatusOptions = (status?: Status) => {
  switch (status) {
    case Status.closed:
      return closedStatusOptions;
    case Status.deleted:
      return deletedStatusOptions;
    default:
      return defaultStatusOptions;
  }
};

const getInputFields = ({
  isEditing,
  merchant,
  categoriesOptions,
  issuersOptions,
  onChangeCheckboxStateHandler,
  realTimeMatch,
  setRealTimeMatch,
  setReviewMatches,
  reviewMatches,
  status,
  setAssociatedNames,
  associatedNames,
  excludedNames,
  setExcludedNames,
  merchantNetworkOptions,
}: getInputFieldsType) => [
  {
    rowId: '1',
    fields: [
      {
        id: 'merchantId',
        name: 'merchantId',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Merchant ID*',
          value: merchant?.merchantId,
          placeholder: 'Merchant ID',
          type: InputType.Text,
          readOnly: true,
          disabled: true,
          size: InputSize.S,
          color: InputColor.Black,
        },
      },
    ],
  },
  {
    rowId: '2',
    fields: [
      {
        id: 'name',
        name: 'name',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Name*',
          value: merchant?.name,
          placeholder: 'Name',
          readOnly: !isEditing,
          type: InputType.Text,
          size: InputSize.S,
          color: isEditing ? InputColor.Black : InputColor.Gray,
        },
      },
    ],
  },
  {
    rowId: '3',
    fields: [
      {
        id: 'description',
        name: 'description',
        className: classes.input,
        component: Textarea,
        color: isEditing ? InputColor.Black : InputColor.Gray,
        label: 'Description',
        labelPosition: LabelPosition.Top,
        placeholder: 'Description',
        preventNewLines: true,
        readOnly: !isEditing,
        value: merchant?.description,
      },
    ],
  },
  {
    rowId: '4',
    fields: [
      {
        id: 'status:',
        name: 'status',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: status,
        label: 'Status*',
        readOnly: !isEditing,
        disabled: !isEditing,
        options: getStatusOptions(status),
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '5',
    fields: [
      {
        id: 'createdDate',
        name: 'createdDate',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Created date*',
          value: merchant?.createdDate
            ? new Date(merchant.createdDate).toLocaleString(undefined, {
                timeZoneName: 'short',
              })
            : '-',
          placeholder: 'Created date',
          type: InputType.Text,
          readOnly: true,
          disabled: true,
          size: InputSize.S,
          color: InputColor.Black,
        },
      },
    ],
  },
  {
    rowId: '6',
    fields: [
      {
        id: 'source',
        name: 'source',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.source,
        label: 'Source*',
        readOnly: !isEditing,
        disabled: !isEditing,
        options: merchantSources,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '7',
    fields: [
      {
        id: 'merchantNetwork',
        name: 'merchantNetwork',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.merchantNetwork,
        label: 'Merchant Network*',
        readOnly: !isEditing,
        disabled: !isEditing,
        options: merchantNetworkOptions,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '8',
    fields: [
      {
        id: 'type',
        name: 'type',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.type,
        label: 'Type*',
        readOnly: !isEditing,
        disabled: !isEditing,
        options: merchantTypes,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '9',
    fields: [
      {
        id: 'category',
        name: 'category',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.category,
        label: 'Category',
        readOnly: !isEditing,
        options: categoriesOptions,
        disabled: !isEditing,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '10',
    fields: [
      {
        id: 'acceptedCards',
        name: 'acceptedCards',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.acceptedCards,
        label: 'Accepted Cards',
        readOnly: !isEditing,
        disabled: !isEditing,
        options: merchantAcceptedCards,
        isMulti: true,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '11',
    fields: [
      {
        id: 'qualifiedIssuer',
        name: 'qualifiedIssuer',
        component: FormControlSelect,
        labelPosition: LabelPosition.Top,
        defaultValue: merchant?.qualifiedIssuer,
        label: 'Qualified Issuer(s)',
        options: issuersOptions,
        disabled: true,
        isMulti: true,
        color: isEditing ? SelectColor.Black : SelectColor.Gray,
      },
    ],
  },
  {
    rowId: '12',
    fields: [
      {
        id: 'websiteURL',
        name: 'websiteURL',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Website URL',
          value: merchant?.websiteURL,
          placeholder: 'Name',
          readOnly: !isEditing,
          type: InputType.Text,
          size: InputSize.S,
          color: isEditing ? InputColor.Black : InputColor.Gray,
        },
      },
    ],
  },
  {
    rowId: '13',
    fields: [
      {
        id: 'fraudWarningAmountInCents',
        name: 'fraudWarningAmountInCents',
        component: Input,
        renderProps: {
          labelPosition: LabelPosition.Top,
          className: classes.input,
          label: 'Max Allowable Transaction Amount for Notification',
          value: merchant?.fraudWarningAmountInCents,
          placeholder: 'Max Allowable Transaction Amount for Notification',
          readOnly: !isEditing,
          type: InputType.Text,
          size: InputSize.S,
          color: isEditing ? InputColor.Black : InputColor.Gray,
        },
      },
    ],
  },
  {
    rowId: '14',
    fields: [
      {
        id: 'associatedName',
        name: 'associatedName',
        component: Input,
        renderProps: {
          label: 'Matching Regular Expressions',
          defaultValue: associatedNames,
          placeholder: 'No names created',
          type: InputType.Text,
          readOnly: !isEditing,
          disabled: !isEditing,
          iconPosition: IconPosition.Right,
          onChange: (e: ChangeEvent<HTMLInputElement>) =>
            setAssociatedNames(e.target.value),
          realTimeMatch,
        },
      },
    ],
  },
  {
    rowId: '15',
    fields: [
      {
        id: 'excludedNames',
        name: 'excludedNames',
        component: Input,
        renderProps: {
          label: 'Excluded Regular Expressions',
          defaultValue: excludedNames,
          placeholder: 'No expressions created',
          type: InputType.Text,
          readOnly: !isEditing,
          disabled: !isEditing,
          iconPosition: IconPosition.Right,
          onChange: (e: ChangeEvent<HTMLInputElement>) =>
            setExcludedNames(e.target.value),
        },
      },
    ],
  },
  {
    rowId: '16',
    fields: [
      {
        id: 'reviewMatches',
        name: 'reviewMatches',
        component: Checkbox,
        label: 'Manual Review',
        setSelected: () => onChangeCheckboxStateHandler(setReviewMatches),
        selected: reviewMatches,
        disabled: !isEditing,
        className: classes.checkbox,
        size: CheckboxSize.S,
      },
    ],
  },
  {
    rowId: '17',
    fields: [
      {
        id: 'realTimeMatch',
        name: 'realTimeMatch',
        component: Checkbox,
        label: 'Real Time Match',
        setSelected: () => onChangeCheckboxStateHandler(setRealTimeMatch),
        selected: realTimeMatch,
        disabled: !isEditing,
        className: classes.checkbox,
        size: CheckboxSize.S,
        associatedNames,
      },
    ],
  },
];

export const getMerchantAssetFields = ({
  isEditing,
  merchant,
}: getMerchantAssetFieldsType) => [
  {
    rowId: '1',
    fields: [
      {
        id: 'imgUrl',
        name: 'imgUrl',
        component: InputImage,
        labelPosition: LabelPosition.Top,
        className: classes.input,
        label: 'Image URL',
        value: merchant?.imgUrl,
        placeholder: 'Image URL',
        readOnly: !isEditing,
        type: InputType.Text,
        size: InputSize.S,
        color: isEditing ? InputColor.Black : InputColor.Gray,
      },
    ],
  },
  {
    rowId: '2',
    fields: [
      {
        id: 'bannerImgUrl',
        name: 'bannerImgUrl',
        component: InputImage,
        labelPosition: LabelPosition.Top,
        className: classes.input,
        label: 'Banner Image URL',
        value: merchant?.bannerImgUrl,
        placeholder: 'Banner Image URL',
        readOnly: !isEditing,
        type: InputType.Text,
        size: InputSize.S,
        color: isEditing ? InputColor.Black : InputColor.Gray,
      },
    ],
  },
];

export default getInputFields;
