import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Tab } from '@headlessui/react';
import { Spinner } from 'flowbite-react';

/* redux */
import { fetchLocationsByMerchantId } from 'state/actions/locations';
import { selectFetchLocationsByMerchantIdState } from 'state/selectors/locations';

/* types */
import Types from 'enums/type/type.enum';
import { LocationResponse } from 'types/responses';
import { IOffer } from '@kardfinancial/models';

import OffersTab from './OffersTab';
import LocationsTab from './LocationsTab';

import classes from './OffersAndLocationsTab.module.scss';

type OffersAndLocationsType = {
  merchantId: string;
  priorityOffers?: IOffer[];
  setPriorityOffers?: Function;
  isEditing?: boolean;
};

const OffersAndLocationsTab = ({
  merchantId,
  priorityOffers,
  setPriorityOffers,
  isEditing,
}: OffersAndLocationsType) => {
  const dispatch = useDispatch();

  const { loading, locations }: LocationResponse = useSelector(
    selectFetchLocationsByMerchantIdState,
    shallowEqual,
  );

  const instoreLocations = locations.filter(
    (location) => (location.locationType as unknown as Types) === Types.INSTORE,
  );

  useEffect(() => {
    dispatch(fetchLocationsByMerchantId(merchantId));
  }, [dispatch, merchantId]);

  return (
    <Tab.Group>
      <Tab.List>
        <Tab className={classes.tabOption}>
          Offers ({priorityOffers?.length || 0})
        </Tab>
        <Tab className={classes.tabOption}>
          Locations ({instoreLocations?.length || 0})
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className={classes.content}>
          <OffersTab
            priorityOffers={priorityOffers}
            isEditing={isEditing}
            setPriorityOffers={setPriorityOffers}
          />
        </Tab.Panel>
        <Tab.Panel className={classes.content}>
          {loading ? (
            <div className="flex h-screen items-center justify-center">
              <Spinner size="lg" color="gray" />
            </div>
          ) : (
            <LocationsTab
              locations={instoreLocations}
              merchantId={merchantId}
            />
          )}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default OffersAndLocationsTab;
