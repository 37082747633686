import client, { TXN_BASE_URL } from 'services/kardAPI';

const fetchAudits = async (filters = '', issuerId) => {
  try {
    const config = {
      headers: {
        'X-Kard-IssuerID': issuerId,
      },
    };

    const { data } = await client.get(
      `${TXN_BASE_URL}/portal/audit${filters}`,
      config,
    );

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchAudits;
