import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import Dropdown, { DropdownProps } from '../Dropdown';

export type TableauVizFilter = {
  field: string;
  value?: string;
};

export type TableauWrapperProps = {
  blockInteraction?: Boolean;
  className?: string;
  filters?: TableauVizFilter[];
  height?: string;
  hideTabs?: Boolean;
  showToolbar?: Boolean;
  title?: string;
  titlePosition?: 'upper' | 'lower';
  url: string;
  width?: string;
  dropdownFilter?: DropdownProps;
  token: string;
};

type VizElement = HTMLElement | null | undefined;

const TableauWrapper = ({
  blockInteraction = false,
  className,
  filters = [],
  height = '100px',
  width = 'auto',
  hideTabs = true,
  showToolbar = false,
  title,
  titlePosition = 'upper',
  url,
  dropdownFilter,
  token,
}: TableauWrapperProps) => {
  const toolbarAttr = showToolbar ? '' : 'toolbar="hidden"';
  const hideTabsAttr = hideTabs ? 'hide-tabs' : '';
  const interactionBlock = blockInteraction ? (
    <div className="absolute z-10 h-full w-full" />
  ) : null;
  const vizFilters = filters.map(
    (filter) =>
      `<viz-filter field="${filter.field}" value="${filter.value}"></viz-filter>`,
  );

  const isTitleUpper = title && titlePosition === 'upper';
  const isTitleLower = title && titlePosition === 'lower';
  const containerRef = useRef<HTMLDivElement>(null);

  function resizeTableauEmbed() {
    const container = containerRef.current;
    const tableauViz: VizElement =
      containerRef.current?.querySelector('tableau-viz');

    if (container) {
      const { height: containerHeight, width: containerWidth } =
        container.getBoundingClientRect();

      if (tableauViz) {
        tableauViz.setAttribute('height', `${containerHeight}px`);
        tableauViz.setAttribute('width', `${containerWidth}px`);
      }
    }
  }

  const debResizeTableauEmbed = useDebouncedCallback(resizeTableauEmbed, 1000, {
    trailing: true,
  });

  useEffect(() => {
    const viz: VizElement = containerRef.current?.querySelector('tableau-viz');

    debResizeTableauEmbed();
    window.addEventListener('resize', debResizeTableauEmbed);

    return () => {
      window.removeEventListener('resize', debResizeTableauEmbed);
      if (viz) {
        viz.removeEventListener('firstinteractive', () => {
          debResizeTableauEmbed();
        });
      }
    };
  }, [debResizeTableauEmbed, filters, url]);

  return (
    <div
      className={`w-fit overflow-clip rounded-lg border border-gray-200 p-2 ${className}`}
      data-testid="tableau-wrapper"
      style={{ width }}
    >
      {isTitleUpper && (
        <div className="flex justify-between">
          <h3 className="py-2 text-base font-medium text-gray-900">{title}</h3>
          {dropdownFilter && <Dropdown {...dropdownFilter} />}
        </div>
      )}
      <div
        className="relative flex"
        data-testid="tableau-container"
        ref={containerRef}
        style={{ width, height }}
      >
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
            <tableau-viz
            data-testid="tableau-embed"
            src="${url}"
            token="${token}"
            ${hideTabsAttr}
            ${toolbarAttr}
          >
            ${vizFilters.join('')}
          </tableau-viz>
          `,
          }}
        />
        {interactionBlock}
      </div>
      {isTitleLower && (
        <h3 className="p-2 text-base font-medium text-gray-900">{title}</h3>
      )}
    </div>
  );
};

export default TableauWrapper;
