import client, { AUDIENCE_BASE_URL } from 'services/kardAPI';

const fetchAudiences = async (filters = '') => {
  try {
    const { data } = await client.get(
      `${AUDIENCE_BASE_URL}/portal/audience${filters}`,
    );
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export default fetchAudiences;
