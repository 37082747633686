export const defaultStatusOptions = [
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
];

export const defaultOfferStatusOptions = [
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Upcoming',
    value: 'UPCOMING',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
];

export const closedStatusOptions = [
  {
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
];

// Currently This is only used for Merchants.
export const deletedStatusOptions = [
  {
    label: 'Deleted',
    value: 'DELETED',
  },
];
