import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FormControlSelect from 'components/Common/FormControlSelect';
import { selectFetchMerchantIssuersState } from 'state/selectors/merchants';

import { fetchMerchantIssuers } from 'state/actions/merchants';
import StatusValue from 'enums/status/statusValue.enum';

import classes from './OfferEdit.module.scss';

const usePrevious = <T extends unknown>(value: T, defaultValue: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current || defaultValue;
};

type CommissionValueFieldProps = {
  currentValue?: { qualifiedIssuer: string[] };
  isEditing?: boolean;
  editableSelectColor?: string;
};

const QualifiedIssuersField = ({
  currentValue,
  isEditing,
  editableSelectColor,
}: CommissionValueFieldProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMerchantIssuers());
  }, [dispatch]);
  const { issuers, loading: loadingIssuers } = useSelector<
    any,
    { issuers: string[]; loading: boolean }
  >(selectFetchMerchantIssuersState, shallowEqual);

  const issuersOptions =
    issuers?.map((category) => ({
      label: category,
      value: category,
    })) || [];

  const { register, unregister, watch } = useFormContext();
  const qualifiedIssuer = watch('qualifiedIssuer', []) as string[];
  const status = watch('status') as string;
  const prevQualifiedIssuer = usePrevious(qualifiedIssuer, []);

  useEffect(() => {
    const issuersAdded = prevQualifiedIssuer.length < qualifiedIssuer?.length;
    if (issuersAdded) {
      const newIssuers = qualifiedIssuer.filter(
        (issuer) => !prevQualifiedIssuer.includes(issuer),
      );
      newIssuers.forEach((issuer) => {
        register(`issuerCommissionsMap.${issuer}`);
        register(`userCommissionsMap.${issuer}`);
      });
    }
  }, [qualifiedIssuer, prevQualifiedIssuer, register, unregister]);

  return (
    <FormControlSelect
      name="qualifiedIssuer"
      defaultValue={currentValue?.qualifiedIssuer}
      label="Qualified Issuer(s):"
      labelClassName={classes.labelWidth}
      readOnly={!isEditing}
      disabled={loadingIssuers || !isEditing || status === StatusValue.Closed}
      options={issuersOptions}
      isMulti
      color={editableSelectColor}
      testId="qualified-issuer-input"
      className="mb-[0]"
    />
  );
};

export default QualifiedIssuersField;
