import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { DateRangePicker } from 'react-dates';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SortDirection from 'refactored/enums/query/sortDirection.enum';

/* styles & assets */
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import classes from './SelectDateRange.module.scss';

const SelectDateRange = ({
  label,
  query,
  updateQuery,
  fieldName,
  restrictToPastDates,
  dataCy,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null); // START_DATE, END_DATE
  const [displayLabel, setDisplayLabel] = useState(label);

  const onChangeDatesHandler = ({ startDate, endDate }) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const applyRange = () => {
    if (!selectedStartDate || !selectedEndDate) {
      return;
    }

    selectedStartDate.utcOffset(0);
    selectedStartDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    selectedEndDate.utcOffset(0);
    selectedEndDate.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 59,
    });

    const newQuery = query;
    newQuery.pagination = { page: 0, limit: 100 };

    newQuery.filters = {
      ...newQuery.filters,
      [fieldName]: {
        type: 'date',
        start: selectedStartDate,
        end: selectedEndDate,
      },
    };

    setDisplayLabel(
      `${label}: ${selectedStartDate.format(
        'MM/DD/YYYY',
      )} - ${selectedEndDate.format('MM/DD/YYYY')}`,
    );

    updateQuery(newQuery);
  };

  const handleClear = () => {
    const newQuery = { ...query };

    if (selectedStartDate || selectedEndDate) {
      delete newQuery.filters[fieldName];
    }

    if (newQuery.filters && Object.keys(newQuery.filters).length === 0) {
      delete newQuery.filters; // remove filters if empty
    }

    updateQuery(newQuery);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setDisplayLabel(label);
  };

  const isOutsideRange = (date) => {
    if (restrictToPastDates) {
      return new Date() < date;
    }

    return false;
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className={classes.button} data-cy={dataCy}>
            <div>{displayLabel}</div>
            <img
              alt="arrow"
              className={classNames(classes.arrowIcon, {
                [classes.arrowIconOpen]: open,
              })}
              src={arrowDownIcon}
            />
          </Popover.Button>
          <Popover.Panel className={classes.panelContainer}>
            <div className={classes.panelContent}>
              <div className={classes.inputsContainer}>
                <DateRangePicker
                  startDate={selectedStartDate}
                  startDateId="start_date_id"
                  endDate={selectedEndDate}
                  endDateId="end_date_id"
                  onDatesChange={onChangeDatesHandler}
                  focusedInput={focusedInput}
                  onFocusChange={(newFocus) => setFocusedInput(newFocus)}
                  noBorder
                  isOutsideRange={(date) => isOutsideRange(date)}
                  hideKeyboardShortcutsPanel
                />
              </div>
            </div>
            <div className={classes.actions}>
              <Popover.Button
                type="button"
                className={classes.secondaryButton}
                onClick={handleClear}
              >
                Clear filter
              </Popover.Button>
              <Popover.Button
                type="button"
                className={classes.actionButton}
                onClick={applyRange}
              >
                Apply filter
              </Popover.Button>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default SelectDateRange;

SelectDateRange.propTypes = {
  label: PropTypes.string,
  query: PropTypes.shape({
    pagination: PropTypes.shape({
      limit: PropTypes.number,
      page: PropTypes.number,
    }),
    filters: PropTypes.shape({}),
    sort: PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.oneOf(Object.values(SortDirection)),
    }),
  }),
  updateQuery: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  restrictToPastDates: PropTypes.bool,
  dataCy: PropTypes.string,
};

SelectDateRange.defaultProps = {
  label: '',
  query: {},
  updateQuery: () => {},
  restrictToPastDates: false,
  dataCy: 'SelectDateRanges',
};
