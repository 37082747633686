import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = (state) => state.auth;

export const selectSignInState = createSelector(
  selectAuthState,
  ({ signInLoading, signInSuccess, signInError }) => ({
    loading: signInLoading,
    success: signInSuccess,
    error: signInError,
  }),
);

export const selectPasswordChangeRequiredState = createSelector(
  selectAuthState,
  ({ passwordChangeRequired, passwordChangeSuccess }) => ({
    passwordChangeRequired,
    passwordChangeSuccess,
  }),
);

export const selectChangePasswordState = createSelector(
  selectAuthState,
  ({ passwordChangeLoading, passwordChangeSuccess, passwordChangeError }) => ({
    loading: passwordChangeLoading,
    success: passwordChangeSuccess,
    error: passwordChangeError,
  }),
);

export const selectAuthenticatedState = createSelector(
  selectAuthState,
  ({ user }) => ({ isAuthenticated: !!user }),
);

export const selectForgotPasswordState = createSelector(
  selectAuthState,
  ({
    forgotPasswordLoading,
    forgotPasswordSuccess,
    forgotPasswordError,
    username,
  }) => ({
    loading: forgotPasswordLoading,
    success: forgotPasswordSuccess,
    error: forgotPasswordError,
    username,
  }),
);

export const selectForgotPasswordConfirmationState = createSelector(
  selectAuthState,
  ({
    forgotPasswordConfirmationLoading,
    forgotPasswordConfirmationSuccess,
    forgotPasswordConfirmationError,
  }) => ({
    loading: forgotPasswordConfirmationLoading,
    success: forgotPasswordConfirmationSuccess,
    error: forgotPasswordConfirmationError,
  }),
);

export const selectUserAttributesState = createSelector(
  selectAuthState,
  ({ user }) => user ?? {},
);

export const selectResendConfirmationCodeState = createSelector(
  selectAuthState,
  ({
    resendConfirmationCodeLoading,
    resendConfirmationCodeSuccess,
    resendConfirmationCodeError,
  }) => ({
    loading: resendConfirmationCodeLoading,
    success: resendConfirmationCodeSuccess,
    error: resendConfirmationCodeError,
  }),
);

export const selectSubdomainConfig = createSelector(
  selectAuthState,
  ({ subdomainAuthConfig }) => subdomainAuthConfig ?? {},
);

export const selectSubdomain = createSelector(
  selectAuthState,
  ({ subdomain }) => subdomain ?? '',
);
