import client, { USERS_BASE_URL } from 'services/kardAPI';

const fetchPortalUsers = async (filters = '') => {
  try {
    const users = await client.get(
      `${USERS_BASE_URL}/portal/cognito/users${filters}`,
    );
    return users.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchPortalUsers;
