import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/** custom hooks */
import useQuery from 'refactored/hooks/useQuery';
import { useSegments } from 'refactored/hooks/useSegment';

/** custom components */
import PageHeader from 'components/Common/PageHeader';
import { selectUserAttributesState } from 'state/selectors/auth';
import Toast, { Type as ToastType } from 'components/Common/Toast';

/* utils */
import makeColumns from 'refactored/utils/segments/makeColumns';
import Roles from 'enums/users/roles.enum';

import TableConfig from 'enums/table/table.config';

import CreateSegment from '@/components/Pages/Segments/CreateSegment';
import LayoutGap from '@/components/Common/LayoutGap';
import { pick } from 'lodash';
import { DataTable } from '../../../components/ui/data-table';
import { PaginationCursor } from '../../../components/ui/pagination-cursor';
import type { CursorQuery } from '../../../types';

export type Segment = {
  segmentId: string;
  name: string;
  status: string;
  size: number;
  created: string;
};

const Segments = () => {
  const [query, updateQuery] = useQuery<CursorQuery>({
    pagination: { limit: TableConfig.LIMIT },
  });

  // Keep track of previous keys for back navigation
  const [previousKeys, setPreviousKeys] = useState<string[]>([]);

  const [
    {
      data: { results: segments, nextKey, previousKey } = {},
      error: errorFetchSegments,
      loading: loadingFetchSegments,
    },
  ] = useSegments({
    ...query.pagination,
    includeRules: true,
  });

  useEffect(() => {
    if (previousKey && previousKeys.length === 0) {
      setPreviousKeys([previousKey]);
    }
  }, [previousKey, previousKeys, setPreviousKeys]);

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  /* table columns */
  const columns = useMemo(() => {
    const allColumns = makeColumns({
      onSuccess: () => {
        updateQuery({
          pagination: { limit: TableConfig.LIMIT },
        });
      },
    });

    const filteredColumns = allColumns.filter((column) => {
      const allowedRoles = column.meta?.allowedRoles;

      if (!allowedRoles) return true;

      // Convert user roles to Roles enum values
      const userRolesEnum = userRoles
        .map((role: string) => {
          const normalizedRole = role.toLowerCase();

          switch (normalizedRole) {
            case Roles.KardAdmin.toLowerCase():
              return Roles.KardAdmin;
            case Roles.DataAdmin.toLowerCase():
              return Roles.DataAdmin;
            case Roles.KardReporter.toLowerCase():
              return Roles.KardReporter;
            case Roles.Issuer.toLowerCase():
              return Roles.Issuer;
            default:
              return null;
          }
        })
        .filter(Boolean) as Roles[];

      const hasAccess = userRolesEnum.some((userRole) =>
        allowedRoles.includes(userRole),
      );

      return hasAccess;
    });

    return filteredColumns;
  }, [userRoles, updateQuery]);

  const data = useMemo(
    () =>
      segments?.map((segment) => {
        const { id: segmentId, ...rest } = pick(segment, [
          'id',
          'name',
          'status',
          'size',
          'created',
        ]);

        return { segmentId, ...rest };
      }),
    [segments],
  );

  const handleNextPage = () => {
    if (!nextKey) return;

    // Save current key before moving to next page
    const currentKey = query.pagination?.startKey;
    if (currentKey) {
      setPreviousKeys((prev) => [...prev, currentKey]);
    }

    updateQuery({
      pagination: {
        limit: TableConfig.LIMIT,
        startKey: JSON.stringify(nextKey),
      },
    });
  };

  const handlePreviousPage = () => {
    // Get the last key from the stack
    const newPreviousKeys = [...previousKeys];
    const startKey = newPreviousKeys.pop();
    setPreviousKeys(newPreviousKeys);

    updateQuery({
      pagination: {
        limit: TableConfig.LIMIT,
        startKey,
      },
    });
  };

  return (
    <>
      {errorFetchSegments && (
        <Toast
          id="fetch segments"
          text="Error fetching the segments"
          type={ToastType.Error}
        />
      )}

      <LayoutGap direction="column">
        <PageHeader title="Segments" className="mx-6 mt-4">
          <CreateSegment
            key="new-create-segment"
            onSuccess={() => {
              updateQuery({
                pagination: { limit: TableConfig.LIMIT },
              });
            }}
          />
        </PageHeader>

        <div className="relative h-[calc(100vh-11rem)] overflow-y-auto">
          <div className="min-w-full">
            <DataTable
              columns={columns}
              data={data || []}
              loading={loadingFetchSegments}
              emptyMessage="No segments found"
              error={errorFetchSegments}
            />
          </div>
          <div className="sticky bottom-0 flex items-center justify-center border-t bg-white px-6 py-2">
            <PaginationCursor
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              hasNextPage={!!nextKey}
              hasPreviousPage={!!previousKey}
              isLoading={loadingFetchSegments}
            />
          </div>
        </div>
      </LayoutGap>
    </>
  );
};

export default Segments;
