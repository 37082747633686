enum Status {
  ACTIVE = 'active',
  active = 'ACTIVE',
  INACTIVE = 'inactive',
  inactive = 'INACTIVE',
  CLOSED = 'closed',
  closed = 'CLOSED',
  DELETED = 'deleted',
  deleted = 'DELETED',
  DISABLED = 'disabled',
  disabled = 'DISABLED',
  DRAFT = 'draft',
  draft = 'DRAFT',
  IN_PROGRESS = 'in progress',
  'in progress' = 'IN_PROGRESS',
}

export default Status;
