/**
 * Commission values are rounded to 1 decimal place. Converting them to integers before doing
 * math operations and then converting back to decimal helps avoid rounding errors.
 */
const DECIMAL_PLACES = 1;
const DECIMAL_OFFSET = 10 ** DECIMAL_PLACES;

export const subtract = (a: number, b: number): number =>
  (a * DECIMAL_OFFSET - b * DECIMAL_OFFSET) / DECIMAL_OFFSET;

/**
 * We can't confirm IssuerName is a key of CommissionMap, so for now it's
 * T | string. It's functionally just a string, but the generic type adds a bit
 * of clarity for intention. Ideally, it would be just T.
 */
type IssuerName<T extends string> = T | string;

type CommissionMap<T extends string> = {
  [key in IssuerName<T>]: number;
};

type CommissionSplit = {
  issuersCommission: number;
  usersCommission?: number;
  totalCommission: number;
};

const getCommissionSplit = <T extends string>(
  issuerCommissionsMap: CommissionMap<T>,
  userCommissionsMap: CommissionMap<T> | undefined,
  issuerName: IssuerName<T>,
): CommissionSplit => {
  const totalCommission = issuerCommissionsMap?.[issuerName] ?? 0;
  const usersCommission = userCommissionsMap?.[issuerName];

  const issuersCommission = subtract(totalCommission, usersCommission || 0);

  return {
    issuersCommission,
    usersCommission,
    totalCommission,
  };
};

export default getCommissionSplit;
