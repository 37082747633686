import classNames from 'classnames';

import Button from 'components/Common/Button';
import FormControlSelect, {
  Color as SelectColor,
} from 'components/Common/FormControlSelect';
import Input, {
  Type as InputType,
  Size as InputSize,
  Color as InputColor,
} from 'components/Common/Input';
import Checkbox, { Size as CheckboxSize } from 'components/Common/Checkbox';
import {
  behaviorOptions,
  behaviorRangeOptions,
  periodOptions,
  timescaleOptions,
  behaviorTimeframeValues,
  logicOperatorOptions,
} from 'utils/audiences/values';

import classes from './RuleBuilder.module.scss';

const getInputFields = ({
  selectedRule,
  enrollNewUser,
  refreshAudience,
  selectedMerchant,
  audienceIsEditable,
  onChangeSelectedMerchantHandler,
  onChangePeriodValueHandler,
  onSelectEnrollNewUserCheckBoxHandler,
  onSelectRefreshAudienceCheckBoxHandler,
  inputVisible,
  onSelectTimeframe,
  flags,
  showDeleteButtons,
  onDeleteButtonHandler,
  onAddNewCriteria,
}) => {
  const merchantOptions = [
    ...(selectedMerchant
      ? [
          {
            label: 'Selected merchant',
            options: [
              {
                label: selectedMerchant.name,
                value: selectedMerchant._id,
              },
            ],
          },
        ]
      : []),
    {
      label: 'Choose merchant',
      options: [{ label: 'All Merchants..', value: 'allMerchants' }],
    },
  ];

  const criteriaRows = [];
  const criteriaExtraSpanRowOne = [];
  const extraCriteriaRow = [];
  const checkboxRows = [];

  if (flags?.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
    criteriaRows.push({
      id: '2',
      mainWrapperClassName: classes.compact,
      fields: [
        {
          component: 'span',
          children: 'Users with',
          wrapperClassName: classes.labelUsers,
        },
        {
          component: FormControlSelect,
          name: 'behaviorRange.0',
          wrapperClassName: '!w-[15rem]',
          className: classNames(classes.behaviorSelectLogic, '!m-0 !w-[200px]'),
          defaultValue: selectedRule.behaviorRange?.[0],
          options: behaviorRangeOptions,
          color: SelectColor.Gray,
          placeholder: 'Select range',
          noErrorMessage: true,
          disabled: !audienceIsEditable,
          testId: 'range-input',
        },
        {
          name: 'behaviorValue.0',
          noErrorMessage: true,
          component: Input,
          wrapperClassName: '!w-[10rem]',
          renderProps: {
            className: classNames(
              classes.behaviorValueCompact,
              '!w-[4.75rem] relative right-px',
              {
                [classes.disabledInput]: !audienceIsEditable,
              },
            ),
            value: selectedRule.behaviorValue?.[0],
            type: InputType.Number,
            size: InputSize.S,
            color: InputColor.Gray,
            minNumber: 0,
            readOnly: !audienceIsEditable,
          },
        },
        {
          component: 'span',
          children: 'purchase(s) in',
          wrapperClassName: 'w-[10rem]',
        },
        {
          component: FormControlSelect,
          name: 'timescale.0',
          className: classNames(classes.behaviorSelectTime, '!m-0'),
          defaultValue: selectedRule.timescale?.[0],
          options: Object.values(behaviorTimeframeValues),
          controlClassName: '!w-[100px]',
          color: SelectColor.Gray,
          placeholder: 'Select timeframe',
          noErrorMessage: true,
          testId: 'timeframe-input',
          onChangeManual: (evt) => onSelectTimeframe(evt, 0),
          disabled: !audienceIsEditable,
        },
        {
          hidden: !inputVisible[0],
          name: 'timescaleValue.0',
          noErrorMessage: true,
          component: Input,
          renderProps: {
            className: classNames(
              classes.timescaleValueCompact,
              'ml-6 top-0 !w-[90px]',
              {
                [classes.disabledInput]: !audienceIsEditable,
              },
            ),
            value: selectedRule.timescaleValue?.[0],
            type: InputType.Number,
            size: InputSize.S,
            color: InputColor.Gray,
            minNumber: 0,
            readOnly: !audienceIsEditable,
          },
        },
        {
          hidden: !inputVisible[0],
          component: FormControlSelect,
          className: classNames(
            classes.daySelect,
            '!w-[100px] !m-0 !-ml-[4px]',
          ),
          controlClassName: '!w-[100px]',
          name: 'period.0',
          defaultValue: selectedRule.period?.[0],
          options: periodOptions,
          color: SelectColor.Gray,
          noErrorMessage: true,
          disabled: !audienceIsEditable,
          onChangeManual: onChangePeriodValueHandler,
          testId: 'period-input',
        },
        {
          hidden: !showDeleteButtons,
          component: Button,
          wrapperClassName: classes.deleteCriteriaButtonWrapper,
          className: classNames(
            classes.deleteCriteriaButton,
            'relative bottom-px -ml-[3px]',
          ),
          variant: 'secondary',
          onClick: () => onDeleteButtonHandler(0),
          disabled: !audienceIsEditable,
        },
      ],
    });

    criteriaExtraSpanRowOne.push({
      id: '2a',
      mainWrapperClassName: classes.wrapperlabelTimeFrame,
      fields: [
        {
          component: 'span',
          children: '*Timeframe must be less than 2 years',
          wrapperClassName: classes.labelTimeFrame,
        },
      ],
    });

    extraCriteriaRow.push({
      id: '2b',
      mainWrapperClassName: classes.compact,
      fields: !showDeleteButtons
        ? [
            {
              component: Button,
              wrapperClassName: 'block',
              children: '+ Add Criteria',
              variant: 'secondary',
              onClick: onAddNewCriteria,
            },
          ]
        : [
            {
              component: FormControlSelect,
              name: 'logicOperator',
              wrapperClassName: 'w-fit',
              className: '-ml-1 w-[5.3rem]',
              defaultValue:
                selectedRule.logicOperator || logicOperatorOptions[0].value,
              options: logicOperatorOptions,
              color: SelectColor.Gray,
              placeholder: 'Select',
              noErrorMessage: true,
              disabled: !audienceIsEditable,
              testId: 'logic-operator-input',
            },
            {
              component: FormControlSelect,
              name: 'behaviorRange.1',
              wrapperClassName: 'w-fit pl-2',
              className: '-ml-[3px] !w-[14.6rem]',
              defaultValue: selectedRule.behaviorRange?.[1],
              options: behaviorRangeOptions,
              color: SelectColor.Gray,
              placeholder: 'Select range',
              noErrorMessage: true,
              disabled: !audienceIsEditable,
              testId: 'range-input-1',
            },
            {
              name: 'behaviorValue.1',
              noErrorMessage: true,
              component: Input,
              renderProps: {
                className: classNames(
                  '!w-[4.8rem] relative bottom-2.5 -ml-[2px]',
                  {
                    [classes.disabledInput]: !audienceIsEditable,
                  },
                ),
                value: selectedRule.behaviorValue?.[1],
                type: InputType.Number,
                size: InputSize.S,
                color: InputColor.Gray,
                minNumber: 0,
                readOnly: !audienceIsEditable,
              },
            },
            {
              component: 'span',
              children: 'purchase(s) in',
              wrapperClassName: classes.labelUnwrapped,
            },
            {
              component: FormControlSelect,
              name: 'timescale.1',
              className: classes.behaviorSelectTime,
              defaultValue: selectedRule.timescale?.[1],
              options: Object.values(behaviorTimeframeValues),
              color: SelectColor.Gray,
              placeholder: 'Select timeframe',
              noErrorMessage: true,
              testId: 'timeframe-input',
              onChangeManual: (evt) => onSelectTimeframe(evt, 1),
              disabled: !audienceIsEditable,
            },
            {
              hidden: !inputVisible[1],
              name: 'timescaleValue.1',
              noErrorMessage: true,
              component: Input,
              renderProps: {
                className: classNames(
                  classes.timescaleValueCompact,
                  ' relative left-[5px] !w-[90px]',
                  {
                    [classes.disabledInput]: !audienceIsEditable,
                  },
                ),
                value: selectedRule.timescaleValue?.[1],
                type: InputType.Number,
                size: InputSize.S,
                color: InputColor.Gray,
                minNumber: 0,
                readOnly: !audienceIsEditable,
              },
            },
            {
              hidden: !inputVisible[1],
              component: FormControlSelect,
              name: 'period.1',
              className: classNames(classes.daySelect, '!w-[100px]'),
              defaultValue: selectedRule.period?.[1],
              options: periodOptions,
              color: SelectColor.Gray,
              noErrorMessage: true,
              disabled: !audienceIsEditable,
              onChangeManual: onChangePeriodValueHandler,
              testId: 'period-input-1',
            },
            {
              component: Button,
              wrapperClassName: classes.deleteCriteriaButtonWrapper,
              className: classNames(
                classes.deleteCriteriaButton,
                'relative bottom-3',
              ),
              variant: 'secondary',
              onClick: () => onDeleteButtonHandler(1),
              disabled: !audienceIsEditable,
            },
          ],
    });

    checkboxRows.push(
      {
        id: '3',
        mainWrapperClassName: classes.checkboxBottom,
        fields: [
          {
            component: Checkbox,
            name: 'willRefreshYes',
            label:
              'Set as dynamic audience (refresh audience based on above timeframe)',
            setSelected: onSelectRefreshAudienceCheckBoxHandler,
            selected: refreshAudience,
            className: classes.checkbox,
            size: CheckboxSize.S,
            disabled: !audienceIsEditable,
          },
        ],
      },
      {
        id: '4',
        mainWrapperClassName: classes.checkboxBottom,
        fields: [
          {
            component: Checkbox,
            name: 'enrollNewUser',
            label: 'Allow new cardholders to be added automatically',
            setSelected: onSelectEnrollNewUserCheckBoxHandler,
            selected: enrollNewUser,
            className: classes.checkbox,
            size: CheckboxSize.S,
            disabled: !audienceIsEditable,
          },
        ],
      },
    );
  }

  if (!flags.REWARDS_2447_FLEXIBLE_AUDIENCE_CREATION_FRONTEND) {
    criteriaRows.push(
      {
        id: '2',
        label: 'Purchase count',
        fields: [
          {
            component: FormControlSelect,
            name: 'behaviorRange',
            className: classes.behaviorSelect,
            defaultValue: selectedRule.behaviorRange,
            options: behaviorRangeOptions,
            color: SelectColor.Gray,
            placeholder: 'Select range',
            noErrorMessage: true,
            disabled: !audienceIsEditable,
            testId: 'range-input',
          },
          {
            name: 'behaviorValue',
            noErrorMessage: true,
            component: Input,
            renderProps: {
              className: classNames(classes.behaviorValue, {
                [classes.disabledInput]: !audienceIsEditable,
              }),
              value: selectedRule.behaviorValue,
              type: InputType.Number,
              size: InputSize.S,
              color: InputColor.Gray,
              minNumber: 0,
              readOnly: !audienceIsEditable,
            },
          },
        ],
      },
      {
        id: '3',
        label: 'Select timescale, between 7 days and 2 years',
        fields: [
          {
            component: FormControlSelect,
            name: 'timescale',
            className: classes.timescaleSelect,
            defaultValue: selectedRule.timescale,
            options: timescaleOptions,
            color: SelectColor.Gray,
            placeholder: 'Select timescale',
            noErrorMessage: true,
            disabled: !audienceIsEditable,
            testId: 'timescale-input',
          },
          {
            name: 'timescaleValue',
            noErrorMessage: true,
            component: Input,
            renderProps: {
              className: classNames(classes.timescaleValue, {
                [classes.disabledInput]: !audienceIsEditable,
              }),
              value: selectedRule.timescaleValue,
              type: InputType.Number,
              size: InputSize.S,
              color: InputColor.Gray,
              minNumber: 0,
              readOnly: !audienceIsEditable,
            },
          },
          {
            component: FormControlSelect,
            name: 'period',
            className: classes.daySelect,
            defaultValue: selectedRule.period,
            options: periodOptions,
            color: SelectColor.Gray,
            noErrorMessage: true,
            disabled: !audienceIsEditable,
            onChangeManual: onChangePeriodValueHandler,
            testId: 'period-input',
          },
        ],
      },
    );

    checkboxRows.push(
      {
        id: '4',
        fields: [
          {
            component: Checkbox,
            name: 'enrollNewUser',
            label: 'Allow new cardholders to be added automatically',
            setSelected: onSelectEnrollNewUserCheckBoxHandler,
            selected: enrollNewUser,
            className: classes.checkbox,
            size: CheckboxSize.S,
            disabled: !audienceIsEditable,
          },
        ],
      },
      {
        id: '5',
        label: 'Will this audience refresh',
        fields: [
          {
            component: Checkbox,
            name: 'willRefreshYes',
            label: 'Yes',
            setSelected: onSelectRefreshAudienceCheckBoxHandler,
            selected: refreshAudience,
            className: classes.checkbox,
            size: CheckboxSize.S,
            disabled: !audienceIsEditable,
          },
          {
            component: Checkbox,
            name: 'willRefreshNo',
            label: 'No',
            setSelected: onSelectRefreshAudienceCheckBoxHandler,
            selected: !refreshAudience,
            className: classes.checkbox,
            size: CheckboxSize.S,
            disabled: !audienceIsEditable,
          },
        ],
      },
    );
  }

  return [
    {
      id: '1',
      fields: [
        {
          title: 'What Behavior do you want to target?',
          component: FormControlSelect,
          className: classes.formControlSelect,
          name: 'behavior',
          defaultValue: selectedRule.behavior,
          options: behaviorOptions,
          color: SelectColor.Gray,
          placeholder: 'Select Behavior',
          noErrorMessage: true,
          disabled: !audienceIsEditable,
          testId: 'behavior-input',
        },
        {
          title: 'What Merchant is this for?',
          component: FormControlSelect,
          className: classes.formControlSelect,
          controlClassName: '!w-[300px]',
          name: 'merchant',
          defaultValue: selectedMerchant?._id,
          value: selectedMerchant?._id,
          onChangeManual: onChangeSelectedMerchantHandler,
          options: merchantOptions,
          color: SelectColor.Gray,
          placeholder: 'Select Merchant',
          noErrorMessage: true,
          disabled: !audienceIsEditable,
          testId: 'merchant-input',
        },
      ],
    },
    ...criteriaRows,
    ...criteriaExtraSpanRowOne,
    ...extraCriteriaRow,
    ...checkboxRows,
  ];
};

export default getInputFields;
