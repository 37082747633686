import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchBox from '../SearchBox';

const Search = ({
  query,
  updateQuery,
  field,
  className,
  placeholder,
  testId,
  showCancelIcon,
}) => {
  // this is a controlled search input value.
  const [searchValue, setSearchValue] = useState('');
  // sync searchValue with the query.
  useEffect(
    () => setSearchValue(query.filters?.[field]?.selected?.[0] ?? ''),
    [field, query.filters],
  );

  const search = (value) => {
    const filters = query?.filters || {};

    if (value.length === 0) {
      delete filters[field];
    }

    if (value.length > 0) {
      filters[field] = {
        type: 'search',
        selected: [value],
      };
    }

    const newQuery = Object.keys(filters).length > 0 ? { filters } : {};

    updateQuery(newQuery, ['pagination', 'filters']);
  };

  const onChangeHandler = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value === '' && query.filters?.[field]?.selected?.[0]) {
      search(event.target.value);
    }
  };

  const onEnterDownHandler = (event) => {
    search(event.target.value);
  };

  const onCancelHandler = () => {
    setSearchValue('');
    search('');
  };

  return (
    <SearchBox
      className={className}
      placeholder={
        placeholder ??
        `Search by ${field}` /* TODO: This exposes an internal id */
      }
      testId={testId}
      searchValue={searchValue}
      onChange={onChangeHandler}
      onEnterDown={onEnterDownHandler}
      showCancelIcon={showCancelIcon}
      onCancel={onCancelHandler}
    />
  );
};

Search.propTypes = {
  query: PropTypes.shape({
    filters: PropTypes.objectOf(
      PropTypes.shape({
        selected: PropTypes.arrayOf(PropTypes.string.isRequired),
      }),
    ),
  }).isRequired,
  updateQuery: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  showCancelIcon: PropTypes.bool,
};

Search.defaultProps = {
  className: undefined,
  placeholder: undefined,
  testId: null,
  showCancelIcon: false,
};

export default Search;
