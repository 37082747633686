import client, { REWARDS_BASE_URL } from 'services/kardAPI';

/**
 * @deprecated Use refactored/hooks/useMerchantNetworks instead
 */
const fetchMerchantNetwork = async () => {
  try {
    const merchantNetwork = await client.get(
      `${REWARDS_BASE_URL}/portal/network`,
    );
    return merchantNetwork;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchantNetwork;
