import { ReactNode } from 'react';
import classNames from 'classnames';

import LayoutGap from 'components/Common/LayoutGap';
import Heading, { Size as HeadingSize } from 'components/Typography/Heading';
import Body, { Size as BodySize } from 'components/Typography/Body';

export type EmptyStateProps = {
  className?: string;
  title: ReactNode;
  description: ReactNode;
};

const EmptyState = ({ className, title, description }: EmptyStateProps) => (
  <LayoutGap
    direction="column"
    size="small"
    className={classNames(
      'm-auto my-[5rem] max-w-[500px] text-center',
      className,
    )}
  >
    <Heading size={HeadingSize.S} className="!m-0">
      {title}
    </Heading>
    <Body size={BodySize.XS}>{description}</Body>
  </LayoutGap>
);

export default EmptyState;
