import { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import LoginRoute from 'components/Navigation/LoginRoute/index';
import AuthenticatedRoute from 'components/Navigation/AuthenticatedRoute';
import selectFeatureFlagsState from 'state/selectors/featureFlags';
import { fetchFeatureFlags } from 'state/actions/featureFlags';
import { resumeSession } from 'state/actions/auth';
import {
  selectAuthenticatedState,
  selectUserAttributesState,
} from 'state/selectors/auth';
import Roles from 'enums/users/roles.enum';
import NavigationLayout from 'components/Layouts/NavigationLayout';
import { getAuthenticatedRoutes, getLoginRoutes } from './paths';

const Router = () => {
  const dispatch = useDispatch();
  const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';

  useEffect(() => {
    dispatch(resumeSession());
  }, [dispatch]);

  const { isAuthenticated } = useSelector(
    selectAuthenticatedState,
    shallowEqual,
  );

  const { flags } = useSelector(selectFeatureFlagsState, shallowEqual);

  const { IS_1438_PENDING } = flags;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchFeatureFlags());
    }
  }, [dispatch, isAuthenticated]);

  const { roles: userRoles } = useSelector(
    selectUserAttributesState,
    shallowEqual,
  );

  const hasKardAdminRole = useMemo(
    () =>
      userRoles?.includes(Roles.KardAdmin) ||
      userRoles?.includes(Roles.DataAdmin),
    [userRoles],
  );

  const loginRoutes = getLoginRoutes(isAuthenticated);

  const authenticatedRoutes = getAuthenticatedRoutes({
    isAuthenticated,
    hasKardAdminRole,
    isPendingSummaryEnabled: IS_1438_PENDING,
  });

  // don't display the header or sidebar when the user is not authenticated
  const isFullscreen = useMemo(() => !isAuthenticated, [isAuthenticated]);

  return (
    <BrowserRouter>
      <NavigationLayout fullscreen={isFullscreen}>
        <Routes>
          {loginRoutes.map(({ component, condition, path }) => (
            <Route
              key={path}
              path={path}
              element={
                <LoginRoute
                  key={path}
                  component={component}
                  condition={condition}
                  redirectTo={redirectUrl}
                  path={path}
                />
              }
            />
          ))}

          {flags &&
            authenticatedRoutes.map(
              ({ component, condition, redirectTo, path }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <AuthenticatedRoute
                      key={path}
                      component={component}
                      condition={condition}
                      redirectTo={redirectTo}
                      path={path}
                    />
                  }
                />
              ),
            )}
        </Routes>
      </NavigationLayout>
    </BrowserRouter>
  );
};

export default Router;
