export const offerTypeFilterOptions = [
  { label: 'Instore', value: 'INSTORE' },
  { label: 'Online', value: 'ONLINE' },
];

export const offerStatusFilterOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Closed', value: 'CLOSED' },
  { label: 'Upcoming', value: 'UPCOMING' },
];

export const commissionTypeFilterOptions = [
  { label: 'Flat', value: 'FLAT' },
  { label: 'Percent', value: 'PERCENT' },
];

export const targetedOfferFilterOptions = [
  { label: 'Targeted', value: 'true' },
  { label: 'Not Targeted', value: 'false' },
];

export const merchantSourceFilterOptions = [
  { label: 'National', value: 'NATIONAL' },
  { label: 'Local', value: 'LOCAL' },
];
