import dollarIcon from 'assets/icons/dollar.png';
import percentIcon from 'assets/icons/percent.png';

export enum CommissionType {
  Percent = 'PERCENT',
  Flat = 'FLAT',
}

export const CommissionTypeIcon: Record<CommissionType, string> = {
  [CommissionType.Percent]: percentIcon,
  [CommissionType.Flat]: dollarIcon,
};

export default CommissionType;
