import {
  fetchMerchantNetworksInit,
  fetchMerchantNetworksSuccess,
  fetchMerchantNetworksFail,
  createMerchantNetworksInit,
  createMerchantNetworksSuccess,
  createMerchantNetworksFail,
  clearMerchantNetworksErrorsProps,
  clearMerchantNetworksSuccessProps,
} from 'state/actionCreators/merchantNetworks';

import fetchMerchantNetworksService from 'services/merchantNetworks/fetchMerchantNetwork';
import createMerchantNetworksService from 'services/merchantNetworks/createMerchantNetwork';

/**
 * @deprecated Use refactored/hooks/useMerchantNetworks instead
 */
export const fetchMerchantNetworks = () => async (dispatch) => {
  dispatch(fetchMerchantNetworksInit());

  try {
    const { data } = await fetchMerchantNetworksService();
    return dispatch(fetchMerchantNetworksSuccess({ merchantNetworks: data }));
  } catch (error) {
    return dispatch(fetchMerchantNetworksFail({ error: error.message }));
  }
};

export const createMerchantNetworks =
  (merchantNetworkData) => async (dispatch) => {
    dispatch(createMerchantNetworksInit());

    try {
      await createMerchantNetworksService(merchantNetworkData);
      return dispatch(createMerchantNetworksSuccess());
    } catch (error) {
      return dispatch(createMerchantNetworksFail({ error: error.message }));
    }
  };

export const clearMerchantNetworksErrors = () =>
  clearMerchantNetworksErrorsProps();

export const clearMerchantNetworksSuccess = () =>
  clearMerchantNetworksSuccessProps();
