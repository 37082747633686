import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Card } from 'flowbite-react';
import ErrorMessage from 'components/Typography/ErrorMessage';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button from 'components/Common/Button';
import Link from 'components/Typography/Link';
import Path from 'enums/path.enum';
import { forgotPassword } from 'state/actions/auth';
import { selectForgotPasswordState } from 'state/selectors/auth';
import kardLogo from 'assets/logos/kard-blue.svg';

import emailValidationSchema from './ForgotPasswordFormSchema.schema';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);

  const { loading, error } = useSelector(
    selectForgotPasswordState,
    shallowEqual,
  );

  const onSubmitHandler = useCallback(
    ({ email }) => {
      dispatch(forgotPassword(email));
    },
    [dispatch],
  );

  return (
    <div className="mb-72 flex w-[499px] flex-col gap-4 self-stretch">
      <div className="flex items-center justify-center gap-10 pb-6">
        <img className="h-9 w-24" src={kardLogo} alt="kard logo" />
      </div>
      <Card className="rounded-lg shadow-custom">
        <div className="flex flex-col items-start">
          <p className="text-primaryBlack text-2xl font-[700] leading-tight">
            Reset your password
          </p>
          <p className="inline self-stretch text-sm font-medium leading-5 text-gray-500">
            Enter the email address associated with your account and we’ll send
            you a code to reset your password.
          </p>
        </div>
        <Form
          validationSchema={emailValidationSchema}
          validationMode={ValidationMode.OnChange}
          onSubmit={onSubmitHandler}
          setValid={setValid}
        >
          <FormControl
            name="email"
            className="mb-6"
            render={(props) => (
              <>
                <h1 className="pb-2 text-sm">Email</h1>
                <Input
                  placeholder="name@example.com"
                  type={InputType.Email}
                  isFullScreen
                  {...props}
                />
              </>
            )}
          />
          {error && (
            <ErrorMessage className="relative bottom-5 mb-0 w-[29rem]">
              {error}
            </ErrorMessage>
          )}
          <div className="flex flex-col gap-6">
            <Button type="submit" loading={loading} disabled={!valid}>
              Reset password
            </Button>

            <div className="flex w-full items-center justify-center self-stretch text-right text-blue-800">
              <Link to={Path.Home}>
                <p className="w-[120px] text-center text-sm leading-normal">
                  Back to log in
                </p>
              </Link>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPasswordForm;
