const allFilters = [
  'rewardType',
  'validReward',
  'underReview',
  'status',
  'createdDate',
  'receivedPaymentStatus',
  'paidToIssuer',
  'referringPartnerUserId',
  'cardBIN',
  'cardLastFour',
  // 'merchantName',
  'isFirstMerchantOffer',
  'merchantNetwork',
  'offerId',
  'amount',
  'receivedCommissionInCents',
  'commissionToIssuer',
  'authorizationDate',
  'settledDate',
  'merchantAddrCity',
  'merchantAddrState',
  'merchantAddrCountry',
  'offer.commissionType',
];

export default allFilters;
