import client, { TXN_BASE_URL } from 'services/kardAPI';

const fetchAuditById = async (id, issuerId) => {
  try {
    const config = {
      headers: {
        'X-Kard-IssuerID': issuerId,
      },
    };

    const { data } = await client.get(
      `${TXN_BASE_URL}/portal/audit/${id}`,
      config,
    );

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchAuditById;
