import { ComponentProps } from 'react';
import { Toaster as Sonner } from 'sonner';
import {
  CheckIcon,
  TriangleAlertIcon,
  InfoIcon,
  OctagonAlertIcon,
} from 'lucide-react';

type ToasterProps = ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => (
  <Sonner
    duration={10_000}
    toastOptions={{
      unstyled: true,
      classNames: {
        toast:
          'shadow-lg rounded-lg p-5 flex items-center gap-5 min-w-[20em] max-w-[40em] border border-gray-200',
        success: 'bg-green-50 text-green-500  border-green-200',
        info: 'bg-blue-50 text-blue-500  border-blue-200',
        warning: 'bg-yellow-50 text-yellow-500  border-yellow-200',
        error: 'bg-red-50 text-red-500  border-red-200',
        title: 'text-md font-medium',
        description: 'text-sm',
      },
    }}
    icons={{
      success: <CheckIcon size="1em" />,
      info: <InfoIcon size="1em" />,
      warning: <TriangleAlertIcon size="1em" />,
      error: <OctagonAlertIcon size="1.2em" />,
    }}
    {...props}
  />
);

export { Toaster };
