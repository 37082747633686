import client, { CATEGORIES_BASE_URL } from 'services/kardAPI';

const fetchMerchantCategories = async () => {
  try {
    const categoriesData = await client.get(
      `${CATEGORIES_BASE_URL}/portal/categories`,
    );
    const categories = categoriesData.data.map(
      (category) => category.primaryCategory,
    );
    return categories;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fetchMerchantCategories;
