import { useNavigate } from 'react-router-dom';
import MerchantCreate from 'components/Pages/MerchantCreate';
import Path from 'enums/path.enum';

const AddMerchant = () => {
  const navigate = useNavigate();
  const onCancelHandler = () => navigate(Path.Merchants);

  return (
    <div className="pl-10 pt-10">
      <MerchantCreate onCancel={onCancelHandler} />
    </div>
  );
};

export default AddMerchant;
