import dayjs from 'dayjs';

import { audienceType, behaviorRangeValues } from '../values';

export function getBehaviorRange(audience) {
  if (audience.transactionCount?.eq !== undefined) {
    return behaviorRangeValues.ExactlyEqualTo;
  }
  if (audience.transactionCount?.gte !== undefined) {
    return behaviorRangeValues.GreaterThanOrEqualTo;
  }

  if (audience.transactionCount?.lte !== undefined) {
    return behaviorRangeValues.LessThanOrEqualTo;
  }

  return '';
}

export function getBehaviorValue(audience) {
  if (audience.transactionCount?.eq !== undefined) {
    return audience.transactionCount.eq;
  }

  if (audience.transactionCount?.gte !== undefined) {
    return audience.transactionCount.gte;
  }

  if (audience.transactionCount?.lte !== undefined) {
    return audience.transactionCount.lte;
  }

  return null;
}

const getAudienceRuleData = (audience, merchant) => {
  if (audience) {
    const behaviorValue = getBehaviorValue(audience);

    const behaviorRange = getBehaviorRange(audience);

    const refreshAudience = audience.type === audienceType.Dynamic;

    const getTimescaleValue = () => {
      const rangeDates = audience.query?.compound?.must?.find(
        ({ range }) => range?.path === 'authorizationDate',
      );
      const startDate = dayjs(rangeDates?.range?.gte);
      const endDate = dayjs(rangeDates?.range?.lte);

      return endDate.diff(startDate, 'days');
    };

    return {
      behaviorValue,
      behaviorRange,
      period: 'days',
      refreshAudience,
      timescale: 'Last',
      behavior: 'Purchases',
      merchant,
      timescaleValue: getTimescaleValue(),
    };
  }

  return {};
};

export default getAudienceRuleData;
